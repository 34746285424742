import { NgModule }      from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicModalContainerComponent }  from './dynamic-modal-loader.component';
//import { ModalModule } from 'ng2-modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import {OkCancelDialogComponent} from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { LandingAlertDialogComponent } from './landing-alert-component/landing-alert.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
//import { VideoDialogComponent } from './video-dialog-component/video-dialog.component';
import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
import { TermsDialogComponent } from './terms-dialog-component/terms-dialog.component';
import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct.component';
//import { CustomizeReportComponent } from './customize-report-component/customize-report.component';
//import { CoverPageTitleEditComponent } from './cover-page-title-component/cover-page-title.component';
import { AddCustomActionComponent } from './add-custom-action-component/add-custom-action.component';
import { ModalDialogService } from './modal-dialog.service';
import { BrowserModule } from '@angular/platform-browser';
import { PipeModule } from '../pipe/pipe.module';
import { RestoreActionComponent } from './restore-action-component/restore-action.component';
import { DirectiveModule } from './../directive/directive.module' ;
//import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { VideoDialogHottopicComponent } from './video-dialog-hottopics-component/video-dialog-hottopics.component'
import { ComponentModule } from './../component/component.module';
import { ActionDetailComponent } from './action-detail-component/action-detail.component';
import { MyDatePickerModule } from 'mydatepicker';
//import { ProgressBarModule } from '../progress-bar-module/progress-bar.module';
//import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
import { HelpTopicsComponent } from "./help-topics-component/help-topics.component";
import { SaveCancelDialogComponent } from "./save-cancel-dialog-component/save-cancel-dialog.component";
import { DatePipe } from '@angular/common';
//import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { LandingDialogComponent } from './landing-page-component/landing-page.component';
import { VideoDialogLandingPageComponent } from './video-dialog-landingPage-component/video-dialog-landingPage.component';
import { InsightModalComponent } from './insight-component/insight-component';
//import { ThemeDialogComponent } from './theme-dialog-component/theme-dialog.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { addNotesComponent } from './add-notes-component/add-notes.component';
import { addNotesAssesmentComponent } from './add-notes-assesment/add-notes-assesment.component';
import { CreateNewGrowthPlanComponent } from './create-new-growth-plan-component/create-new-growth-plan.component';
////import { F2FLoginComponent } from './f2f-login-component/f2f-login.component';
////import { facilitatorCodeComponent } from './f2f-login-component/facilitator-code/facilitator-code.component';
////import { selectMemberComponent } from './f2f-login-component/select-member/select-member.component';
////import { AttendessInfoComponent } from './f2f-login-component/attendees-info/attendees-info.component';
////import { ConfirmationComponent } from './f2f-login-component/confirmation/confirmation.component';
import { F2FInfoDialogComponent } from './f2f-info-dialog-component/f2f-info-dialog-component';
//import { InfoAttendessComponent } from './f2f-info-dialog-component/info-attendees/info-attendees.component';
import { F2FCodeVerificationComponent } from './f2f-code-verification-component/f2f-code-verification.component';
//import { F2FDuplicateGrowthPlanComponent } from './f2f-duplicate-growth-plan/f2f-duplicate-growth-plan.component';
import { CorporateParticipantInfoDialogComponent } from './corporate-participant-info-dialog-component/corporate-participant-info-dialog-component';
import { CorporateOwnerInfoDialogComponent } from './corporate-owner-info-dialog-component/corporate-owner-info-dialog-component';
import { FOPQuadrantComponent } from './fop-quadrant/fop-quadrant.component';
import { ZooomDialogComponent } from './zoom-2x2-matrix/zoom-2x2-matrix';
import { ZooomFocusGPComponent } from './zoom-2x2-focus-gp/zoom-2x2-focus-gp';
import { ViewNoteDialogComponent } from './view-note-component/view-note.component';
import { ViewGrowthMatrixAxesComponent } from './view-growth-matrix-axes/view-growth-matrix-axes.component';

//import { AccordionModule } from 'ngx-bootstrap/accordion';


@NgModule({
    imports: [
        ModalModule.forRoot(),
        BrowserModule, 
        FormsModule, 
        ReactiveFormsModule, 
    PipeModule,
    DirectiveModule,
        //MalihuScrollbarModule.forRoot(),
        ComponentModule,
        MyDatePickerModule,
        //ProgressBarModule,
        //AngularMultiSelectModule,
        TooltipModule.forRoot(),
        //AccordionModule.forRoot()
   ],
    declarations: [
        OkCancelDialogComponent,
        AlertDialogComponent,
        LandingAlertDialogComponent,
        InfoDialogComponent, 
        //VideoDialogComponent,
        VideoDialogHottopicComponent,
        FormDialogComponent,
        TermsDialogComponent,
        CodeOfConductDialogComponent,
        //CustomizeReportComponent,
        //CoverPageTitleEditComponent,
        AddCustomActionComponent,
        RestoreActionComponent,
        ActionDetailComponent,
        //UserProfileDialogComponent,
        HelpTopicsComponent,
        SaveCancelDialogComponent,
        LandingDialogComponent,
        VideoDialogLandingPageComponent,
        InsightModalComponent,
        //ThemeDialogComponent,
        ConfirmResetComponent,
        addNotesComponent,
        addNotesAssesmentComponent,
        CreateNewGrowthPlanComponent,
        ////F2FLoginComponent,
        ////facilitatorCodeComponent,
        ////selectMemberComponent,
        ////AttendessInfoComponent,
        ////ConfirmationComponent,
        F2FInfoDialogComponent,
        //InfoAttendessComponent,
        F2FCodeVerificationComponent,
        //F2FDuplicateGrowthPlanComponent,
        CorporateParticipantInfoDialogComponent,
        CorporateOwnerInfoDialogComponent,
        FOPQuadrantComponent,
        ZooomDialogComponent,
        ZooomFocusGPComponent,
        ViewNoteDialogComponent,
        ViewGrowthMatrixAxesComponent
    ],
    //entryComponents: [
    //    SaveCancelDialogComponent,
    //    LandingDialogComponent,
    //    VideoDialogLandingPageComponent,
    //    CreateNewGrowthPlanComponent,
    //    F2FCodeVerificationComponent,
    //    //F2FDuplicateGrowthPlanComponent
    //],
    providers: [ModalDialogService, DatePipe],
    bootstrap: [DynamicModalContainerComponent]
})
export class ModalDialogModule { }
