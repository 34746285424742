<div class="modal fade ey-dialog-modal" 
[ngClass]='{
    "location-modal-save": (customize.type === "LOCATION_DELETE"),
    "register-confirm-modal": (customize.type === "REGISTER_CONFIRM"),
    "logout-modal": (customize.type === "LOGOUT")
    }' 
bsModal #okCancelModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">        
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
                <img class="whiteClose" (click)="closeBtn()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body" *ngIf="!clickGrowthPlan">
                {{message}}
            </div>
            <div class="modal-body" *ngIf="clickGrowthPlan">
                {{part1}}<a (click)="goToSummaryPage()">{{part2}}</a>{{part3}}
            </div>
            <div class="modal-footer">
                <div class="action-buttons">
                    <button class="btn btn-outline" (click)="cancelClicked();" type="button"
                            [innerHTML]="customize.action.cancel || 'CANCEL'"></button>
                    <span class="or">{{SiteContents.lblOr}}</span>
                    <a class="back"
                       (click)='okayClicked()'>
                        {{customize.action.ok || 'OK'}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>