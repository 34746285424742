import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, SimpleChange } from '@angular/core';

@Directive({
    selector: '[handleOverflow]'
})
export class handleOverFlowDirective {

    @Input() configSet: any;

    element: ElementRef;
      
    ngOnInit() {
    }

    constructor(private el: ElementRef) {
        this.element = el;

        //this.checkOverflow(this.element.nativeElement);
    }

    @HostListener('window:resize') onResize() {
        this.checkOverflow(this.element.nativeElement);
    }

    ngOnChanges(change:SimpleChange) {
        //console.log(change);
        if(change['hasApply'] != undefined) {
            if(change['hasApply']['currentValue']) {
                //console.log(change['hasApply']['currentValue']);
                this.checkOverflow(this.element.nativeElement);
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.checkOverflow(this.element.nativeElement);
        });
    }

    @Input() set hasApply(shouldAdd: boolean) {
        //console.log('here');
        if (shouldAdd) {
            this.checkOverflow(this.element.nativeElement);   
        }
    }

    ngAfterViewChecked() {
        //this.checkOverflow(this.element.nativeElement);
    }

    checkOverflow(selEl) {
        //console.log(this.configSet);
    
        if(this.configSet != undefined) {
            this.configSet.forEach(config => {
                let elements = selEl.getElementsByClassName(config['className']);
                var isOverflowing = false;
                for (let el of elements) {
                    var curOverf = el.style.overflow;
    
                    if (!curOverf || curOverf === "visible")
                        el.style.overflow = "hidden";
    
                    //console.log(el.clientWidth);
                    //console.log(el.scrollWidth);
                    //console.log(el.offsetWidth);
                    //console.log(el.clientWidth - el.offsetWidth)
                    //console.log(el.offsetWidth - el.scrollWidth)
                    //console.log(el.clientHeight);
                    //console.log(el.scrollHeight);
                    //console.log(el.offsetHeight);
                    //console.log(el.clientHeight - el.offsetHeight)
                    //console.log(el.offsetHeight - el.scrollHeight)
                    
                    isOverflowing = el.clientWidth < (el.scrollWidth)
                        || el.clientHeight < (el.scrollHeight + (el.clientHeight - el.offsetHeight) +  (el.offsetHeight - el.scrollHeight));
    
                    el.style.overflow = curOverf;
                    //console.log(isOverflowing);
    
                    if (isOverflowing)
                        break;
                }
                //console.log(isOverflowing);
                if (isOverflowing) {
                    for (let el of elements) {
                        el.style.setProperty("font-size", config['newFont'], "important")
                        if (config['className'] == 'sd-title-margin') {
                            el.style.setProperty("white-space", 'normal', "important")
                        }
                    }
                //} else {
                //    for (let el of elements) {
    
                //        el.style.fontSize = config['oldFont'];
                //    }
                }
            });
        }
    }

   
    

}