import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class DataShareService {
  
    actionSaved = new BehaviorSubject<string>("action");
    mainSearchString = new BehaviorSubject<any>({ 'searchStr': '', 'categoryId': 0 });

    public activePlan = new BehaviorSubject<boolean>(false);
    public callActivePlan = this.activePlan.asObservable();

    public setActiveGrowthPlanContainer = new BehaviorSubject<boolean>(false);
    public getActiveGrowthPlanContainer = this.setActiveGrowthPlanContainer.asObservable();

    public changeGP = new BehaviorSubject<boolean>(false);
    public callChangeGP = this.changeGP.asObservable();

    public changeLanguage = new BehaviorSubject<boolean>(false);
    public callChangeLanguage = this.changeLanguage.asObservable();

    public actionedGP = new BehaviorSubject<boolean>(false);
  public callActionedGP = this.actionedGP.asObservable();
    public changeActiveGP = new BehaviorSubject<boolean>(false);
    public callChangeActiveGP = this.changeActiveGP.asObservable();

    constructor() { }
    public privateData: any = {};

    getSharedData(key: string): any {
        return this.privateData[key];
    }

    setSharedData(key: string, data: any): void {
        if (key && data) {
            if (this.privateData[key] == undefined) {
                this.privateData[key] = data;
            }
        }
    }

    refreshData() {
        //this.privateData = {};
     //   var oldValues = this.privateData;
        let noRemove = ['sitecontent', 'home'];
       // console.log(this.privateData);
        var res = {}

        res[noRemove[0]] = this.privateData[noRemove[0]];
        res[noRemove[1]] = this.privateData[noRemove[1]];
        this.privateData = {};
        this.privateData = res;
       // console.log(this.privateData);

        //Object.keys(this.privateData).forEach((key) => noRemove.includes(key) || delete this.privateData[key]);
    }
}
