import { Component, Input, ViewChild, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from "../../component/base.component";


@Component({
    //moduleId: module.id.toString(),
    selector: "landing-alert",
  templateUrl: './landing-alert.component.html',
  styleUrls: ['./landing-alert.component.scss'],
})

export class LandingAlertDialogComponent extends BaseComponent {
    title: string = "";
    message: string = "";
    btnText: string = '';
    showCloseButton: boolean = true;
  @ViewChild('landingAlertModal', { static: true }) landingAlertModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'ok';

    siteContents: any;
    
    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(private injector: Injector) {
        super(injector);
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.showCloseButton = this.injector.get('showCloseButton');
        this.btnText = this.injector.get('okButton');

    }

    ngOnInit() {
        this.siteContents = this.SiteContents;
    }

    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }
    }

    okayClicked() {
        this.returnedValue = "ok";
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = "ok";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.landingAlertModal.config = this.config;
        this.landingAlertModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.landingAlertModal.hide();
    }
}
