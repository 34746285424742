<div class="rag-container ac actionPopUp px-0" *ngIf="!fromCard">
    <!--<h3>{{appContent.labelAddModifyActionStatus}}</h3>-->
    <div class="row">
        <div class="col-md-4">
            <div class="owner">
                <div class="text">
                    {{appContent.labelOwner}}
                </div>
                <input style="border: 1px solid #CCC; height: 36px;" autocomplete="off" type="text" (blur)="setOwner()" [disabled]="isGrowthPlanLocked" [ngClass]="{'locked':isGrowthPlanLocked}"  [(ngModel)]="owner">
            </div>
        </div>

        <!--<div class="col-6">
        <div class="rags">
            <div class="text" #textStatus>
                {{appContent.labelSelectActionStatus}}
            </div>
            <div class="rag">
                <div class="red fill" (click)="changeRag('R')" *ngIf="!isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                </div>
                <div class="red fill locked" *ngIf="isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                </div>
            </div>
            <div class="rag">
                <div class="yellow fill" (click)="changeRag('A')" *ngIf="!isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                </div>
                <div class="yellow fill locked" *ngIf="isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                </div>
            </div>
            <div class="rag">
                <div class="green fill" (click)="changeRag('G')" *ngIf="!isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                </div>
                <div class="green fill locked" *ngIf="isGrowthPlanLocked">
                    <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                </div>
            </div>
        </div>
    </div>-->
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4 cost-text">
                    <div class="doller-icon">
                        <div class="text">
                            {{appContent.labelCost}} ({{currencySymbol}})
                        </div>
                        <div class="custom-number">
                            <input style="border: 1px solid #CCC;height:36px;" id="estCost" [style.visibility]="!isGrowthPlanLocked ? 'visible' : 'hidden'" [ngClass]="{'locked':isGrowthPlanLocked}" autocomplete="off" type="number" (blur)="setActionCost()" (keypress)="numberOnly($event)" min="0" max="999999999" [disabled]="isGrowthPlanLocked" [(ngModel)]="estimatedCost">
                            <!--<label for="estCost" [ngClass]="{'cost-locked':isGrowthPlanLocked}">${{estimatedCost ? (estimatedCost | number) : ''}}</label>-->
                            <label for="estCost" [ngClass]="{'cost-locked':isGrowthPlanLocked}">
                                <!--<span class="currency-block-font">{{currencySymbol}}</span>-->
                                <span class="currency-block-amount">{{estimatedCost ? (getAmount(estimatedCost) | number) : ''}}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 pr-0" #startContainer>
                    <div class="date">
                        <div class="text" #start>
                            {{appContent.labelStartDate}}
                        </div>
                        <my-date-picker [options]="myDatePickerOptionsStart" [(ngModel)]="startDate" [disabled]="isGrowthPlanLocked"
                                        (dateChanged)="onDateChanged($event,'start',completionDate,'action')"
                                        [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
                    </div>
                </div>
                <div class="col-md-4 pr-0" #endContainer>
                    <div class="date">
                        <div class="text" #end>
                            {{appContent.labelCompletionDate}}
                        </div>
                        <my-date-picker [options]="myDatePickerOptionsComplete" [(ngModel)]="completionDate" [disabled]="isGrowthPlanLocked"
                                        (dateChanged)="onDateChanged($event,'completed',startDate,'action')"
                                        [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <div class="text">
                {{settingLabel1}} <a [routerLink]="'/user-detail/settings/3'" class="go-to-setting">{{appContent.lblSetting}}</a>{{settingLabel2}}
            </div>
        </div>
        <div>
            <ng-container *ngFor="let setting of statusSettings; let i = index">
                <div class="checkbox form-check status-check" [class.ps-0]="i == 0">
                    <label>
                        <input (click)="changeRag(setting.name)" type="radio" name="status" value="{{setting.name}}" [checked]="ragValue.status == setting.name">
                        <div class="label-text">
                          <div class="status-content status-content-new">{{setting.content}}</div>
                          <div class="status-square" [ngClass]="{'red': setting.name === 'R', 'amber' : setting.name === 'A', 'green' : setting.name === 'G' }"></div>
                        </div>
                    </label>
                </div>
            </ng-container>
        </div>

        <!--<ng-container *ngFor="let setting of statusSettings">
            <div class="col-md-3">
                <div class="checkbox form-check" style="padding-left:0px;">
                    <label>
                        <input (click)="changeRag(setting.name)" type="radio" name="status" value="{{setting.name}}" [checked]="ragValue.status == setting.name">
                        <span class="label-text">
                            <span class="status-circle" [ngClass]="{'red': setting.name === 'R', 'amber' : setting.name === 'A', 'green' : setting.name === 'G' }"></span>
                            <span class="status-content">{{setting.content}}</span>-->
                            <!--{{setting.content}}-->
                        <!--</span>
                    </label>
                </div>-->
                <!--<label (click)="changeRag(setting.name)">
                    <input class="status-radio" type="radio" name="status" value="{{setting.name
                            }}" [checked]="ragValue.status == setting.name" />
                            <div class="status-circle" [ngClass]="{'red': setting.name === 'R', 'amber' : setting.name === 'A', 'green' : setting.name === 'G' }"></div>
                            <span style="vertical-align:middle;">{{setting.content}}</span>
</label>-->
            <!--</div>
        </ng-container>-->
        
        <!--<div class="col-md-3">
            <label><input class="status-radio" type="radio" name="status"/> <div class="status-circle amber"></div></label>
        </div>
        <div class="col-md-3">
            <label><input class="status-radio" type="radio" name="status"/> <div class="status-circle green"></div></label>
        </div>-->


    </div>
    
    <!--<div class="row" style="padding-top:10px">
        <div class="col-6 pr-0" #startContainer>
            <div class="date">
                <div class="text" #start>
                    {{appContent.labelStartDate}}
                </div>
                <my-date-picker [options]="myDatePickerOptionsStart" [(ngModel)]="startDate" [disabled]="isGrowthPlanLocked"
                                (dateChanged)="onDateChanged($event,'start',completionDate,'action')"
                                [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
            </div>
        </div>
        <div class="col-6 p-0" #endContainer>
            <div class="date">
                <div class="text" #end>
                    {{appContent.labelCompletionDate}}
                </div>
                <my-date-picker [options]="myDatePickerOptionsComplete" [(ngModel)]="completionDate" [disabled]="isGrowthPlanLocked"
                                (dateChanged)="onDateChanged($event,'completed',startDate,'action')"
                                [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
            </div>
        </div>
    </div>-->
    {{init()}}
</div>
<div class="rag-container ac" *ngIf="fromCard == 'true'">
    <div class="row">
        <div class="col-6 {{colorContainer}}">
            <div class="rags">
                <div class="text" #textStatus>
                    {{appContent.labelSelectActionStatus}}
                </div>
                <div class="rag">
                   
                    <div class="red fill" (click)="changeRag('R')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                    </div>
                    <div class="red fill locked"  *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                    </div>
                </div>
                <div class="rag">

                    <div class="yellow fill" (click)="changeRag('A')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                    </div>
                    <div class="yellow fill locked" *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                    </div>

                </div>
                <div class="rag">
                 
                    <div class="green fill" (click)="changeRag('G')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                    </div>
                    <div class="green fill locked" *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 {{costContainer}}" #cost>
            <div class="doller-icon">
                <div class="text">
                    {{appContent.labelCost}} 
                </div>
                <div class="custom-number">
                    <input id="est" [style.visibility]="!isGrowthPlanLocked ? 'visible' : 'hidden'" [ngClass]="{'locked':isGrowthPlanLocked}" autocomplete="off" type="number" (blur)="setActionCost()" (keypress)="numberOnly($event)" min="0" max="999999999" [disabled]="isGrowthPlanLocked" [(ngModel)]="estimatedCost">
                    <!--<label for="est" [ngClass]="{'cost-locked':isGrowthPlanLocked}">${{estimatedCost ? (estimatedCost | number) : ''}}</label>-->
                    <label for="est" [ngClass]="{'cost-locked':isGrowthPlanLocked}">
                        <span class="currency-block-font">{{currencySymbol}}</span>
                        <span class="currency-block-amount">{{estimatedCost ? (getAmount(estimatedCost) | number) : ''}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top:10px">
        <div class="col-12">
            <div class="owner">
                <div class="text">
                    {{appContent.labelOwner}}
                </div>
                <input autocomplete="off" type="text" (blur)="setOwner()" [disabled]="isGrowthPlanLocked" [ngClass]="{'locked':isGrowthPlanLocked}" [(ngModel)]="owner">
            </div>
        </div>
    </div>
    <div class="row" style="padding-top:10px">
        <div class="col-6 start-date pr-0" #startContainer>            
            <div class="date">
                <div class="text" #start>
                    {{appContent.labelStartDate}}
                </div>
                <div [ngClass]="{'date-error':invalidStartDate}">
                    <my-date-picker [selector]="selectorStart" [options]="myDatePickerOptionsStart" [(ngModel)]="startDate" [disabled]="isGrowthPlanLocked"
                                    (dateChanged)="onDateChanged($event,'start',completionDate,'action')"
                                    (calendarToggle)="calendarOpen($event, true)" [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked }"></my-date-picker>
                </div>
                
            </div>
        </div>
        <div class="col-6 comp-date p-0" #endContainer>
            <div class="date">
                <div class="text" #end>
                    {{appContent.labelCompletionDate}}
                </div>
                <div [ngClass]="{'date-error':invalidCompletionDate}">
                    <my-date-picker [selector]="selectorCom" [options]="isCorporate?myDatePickerOptionsCompleteRight:myDatePickerOptionsComplete" [(ngModel)]="completionDate" [disabled]="isGrowthPlanLocked"
                                    (dateChanged)="onDateChanged($event,'completed',startDate,'action')"
                                    (calendarToggle)="calendarOpen($event,false)" [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
                </div>                    
                </div>
            </div>
    </div>

</div>


<div class="rag-container ac zoom-view" *ngIf="fromCard == 'zoom'">
    <div class="row">
        <div class="col-6 color-container">
            <div class="rags">
                <div class="text" #textStatus>
                    {{appContent.labelSelectActionStatus}}
                </div>
                <div class="rag">

                    <div class="red fill" (click)="changeRag('R')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                    </div>
                    <div class="red fill locked" *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'R'"></i>
                    </div>
                </div>
                <div class="rag">

                    <div class="yellow fill" (click)="changeRag('A')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                    </div>
                    <div class="yellow fill locked" *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'A'"></i>
                    </div>

                </div>
                <div class="rag">

                    <div class="green fill" (click)="changeRag('G')" *ngIf="!isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                    </div>
                    <div class="green fill locked" *ngIf="isGrowthPlanLocked">
                        <i class="fa fa-check fa-select" *ngIf="ragValue.status == 'G'"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 cost-container" #cost>
            <div class="doller-icon">
                <div class="text">
                    {{appContent.labelCost}}
                </div>
                <div class="custom-number">
                    <input id="est" [style.visibility]="!isGrowthPlanLocked ? 'visible' : 'hidden'" [ngClass]="{'locked':isGrowthPlanLocked}" autocomplete="off" type="number" (blur)="setActionCost()" (keypress)="numberOnly($event)" min="0" max="999999999" [disabled]="isGrowthPlanLocked" [(ngModel)]="estimatedCost">
                    <!--<label for="est" [ngClass]="{'cost-locked':isGrowthPlanLocked}">${{estimatedCost ? (estimatedCost | number) : ''}}</label>-->
                    <label for="est" [ngClass]="{'cost-locked':isGrowthPlanLocked}">
                        <span class="currency-block-font">{{currencySymbol}}</span>
                        <span class="currency-block-amount">{{estimatedCost ? (getAmount(estimatedCost) | number) : ''}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top:10px">
        <div class="col-12">
            <div class="owner">
                <div class="text">
                    {{appContent.labelOwner}}
                </div>
                <input autocomplete="off" type="text" (blur)="setOwner()" [disabled]="isGrowthPlanLocked" [ngClass]="{'locked':isGrowthPlanLocked}"  [(ngModel)]="owner">
            </div>
        </div>
    </div>
    <div class="row" style="padding-top:10px">
        <div class="col-6 start-date pr-0" #startContainer>
            <div class="date">
                <div class="text" #start>
                    {{appContent.labelStartDate}}
                </div>
                <div [ngClass]="{'date-error':invalidStartDate}">
                    <my-date-picker [selector]="selectorStart" [options]="myDatePickerOptionsStart" [(ngModel)]="startDate" [disabled]="isGrowthPlanLocked"
                                    (dateChanged)="onDateChanged($event,'start',completionDate,'action')"
                                    (calendarToggle)="calendarOpen($event, true)" [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked }"></my-date-picker>
                </div>

            </div>
        </div>
        <div class="col-6 comp-date p-0" #endContainer>
            <div class="date">
                <div class="text" #end>
                    {{appContent.labelCompletionDate}}
                </div>
                <div [ngClass]="{'date-error':invalidCompletionDate}">
                    <my-date-picker [selector]="selectorCom" [options]="isCorporate?myDatePickerOptionsCompleteRight:myDatePickerOptionsComplete" [(ngModel)]="completionDate" [disabled]="isGrowthPlanLocked"
                                    (dateChanged)="onDateChanged($event,'completed',startDate,'action')"
                                    (calendarToggle)="calendarOpen($event,false)" [ngClass]="{'padding':overallStartDate,'locked':isGrowthPlanLocked}"></my-date-picker>
                </div>
            </div>
        </div>
    </div>

</div>
