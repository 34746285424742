<div class="modal fade f2f-info-dialog-modal"
     bsModal #f2fInfoDialogModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rowWithoutFlex" *ngIf="showGpInfoDialog">
            <div class="modal-header">
                <span *ngIf="!gpNameSectionEdit">
                    <h4 class="modal-title pull-left">{{growthPlanDetailsArr.growthPlanName}}</h4>
                    <i class="fa fa-pencil editIcon" (click)="reNameGp()" *ngIf="isGpEditable"></i>
                </span>
                <div class="gp-title-edit" *ngIf="gpNameSectionEdit">
                    <form #form="ngForm">
                        <div class="row">
                            <div class="col-7">
                                <div class="gp-edit-title">
                                    <input type="text" name="gp_title" autocomplete="off"
                                           [ngClass]="{'red-border-class': errorMessageRename || errorGPEmpty}"
                                           [placeholder]="PageContents.lblEnterAssessmentName"
                                           class="form-control" maxlength="50"
                                           (keyup)="clearError()"
                                           (keyup.enter)="onKeyUPTitle()" [(ngModel)]="gpTitle">
                                </div>
                            </div>
                            <div class="col-5">

                                <button [disabled]="prevGpTitle == gpTitle" class="btn btn-outline" (click)="onKeyUPTitle()">{{SiteContents.buttonSave}}</button>
                                <span class="edit-gp-btn-cancel" style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                                <span class="edit-gp-btn-cancel" (click)="cancelRenameGp()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                            </div>
                        </div>
                    </form>
                </div>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />

            </div>
            <div *ngIf="errorMessageRename" class="col-12 error-block">{{PageContents.lblPlanAlreadyExists}}</div>
            <div *ngIf="errorGPEmpty" class="col-12 error-block">{{PageContents.lblEnterAssessmentName}}</div>
            <div class="modal-body" eyv-update-panel="f2fInfoDialog" custom-scroll [scrollPos]="scrollPos">
                <div class="info-dialog-tag">               
                    <ng-container [ngSwitch]="growthPlan.growthPlanTypeId"> 
                        <span class="dialog-tag"  *ngSwitchCase="1">{{PageContents.lblSelf}}</span>
                        <span class="dialog-tag"  *ngSwitchCase="2">{{PageContents.lblFacilitated}}</span>
                        <span class="dialog-tag"  *ngSwitchCase="3">{{PageContents.lblCorporate}}</span>
                        <span class="dialog-tag"  *ngSwitchCase="4">{{PageContents.lblDSTagForDroplist}}</span>
                        <span class="dialog-tag"  *ngSwitchCase="5">
                            <span *ngIf=" !growthPlan.isPracticeDemoMeeting">{{PageContents.lblCorporate}}</span>
                            <span *ngIf="growthPlan.isPracticeDemoMeeting">{{PageContents.lblLeadershipTeamDemoMeetingTag}}</span>
                        </span>
                      </ng-container>
                </div>

                <div class="container info-dialog-container">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 label-style">{{PageContents.lblViewAllSortCreateddate}}</div>
                        <div class="col-8">{{growthPlanDetailsArr.createdDate}}</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-3 label-style">{{PageContents.lblViewAllLastSaved}}</div>
                        <div class="col-8">{{growthPlanDetailsArr.modifiedDate}}</div>
                    </div>
                </div>

                <div class="container info-dialog-container clearfix">
                    <div class="col-4 left-align" *ngIf="showOwnerDetails">
                        <div class="row">
                            <div class="col-2 label-style">{{PageContents.lblViewAllOwner}}</div>
                        </div>
                        <div class="row">
                            <div class="col user-avatar">
                                <div class="user-avatar-black">{{ownerDetailsArr.ownerAbbrivation}}</div>
                                <div class="user-info">
                                    <!--ownerImageURL -->
                                    <span class="user-avatar-name"> {{ownerDetailsArr.ownerName}}</span>
                                    <span class="user-avatar-desc">{{ownerDetailsArr.ownerCompanyName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 left-align" *ngIf="showFacilitatorDetails">
                        <div class="row">
                            <div class="col-2 label-style">{{PageContents.lblViewAllFacilitator}}</div>
                        </div>
                        <div class="row">
                            <div class="col user-avatar">
                                <div class="user-avatar-black">{{facilitatorDetailsArr.facilitatorAbbrivation}}</div>
                                <div class="user-info">
                                    <span class="user-avatar-name">{{facilitatorDetailsArr.facilitatorName}}</span>
                                    <span class="user-avatar-desc">{{facilitatorDetailsArr.facilitatorCompanyName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <info-attendees *ngIf="showAttendees"
                                [isEditAttendee]="isEditAttendee" [growthPlanId]="growthPlanId"
                                (scrollPos)="scrollValue($event)" [PageContents]="PageContents"
                                [attendeeInfo]="attendeeInfo"></info-attendees>

                <div class="container info-dialog-container" *ngIf="showSharedDetails && sharedListArr.length>0">
                    <div class="row">
                        <div class="col-3 label-style"> {{PageContents.lblSharedWith}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 user-avatar" *ngFor="let sharedList of sharedListArr">
                            <div class="user-avatar-black">{{sharedList.sharedWithAbbrivation}}</div>
                            <div class="user-info">
                                <span class="user-avatar-name">{{sharedList.sharedWithName}}</span>
                                <span class="user-avatar-desc">{{sharedList.sharedWithCompanyName}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container info-dialog-container" *ngIf="showButtonSection">
                    <div class="button-rows" *ngIf="typeId == 1 && growthPlanDetailsArr.growthPlanTypeId !== 4">
                        <button class="btn btn-outline" (click)="shreGP()" *ngIf="showGpShareButton">
                            <img src="../../../assets/images/f2f-share-icon.png" alt="share" />{{PageContents.lblViewAllShare}}
                        </button>
                        <button class="btn btn-outline" (click)="unShreGP()" *ngIf="showGpUnShareButton && sharedListArr?.length > 0">
                            <img src="../../../assets/images/unshare-icon.png" alt="un share" />{{PageContents.lblViewAllUnshare}}
                        </button>
                        <button class=" btn btn-outline duplicateBtnClass" (click)="duplicateGP()">
                            <img src="../../../assets/images/duplicate-icon.png" alt="duplicate" />{{PageContents.lblDuplicate}}
                        </button>
                        <button class="btn btn-outline" (click)="deleteGP()" *ngIf="showGpDeleteButton">
                            <img src="../../../assets/images/delete-icon.png" alt="share" />{{PageContents.lblDelete}}
                        </button>
                    </div>

                    <div *ngIf="typeId == 1 && growthPlanDetailsArr.growthPlanTypeId == 4">
                        <div style="margin-bottom: 20px;">
                            <span class="label-style">{{PageContents.lblDSInfoPopUpMessage}}</span>
                        </div>
                        <div class="button-rows">
                            <button class="btn btn-outline" (click)="resetAllGP()">
                                {{PageContents.btnDSResetAll}}
                            </button>
                            <button class="btn btn-outline" (click)="resetSomeGP()">
                                {{PageContents.btnDSResetSome}}
                            </button>
                            <button class="btn btn-outline" (click)="deleteGP()">
                                {{PageContents.lblDelete}}
                            </button>
                        </div>
                    </div>

                    <div class="button-rows float-right" *ngIf="typeId == 2">
                        <button class="btn btn-outline" (click)="goToDriverAssessment()">
                            {{PageContents.lblViewSevenDriverAssessment}}
                        </button>
                    </div>
                </div>

                <div class="container info-dialog-container" *ngIf="showShareSection">
                    <div class="row">
                        <div class="col-3 label-share-with">{{PageContents.lblShareWith}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12 existing-member">
                            <people-picker [appendData]="true" [PageContents]="PageContents" [gpId]="growthPlanId" [selectedMember]="selUser" (onChange)="onSelectionChange($event)"></people-picker>
                            <div class="profile-contents" *ngIf="selUser && selUser.length > 0 && selUser[0].userId">
                                <div class="profile">
                                    <div class="left">
                                        <img [src]="selUser[0].profileUrl" />
                                    </div>
                                    <div class="right">
                                        <p class="name">{{selUser[0].firstName}}&nbsp;{{selUser[0].lastName}}</p>
                                        <p class="company">{{selUser[0].company}}</p>
                                        <p class="email">{{selUser[0].email}}</p>
                                    </div>
                                </div>
                                <div class="remove" (click)="removeMember()">
                                    <i class="fa fa-trash-o"></i>
                                    <p>{{PageContents.btnRemove}}</p>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="errorMessage" class="col-12 error-block">{{PageContents.lblSelectMember}}</div>

                    </div>
                    <div class="button-rows">
                        <div class="share-action-btn">
                            <button class="btn btn-outline" (click)="shareGrowthplan()">{{PageContents.lblCreateGrowthPlanSubmit}}</button>
                            <span style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                            <span class="share-action-btn-cancel" (click)="cancelShreGP()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        </div>
                    </div>
                </div>

                <div class="container info-dialog-container" *ngIf="showUnShareSection">
                    <div class="row">
                        <div class="col-12 label-share-with">{{PageContents.lblSelectMemberToUnshare}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 user-avatar" *ngFor="let sharedList of sharedListArr">
                            <div class="user-sel-unshare">
                                <input type="checkbox" [(ngModel)]="selectedMemberList[sharedList.id]" [value]="selectedMemberList[sharedList.id]" name="sharedUser">
                                <span class="checkmark"></span>
                            </div>
                            <div class="user-avatar-black">{{sharedList.sharedWithAbbrivation}}</div>
                            <div class="user-info unshare-user">
                                <span class="user-avatar-name">{{sharedList.sharedWithName}}</span>
                                <span class="user-avatar-desc">{{sharedList.sharedWithCompanyName}}</span>
                            </div>
                        </div>

                        <div *ngIf="errorMessageUnshare" class="col-12 error-block">{{PageContents.msgUnshareMember}} </div>
                    </div>
                    <div class="button-rows">
                        <div class="share-action-btn">
                            <button class="btn btn-outline" (click)="unShareGrowthplan()">{{PageContents.lblViewAllUnshare}}</button>
                            <span style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                            <span class="share-action-btn-cancel" (click)="cancelUnshreGP()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete button popup-->

        <div class="modal-content rowWithoutFlex delete-gp-section" *ngIf="showGpDelete">
            <div class="modal-header">
                <span> <h4 *ngIf="!errorMsgDelete" class="modal-title pull-left">{{PageContents.lblConfirmDeleteHeader}}</h4> </span>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
            </div>

            <div class="modal-body" *ngIf="!errorMsgDelete">
                <p> {{PageContents.lblConfirmDeleteMessage}}</p>
                <p> {{PageContents.msgConfirmDelete}}</p>

                <div class="button-rows">

                    <div class="share-action-btn">
                        <button class="btn btn-outline" (click)="deleteGrowthPlan()"> {{PageContents.lblDelete}}</button>
                        <span style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="share-action-btn-cancel" (click)="cancelDeleteGP()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="errorMsgDelete">
                <p> {{PageContents.msgDeleteLastGrowthPlan}}</p>
                <div class="button-rows">
                    <div class="share-action-btn btn-delete-error-margin-gp">
                        <button class="btn btn-outline" (click)="closeDialog()">{{SiteContents.buttonOk}}</button>
                    </div>
                </div>
            </div>


        </div>


        <!--Edit-->
        <div class="modal-content rowWithoutFlex delete-gp-section" *ngIf="showGpalreadyNominated">

            <div class="modal-body">
                <p class="warningdeletepopup">
                    {{PageContents.msgCannotDelete}}
                </p>

                <div class="row button-rows warningdeletepopup-button">

                    <div class="col-12 share-action-btn">
                        <button class="btn btn-outline" (click)="closeshowGpalreadyNominated()">{{SiteContents.buttonOk}}</button>

                    </div>
                </div>
            </div>

        </div>
        <!--Edit-->
        <div class="modal-content rowWithoutFlex f2f-info-duplicate-growth-plan" *ngIf="showGpDuplicate && !isLoading" eyv-update-panel="f2finfoduplicategrowthplan" update-panel-action="click">
            <div class="modal-header">
                <h4 class="modal-title pull-left plan-name" *ngIf="!hasAxisDifference || isContinued">
                    {{PageContents.lblDuplicate}} - {{growthPlan.growthPlanName}}
                </h4>
                <h4 class="modal-title pull-left plan-name" *ngIf="hasAxisDifference && !isContinued">
                    {{this.otherContents.lblGPAxisWarning}}
                    <img class="matrix-icon" [src]="this.otherContents.lblDuplicateGPAxisPopup" />
                </h4>
                <span class="whiteClose">
                    <img (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
                </span>
            </div>
            <div class="modal-body" *ngIf="hasAxisDifference && !isContinued">
                <p class="create-desc" [innerHtml]="axesDifferenceModalContent" style="margin-top: 15px;"></p>
                <div class="axes-difference-content">
                    <button class="btn btn-outline" (click)="isContinued = !isContinued">{{this.otherContents.lblDuplicateGPAxisContinue}}</button>
                </div>
            </div>
            <div class="modal-body" custom-scroll [scrollPos]="scrollPos" *ngIf="!hasAxisDifference || isContinued">
                <p class="create-desc ellipsis" [innerHtml]="PageContents.lblDuplicateGrowthPlanTagLine"></p>
                <form name="form" #duplicate_GP="ngForm">
                  <div class="plan-name-container">
                    <label class="plan-name-lbl">{{PageContents.lblNewGrowthPlanNameForDuplicate}}</label>
                    <input class="plan-name" autocomplete="off" type="text" name="planName"
                           [ngClass]="{'red-border-class': errorMessageData || errorGPEmptyDuplicate}"
                           [(ngModel)]="duplicateGrowthPlanName" #planName="ngModel" maxlength="50"
                           required (keyup)="clearErrorMsg()" placeholder="{{PageContents.lblGrowthPlanName}}" />
                    <div *ngIf="errorGPEmptyDuplicate" class="help-block">{{PageContents.messageGrowthPlanPleaseEnter}} {{PageContents.lblNewGrowthPlanNameForDuplicate | lowercase }}</div>
                    <div *ngIf="errorMessageData" class="help-block">{{errorMessageData}}</div>
                  </div>
                  <p class="create-desc ellipsis" *ngIf="IsFacilitator" [innerHtml]="PageContents.lblDuplicateGrowthPlan"></p>
                  <p class="create-desc ellipsis" *ngIf="!IsFacilitator" style="margin-bottom:10px;"
                     [innerHtml]="PageContents.lblLTMGrowthPlanDupInfo"></p>

                  <div class="radioContainer" *ngIf="IsFacilitator">
                    <div>
                      <input type="radio" id="radiobutton_faci" name="duplicate" [checked]="facilitatedSession === true" (change)="onDuplicationRadioChange('facilitatedSession')" />
                      <label for="radiobutton_faci" class="radiolabel">{{PageContents.lblFacilitatedSession}}</label>
                    </div>
                    <div *ngIf="!IsFacilitator">
                      <input type="radio" id="radiobutton_self" name="duplicate" [checked]="selfAssessment === true" (change)="onDuplicationRadioChange('selfAssessment')" />
                      <label for="radiobutton_self" class="radiolabel">{{PageContents.lblSelfSession}}</label>
                    </div>
                    <div *ngIf="IsFacilitator">
                      <input type="radio" id="radiobutton_self" name="duplicate" [checked]="selfAssessment === true" (change)="onDuplicationRadioChange('demoSession')" />
                      <label for="radiobutton_self" class="radiolabel">{{PageContents.lblDSSelectionLabel}}</label>
                    </div>
                  </div>
                  <div *ngIf="isFacilitatedSession">
                    <div><label class="container-lbl">{{facNonfaclabel}}</label></div>
                    <!--<div class="gp-edit-fac" *ngIf="!IsFacilitator">
        <div class="row">
            <div class="col-4">
                <input type="password" name="cgp_facilitator" [ngClass]="{'red-border-class': cgpFacErrorSection}"
                       placeholder="Enter Code"
                       class="form-control"
                       maxlength="3"
                       [(ngModel)]="cgpFacCode"
                       (keyup)="clearFacError($event)"
                       (keyup.enter)="onKeyUPFacCode()"
                       [readonly]="is_edit">
            </div>

            <div class="col-8 Facsection-padding">
                <span class="Facvalid" *ngIf="isFacSuccesswithImage"><img src="../../../assets/images/tick-icon.png" class="Facvalid-img" /></span>
                <button class="btn btn-outline" (click)="onKeyUPFacCode()" *ngIf="!isFacSuccesswithImage">{{PageContents.lblCreateGrowthPlanSubmit}}</button>
                <span class="edit-gpFac-btn-cancel" *ngIf="!isFacSuccesswithImage">{{PageContents.lblCreateGrowthPlanOr}}</span>
                <span class="edit-gpFac-btn-cancel" (click)="cancelFacCode()" *ngIf="!isFacSuccesswithImage"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
            </div>
        </div>
    </div>-->                    
                    <div *ngIf="cgpFacErrorSection" class="error-block">{{cgpFacErrorTxt}}</div>
                    <div *ngIf="IsFacilitator">
                      <div class="radioContainer-member">
                        <div>
                          <input type="radio" id="radiobutton_existing" name="member" [checked]="existingmember === true" (change)="onMemberRadioChange('existingmember')" />
                          <label for="radiobutton_existing" class="radiolabel">{{PageContents.lblExistingMember}}</label>
                        </div>
                        <div>
                          <input type="radio" id="radiobutton_non" name="member" [checked]="nonmember === true" (change)="onMemberRadioChange('nonmember')" />
                          <label for="radiobutton_non" class="radiolabel">{{PageContents.lblNonMember}}</label>
                        </div>
                      </div>
                      <div class="existing-member" *ngIf="isExistingMember && !isFacSuccesswithImage">
                        <people-picker [appendData]="true" [PageContents]="PageContents" [selectedMember]="selUser" [participantId]="true" (onChange)="onSelectionChange($event)" autocomplete="off"></people-picker>
                        <div class="help-block" *ngIf="isUserequired">
                          <p>{{PageContents.lblSelectMember}}</p>
                        </div>
                      </div>

                      <div class="non-member" *ngIf="!isExistingMember">
                        <div class="member-group">
                          <!--<input autocomplete="off" maxlength="100" class="member-input" formControlName="clientName" [placeholder]="PageContents.lblclientNamePlaceholder">-->
                          <input class="member-input" autocomplete="off" type="text" name="clientName"
                                 [ngClass]="{'red-border-class': isNamerequired}"
                                 [(ngModel)]="clientName" maxlength="100"
                                 required (keyup)="clearclientnameErrorMsg()" placeholder="{{PageContents.lblclientNamePlaceholder}}" />
                        </div>
                        <div class="help-block" *ngIf="isNamerequired">
                          <p>{{PageContents.msgClientNameRequired}}</p>
                        </div>

                        <div class="member-group">
                          <!--<input autocomplete="off" maxlength="100" class="member-input" formControlName="clientEmail" [placeholder]="PageContents.lblclientEmailPlaceholder" (blur)="isEmailAlreadyExists()">-->
                          <input class="member-input" autocomplete="off" id="clientEmail" type="text" name="clientEmail"
                                 [ngClass]="{'red-border-class': isExistingEmail}"
                                 [(ngModel)]="clientEmail" maxlength="100"
                                 required (blur)="isEmailAlreadyExists()" placeholder="{{PageContents.lblclientEmailPlaceholder}}" />
                        </div>

                        <div class="help-block" *ngIf="isExistingEmail">
                          <p>{{emailValidationError}}</p>
                        </div>
                        <div class="member-group">
                          <!--<input autocomplete="off" maxlength="100" class="member-input" formControlName="companyName" [placeholder]="PageContents.lblclientCompanyPlaceholder">-->
                          <input class="member-input" autocomplete="off" type="text" name="companyName"
                                 [ngClass]="{'red-border-class': isCompanyrequired}"
                                 [(ngModel)]="companyName" maxlength="100"
                                 required (keyup)="clearcompanyErrorMsg()" placeholder="{{PageContents.lblclientCompanyPlaceholder}}" />
                        </div>
                        <div class="help-block" *ngIf="isCompanyrequired">
                          <p>{{PageContents.msgClientCompanyRequired}}</p>
                        </div>

                      </div>

                    </div>
                    <div class="profile-contents" *ngIf="isFacSuccesswithImage">
                      <div class="profile">
                        <div class="left">
                          <img [src]="profileImageValue" alt="delegated Owner" />
                        </div>
                        <div class="right">
                          <p class="name">{{profileNameValue}}</p>
                          <p class="company">{{profileCompanyValue}}</p>
                        </div>
                      </div>
                      <div class="remove" (click)="removeFacilitator()">
                        <i class="fa fa-trash-o"></i> <p>{{PageContents.btnRemove}}</p>
                      </div>

                    </div>

                    <!--<div class="col-5 user-avatar">
        <div class="user-avatar-black"> {{eyFacArr.facilitatorAbbrivation}} </div>
        <div class="user-info">
            <span class="user-avatar-name"> {{eyFacArr.facilitatorName}} </span>
            <span class="user-avatar-desc">{{eyFacArr.facilitatorCompanyName}}  </span>
        </div>
        <div class="remove-item" *ngIf="isCGpEditable && (isOwner|| isDelOwner)" (click)="removeFacilitator(eyFacArr.facilitatorId)">
            <i class="fa fa-trash-o"></i>
        </div>
    </div>-->

                    <div class="datepickerContainer">
                      <div><label class="container-lbl">{{PageContents.lblMeetingDate}}</label></div>
                      <my-date-picker [options]="myDatePickerOptions" name="meetingDate" required [(ngModel)]="meetingDate"></my-date-picker>
                    </div>
                  </div>
                  <div class="growth-plan-footer">
                    <button class="btn-outline" (click)="duplicateGrowthPlanSubmission()">{{PageContents.lblCreateGrowthPlanSubmit}}</button>
                    <span class="or">{{PageContents.lblCreateGrowthPlanOr}}</span>
                    <span class="back" (click)="cancelDuplicateGP()">{{PageContents.lblCreateGrowthPlanCancel}}</span>
                    <div *ngIf="errorresponseMessageData" class="help-block errorresponseMessageDataHeight" id="errorresponseMessageDataID">{{errorresponseMessageData}}</div>
                  </div>

                </form>

            </div>
        </div>


    </div>
</div>

