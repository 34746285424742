<div class="modal fade ey-info-modal" bsModal #infoModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog"
         [ngClass]="{'image-dialog': templateData.mediaType === 'image', 'insightPopup': templateType === types.INSIGHT_POPUP,'theme-complete':templateType === types.THEME_ASSESSMENT_COMPLETED}"
         >
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()" *ngIf="!hideCloseButton">
                    <img class="whiteClose" src="../../../assets/images/close-bt.png"><!--{{SiteContents.lblClose}}-->
                </div>
                <div class="clear-fix"></div>
                <div [ngSwitch]="templateType">
                    <ng-template [ngTemplateOutlet]="simpleMessageTemplate" *ngSwitchCase="types.SIMPLE">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="assesmentCompleted" *ngSwitchCase="types.ASSESSMENT_COMPLETED"></ng-template>

                    <ng-template [ngTemplateOutlet]="subDriverAssesmentCompleted"
                                 *ngSwitchCase="types.SUB_DRIVER_ASSESSMENT_COMPLETED"></ng-template>

                    <ng-template [ngTemplateOutlet]="themeAssesmentCompleted"
                                 *ngSwitchCase="types.THEME_ASSESSMENT_COMPLETED"></ng-template>

                    <!--<ng-template [ngTemplateOutlet]="maturityLevelTemplate" *ngSwitchCase="types.MATURITY_LEVEL">

                    </ng-template>-->
                    <ng-template [ngTemplateOutlet]="insightPopupTemplate" *ngSwitchCase="types.INSIGHT_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="needHelpPopupTemplate" *ngSwitchCase="types.NEED_HELP_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="copyrightPopupTemplate" *ngSwitchCase="types.COPYRIGHT_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="GrowthPlanNoActionTemplate" *ngSwitchCase="types.GROWTH_PLAN_NO_ACTION">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="imageVideoPopupTemplate" *ngSwitchCase="types.IMAGE_VIDEO_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="defaultMessageTemplate" *ngSwitchDefault>

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="commentNotificationTemplate" *ngSwitchCase="types.COMMENT_NOTIFICATION">

                    </ng-template>

                </div>


            </div>
        </div>
    </div>
</div>

<ng-template #defaultMessageTemplate>
    <div class="ey-info-modal-default">{{templateData.message}}</div>
</ng-template>

<ng-template #simpleMessageTemplate>
    <div class="ey-info-modal-default">{{templateData.message}}</div>
</ng-template>

<ng-template #assesmentCompleted>
    <div class="ey-info-modal-default">
        Congratulations! You have now finished scoring all your themes for
        your subdriver. The average of these scores is shown in the {{templateData.SubDriverName}} subdriver[
        <img src="../../../assets/images/help-arrow-outline.png"/>
        <!--<i class="fa fa-arrow-circle-o-right" style="color:green"></i>&nbsp;<i class="fa fa-arrow-circle-o-up" style="color:green"></i>-->
        ]
        <br /><br />
        You now need to score your current and aspirational scores for this subdriver using the green markers [
        <img src="../../../assets/images/help-arrow.png"/>
        <!--<i class="fa fa-arrow-circle-right" style="color:green"></i>&nbsp;<i class="fa fa-arrow-circle-up" style="color:green"></i>-->
        ]
    </div>
</ng-template>

<ng-template #themeAssesmentCompleted>
    <div class="ey-info-modal-default">
        {{templateData.notifyText1}}
        [
        <img src="../../../assets/images/help-arrow.png" />
        <!--<i class="fa fa-arrow-circle-o-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-o-up fa-lg" style="color:green"></i>-->
        ]
        <br /><br />
        {{templateData.notifyText2}}
        [
        <img src="../../../assets/images/help-arrow-outline.png" />
        <!--<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>-->
        ]
    </div>
</ng-template>

<ng-template #subDriverAssesmentCompleted>
    <div class="ey-info-modal-default">
        {{templateData.notifyText1}}
        [
        <img src="../../../assets/images/help-arrow.png" />
        <!--<i class="fa fa-arrow-circle-o-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-o-up fa-lg" style="color:green"></i>-->
        ]
        <br /><br />
        {{templateData.notifyText2}}
        [
        <img src="../../../assets/images/help-arrow.png" />
        <!--<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>-->
        ]
        <br /><br />

        {{templateData.notifyText3}}
        [
        <img src="../../../assets/images/help-arrow-outline.png" />
        <!--<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>-->
        ]
    </div>
</ng-template>

<!--<ng-template #maturityLevelTemplate>
    <div class="ey-info-modal-default">
        <h2 class="maturity-level-heading">{{templateData.name}}</h2>
        <div class="maturity-level-content" [innerHTML]="templateData.description"></div>
    </div>
</ng-template>-->
<!--Template for Insight popup-->
<ng-template #insightPopupTemplate>
    <div class="insight-popup-content">
        <!--<button class="btn insight-back pull-left" *ngIf="templateData.back" (click)="closeDialog()">
            <i class="fa fa-arrow-left fa-sm"></i>
            <span>{{SiteContents.lblInsights}}</span>
        </button>-->
        <!--<div class="insight-close-btn" (click)="closeDialog()" *ngIf="hideCloseButton">
            <img class="whiteClose" src="../../../assets/images/close-btn.png" />
        </div>-->
        <div class="floating-dialog-close pull-right" (click)="closeDialog()" *ngIf="hideCloseButton">
            <img class="whiteClose" src="../../../assets/images/close-bt.png"><!--{{SiteContents.lblClose}}-->
        </div>
        <div class="link pin-title" (click)="onPinInsights()">
            <!--<img class="image-pinned" *ngIf="templateData.isPinned" src="../../../assets/images/pinned-icon.png" />
            <img class="image-pinned" *ngIf="!templateData.isPinned" src="../../../assets/images/pin-icon.png" />
            {{templateData.isPinned  ? SiteContents.labelPinnedThisInsight :SiteContents.labelPinThisInsight }}-->

            <span [ngClass]="templateData.isPinned ? 'ht-icon-pinned': 'ht-icon-pin'"></span>
            <span class="like-text">{{templateData.isPinned  ? SiteContents.labelPinnedThisInsight :SiteContents.labelPinThisInsight}}</span>


        </div>


        <iframe width="100%" height="700" frameborder="0" [src]="sanitizer.bypassSecurityTrustResourceUrl(templateData.content)"></iframe>
    </div>
</ng-template>

<!--Template for Copyright popup-->
<ng-template #copyrightPopupTemplate>
    <div class="copyright-popup-content">
        <div [innerHTML]="templateData.content"></div>
    </div>
</ng-template>
<ng-template #needHelpPopupTemplate>
    <div class="need-help-content">
        <script src="../template-types.enum.js"></script>
        <!--[innerHTML]="templateData.content"-->
        <iframe width="100%" height="700" frameborder="0" [src]="sanitizer.bypassSecurityTrustResourceUrl(templateData)"></iframe>
    </div>
</ng-template>

<ng-template #GrowthPlanNoActionTemplate>
    <div class="ey-info-modal-default">
        <div class="no-Action-content">
            <p>{{templateData.description}}</p>
            <button #infoInput autofocus class="btn btn-outline" (click)="onGrowthPlanAction()">{{templateData.buttonText}}</button>
        </div>
    </div>
</ng-template>

<ng-template #imageVideoPopupTemplate>
    <div class="tds-image-dialog">
        <img [src]="templateData.content" style="width:100%;">
    </div>
</ng-template>

<ng-template #commentNotificationTemplate>
    <div class="comment-popup-content">
        <div [innerHTML]="templateData"></div>
    </div>
</ng-template>