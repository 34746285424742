<div class="modal fade ey-landing-dialog-modal" bsModal #landingAlertModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeDialog()" *ngIf="showCloseButton">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"  [innerHtml]="message">
               
            </div>
            <div class="modal-footer">
                
                <button class="btn btn-outline" (click)="okayClicked();" type="button">{{btnText?btnText:siteContents.buttonOk }}</button>
                   
            </div>
        </div>
    </div>
</div>