export const CONTENTS: any = {
    SITE_CONTENT: "sitecontent",
    SEVEN_DRIVERS_OF_GROWTH: "sevendriversofgrowth",
    HOME: "home",
    FOOTER: "footer",
    FOCUS: "focus",
    DRIVER_THEMES:"driverThemes",
    ACTION_CARDS: "actionCards",
    SUB_THEME_ASSESSMENT: "driversubthemes",
    TERMS_AND_CONDITIONS: 'termsconditions',
    REGISTRTATION: 'registration',
    REGISTER_GET_STARTED: 'registrationGetStarted',
    REGISTER_COMPANY_INFO: 'registrationCompanyInfo',
    REGISTER_INDEPENDENCE_FORM: 'registrationIndependence',
    REGISTER_COMP_LOCATIONS: 'registrationCompanyLocation',
    REGISTER_MILESTONES: 'registrationMilestone',
    REGISTER_COMPLETE: 'registrationThankYou',
    MY_GROWTH_PLAN: 'myGrowthPlan',
    DASHBOARD: 'dashboard',
    ACTION_DETAILS: 'actionDetails',
    ERROR404:'error404',
    USER_PROFILE: 'userProfile',
    NOTIFICATIONS: 'notifications',
    SEARCH: 'searchContent',
    CONTACT:'contactUs',
    UPCOMING_EVENTS: 'upcomingEvents',
    SETTINGS: 'settings',
    GROWTH_SUMMARY_REPORT: 'GrowthSummaryReport',
    CUSTOMIZE_REPORTS_PAGE: 'growthsummaryreport',
    LANDING_PAGE: 'MarketingLandingPage',
    LANDING_CONTACT_US: 'MarketingContactUs',
    SUB_DRIVER_LANDING: 'subdriverlandingpage',
    SUB_DRIVER_GROWTH_PLAN: 'subdrivergrowthplan',
    VIEW_GROWTH_PLAN:'viewAllGrowthPlan',
    GROWTH_PLAN_SESSION: 'growthPlanSession',
    FOP: 'fieldsOfPlay',
    MENUCONTENT: 'menucontent',
    STANDARD_REPORT_TEMPLATE: 'standardReportTemplate',
    CUSTOM_REPORT_TEMPLATE_1: 'customReportTemplateOne',
    CUSTOM_REPORT_TEMPLATE_2: 'customReportTemplateTwo',
    CUSTOM_REPORT_TEMPLATE_3: 'customReportTemplateThree',
    CUSTOM_REPORT_TEMPLATE_4: 'customReportTemplateFour',
    CUSTOM_REPORT_TEMPLATE_5: 'customReportTemplateFive',
    ABOUT_SEVEN_DRIVERS: 'aboutSevenDrivers',
};