import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { ModalDialogService } from '../modal-dialog.service';


@Component({
    selector: "add-notes",
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})

export class addNotesComponent implements OnInit, AfterViewInit {

    //SiteContents: any = {};
    observable: Observable<any>;
    //codeOfConduct: string = "";
    subject: any;
    //data: any;
    inputObj: any;
    btnSubmitText: string = "";
    lblNotes: string = ""
    noteValue: string;
    lblEditNote: string;
    @Output("closeEvent") componentEvent: any = new EventEmitter()
  @ViewChild('addNotesModal', { static: true }) addNotesModal: ModalDirective;
  @ViewChild("titleTextArea", { static: false }) titleTextArea: ElementRef;
    showNote = true;
   
    card: any;
    isLocked: boolean;
    isContainingSpecialCharacter:boolean=false;
    customActionCardRequired :string;
    constructor(
     
        private injector: Injector,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private dialogService: ModalDialogService,
    ) {
        this.inputObj = this.injector.get('inputObj');
        this.card = this.inputObj.card;
        this.isLocked = this.inputObj.isLocked;
        this.btnSubmitText = this.inputObj.btnSubmit;
        this.lblNotes = this.inputObj.lblNote;
        this.noteValue = this.card.note ? this.card.note : '';
        this.lblEditNote = this.inputObj.lblEditNote;
        this.customActionCardRequired = this.inputObj.lblcustomActionCardRequired
        if (!this.noteValue)
            this.showNote = false;
        //this.getCodeOfConduct();
    }

    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


  //  @ViewChild('addNotesModal') termsModal: any;
    returnedValue: any = {};
    // offsetTop = 0;
    ngOnInit() {
    }

    autogrow() {
        var textArea = this.titleTextArea.nativeElement;
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }


    cancelClicked() {

        this.returnedValue = "no";
        this.closeDialog();
    }
    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });

    }
    ngAfterViewInit() {
        //this.input.nativeElement.focus();
        //var me = this;
        //setTimeout(function () {
        //    me.input.nativeElement.focus();
        //});
        //setTimeout(function () {
        //  //  me.show = true;
        //    me.topMargin = me.inputObj.offseTop ? -200 : 0;

        //    me.input.nativeElement.focus();


        //}, 1000);
    }
    okayClicked() {
        // restrict the readonly growth plan changes
        if (this.isLocked) {
            return;
        }

        let params = {
            'growthPlanActionId': this.card.growthPlanActionId,
            'actionNote': this.noteValue,
            'mode': 1
        }
        this.velocityService.saveActionNote(params).subscribe(
            (data: any) => {
                this.returnedValue.note = this.noteValue;
        this.returnedValue.action = 'yes';
        this.closeDialog();
            });
        //if (this.isTitleMandatory) {
        //    if (this.titleValue == "") {
        //        this.showError = true;
        //        return;
        //    }
        //}
        //var returnedValues = [this.titleValue, this.subtitleValue];
        //this.returnedValue.params = returnedValues;
        //this.returnedValue.action = 'yes';
        //this.closeDialog();

        //let modifiedData = { id: card.id, growthPlanId: this.growthPlanId, title: this.EditedActionTitle, driverId: this.driverId };
        //this.velocityService.modifyCustomActionCards(modifiedData).subscribe(
        //    (data: any) => {
        //        if (data) {
        //            card.IsEdit = false;
        //            this.IsCardEdit = false;
        //        }
        //    }
        //);
    }
    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.addNotesModal.config = this.config;
        this.addNotesModal.show();
        return this.observable;
    }

    public closeDialog() {

        this.addNotesModal.hide();
    }
    onChange(e){
        let isValidGp = this.dialogService.validateAlphanumericName(this.noteValue);
        if (!isValidGp) {
          this.isContainingSpecialCharacter = false;
        }
        else {
          if (this.noteValue.trim() != "") {
            this.isContainingSpecialCharacter = true;
        
          }
          else {
            this.isContainingSpecialCharacter = false;
          }
        }
      }
      isSubmitDisabled(){
        if(this.noteValue.trim()=='' || this.showNote || this.isContainingSpecialCharacter)
            return true;
        return false;    
      }
}


