<div class="modal fade landing-modal"
     bsModal #landingModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bullet-style">

            <div *ngIf="!isPrivacy" class="floating-dialog-close float-right" (click)="closeDialog()">
                <img class="whiteClose" src="../../../assets/images/close-bt.png" />
                <!--<div class="white-close-tablet float-right" *ngIf="!isPrivacy"></div>-->
                <!--<span class="close-text">{{SiteContents.lblClose}}</span>-->
            </div>

            <div class="float-right privacy-back-to-contact" *ngIf="isPrivacy" (click)="closePrivacy()">
                <!--<i class="fa fa-arrow-left" style="font-size:20px"></i>-->
                <img src="../../../assets/images/back-icon.png"/>
                {{SiteContents.buttonBack}}
            </div>

            <div *ngIf="page==1" eyv-update-panel="landingPage">
                <div class="modal-header" [innerHTML]="titleText">
                </div>
                <div class="modal-body" custom-scroll>
                    <p [innerHTML]="contents"></p>
                </div>
            </div>
            <div *ngIf="page==2" eyv-update-panel="landingPage">
                <div class="modal-header" [innerHTML]="titleText">
                </div>
                <div class="modal-body" custom-scroll>
                    <p [innerHTML]="contents"></p>
                </div>
            </div>

            <div *ngIf="page==3">
                <div class="modal-header" [innerHTML]="titleText"></div>
                <div class="modal-body" custom-scroll>
                    <div class="contact-container" *ngIf="isLoad && !isPrivacy">
                        <form action="">
                            <label for="name">{{pageContents.lblYourName}} </label>
                            <input [(ngModel)]="contactForm.name" name="name" autocomplete="off" maxlength="80" class="c-input-margin" [ngClass]="{'red-border-class': !nameValidator}" type="text" placeholder="">
                            <div *ngIf="!nameValidator" class="alert alert-danger-landing contact-error-div">{{pageContents.msgNameValidation}}</div>

                            <label for="email">{{pageContents.lblEmail}}</label>
                            <input [(ngModel)]="contactForm.email" name="email" autocomplete="off" maxlength="80" class="c-input-margin" [ngClass]="{'red-border-class': !emailValidator}" type="text" placeholder="">
                            <div *ngIf="!emailValidator" class="alert alert-danger-landing contact-error-div">{{emailErrorText}}</div>

                            <label for="ccountry">{{pageContents.lblCountry}} </label>
                            <select [(ngModel)]="contactForm.ccountry" class="c-input-margin" id="ccountry" name="ccountry">
                                <option value="0">{{pageContents.lblSelectCountry}}</option>
                                <option value="{{country.id}}" *ngFor="let country of countryList">{{country.text}}</option>
                            </select>


                            <label for="c_reason">{{pageContents.lblContactReason}} </label>
                            <select [(ngModel)]="contactForm.creason" class="c-input-margin" [ngClass]="{'red-border-class': !reasonValidator}" id="c_reason" name="c_reason">
                                <option value="0">{{pageContents.lblSelectReason}}</option>
                                <option value="{{reason.id}}" *ngFor="let reason of contactReason">{{reason.text}}</option>
                            </select>
                            <div *ngIf="!reasonValidator" class="alert alert-danger-landing contact-error-div">{{pageContents.msgReasonValidation}} </div>

                            <label for="cotact-query">{{pageContents.lblDescription}} </label>
                            <textarea [(ngModel)]="contactForm.desc" name="cotact-query" maxlength="250" class="c-input-margin" [ngClass]="{'red-border-class': !descValidator}" placeholder=""></textarea>
                            <div *ngIf="!descValidator" class="alert alert-danger-landing contact-error-div">{{pageContents.msgDescriptionValidation}}</div>

                            <p><label><span (click)="openPrivacy()" class="cpage-link">Click here</span> to read privacy policy</label></p>
                            <div class="contact-actions">
                                <button class="btn btn-outline" type="button" (click)="validateContactUs()"> {{siteContents.buttonSubmit}}</button>
                                <span class="contact-or">{{siteContents.lblOr}}</span>
                                <div class="cancelLink" (click)="cancelContactUs()"><span>{{siteContents.buttonCancel}}</span></div>
                            </div>
                            
                            <!--<button class="btn btn-cancel" type="button" (click)="cancelContactUs()">{{siteContents.buttonCancel}}</button>-->
                        </form>
                    </div>
                    <div class="contact-container-success" *ngIf="!isLoad">
                        <div class="centerContactDiv">
                            <!--<div class="tick-div">
                                <i class="fa fa-check tick-icon" aria-hidden="true"></i>
                            </div>-->
                            <span [innerHTML]="pageContents.msgConfirm"></span>
                        </div>
                    </div>
                    <div class="contact-container-privacy" *ngIf="isPrivacy" eyv-update-panel="landingPage">
                        <p [innerHTML]="contents"></p>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>