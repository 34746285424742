import { Directive, Input,Output, EventEmitter, Renderer2, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from './../../service/util.service';
import { DragStartEvent, DragStopEvent } from './models/dragevent';
import { DragDataService } from './service/drag-data.service';
//import { setTimeout } from 'core-js/library/web/timers';
declare var $: any;

@Directive({
    selector: '[eyv-draggable]'

})
export class DraggableDirective implements OnInit, OnDestroy, OnChanges {
    
    @Input('eyv-dragEnabled') dragEnabled?: boolean = true;
    @Input('eyv-dragData') dragData: any;
    @Input('eyv-dragData-size') size: any;

    @Output("onEYVDragStart") onDragStart: EventEmitter<any> = new EventEmitter<any>();
    @Output("onEYVDragStop") onDragStop: EventEmitter<any> = new EventEmitter<any>();



    constructor(private renderer: Renderer2, private el: ElementRef, private utilService: UtilService, private dragDataService: DragDataService) {
        let component: DraggableDirective = this;
        if (this.dragEnabled == false) {
            $(el.nativeElement).draggable({ disabled: true });
        }
        else {
            let component: DraggableDirective = this;
            setTimeout(() => {
                $(el.nativeElement).draggable({
                    appendTo: "body",
                    cursorAt: { top: 0, left: 0 },
                    opacity: 0.5,
                    helper: function (event) { return component.getHelper(event, component.dragData.cardNumber,component.size) },
                    start: function (event, ui) {
                        
                        component.dragDataService.dragData = component.dragData;
                        component.dragDataService.isDragging = true;
                        //console.log("started", event, ui);
                        var mouseEvent = { offsetX: 0, offsetY: 0 };
                        var dsEvent: DragStartEvent = { event: event, ui: ui, mouseEvent: mouseEvent, dragData: component.dragData };
                        component.onDragStart.emit(dsEvent);
                    },
                    stop: function (event, ui) {
                        component.dragDataService.dragData = null;
                        //console.log("stopped", event, ui);
                        var mouseEvent = { offsetX: 0, offsetY: 0 };
                        var dsEvent: DragStopEvent = { event: event, ui: ui, mouseEvent: mouseEvent, dragData: component.dragData };
                        component.onDragStop.emit(dsEvent);
                        setTimeout(() => {
                            component.dragDataService.isDragging = false;
                        }, 200);
                    }
                });
            });
        }


    }

    ngOnInit() {

    }

    getHelper(event: any, cardNumber: any,size:any ): any {
        //TODO - Get handler template from parent
        //console.log("helper",event);
        //return $("<div style='width:100px;height:100px;background-color:red;color:#fff'>I'm a custom helper</div>")
        var draggableHandle = $(`<div class="gp-action-bubble ${size}" style="z-index:900005 !important;">${cardNumber}</div>`);
        //console.log(draggableHandle);
        return draggableHandle;
    }




    ngOnDestroy() {

    }



    ngOnChanges(changes: SimpleChanges) {
        for (let prop in changes) {
            if (prop == 'dragEnabled') {
                var change = changes[prop];
                if (change.currentValue == true) {
                    $(this.el.nativeElement).draggable({ disabled: false });
                }
                else {
                    $(this.el.nativeElement).draggable({ disabled: true });
                }
            }
        }
    }

}
