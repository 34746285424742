import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './../service/user.service'
import { AuthenticationService } from '../service/auth.service';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { currencyFormatter } from '../../pipe/currency-formatter.pipe';
import { VelocityService } from '../../service/velocity.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {


    constructor(private router: Router,
        private userService: UserService,
        private authService: AuthenticationService,
        private dialogService: ModalDialogService,
        private velocityService: VelocityService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve, reject) => {
            var currentUser: any = this.userService.getCurrentUser();
            if (route.routeConfig.path == "landing" || route.routeConfig.path == "welcome" || route.routeConfig.path == "login" || route.routeConfig.path == "privacy" || route.routeConfig.path == "register") {

               // console.log(process.env.ENV)
                if (route.routeConfig.path == "login") {

                  if (environment.environmentName != "dev" && environment.environmentName != "qa") {

                        this.router.navigate(['welcome']);
                        resolve(false);
                        return;
                    }
                }

                //User exists in local
                if (currentUser && currentUser.token) {
                    let main = this;
                    console.log(currentUser);
                    if (!currentUser.IsTCAccepted && currentUser.userType == 2) {
                        this.dialogService.termsModal('Afterlogin').subscribe(
                            (data) => {
                                if (data && data.action && (data.action === 'yes')) {
                                    main.userService.TCSubject.next(true);

                                    main.userService.updateUserTC(true);

                                    main.authService.checkAuth().subscribe((x: boolean) => {
                                        // Check the user has permission to access the page
                                        // If NO route to permission denied page
                                        //if (this.hasPermission(route, currentUser)) {
                                        //User is valid - redirect to dash board or return URL
                                        var returnUrl = '/dashboard';//route.queryParams.returnUrl || '/dashboard';
                                        main.router.navigate([returnUrl]);
                                        resolve(false);
                                        //} else {
                                        //    this.router.navigate(['/general/error-404']);
                                        //    resolve(false);
                                        //}

                                    },
                                        (err: any) => {
                                            //Token is not valid - permit redirect to login
                                            main.userService.logout();
                                            resolve(true);
                                        });
                                } else {
                                    main.userService.logout();
                                    resolve(true)
                                }
                            });
                    }
                    else {
                        this.authService.checkAuth().subscribe((x: boolean) => {
                            // Check the user has permission to access the page
                            // If NO route to permission denied page
                            //if (this.hasPermission(route, currentUser)) {
                            //User is valid - redirect to dash board or return URL
                            var returnUrl = '/dashboard';//route.queryParams.returnUrl || '/dashboard';
                            this.router.navigate([returnUrl]);
                            resolve(false);
                            //} else {
                            //    this.router.navigate(['/general/error-404']);
                            //    resolve(false);
                            //}

                        },
                            (err: any) => {
                                //Token is not valid - permit redirect to login
                                main.userService.logout();
                                resolve(true);
                            });
                    }
                }
                else {
                    //No token - redirect to login
                    resolve(true);
                }


            }
            else {
                if (currentUser && currentUser.token) {
                    this.authService.checkAuth().subscribe((x: boolean) => {

                        // Check the user has permission to access the page
                        // If NO route to permission denied page
                        if (this.hasPermission(route, currentUser)) {
                            //User is valid - redirect to requested URL

                            /* 
                             * If User Has Menu Permission it will additionally check for URL permission as well
                             * This is the part of PE implementation
                             * Currently this check is only for discussion thread and comunity details page
                             * */

                            let params = {};
                            let needApiCall = 0;
                            /*
                                console.log("path is -------------------" + route.routeConfig.path);
                                console.log(route);
                            */
                            if (route.routeConfig.path == "community/:communityId") {
                                params = {
                                    type: 1,
                                    id: route.params.communityId
                                }
                                needApiCall = 1;
                            }
                            else if (route.routeConfig.path == "discussion/:threadId") {
                                params = {
                                    type: 2,
                                    id: route.params.threadId
                                }
                                needApiCall = 1;
                            }
                            else if (route.routeConfig.path == "event-detail/:eventId") {
                                params = {
                                    type: 3,
                                    id: route.params.eventId
                                }
                                needApiCall = 1;
                            }
                            else if (route.routeConfig.path == "hot-topics/detail/:id") {
                                params = {
                                    type: 4,
                                    id: route.params.id
                                }
                                needApiCall = 1;
                            }
                            else if (route.routeConfig.path == "tool-details/:toolId/:sourceId/:driverId/:subDriverId") {
                                params = {
                                    type: 5,
                                    id: route.params.toolId
                                }
                                needApiCall = 1;
                            }
                            else if (route.routeConfig.path == "corporate-growthplan") {
                                needApiCall = 2;
                            } else if (route.routeConfig.path == "fac-corporate-growthplan" || route.routeConfig.path == "demo-corporate-growthplan") {
                                needApiCall = 3;
                            }
                            else {
                                needApiCall = 0;
                            }

                            if (needApiCall === 1) {
                                // Make the API call and return true or false based on user access to Community / Discussion as per PE
                                this.authService.isAuthorizedURL(params).subscribe(
                                    (result) => {
                                        if (result) {
                                            resolve(true);
                                        }
                                        else {
                                            this.router.navigate(['/general/error-404']);
                                            resolve(false);
                                        }
                                    }
                                )
                            }
                            // else if (needApiCall === 2) {
                            //     this.velocityService.checkIsFacilitator().subscribe((data) => {
                            //         if (data.IsCorporateFacilitator) {
                            //             resolve(true); // Permission for Corporate GP
                            //         }
                            //         else {
                            //             this.router.navigate(['/general/error-404']);
                            //             resolve(false);
                            //         }
                            //     }); 
                            // } 
                            else if (needApiCall === 3) {
                                this.velocityService.checkIsFacilitator().subscribe((data) => {
                                    if (data.IsFacilitator) {
                                        resolve(true); // Permission for Corporate GP
                                    }
                                    else {
                                        this.router.navigate(['/general/error-404']);
                                        resolve(false);
                                    }
                                });
                            }
                            else {
                                resolve(true);
                            }
                            //########################### Check weather user has URL permission ############# Ends Here #########################

                        } else {
                            if (route.routeConfig.path == "fop" || route.routeConfig.path =='about-ey.aimaturitymodel' || route.routeConfig.path =='about-ey.aimaturitymodel/steps') {
                                this.router.navigate(['/general/permission-denied']);

                            } else {
                                this.router.navigate(['/general/error-404']);
                            }
                            resolve(false);
                        }
                        //resolve(true);
                    },
                        (err: any) => {
                            this.clearUserSessionAndRedirect(state.url);
                            resolve(false);
                        });
                }
                else {
                    //Clear user values in local / session and redirect
                    this.clearUserSessionAndRedirect(state.url);
                    resolve(false);
                }


            }



        });




        //Old code

        //var currentUser: any = this.userService.getCurrentUser();

        //if (route.routeConfig.path == "welcome" || route.routeConfig.path == "login") {

        //    if (currentUser && currentUser.token) {
        //        var returnUrl = route.queryParams.returnUrl || '/dashboard';

        //        this.router.navigate([returnUrl]);
        //        return false;
        //    }

        //    return true;
        //} else {
        //    if (currentUser && currentUser.token) {
        //        // logged in so return true
        //        return true;
        //    }

        //    console.log("not logged in")
        //    // not logged in so redirect to login page
        //    this.router.navigate(['/welcome'], { queryParams: { returnUrl: state.url } });
        //    return false;
        //}
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve, reject) => {
            var currentUser: any = this.userService.getCurrentUser();
            if (currentUser && currentUser.token) {
                this.authService.checkAuth().subscribe((x: boolean) => {
                    //User is valid - redirect to requested URL
                    resolve(true);
                },
                    (err: any) => {
                        //Token is not valid - clear token in local and redirect to login
                        this.clearUserSessionAndRedirect(state.url);
                        resolve(false);
                    });
            }
            else {
                this.clearUserSessionAndRedirect(state.url);
                resolve(false);
            }


        });
    }



    clearUserSessionAndRedirect(returnUrl?: string) {
        this.userService.logout();
        //var dialogText = "Your session has expired route.";
        //this.dialogService.alert("", dialogText, false).subscribe(x => {
     
            this.router.navigate(['/welcome'], { queryParams: { returnUrl: returnUrl } });
        
        //});


    }

    hasPermission(route: any, user: any) {
        let routePermission = route.data["permission"] as string;
        if (routePermission == "" || routePermission == " " || routePermission == undefined || routePermission == null) {
            return false;
        }

        // Permission check for general pages. t&c , privacy etc
        if (routePermission == "General") {
            return true;
        }

        for (let menu of user.portalPermissions) {
            if (menu.hasChild && menu.children.length > 0) {
                for (let submenu of menu.children) {
                    if (submenu.menuName == routePermission) {
                        return true;
                    }
                }
            }
            else {
                if (menu.menuName == routePermission) {
                    return true;
                }
            }
        }

        return false;
    }
}
