import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';

import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { SaveAssessment } from './model/save-assessment.model';
import { SaveGrowthPlanAction } from '../focus/model/save-growthPlanAction.model';
import { ActionCard } from '../focus/model/action-card.model';
import { SaveCustomAction } from '../focus/model/save-newCustomAction.model';
import { GetHotTopics } from '../focus/model/get-hot-topics.model';
import { ProgressBarOptions } from './../progress-bar-module/progress-bar-options';
import { SaveGrowthPlanActionPrioritize, SavePrioritizeActionsModel, ParticipantPrioritizeModel } from '../focus/model/save-gp-action-prioritize.model';
import { SaveActionTask } from './model/save-action-task';
import { SaveRagCost } from './model/save-rag-cost';
import { AjaxErrorOptions, ErrorHandlerHelper } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { StorageHelperService } from './storage-helper.service';
import { GrowthPlanListDto, GrowthPlanSessionDto } from '../focus/model/face-to-face.model';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';


const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class VelocityService {
    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router: Router,
        private storageHelper: StorageHelperService
    ) { }

    invokeGetAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {

        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                      throwError(response.message);
                    }
                }, (error: any) => {
                    this.handleError(error, errorHandler);
                    //this.utilService.errorHandler(error);
                });
        });
    }

    invokePostAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();
        if (user) {
            params.userId = user.userId;
        }
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else if (response && response.status && (response.code === '80001')) {
                        observer.next(response);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        observer.next(response);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostWithOutUserIdAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }


    invokePostDownloadAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();
        //if (user) {
        //    params.userId = user.userId;
        //}
        return Observable.create(observer => {
            this.utilService.doDownload(ApiUrl + url, params, progressBarOptions).subscribe(
                //this.utilService.doDownload('http://localhost:49226/api/' + url, params, progressBarOptions).subscribe(
                (response: any) => {
                    observer.next(response);
                    observer.complete();
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostUploadAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();

        return Observable.create(observer => {
            this.utilService.doUpload(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {
        //Growth plan validation
        if (errorCode == "400001") {
            this.router.navigate(['general', 'error404']);
        }
        else if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (errorCode == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
            this.utilService.showLoginPopup();
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }

    }

    handleError(error, errorHandler?: AjaxErrorOptions) {
        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (error.status == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
            this.utilService.showLoginPopup();
        }
        else if (error.status == "404" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }
    getDriverAssessmentsWithGPID(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();

        return this.invokeGetAPI(`${this.constants.FOCUS.GET_DRIVER_ASSESSMENT}/${user.userId}` + '/' + growthPlanId, {}, progressBarOptions);


    }
    getDriverAssesments(progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';

        return this.invokeGetAPI(`${this.constants.FOCUS.GET_DRIVER_ASSESSMENT}/${user.userId}` + growthPlanId, {}, progressBarOptions);

        //var dummyAssessmentValues = { "growthPlan": { "id": 1, "userId": 13, "IsAnyDriverAssessed": true, "IsAnyThemeAssessed": true, "IsAnySubThemeAssessed": false }, "driverAssessments": [{ "Id": "gd0004", "presentValue": 1, "aspirationalValue": 9, "presentValueAverage": 4.00, "aspirationalValueAverage": 8.00 }, { "Id": "gd0001", "presentValue": 1, "aspirationalValue": 5, "presentValueAverage": 1.50, "aspirationalValueAverage": 2.50 }], "driverThemeAssessments": [{ "Id": "dt0002", "presentValue": 4, "aspirationalValue": 8, "presentValueAverage": 1.50, "aspirationalValueAverage": 3.25 }, { "Id": "dt0001", "presentValue": 2, "aspirationalValue": 3, "presentValueAverage": null, "aspirationalValueAverage": null }, { "Id": "dt0003", "presentValue": 1, "aspirationalValue": 2, "presentValueAverage": 4.00, "aspirationalValueAverage": 5.00 }], "driverSubThemeAssessments": [] };

        //return dummyAssessmentValues;
    }

    getDriverGridSummary(progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        return this.invokeGetAPI(`${this.constants.FOCUS.SEVEN_DRIVER_GRID_SUMMARY}/${user.userId}` + growthPlanId, {}, progressBarOptions);
    }
    getDriverGridSummaryWithGrowthPlanId(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.SEVEN_DRIVER_GRID_SUMMARY}/${user.userId}/` + growthPlanId, {}, progressBarOptions);
    }
    getGrowthPlanDetails(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_GROWTH_PLAN_DETAILS}/${user.userId}/` + growthPlanId, {}, progressBarOptions);
    }
    getCorporateOwnerGrowthPlanDetails(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_CORPORATE_OWNER_GP_DETAILS}/` + growthPlanId, {}, progressBarOptions);
    }
    getGrowthPlan(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_GROWTH_PLAN}/` + growthPlanId, {}, progressBarOptions);
    }

    getDriverAssessment(params: any, progressBarOptions?: ProgressBarOptions) {
        const sibling = params.sibling || 0;
        const child = params.child || 0;
        const user = this.userService.getCurrentUser();
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_DRIVER_ASSESSMENT}/${user.userId}/${params.driverId}/${sibling}/${child}` + growthPlanId, {}, progressBarOptions);
    }
    getParticipantAssessment(params: any, progressBarOptions?: ProgressBarOptions) {
        const themeId = params.themeId || 0;
        const user = this.userService.getCurrentUser();
        let growthPlanId;
        if (!params.growthPlanId) {
            growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        } else {
            growthPlanId = '/' + params.growthPlanId;
        }
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_PARTICIPANT_DRIVER_ASSSESSMENT}/${user.userId}/${params.driverId}/${themeId}` + growthPlanId, {}, progressBarOptions);
    }
    getRealParticipantAssessment(params: any, progressBarOptions?: ProgressBarOptions) {
        const themeId = params.themeId || 0;
        const user = this.userService.getCurrentUser();
        let growthPlanId;
        if (!params.growthPlanId) {
            growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        } else {
            growthPlanId = '/' + params.growthPlanId;
        }
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_REAL_PARTICIPANT_ASSESSMENT}` + growthPlanId + `/${params.driverId}/${themeId}`, {}, progressBarOptions);
    }
    getRealParticipantAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_REAL_PARTICIPANT_ACTIONS}/${params.growtPlanId}/${params.driverId}/${params.subDriverId}`, {}, progressBarOptions);
    }
    getDriverThemeAssessment(params?: any, progressBarOptions?: ProgressBarOptions) {
        const sibling = params.sibling || 0;
        const child = params.child || 0;
        const user = this.userService.getCurrentUser();
        const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '0';
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_THEME_ASSESSMENT}/${user.userId}/${params.driverId}/${params.themeId}/${sibling}/${child}/${growthPlanId}`, {}, progressBarOptions);
    }

    getDriverSubThemeAssessment(params: any, progressBarOptions?: ProgressBarOptions) {
        const sibling = params.sibling || 0;
        const child = params.child || 0;
        const user = this.userService.getCurrentUser();
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_SUB_THEME_ASSESSMENT}/${user.userId}/${params.gdId}/${params.dtId}/${params.stId}/${sibling}/${child}` + growthPlanId, {}, progressBarOptions);
    }

    saveAssessment(params: SaveAssessment) {
        if (!params.growthPlanId) {
            const growthPlanId = this.userService.getGrowthplanId();
            if (growthPlanId) {
                params.growthPlanId = growthPlanId;
            }
        }
        return this.invokePostAPI(this.constants.FOCUS.SAVE_ASSESSMENT, params);
    }

    getCustomActionCards(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_ACTION_CARD_DETAILS}/${params.growthPlanId}/${params.driverId}/${params.themeId}`, {}, progressBarOptions);
    }

    saveSelectedActionCards(params: SaveGrowthPlanAction[]) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_SELECTED_ACTION_CARDS, params);
    }

    saveNewCustomActionCards(params: SaveCustomAction) {
        var errorHandler = ErrorHandlerHelper.Default;
        return this.invokePostAPI(this.constants.FOCUS.SAVE_NEW_CUSTOMACTION_CARD, params, null, errorHandler);
    }

    modifyCustomActionCards(params: any) {
        var errorHandler = ErrorHandlerHelper.Default;
        return this.invokePostAPI(this.constants.FOCUS.MODIFY_CUSTOMACTION_CARD, params, null, errorHandler);
    }

    getHelpContentURL(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.FOCUS.NEED_HELP_POPUP_API, {}, progressBarOptions);
    }

    getHelpUserProfileContentURL(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.NEED_HELP_USERPROFILE_POPUP_API, {}, progressBarOptions);
    }

    getLoginHelpContentURL(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.FOCUS.LOGIN_HELP_POPUP_API, {}, progressBarOptions);
    }
    getTermsAndCondition(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_TERMS_CONDITION, {}, progressBarOptions);
    }

    getUserTermsAndCondition(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_USER_TERMS_CONDITION, {}, progressBarOptions);
    }

    getPrivacyPolicy(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_PRIVACY_POLICY, {}, progressBarOptions);
    }

    getPrivacyPolicyShort(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_PRIVACY_POLICY_SHORT, {}, progressBarOptions);
    }

    getCodeofConduct(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_CODE_OF_CONDUCT, {}, progressBarOptions);
    }

    getWhyEY(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_WHY_EY, {}, progressBarOptions);
    }

    getAboutEYVelocity(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_ABOUT_EY_VELOCITY, {}, progressBarOptions);
    }

    getRegisterTopHottopics(params: any) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
        return this.invokePostAPI(`${this.constants.AUTH.API_HOT_TOPIC_LIST}`, params, null, errorHandler);
    }

    getGrowthPlanActions(growthPlanId: number, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_GROWTH_PLAN_ACTIONS}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    }

    //getGrowthPlanForDashboard(progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    const growthPlanId = this.userService.getGrowthplanId();
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_GROWTH_PLAN_ACTIONS_DASHBOARD}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    //}

    //saveGrowthPlanActionPrioritize(params: SaveGrowthPlanActionPrioritize[]) {
    saveGrowthPlanActionPrioritize(params: SavePrioritizeActionsModel) {
        return this.invokePostAPI(`${this.constants.FOCUS.GROWTH_PLAN_Action}/${this.constants.FOCUS.PRIORITIZE}`, params);
    }

    getParticipantPrioritization(params: ParticipantPrioritizeModel, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.GET_PARTICIPANT_PRIORITIZATION}`, params, progressBarOptions);
    }
    saveAssessmentMode(params: any) {
        return this.invokePostAPI(`${this.constants.FOCUS.SAVE_ASSESSMENT_MODE}`, params );
    }
    //getCustomActionDetails(actionId: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.FOCUS.CUSTOM_ACTION_DETAILS}/${user.userId}/${actionId}`, {}, progressBarOptions);
    //}
    getActionTask(growthPlanActionId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_ACTION_TASK}/${user.userId}/${growthPlanActionId}`, {}, progressBarOptions);
    }
    saveActionTask(saveItem: SaveActionTask, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_ACTION_TASK, saveItem, progressBarOptions);

    }
    saveActionRagEstimate(saveItem: SaveRagCost, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_RAG_ESTIMATE, saveItem, progressBarOptions);

    }

    saveActionProperty(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SET_ACTION_PROPERTY, params, progressBarOptions);

    }
   
    /* HOT TOPICS Start*/
    //getHotTopics(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.GET_HOT_TOPICS, params, progressBarOptions);
    //}

    //getHTTopicDetails(topicId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
    //    var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
    //    if (isMainApi) {
    //        errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
    //    }
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_HT_DETAILS}/${topicId}/${user.userId}`, {}, progressBarOptions, errorHandler);
    //}
    //getHTSurvey(topicId: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_HT_SURVEY}/${topicId}/${user.userId}`, {}, progressBarOptions);
    //}

    //getHTCards(topicId: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_HT_CARDS}/${topicId}`, {}, progressBarOptions);
    //}

    //saveHTSurveyResults(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.SAVE_HT_SURVEY_RESULT, params, progressBarOptions);
    //}

    //getHTSubTopics(topicId: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_HT_SUB_TOPICS}/${topicId}`, {}, progressBarOptions);
    //}
    //getHTSubTopicDetails(topicId: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_HT_SUB_TOPIC_DETAILS}/${topicId}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    //}
    //getHTRelativeEvents(topicId: any, pageCount: number, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokeGetAPI(`${this.constants.FOCUS.GET_RELATIVE_EVENTS}/${topicId}/${pageCount}`, {}, progressBarOptions);
    //}

    //followCommunityThread(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.FOLLOW_HT_COMMUNITY_QUESTION, params, progressBarOptions);
    //}
    //likeHotTopic(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.LIKE_HT, params, progressBarOptions);
    //}
    //getHTActionSummary(topicId: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
    //    return this.invokeGetAPI(`${this.constants.FOCUS.HT_ACTION_SUMMARY}/${topicId}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    //}

    //removeConsiderations(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.REMOVE_CONSIDERATIONS, params, progressBarOptions);
    //}
    /* HOT TOPICS End*/

    /* Communites and Events  start*/
    //getRecommendedCommunities(params: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.CONNECT.GET_RECOMMENDED_COMMUNITY}/${user.userId}/${params.currentPage}`, {}, progressBarOptions);
    //}

    //getEventDetails(eventId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
    //    var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
    //    if (isMainApi) {
    //        errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
    //    }
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.CONNECT.EVENT_DETAILS}/${eventId}/${user.userId}`, {}, progressBarOptions, errorHandler);
    //}

    //getEDRecentEvents(eventId: any, pageCount: number, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokeGetAPI(`${this.constants.CONNECT.EVENT_RECENT_EVENTS}/${eventId}/${pageCount}`, {}, progressBarOptions);
    //}

    //getUpcomingEvents(params: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.CONNECT.GET_UPCOMING_EVENTS}/${user.userId}`, {}, progressBarOptions);
    //}

    //getCommunityEvents(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.CONNECT.GET_COMMUNITY_EVENTS, params, progressBarOptions);
    //}

    getProfileDetails(userId: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        return this.invokeGetAPI(`${this.constants.USER.GET_PROFILE_DETAILS}/${userId}`, {}, progressBarOptions, errorHandler);
    }

    //subscribeToEvent(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.CONNECT.EVENT_SUBSCRIBE, params, progressBarOptions);
    //}

    //getMyEventsList(page: number, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.CONNECT.GET_MY_EVENTS_LIST}/${user.userId}/${page}`, {}, progressBarOptions);
    //}

    //getMyUpcomingEvents(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.CONNECT.GET_MY_UPCOMING_EVENTS, params, progressBarOptions);
    //}

    //getAllEvents(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.CONNECT.GET_ALL_EVENTS, params, progressBarOptions);
    //}

    /* Communites and Events  end*/

    /* Dashbaord Start */
    //getMyInsightCards(pageNo: number, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.DASHBOARD.MY_INSIGHT_LIST}/${user.userId}/${pageNo}`, {}, progressBarOptions);
    //}

    //getDashboardToolsList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_DASHBOARD_TOOLS, params);
    //}

    //getDashboardResourcesList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_DASHBOARD_RESOURCES, params);
    //}

    getFieldsOfPlayMatrics(currentGrowthPlanId?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_FIELDS_OF_PLAY_MATRICS}/${currentGrowthPlanId}`, {}, progressBarOptions);
    }

    getDriverMetrics(currentGrowthPlanId?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_DRIVER_METRICS}/${currentGrowthPlanId}`, {}, progressBarOptions);
    }

    getFieldsOfPlayReport(currentGrowthPlanId?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_FIELDS_OF_PLAY_REPORT}/${currentGrowthPlanId}/true`, {}, progressBarOptions);
    }

    getFieldsOfPlayActions(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_FIELDS_OF_PLAY_ACTIONS}/${params.currentGrowthPlanId}/${params.fieldsOfPlayId}/${params.fopOrDriverType}`, {}, progressBarOptions);
    }

    //getIsFirstTimeUser(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
    //    return this.invokeGetAPI(`${this.constants.GENERAL.IS_FIRST_TIME_USER}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    //}
    /* Dashbaord End */

    /* Insight Pin Start */
    getInsightsPin(cardId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.FOCUS.SET_INSIGHT_PIN}/${user.userId}/${cardId}`, {}, progressBarOptions);
    }
    /* Insight Pin End */

    setInsightsPin(cardId: string, isPinned: number, source: string, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(this.constants.FOCUS.GET_INSIGHT_PIN, { userId: user.userId, cardId: cardId, source: source, isPinned: isPinned }, progressBarOptions);

    }

    getIfPageIsAccessed(pageName: string, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.GENERAL.IS_PAGE_ACCESSED}/${user.userId}/${pageName}`, {}, progressBarOptions);
    }

    setIfPageIsAccessed(pageName: string) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.GENERAL.SET_PAGE_ACCESSED}/${user.userId}/${pageName}`, {});
    }
    //setIfConnectPageIsAccessed() {
    //    const user = this.userService.getCurrentUser();
    //    var pageName = 'connect';
    //    return this.invokePostAPI(`${this.constants.GENERAL.SET_PAGE_ACCESSED_CONNECT}`, {});

    //}
    //savePinStatus(params: any) {
    //    const user = this.userService.getCurrentUser();
    //    params.userId = user.userId;
    //    return this.invokePostAPI(this.constants.FOCUS.SAVE_HT_PIN, params);
    //}

    /************** Tools and Resources Start ********************/
    //getToolsList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_TOOLS_LIST, params);
    //}

    //getResourcesList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_RESOURCE_LIST, params);
    //}

    //getActResourcesList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_ACT_RESOURCE_LIST, params);
    //}

    //getAllResourcesList(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.GET_ALL_RESOURCE_LIST, params);
    //}

    //subscribeToTool(params?: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.ACT.SUBSCRIBE_TO_TOOL, params);
    //}
    //IsAnyCardPinned(cardType: number, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGetAPI(`${this.constants.ACT.IS_CARD_PINNED}/${user.userId}/${cardType}`, {}, progressBarOptions);

    //}
    /************** Tools and Resources End ********************/

    setUserProfileImage(params: any) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        const user = this.userService.getCurrentUser();
        return this.invokePostUploadAPI(`${this.constants.USER.PROFILE_IMAGE}/${user.userId}`, params, null, errorHandler);

    }

    getUserProfileDetails(params: any) {
        //         {
        //                 userId : 9,
        //                 loadBasic : true,                                   load very basic info to be shown on the left panel.
        //                 loadRecentActivity : true,                    load recentActivities
        //                 loadUserInfo : true,                             load user information tab details
        //                 loadCompanyInfo : true,                    load company details
        //                 loadLocations : true                            load locations
        // }

        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.USER.PROFILE}`, { userId: user.userId, ...params });

    }

    getUserSettings(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.GET_USER_SETTINGS, {}, progressBarOptions);
    }
    getReportName(gid?: any, progressBarOptions?: ProgressBarOptions) {
        //return this.invokeGetAPI(this.constants.GENERAL.GET_REPORT_NAME, {}, progressBarOptions);
        var growthPlanId;
        if (gid) {
            growthPlanId = gid;
        } else {
            growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
        }
        return this.invokeGetAPI(`${this.constants.GENERAL.GET_REPORT_NAME}/${growthPlanId}`, {}, progressBarOptions);
    }
    getDriverActionStatus(gid?: any, progressBarOptions?: ProgressBarOptions) {
        let growthPlanId;
        if (gid)
            growthPlanId = gid;
        else
            growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
        return this.invokeGetAPI(`${this.constants.GENERAL.GET_DRIVER_ACTION_STATUS}/${growthPlanId}`, {}, progressBarOptions);
    }

    getActionStatusDetails(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.USER.GET_RAG_VALUES}`, {}, progressBarOptions);
    }

    getGpRagDetails(growthPlanId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.USER.GET_GROWTH_PLAN_RAG_VALUES}/${growthPlanId}`, {}, progressBarOptions);
    }

    updateUserProfileInfo(params: any) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.USER.UPDATE_PROFILE_INFO}`, { userId: user.userId, input: { ...params } });

    }

    updateLocationDetails(params: any) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.USER.UPDATE_PROFILE_INFO}`, { userId: user.userId, Location: { ...params } });

    }

    updateMultiSelectDetails(params: any) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.USER.UPDATE_PROFILE_INFO}`, { userId: user.userId, ...params });

    }

    setCompanyProfileImage(companyID: number, params: any) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        const user = this.userService.getCurrentUser();

        if (companyID == null) {
            companyID = 0;
        }

        return this.invokePostUploadAPI(`${this.constants.USER.COMPANY_PROFILE_IMAGE}/${user.userId}/${companyID}`, params, null, errorHandler);

    }

    //followUser(followUserId: number, action: number) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokePostAPI(`${this.constants.CONNECT.FOLLOW_USER}`, { followUserId: followUserId, action: action });
    //}

    updateTimezone(timezoneID: number) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.USER.UPDATE_TIMEZONE}`, { userId: user.userId, preferredTimezoneId: timezoneID });

    }

    updateCurrency(cID: number) {
        return this.invokePostAPI(`${this.constants.USER.UPDATE_CURRENCY}`, { currencyId: cID });
    }

    checkActionCost(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.GENERAL.CHECK_ACTION_COST, {}, progressBarOptions);
    }

    updateCurrencyForActiveGP(cID: number) {
        return this.invokePostAPI(`${this.constants.USER.UPDATE_GP_CURRENCY}`, { currencyId: cID });
    }


    /*********************** Notifications ******************************/

    getNotificationsCount(userId) {

        return this.invokeGetAPI(`${this.constants.NOTIFICATION.GET_NOTIFICATIONS_COUNT}/${userId}`);
    }

    getNotificationList(pageNo: number, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.NOTIFICATION.GET_NOTIFICATION_LIST}/${user.userId}/${pageNo}`, {}, progressBarOptions);
    }

    removeNotification(notificationId: number, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.NOTIFICATION.REMOVE_NOTIFICATIONS}`, { userId: user.userId, notificationId: notificationId, progressBarOptions });
    }


    getSearchCategories(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.GENERAL.SEARCH_CATEGORY}`, {}, progressBarOptions);

    }
    getSearchResults(params: any) {
        return this.invokePostAPI(this.constants.GENERAL.SEARCH_GLOBAL, params);
    }

    commentNotification(threadId: number, postId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.NOTIFICATION.COMMENT_NOTIFICATIONS}`, { threadId: threadId, postId: postId }, progressBarOptions);
    }

    getCountryTermsAndCondition(countryId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.GENERAL.GET_COUNTRY_TERMS_CONDITION}/${countryId}`, {}, progressBarOptions);
    }
    checkTC(oldCountryId: number, newCountryId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.GENERAL.CHECK_TERMS_CONDITION}/${oldCountryId}/${newCountryId}`, {}, progressBarOptions);
    }
    acceptTC(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.GENERAL.ACCEPT_TERMS_CONDITION}`, params, progressBarOptions);
    }
    getHelpTopics(sectionId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.GENERAL.HELP_TOPICS}/${sectionId}`, {}, progressBarOptions);

    }
    getHelpSections(topicId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.GENERAL.HELP_SECTIONS}/${topicId}`, {}, progressBarOptions);
    }
    saveContact(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.GENERAL.SAVE_CONTACT, params, progressBarOptions);
    }

    accountDeletion(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.GENERAL.ACCOUNT_DELETION, params, progressBarOptions);

    }

    saveNotificationStatus(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.GENERAL.SAVE_NOTIFICATION_STATUS, params, progressBarOptions);
    }

    /**********************  Upcoming Event and Videos  start*******************************/

    //getShowcaseEvents(params: any, progressBarOptions?: ProgressBarOptions) {
    //    let parameters: any = {};
    //    parameters.page = params.page || 1;
    //    parameters.sortBy = 1;
    //    parameters.pageSize = params.pageSize || 4;
    //    parameters.keyword = '';
    //    parameters.isActive = true;
    //    return this.invokePostAPI(this.constants.CONNECT.GET_SHOWCASE_EVENTS_LIST, parameters, progressBarOptions);
    //}
    //getShowcaseVideos(page: any, progressBarOptions?: ProgressBarOptions) {
    //    let parameters: any = {};
    //    parameters.page = page || 1;
    //    parameters.sort = 1;
    //    parameters.pageSize = 6;
    //    parameters.keyword = '';
    //    parameters.isActive = true;
    //    return this.invokePostAPI(this.constants.CONNECT.GET_UPCOMING_VIDOES_LIST, parameters, progressBarOptions);
    //}
    /**********************  Upcoming Event and Videos end*******************************/

    saveActionTitle(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_ACTION_TITLE, params, progressBarOptions);
    }
    saveActionNote(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SAVE_ACTION_NOTES, params, progressBarOptions);
    }
    saveActionOwnership(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_ACTION_OWNERSHIP, params, progressBarOptions);
    }
    getActionOwnership(actionId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_ACTION_OWNERSHIP}/${actionId}`, {}, progressBarOptions);
    }

    //----------------------Reset GN Starts------------------------------
    getResetInfo() {
        return this.invokeGetAPI(this.constants.GENERAL.GET_RESET_GN_INFO);
    }

    resetGN(param) {
        return this.invokePostAPI(this.constants.GENERAL.RESET_GROWTH_NAVIGATOR, param);
    }
    //----------------------Reset GN Ends ----------------------------

    /*------------------------Report-----------------------*/

    getReport(params: any, progressBarOptions?: ProgressBarOptions,) {
        return this.invokePostWithOutUserIdAPI(this.constants.ACT.GET_SUMMARY_REPORTS, params, progressBarOptions);
    }
    getCustomizeReport(reportId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.ACT.GET_REPORT_CUSTOMIZATION}/${reportId}`, progressBarOptions);
    }
    saveCustomizationReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SAVE_REPORT_CUSTOMIZATION, params, progressBarOptions);
    }
    finalizeReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.FINALIZE_REPORT, params, progressBarOptions);
    }
    saveDraftReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SAVE_DRAFT_REPORT, params, progressBarOptions);
    }
    createReport(templateId = 1) {
        let params;
        params = { 'growthPlanId': 0, 'userId': 0 }
        const growthPlanId = this.userService.getGrowthplanId();
        if (growthPlanId) {
            params.growthPlanId = growthPlanId;
        }
        params.reportTemplateId = templateId;
        return this.invokePostAPI(this.constants.ACT.CREATE_REPORT, params);
    }

    //createReport() {

    //    let params;
    //    params = { 'growthPlanId': 0 } 
    //    const growthPlanId = this.userService.getGrowthplanId();
    //    if (growthPlanId) {
    //        params.growthPlanId = growthPlanId;
    //    }
    //    return this.invokePostAPI(this.constants.ACT.CREATE_REPORT, params);
    //}
    deleteReport(params: any) {
        return this.invokePostAPI(this.constants.ACT.DELETE_REPORT, params);
    }
    //downloadReportAsBlob(params: any) {
    //    return this.invokePostDownloadAPI(this.constants.ACT.DOWNLOAD_REPORT_AS_BLOB + '/' + params.reportId, {});
    //}
    getCoverPage(reportId: any, progressBarOptions?: ProgressBarOptions) {

        return this.invokeGetAPI(`${this.constants.ACT.GET_COVER_PAGE}/${reportId}`, ProgressBarOptions);
    }

    getReportImages() {

        return this.invokeGetAPI(this.constants.ACT.REPORT_IMAGES);
    }
    uploadCompanyLogo(reportId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostUploadAPI(`${this.constants.ACT.UPLOAD_COMPANY_LOGO}/${reportId}`, params, progressBarOptions);
    }

    setReportField(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SET_REPORT_FIELDS, params, progressBarOptions);
    }

    getGrowthReadinessReport(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.GET_READNINESS_REPORT, params, progressBarOptions);
    }
    getDriverAssessmentSummaryDetails(reportId: number) {
        return this.invokeGetAPI(`${this.constants.ACT.GET_DRIVER_ASSESSMENT_REPORT}/${reportId}`);
    }
    getDriverActionReport(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.GET_DRIVER_ACTION_REPORT, params, progressBarOptions);
    }
    getSubDriverDetails(reportId: number) {
        return this.invokeGetAPI(`${this.constants.ACT.GET_SUB_DRIVER_PROGRESS}/${reportId}`);
    }
    getNotesDetails(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.GET_NOTES,params,progressBarOptions);
    }
    getSubDriverActions(subdriverId: number, growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_SUBDRIVER_ACTIONS}/${subdriverId}/${growthPlanId}`, {},progressBarOptions);
    }
    getReportCreationPermission(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.GET_REPORT_CREATE_PERMISSION, params, progressBarOptions);
    }
    /*------------------------Contact Us from landing page-----------------------*/
    saveContactUs(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.GENERAL.SAVE_CONTACTUS, params, progressBarOptions);
    }
    //getActOverview(progressBarOptions?: ProgressBarOptions) {
    //    const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
    //    return this.invokeGetAPI(`${this.constants.ACT.ACT_OVERVIEW}/${growthPlanId}`, {}, progressBarOptions);
    //}
    //getThreeYearCost(progressBarOptions?: ProgressBarOptions) {
    //    const growthPlanId = this.userService.getGrowthplanId() ? String(this.userService.getGrowthplanId()) : '';
    //    return this.invokeGetAPI(`${this.constants.ACT.GET_THREE_YEAR_COST}/${growthPlanId}`, {}, progressBarOptions);
    //}
    /*Notification Settings*/
    getNotificationSettings(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.NOTIFICATION.GET_NOTIFICATION_SETTINGS, {}, progressBarOptions);
    }
    saveNotificationSettings(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.NOTIFICATION.SAVE_NOTIFICATION_SETTINGS, params, progressBarOptions);
    }


    /*----------------------- Face To Face -----------------------------------*/
    getGrowthPlanList(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.FOCUS.GET_GROWTH_PLAN_LIST, {}, progressBarOptions);
    }
    setGrowthPlanId(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SET_GROWTH_PLAN_ID, params, progressBarOptions);
    }
    saveGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_GROWTH_PLAN, params, progressBarOptions);
    }
    checkIsFacilitator(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.USER.IS_FACILITATOR, {}, progressBarOptions);
    }
    validateFacilitatorCode(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.FACILITATOR_CODE_VALIDATE, params, progressBarOptions);
    }
    searchMember(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.SEARCH_MEMBER, params, progressBarOptions);
    }
    shareGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SHARE_GROWTH_PLAN, params, progressBarOptions);
    }
    loadGrowthPlans(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.GET_ALL_GROWTH_PLANS, params, progressBarOptions);
    }
    getGrowthPlanSummary(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_GROWTH_PLAN_SUMMARY}/${growthPlanId}`, {}, progressBarOptions);
    }
    getGPEligibleforDeletion(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_GP_NOMINATION_ELIGIBLE_DELETION}/${growthPlanId}`, {}, progressBarOptions);
    }
    searchMemberShare(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.SEARCH_MEMBER_SHARE, params, progressBarOptions);
    }
    renameGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.RENAME_GROWTH_PLAN, params, progressBarOptions);
    }

    deleteGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.DELETE_GROWTH_PLAN, params, progressBarOptions);
    }

    duplicateGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.DULPICATE_GROWTH_PLAN, params, progressBarOptions);
    }
    editAttendee(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.EDIT_ATTENDEE, params, progressBarOptions);
    }
    searchParticipant(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.SEARCH_PARTICIPENT, params, progressBarOptions);
    }
    getSelfNomination(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_SELF_NOMINATION}`, {}, progressBarOptions);
    }
    getSelfFacilitateNomination(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_SELF_FACILITATED_NOMINATIONS}`, {}, progressBarOptions);
    }
    getCorporateGPNomination(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_CORPORATE_GP_NOMINATION}` + '/' + growthPlanId, {}, progressBarOptions);
    }
    saveNomination(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_NOMINATION, params, progressBarOptions);
    }
    updateCorporateGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.UPDATE_CORPORATE_GROWTH_PLAN, params, progressBarOptions);
    }
    updateNominatedGrowthPlan(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.UPDATE_NOMINATEDGROWTH_PLAN, params, progressBarOptions);
    }
    deleteFacilitatorAndAttendees(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.DELETE_FACILITATOR_AND_ATTENDEES, params, progressBarOptions);
    }
    addCorporateGrowthPlanFacilitator(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.ADD_CGP_FACILITATOR, params, progressBarOptions);
    }
    setReportHideStatusDriver(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.SET_REPORT_HIDE_STATUS_DRIVER, params, progressBarOptions);
    }
    removeCorporateGrowthPlanFacilitator(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.REMOVE_CGP_FACILITATOR, params, progressBarOptions);
    }
    saveTeamMeetingOwner(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.SAVE_TEAM_MEETING_OWNER, params, progressBarOptions);
    }

    getParticipant(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_PARTICIPANT}/${growthPlanId}`, {}, progressBarOptions);
    }

    getAnnouncements(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_ANNOUNCEMENTS}`, {}, progressBarOptions);
    }

    getFopMatrics(growthPlanId,progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_FOP_MATRICS}/${growthPlanId}`, {}, progressBarOptions);
    }
    
    getGPStatus(growthPlanId, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_GP_STATUS}/${growthPlanId}`, {}, progressBarOptions);
    }

    getGPActions(growthPlanId, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_GP_ACTIONS}/${user.userId}/${growthPlanId}`, {}, progressBarOptions);
    }
    getgrowthPlans(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_GROWTHPLANS}`, {}, progressBarOptions);
    }

    getQuickLinks(progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.DASHBOARD.GET_QUICK_LINKS}`, {}, progressBarOptions);
    }
    saveRAGValues(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.GENERAL.SAVE_RAG_VALUE, params, progressBarOptions);
    }

    getReportCoverImages(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.ACT.GET_COVER_IMAGES}`, {}, progressBarOptions);
    }

    updateCoverImage(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.UPDATE_COVER_IMAGE, params, progressBarOptions);
    }
    duplicateReportName(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostWithOutUserIdAPI(this.constants.ACT.DUPLICATE_REPORT_NAME, params, progressBarOptions);
    }

    acceptDecline(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.ACCEPT_DECLINE, params, progressBarOptions);
    }

    resendInvite(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.RESEND_INVITE, params, progressBarOptions);
    }

    getTemplates(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.ACT.GET_TEMPLATES}`, {}, progressBarOptions);
    }

    getTemplate(templateId, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.ACT.GET_TEMPLATE}/${templateId}`, {}, progressBarOptions);
    }

    getDriverSummary(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ACT.GER_DEIVER_SUMMARY, params, progressBarOptions);
    }

    getDummyUsers(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.FOCUS.GET_DUMMY_USERS}`, {}, progressBarOptions);
    }
    getDriverNotes(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.GET_DRIVER_NOTES}`, params, progressBarOptions);
    }
    getSubDriverNotes(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.GET_SUB_DRIVER_NOTES}`, params, progressBarOptions);
    }
    deleteDriverNote(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.DELETE_DRIVER_NOTE}`, params, progressBarOptions);
    }
    deleteSubDriverNote(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.DELETE_SUB_DRIVER_NOTE}`, params, progressBarOptions);
    }
    saveAssesmentNote(params: any, progressBarOptions?: ProgressBarOptions) {
        let url = this.constants.FOCUS.SAVE_NOTE_ASSESMENT;
        if(params['driverId'] == undefined) {
            url = this.constants.FOCUS.SAVE_NOTE_SUB_DRIVER_ASSESMENT;
        }
        return this.invokePostAPI(url, params, progressBarOptions);
    }
    getGrowthPlanActionstatus(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.GET_GROWTHPLAN_ACTION_STATUS}`, params, progressBarOptions);
    }

    exportActionDetails(gpId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.ACT.EXPORT_ACTION_DETAILS}/${gpId}`, {}, progressBarOptions);
    }

    getAxisValues(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.USER.GET_AXIS_VALUES}`, {}, progressBarOptions);
    }

    getGPAxisDetails(growthPlanId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.USER.GET_GP_AXIS_VALUES}/${growthPlanId}`, {}, progressBarOptions);
    }

    saveAxisDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.SAVE_AXIS_VALUES, params, progressBarOptions);
    }

    getSolutionMetrics(currentGrowthPlanId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.FOCUS.GET_SOLUTION_METRICS}/${currentGrowthPlanId}`, {}, progressBarOptions);
    }

    getAxisValuesForGrowthPlan(gpId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.USER.GET_USER_AXES_VALUES}/${gpId}`, {}, progressBarOptions);
    }

    getGrowthMatrixDiscrepancy(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.GROWTH_MATRIX_DISCREPANCY, params, progressBarOptions);
    }

    loadGrowthPlanInvitations(progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.GET_GROWTH_PLAN_INVITATIONS, {}, progressBarOptions);
    }
}
