import { Component, Input, Output, EventEmitter, OnInit, Injector, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'growth-plan-count-bubble',
  templateUrl: './growth-plan-count-bubble.component.html',
    styleUrls: ['./growth-plan-count-bubble.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GrowthPlanCountBubbleComponent implements OnInit, OnChanges {

    scaledPosition: any = { x: 0, y: 0 };
    actualPosition: any = { x: 0, y: 0 };
    parentRectangle: any = { x: 0, y: 0, width: 0, height: 0 };
    timeoutHandler: any;
    isLongPress: boolean = false;
    @Input('action') action: any;
    @Input('size') size: any;

    @Input('highLightBubbles') highLightBubbles: boolean;
    @Output('onDragStart') dragStart: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClickAndHold') clickAndHold: EventEmitter<any> = new EventEmitter<any>();

    @Input('isDragEnabled') isDragEnabled: boolean = false;
    @Input('participant') participant: any = null;
    @Input('count') count: number = 0;
    @Input('actions') actions: any = [];
    showTooltip: boolean = false;
    class: string = '';
    topcClass = '';
    @Input('hideShow') hideShowTooltip: boolean = false;
    left: string = '';
    marginLeft: number;
    width: number;
    top: any;
    @Input('selected') selected: boolean = false;
    @Input('highlight') highlight: boolean = false;
    isClicked: boolean = false;
    zIndexClass = '';
    selectedType: number = 1;
    selectedCard: any = {};
    @Input('selectedCardParent') selectedCardParent: any = {};
    selectedId: string = "";
    @Input('view') view: string = '';
    zoomView = "";
    alreadyClicked = [];
    @Input('pageContents') pageContents: any = [];
    @Input('changeFilter') changeFilter: boolean = false;
    @Input('clickable') clickable: boolean = true;
    @Input('selectedDriver') selectedDriver: number = 0;
    @Input('showBorder') showBorder: boolean = false;
    showYellowBorder: boolean = false;
    constructor(

        private injector: Injector
    ) {
    }

    ngOnInit() {       
    }

    onDragStart(ev: any) {
        this.dragStart.emit(ev);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let prop in changes) {
            //if (prop == "scaledPosition") {
            //    var change = changes[prop];
            //    console.log(change.isFirstChange());
            //}
            if (prop == "action") {
                var change = changes[prop];
                //console.log(change);
            }

            if (prop == "hideShowTooltip") {
                var change = changes[prop];
                //console.log(change);
                this.showTooltip = false;
                this.zIndexClass = '';
            }

            if (prop == "selected") {
                let change = changes[prop];
                if (change['currentValue'] != undefined && change['currentValue'] !== false) {
                    //console.log('here');
                    //change['currentValue']
                    //this.clickRightSidePanel();
                } else {
                    this.showTooltip = false;
                    this.zIndexClass = '';
                }
            }
            
            if (prop == "highlight") {
                let change = changes[prop];
                if (change['currentValue'] != undefined && change['currentValue'] !== false) {
                    //console.log('here');
                    //change['currentValue']
                    this.showTooltip = false;
                    this.selectedCard = this.action;
                    //console.log('here');
                    //console.log(change['currentValue']);
                    //if (this.showTooltip) {
                    //    this.showTooltip = !this.showTooltip;
                    //}
                    this.clickRightSidePanel(change['currentValue']);
                } else {
                    this.selectedCard = {};
                    this.showTooltip = false;
                    this.zIndexClass = '';
                }
            }

            if (prop == "view") {
                let change = changes[prop];
                if (change['currentValue'] != undefined && change['currentValue'] === 'zoom-view') {
                    this.zoomView = 'zoom-view';
                }
            }

            if (prop == "changeFilter") {
                let change = changes[prop];
                if (change['currentValue'] != undefined && change['currentValue'] === true) {
                    this.selectedCard = {};
                }
            }
        }

        if (this.actions.length > 1) {
            if (this.actions.filter((x: any) => x.driverTag == this.selectedDriver).length > 0 && this.showBorder) {
                this.showYellowBorder = true;
            } else {
                this.showYellowBorder = false;
            }
        } else {
            this.showYellowBorder = false;
        }
    }

    getBubblePosition(action: any) {
        return { top: action.yPos, left: action.xPos };
    }

    onCardSelected(e: any, selectedAction: any) {
        console.log(e.target.classList)
        e.stopPropagation();
        if (e.target.classList.contains('gp-action-bubble-count')
            || e.target.classList.contains('gp-action-count-bubble')
            || e.target.classList.contains('span')
            || e.target.classList.contains('img')
            || e.target.classList.contains('close-btn')
            || e.target.classList.contains('fa-times')) {
            this.onselectedCard(e, selectedAction);
            //console.log(e.target);
            //console.log(e.target.classList.contains('gp-action-count-bubble'));
            //console.log(angular.element(event.target))
            if (e.target.classList.contains('gp-action-count-bubble')
                || e.target.classList.contains('span')
                || e.target.classList.contains('img')) {
                this.onClick.emit('fromBubble');
                setTimeout(() => {
                    this.clickCount(selectedAction);
                });
            } else {
                console.log('here');
                if (!e.target.classList.contains('close-btn') && !e.target.classList.contains('fa-times')) {
                    console.log('if');
                    this.onClick.emit(selectedAction);
                } else {
                    //this.selectedCard = {};
                    console.log('else');
                    //this.onClick.emit('fromBubble');
                    this.showTooltip = false;
                }
            }
        }
        
    }

    onMouseup() {
        //  if (this.timeoutHandler) {
        //  clearTimeout(this.timeoutHandler);
        // this.timeoutHandler = null;
        this.isLongPress = false;

        //  }

        this.highLightBubbles = false;
        this.clickAndHold.emit("");
    }

    onMousedown(action: any) {
        //  this.timeoutHandler = setTimeout(() => {
        this.isLongPress = true;
        this.timeoutHandler = null;

        this.clickAndHold.emit(action);
        //  }, 500);
    }
    getZvalue(action) {
        if (action.active)
            return 102;
        if (action.zValue)
            return action.zValue;
        return 101;
    }

    clickRightSidePanel(act) {
        this.clickCount(this.actions[0], true, act);
    }

    clickCountBubble(e) {
        console.log(e);
    }

    clickCount(action, highlight = false, act: any = {}) {
        if (!this.clickable) {
            return true;
        }

        if (this.selectedCardParent.cardNumber != undefined) {
            this.selectedCard = this.selectedCardParent;
        }
        //console.log('click ' + this.showTooltip)
        if (this.actions.length > 1) {
            //this.selectedCard = {};
            this.topcClass = '';
            this.showTooltip = true;
            this.zIndexClass = 'z-index-negative';
            if (this.showTooltip) {
                setTimeout(() => {
                    console.log(document.getElementById('four'));
                    let width = document.getElementById('four').offsetWidth;
                    let height = document.getElementById('four').offsetHeight;
                    if (width == 0) {
                        width = document.getElementById('fourZoom').offsetWidth;
                        height = document.getElementById('fourZoom').offsetHeight;
                    }
                    let left = this.getBubblePosition(action).left;
                    let top = this.getBubblePosition(action).top;


                    let zoomModal = document.getElementById('zoom-modal');
                    let bubbleWidth, noOfBuubleInOneRow, tooltipHeight;

                    if (zoomModal != null) {
                        bubbleWidth = document.getElementById('bubble' + this.zoomView + action.id.toString()).offsetWidth;
                        noOfBuubleInOneRow = this.count >= 3 ? 3 : 3;
                        this.width = (bubbleWidth * noOfBuubleInOneRow + 18);
                        tooltipHeight = document.getElementById(this.zoomView + action.id.toString()).offsetHeight;
                    } else {
                        bubbleWidth = document.getElementById('bubble' + action.id.toString()).offsetWidth;
                        noOfBuubleInOneRow = this.count >= 3 ? 3 : 3;
                        this.width = (bubbleWidth * noOfBuubleInOneRow + 18);
                        console.log(document.getElementById(action.id.toString()));
                        if (document.getElementById(action.id.toString()) != null) {
                            tooltipHeight = document.getElementById(action.id.toString()).offsetHeight;
                        }
                    }
                    setTimeout(() => {
                        if (zoomModal != null) {
                            bubbleWidth = document.getElementById('bubble' + this.zoomView + action.id.toString()).offsetWidth;
                            noOfBuubleInOneRow = this.count >= 3 ? 3 : 3;
                            this.width = (bubbleWidth * noOfBuubleInOneRow + 18);
                            tooltipHeight = document.getElementById(this.zoomView + action.id.toString()).offsetHeight;
                        } else {
                            bubbleWidth = document.getElementById('bubble' + action.id.toString()).offsetWidth;
                            noOfBuubleInOneRow = this.count >= 3 ? 3 : 3;
                            this.width = (bubbleWidth * noOfBuubleInOneRow + 18);
                            console.log(document.getElementById(action.id.toString()));
                            if (document.getElementById(action.id.toString()) != null) {
                                tooltipHeight = document.getElementById(action.id.toString()).offsetHeight;
                            }
                        }
                        if (top > tooltipHeight + 10) {
                            this.class = 'top';

                        } else {
                            this.class = 'bottom';
                        }
                        if (this.class == 'top') {
                            if ((left + bubbleWidth / 2) > this.width) {
                                this.left = ((-1 * this.width) + (bubbleWidth / 2)).toString();
                                this.topcClass = 'right-arrow';
                            } else {
                                this.left = '0';
                                this.topcClass = 'left-arrow';
                            }
                        }
                        else {
                            if ((left + bubbleWidth / 2) > this.width) {
                                this.left = ((-1 * this.width) + (bubbleWidth / 2)).toString();
                                this.topcClass = 'right-arrow';
                            } else {
                                this.left = '0';
                                this.topcClass = 'left-arrow';
                            }
                        }
                    }, 10);
                    this.zIndexClass = 'z-index-2';
                    if (highlight) {
                        console.log('highlight');
                        this.selectedId = act.driverTag;
                        this.selectedCard = act;
                    }

                }, 10);
            } else {
                this.zIndexClass = ''
            }
            //let height = document.getElementById('four').offsetHeight;
            //console.log(this.getBubblePosition(action));
        }
    }

    getClassName(action: any) {
        let qudrant = document.getElementById('focus-quadrant');
        //console.log(qudrant);
        
        //console.log(action);
        //if (qudrant == null) {
        let className = '';
        
        if (this.selectedDriver != 0 && this.showBorder) {
            if (action.driverTag == this.selectedDriver)
                className = 'showBorder '
        }
            if (action.rag == 'R') className += 'rag_red';
            else if (action.rag == 'A') className += 'rag_amber';
            else if (action.rag == 'G') className += 'rag_green';
            else className += 'no_rag';
            //console.log(action.cardNumber);
            if (this.selectedCard && action.cardNumber == this.selectedCard.cardNumber) {
                className = 'sel_bubble ' + className;
            }
            return className
        //}
        //return '';
    }

    onselectedCard(e, card) {
        if (e.target.classList.contains('gp-action-bubble')) {
            this.selectedId = card.driverTag;
            this.selectedCard = card;
        }
    }
    }
