import { Component, OnInit, Injector, ViewChild, ElementRef, HostListener, Input, Inject, Renderer2 } from '@angular/core';
import { UtilService } from '../../service/util.service';
import { Router, NavigationEnd, Event, NavigationStart } from '@angular/router';
import { SubMenuService } from './../../service/sub-menu.service';
import { UserService } from './../../auth/service/user.service';
import { CONTENTS } from './../../config/content.const';
import { BaseComponent } from './../base.component';
import { ComponentContent } from './../component-content.decorator'
import { DataShareService } from './../../service/data-share.service';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from "./../window.service";
import { LogService } from "../../service/log.service";
import { LogTypes ,LogAction } from "../../service/model/log-models";
import { GrowthService } from '../../service/growth.service';

import { ModalDialogService } from "./../../modal-dialog-module/modal-dialog.service";
import { NotificationService } from '../../service/notification.service';
import { AuthenticationService } from "../../auth/service/auth.service";
import { AuthService } from '@auth0/auth0-angular';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const HOME = CONTENTS.HOME;

@ComponentContent("",[HOME])
@Component({
    selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'], 
    host: {
        '(document:click)': 'handleClick($event)'
    }
})

export class AppHeaderComponent extends BaseComponent implements OnInit {
  @ViewChild('header', { static: true }) header: any;
  @ViewChild('searchButton', { static: true }) searchButton: any;
  @ViewChild('language', { static: false }) languageIcon: any;
  @ViewChild('smalllanguage', { static: false }) slanguageIcon: any;
    @Input("menuContent")
    get menuContent(): any { return this._menuContent; }
    set menuContent(menuContent: any) {
        this._menuContent = menuContent;
    }
    private _menuContent: any = {};
    searchPlaceholder: string;
    pageContent: any;
    brandName: string;
    headerLinks: any;
    public isLoggedIn: boolean = false;
    showDropdown: boolean = true;
    showProfileDropdown: boolean = true;
    isDropDownMenuVisible: boolean = false;
    isSearchBoxVisible: boolean = false;
    isSearchBox: boolean = false;
    profileUrl: '';
    preferredName: string;
    userEmail: string;
    showNotificationPane: boolean = false;
    showLanguageDropdown: boolean = false;
    searchString = '';
    notificationCount: string;
    startSearch = false;
    focusInput = false;
    navIsFixed: boolean = false;
    screenHeight: number;
    showProfilePopup = false;
    isNotification: boolean = true;
    small = false;
    menu: any;
    language = [];
    languageText = '';
    selLanId = 1;
    languagePos = 18;
    //subMenus: { routerLink: string[], name: string }[] = [];
    constructor(
        private utilService: UtilService,
        private subMenuService: SubMenuService,
        private userService: UserService,
        private router: Router,
        private injector: Injector,
        private notificationService: NotificationService,
        private elementRef: ElementRef,
        private dataShareService: DataShareService,
      private renderer: Renderer2, 
        private authService: AuthenticationService,
        private logService: LogService,
        private logTypes: LogTypes,
        private growthService: GrowthService,
        private dialogService: ModalDialogService,
      private auth: AuthService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window
    ) {
        super(injector);
    }

    ngOnInit() {
        //if(this.currentUser)
        //    this.profileUrl = this.currentUser.profileUrl;
                 
      this.pageContent = this.OtherContents[HOME];
   
        this.userService.getCurrentUserAsObservable().subscribe((user: any) => {
            if (user && user.profileUrl) {
                this.profileUrl = user.profileUrl;
                this.preferredName = user.preferredName;
                this.userEmail = user.userEmail;
                this.headerLinks = user.portalPermissions;                           
                if (this.small)
                    this.screenHeight = window.innerHeight - 55;
                else
                    this.screenHeight = window.innerHeight - 75;

                this.isNotification = user.notificationStatus;
                this.selLanId = user.preferredLanguageId;
                //To get language
                this.getLanguage();            }
        });       

        this.setSmall();

       
        
      this.brandName = 'Velocity';
        this.searchPlaceholder = this.pageContent.searchPlaceholder;

        //this.headerLinks = this.menu;

        //this.headerLinks.push({
        //    name: this.pageContent.connect.text,
        //    routerLink: 'connect'
        //});


        //this.headerLinks.push({
        //    name: this.pageContent.focus.text,
        //    routerLink: 'focus',
        //    children: [{
        //        name: this.pageContent.focus.sevenDriverOfGrowth,
        //        routerLink: 'seven-drivers'
        //    },
        //    {
        //        name: this.pageContent.focus.hotTopics,
        //        routerLink: 'hot-topics'
        //    }]
        //});

        //this.headerLinks.push({
        //    name: this.pageContent.deliver.text,
        //    routerLink: 'act',
        //    children: [{
        //        name: this.pageContent.focus.myGrowthPlan,
        //        routerLink: 'my-growth-plan'
        //    },
        //    {
        //        name: this.pageContent.deliver.toolsResources,
        //        routerLink: 'tool-resources'
        //        },
        //        {
        //            name: this.pageContent.deliver.growthSummaryReport,
        //            routerLink: 'summary'
        //        }
        //    ]
        //});

        //this.headerLinks.push({
        //    routerLink: 'dashboard',
        //    name: this.pageContent.dashBoard.text
        //});

/** My story option is commented  */
        // this.headerLinks.push({
        //     routerLink: 'my-story',
        //     name: this.pageContent.myStory.text
        // });

        //subscribe and set the variable so that any change from any other component should reflect here.
        this.userService.isLoggedIn().subscribe((x: boolean) => {
            this.isLoggedIn = x;
        });

        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: Event) => {
            });

        this.router.events
          .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: Event) => {
                this.showNotificationPane = false;
                this.showLanguageDropdown = false;
            });

        this.notificationService.notificationCountObservable.subscribe((x: number) => {
            if (x == 0) {
                this.notificationCount = '';
            } else if (x > 99) {
                this.notificationCount = '99+';
            } else {
                this.notificationCount = x.toString();;
            }

        });

        
    }
    getLanguage() {
        this.growthService.listLanguage().subscribe(
            (data: any) => {
                this.language = data;
                this.getLanguageText();
            }
        );
    }
    getLanguageText() {
        if (this.selLanId && this.language.length > 0)
            if (this.language.filter(item => item.languageId === this.selLanId).length > 0)
                this.languageText = this.language.filter(item => item.languageId === this.selLanId)[0].abbreviation;
            else {
                this.languageText = this.language.filter(item => item.languageId === 1)[0].abbreviation;
                //In the case of deactivation
                this.selLanId = 1;
                this.userService.setUserPreferredLanguage(this.selLanId);

            }
        this.getLanguagePostion();
    }
    getLanguagePostion() {
        if (this.showLanguageDropdown) {

            if (this.languageIcon && this.languageIcon.nativeElement && this.languageIcon.nativeElement.offsetWidth > 0) {
                if (this.showLanguageDropdown) {
                    this.languagePos = this.languageIcon.nativeElement.getBoundingClientRect().x - 50;
                }
            } else if (this.slanguageIcon && this.slanguageIcon.nativeElement && this.slanguageIcon.nativeElement.offsetWidth > 0) {
                if (this.showLanguageDropdown) {
                    this.languagePos = this.slanguageIcon.nativeElement.getBoundingClientRect().x - 50;
                }
            }
        }
    }
  
    changeLanguage(language: any) {
        let config: any = {
            type: 'LEAVE_COMMUNITY',
            html: true,
            action: {
                cancel: this.SiteContents.buttonCancel,
                ok: this.SiteContents.buttonOk,
                or: this.OtherContents.buttonOr
            }
        };
        let notificationText = this.pageContent.translationNotification;
        if (language.languageId == 1) {
            notificationText = this.pageContent.lblLTMLanguageChanged;
        }
        this.dialogService.confirm(this.pageContent.translationNotificationHeading, notificationText, config).subscribe((data) => {
                    if (data && data === 'yes') {
                        this.growthService.saveLanguage(language.languageId).subscribe(
                            (data: any) => {
                                if (data) {
                                    this.userService.setUserPreferredLanguage(language.languageId);
                                    this.selLanId = language.languageId;
                                    this.getLanguageText();

                                    this.reloadCurrentRoute();
                                }
                            }
                        );                    }
                });
           
     
    }
    reloadCurrentRoute(): void {

        //To reload the content API's
        var namesToBePassedOriginal: Array<string> = Array.from(Object.keys(this.dataShareService.privateData)).map((name) => name as string);
        this.dataShareService.refreshData();

        this.utilService.getContents(namesToBePassedOriginal).subscribe((data: any) => {

            //To reload the url
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigated = false;
            this.router.navigate([currentUrl]);
            this.dataShareService.changeLanguage.next(true);
        });
       
    }
    hideNavigation(menuChild : string, sublinkRoute: string) {
        if(menuChild == 'BenchmarkingReport') {
            window.open(sublinkRoute);
        }
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + menuChild;
        this.logService.logAction(log);
        this.showDropdown = false;
        
    }

    logout() {
        
        this.showProfilePopup = false;
        this.isDropDownMenuVisible = false;
         this.logEvent();
        this.userService.logoutmanual();     
    }

    logEvent() {
        let log = new LogAction();
        log.action = this.logTypes.LOGGED_OUT;
        this.logService.logAction(log);
    }

    showSmallMenu(event) {
        if (event) {
            let activeElement = false;            
            if (event.target && event.target.nextElementSibling) {
                if (event.target.nextElementSibling.style.maxHeight) { activeElement = true } else { activeElement = false };
            }
            let acc = document.getElementsByClassName("small-header-accordian") as HTMLCollectionOf<HTMLElement>;
            var i;

            for (i = 0; i < acc.length; i++) {
                acc[i].classList.remove("open");
            }

            let panels = document.getElementsByClassName("small-header-accordion-panel") as HTMLCollectionOf<HTMLElement>;
            var i;
            for (i = 0; i < panels.length; i++) {
                panels[i].style.maxHeight = null;
            }
            if (event.target) {
                let accordian = event.target;
                accordian.classList.toggle("open");
                let panel = accordian.nextElementSibling;
                if (panel) {
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        if (activeElement == true) {
                            panel.style.maxHeight = null
                            accordian.classList.toggle("open");
                        }
                        else {
                            panel.style.maxHeight = panel.scrollHeight + "px";
                        }

                    }
                }
            }
        }
        
    }
    accessMenu(event, link) {
        if (link.hasChild) {
            this.showSmallMenu(event);
        } else {
            this.navigateMenu(event, link);
        }    
    }
    navigateMenu(event, link) {
        if(link.menuName == 'BenchmarkingReport') {
            window.open(link.routes[0]);
        }
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + link.title;
        this.logService.logAction(log);
        this.showSmallMenu(event);
        this.isDropDownMenuVisible = false;
       // this.router.navigate([link.routes[0]]);
    }
    navigate(event, moduleLink: string, sectionLink: string, pageLink: string, menu: string) {
        this.showSmallMenu(event);
        this.isDropDownMenuVisible = false;
        console.log('navigated:', menu);
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + menu;
        this.logService.logAction(log);
        //if (pageLink) {
        //    this.router.navigate([moduleLink, sectionLink, pageLink]);
        //}
        //else if (sectionLink) {
        //    this.router.navigate([moduleLink, sectionLink]);
        //}
        //else {
        //    this.router.navigate([moduleLink]);
        //}
    }

    navigateMainMenu(event, moduleLink: string, sectionLink: string, pageLink: string, menu: string) {
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + menu;
        this.logService.logAction(log);
        
        this.showSmallMenu(event);

        this.isDropDownMenuVisible = false;

        if (pageLink) {
            this.router.navigate([moduleLink, sectionLink, pageLink]);
        }
        else if (sectionLink) {
             this.router.navigate([moduleLink, sectionLink]);
        }
        else {
            this.router.navigate([moduleLink]);
        }
    }
    showProfilePopupFn(show: boolean) {
        if (!show) {           
                this.showProfilePopup = false;
                this.hideNotification();

        }
        else {
            this.showProfilePopup = true;
            this.isDropDownMenuVisible = false;
            this.showNotificationPane = false;
        }
    }
   
    showNotification(menu :string) {
        if (this.showNotificationPane == true) {
            this.showNotificationPane = false;
            this.showLanguageDropdown = false; 
            this.hideNotification();
            if (this.isNotification) {
                this.notificationService.getNotificationCount(); //To get the new notification count 
            }
            
        }
        else {
            this.showNotificationPane = true;
            this.isDropDownMenuVisible = false;
            this.showLanguageDropdown = false;
            this.showProfilePopup = false;
            let log = new LogAction();
            log.action = this.logTypes.NAVIGATION;
            log.otherInfo = 'Navigated to ' + menu;
            this.logService.logAction(log);
            //AJAX call to get notification
        }
    }

    showLanguageChange(menu :string) {
        if (this.showLanguageDropdown == true) {
            this.showLanguageDropdown = false;
            this.showNotificationPane = false;          
        }
        else {
            this.showLanguageDropdown = true;
            this.isDropDownMenuVisible = false;
            this.showNotificationPane = false;
            this.showProfilePopup = false;
            let log = new LogAction();
            log.action = this.logTypes.NAVIGATION;
            log.otherInfo = 'Navigated to ' + menu;
            this.logService.logAction(log);
            //AJAX call to get notification
        }
        this.getLanguagePostion();
    }

    toggleMenu() {
        this.isDropDownMenuVisible = !this.isDropDownMenuVisible;
        if (this.isDropDownMenuVisible)
            this.showNotificationPane = false;
        this.showProfilePopup = false;
    }

    hideNotification() {
        //update notifications count
    }

    handleClick($ev) {
        var clickedComponent = $ev.target;
        var clickedComponentSearch = $ev.target;
        if (clickedComponent.className == 'btn-search' || clickedComponent.classList.contains('form-control') || clickedComponent.className == 'btn-search-menu') {
            this.isSearchBox = true;
        } else {
            this.isSearchBox = false;
        }
        
        var inside = false;
        var insideSearch = false;
                
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
               
                break;
            }
            
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);

        do {
            if (this.searchButton) {
                if (clickedComponentSearch === this.searchButton.nativeElement) {
                    insideSearch = true;

                    break;
                }
            }
            clickedComponentSearch = clickedComponentSearch.parentNode;
        } while (clickedComponentSearch);

        if (!inside && this.showNotificationPane == true) {
            this.showNotificationPane = false;
            this.hideNotification();
            if (this.isNotification) {
                this.notificationService.getNotificationCount(); //To get the new notification count
            }
             
        }

        if (!inside && this.showLanguageDropdown == true) {
            this.showLanguageDropdown = false; 
        }

        if (!inside && this.showProfilePopup == true) {
            this.showProfilePopup = false;
         
        }
        
        if (!insideSearch && this.isSearchBoxVisible == true) {
            if (this.isSearchBox) {
                this.isSearchBoxVisible = true;
            } else {
                this.isSearchBoxVisible = false;
            }
            
                  

        }
    }
    
    search() {
        if (this.searchString != '') {
            this.dataShareService.mainSearchString.next({ 'searchStr': this.searchString, 'categoryId': 0 });
            this.router.navigate(["search"]);
            this.startSearch = true;
            this.searchString = '';
          this.renderer.selectRootElement(event.target).blur();
        }
    }
    changeSearch(searchValue: string) {
        this.startSearch = false;
        this.searchString = searchValue;;
        if (searchValue != '') {
            this.isSearchBoxVisible = true;
        } else {
            this.isSearchBoxVisible = false;
        }
    }

    openSettings() {
        //console.log("Setting click");
        this.router.navigate(['user-detail','settings']);
        this.showProfilePopup = false;
    }

    openProfile() {
        //console.log("Profile click");
        this.showProfilePopup = false;
        this.router.navigate(['user-detail']);
    }

   

    @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
        this.callScrollfunction();
    } 

    callScrollfunction()
    {

        let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
        if (this.small)
            this.screenHeight = window.innerHeight - 55;
        else
            this.screenHeight = window.innerHeight - 75;

        if (number > 90) {
            this.navIsFixed = true;
            this.screenHeight = window.innerHeight;

        } else if (this.navIsFixed && number < 10) {
            this.navIsFixed = false;
        }
    }

    navigateToUserDetails() {
        this.router.navigate(['user-detail']);
    }

    navigateToSettings() {
        this.router.navigate(['user-detail','settings']);
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    logMenuNavigation(menu: string) {
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + menu;
        this.logService.logAction(log);
        if (menu === 'Settings') {
            this.showProfileDropdown = false;
        }
    }
    hideProfileDropDown() {
        this.showProfileDropdown = false;
    }
    @HostListener('window:resize') onResize() {
        this.setSmall();
        this.callScrollfunction();
        this.getLanguagePostion();


    }

    setSmall() {
        let actualWidth = window.innerWidth;
        if (actualWidth >= 992) {
            this.small = false;

        } else if (actualWidth < 992) {
            this.small = true;

        }
    }

    getMenuValue(item) {
        if (item.element && this._menuContent) {
            return this._menuContent[item.element] ? this._menuContent[item.element] :item.title;
        }
        return item.title;
    }
 }
