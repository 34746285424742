import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';

import { DataShareService } from './data-share.service';
import { CONTENTS } from './../config/content.const';
import { UserService } from '../auth/service/user.service';
import { Response } from './model/response';
import { TimerService } from './timer.service';
import { Constants } from './constant.service';
import { GrowthPlanSessionDto } from '../focus/model/face-to-face.model';
import { StorageHelperService } from './storage-helper.service';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { HTTPService } from "../service/http.service";


const CONTENT_API = environment.VELOCITY_API + 'appcontent/';
const TOKEN_API_URL = environment.VELOCITY_API + 'user/RefreshToken';
const CHECK_AUTH_API_URL = environment.VELOCITY_API + 'user/CheckAuth';
const SITE_CONTENT = CONTENTS.SITE_CONTENT;
const HOME_CONTENT = CONTENTS.HOME;
@Injectable()
export class StartupService {
  constructor(
    private _http: HttpClient,
    private dataShareService: DataShareService,
    private userService: UserService,
    private timer: TimerService,
    private constants: Constants,
    private storageHelper: StorageHelperService,
    private httpService: HTTPService
  ) { }

  loadInitialData() {
    return new Promise((resolve, reject) => {
      var siteContentObservable = this._http.get(CONTENT_API + SITE_CONTENT, { headers: this.getHeaders() });
      var homeContentObservable = this._http.get(CONTENT_API + HOME_CONTENT, { headers: this.getHeaders() });

      var refreshTokenObserver: Observable<any> = Observable.create((observer: any) => {

        this._http.post(TOKEN_API_URL, null, { headers: this.getHeadersWithToken() })
          .pipe(
            retry(1),
            catchError(this.handleError)
          ).
          subscribe((response: any) => {
            if (response && response.status && (response.code === '1000') && response.result) {

              var currentUser = this.userService.getCurrentUser();
              currentUser.token = response.result.token;
              this.userService.setCurrentUser(currentUser);
              observer.next(true);
              observer.complete();
              this.timer.start();
            }
            else {
              observer.next(false);
              observer.complete();
            }
          }, (err: any) => {
            observer.next(false);
            observer.complete();
          });

      });

      var checkAuthObserver: Observable<boolean> = Observable.create((observer: any) => {
        let req = {
          url: CHECK_AUTH_API_URL,
          headers: this.getHeadersWithToken(),
          params: null
        }
        this.httpService.post(req)
          .subscribe((response: any) => {
            if (response && response.status && (response.code === '1000') && response.result && response.result == true) {
              observer.next(true);
              observer.complete();

              var sessionGrowthPlanId = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
              //debugger;
              if (sessionGrowthPlanId == null) {
                var currentUser = this.userService.getCurrentUser();
                var currentGrowthPlanId = currentUser.currentGrowthPlanId;
                var curremtGrowthPlanDetails = currentUser.currentGrowthPlan;
                //Set current growthPlan
                var sessionStorageData: GrowthPlanSessionDto = {
                  growthPlanId: currentGrowthPlanId,
                  growthPlanName: curremtGrowthPlanDetails.growthPlanName,
                  isLocked: curremtGrowthPlanDetails.isLocked,
                  isShared: curremtGrowthPlanDetails.isShared
                };
                this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
              }

              //User IQ
              //var loggedInUser = this.userService.getCurrentUser();
              //if (window["_uiq"] && typeof (window["_uiq"].push) != undefined) {
              //    window["_uiq"].push(['identify', loggedInUser.userEmailEncrypted, {
              //        user_name: "Velocity User",
              //        account_id: 1,
              //        account_name: "Velocity",
              //        user_email: loggedInUser.userEmailEncrypted,
              //        custom_hasAssessedAny: loggedInUser.hasAssessedAny,
              //        custom_isPresentAssessed: false,
              //        custom_isAspirationAssessed: false,
              //        custom_isInternalUser: (loggedInUser.userType == 2) ? false : true,
              //        custom_numberOfEemployees: loggedInUser.numberOfEemployees,
              //        custom_industry: loggedInUser.industry,
              //        custom_companyType: loggedInUser.companyType,
              //        custom_fundingStage: loggedInUser.fundingStage,
              //        custom_country: loggedInUser.country
              //    }]);

              //    window["_uiq"].push(['startTracker']);
              //}
            }
            else {
              observer.next(false);
              observer.complete();
            }
          }, (err: any) => {
            observer.next(false);
            observer.complete();
          });
      });


      //var startupObservables = [siteContentObservable, homeContentObservable, refreshTokenObserver];
      var startupObservables = [siteContentObservable, homeContentObservable, checkAuthObserver];

      forkJoin(startupObservables)
        .subscribe((startupData:any) => {
          if ((startupData || []) && startupData.length > 0) {
            let response: any = startupData[0];
            if (response && response.status && (response.code === '1000') && response.result['Content']) {
              this.dataShareService.setSharedData(SITE_CONTENT, response.result['Content']);
            } else {
              reject(response.message);
            }
          }
          if ((startupData || []) && startupData.length > 1) {
            let response: any = startupData[1];
            if (response && response.status && (response.code === '1000') && response.result['Content']) {
              this.dataShareService.setSharedData(HOME_CONTENT, response.result['Content']);
            } else {
              reject(response.message);
            }
          }
          if ((startupData || []) && startupData.length > 2) {
            let response: boolean = startupData[2];
            if (!response) {
              this.userService.logout();
            } else {
              // debugger;
              var elapsedSecond = this.timer.checkElapsedTime();
              if (elapsedSecond >= this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION) {
                //Clear session, stop timer, clear start time
                this.userService.logout();
              }
              else {
                this.timer.start();
              }
            }
          }

          resolve(null);
        },
          (error: any) => {
            //console.log(error);
            reject(error);
          })
    });
  }

  private getHeaders() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'rid': this.randomvalue(),
    });
    return headers;
  }
  randomvalue() {
    return (Math.random() + 1).toString(36).substring(4);
  }
  private getHeadersWithToken() {
    var currentUser = this.userService.getCurrentUser();
    let headers;
    if (currentUser && currentUser.token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': this.randomvalue(),
        'Authorization': 'Bearer ' + currentUser.token
      })
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': this.randomvalue()
      })

    }
    return headers;
  }
  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
