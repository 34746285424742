import { Component, Input, Output, EventEmitter, ViewChildren, ViewChild, OnInit, AfterViewInit,ViewEncapsulation } from '@angular/core';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';


@Component({
    //moduleId: module.id.toString(),
    selector: "people-picker",
    templateUrl: './people-picker.component.html',
    encapsulation: ViewEncapsulation.None

})

export class PeoplePickerComponent implements OnInit, AfterViewInit {

    constructor(
        private velocityService: VelocityService
    ) { }

    @Input() multiSelect: boolean;
    @Input("PageContents") PageContents: any;
    @Input("gpId") growthPlanId: any;
    @Input("participantId") participantId: any;
    @Input("placeholder") placeholder: string;
    @Input("disabled") disabled: boolean = false;

    //  @Input() selectedUsers: any[] = [];
    @Input() maxSelectionCount: number = 1;
    @Input() minimumChars: number = 2;
    @Input() source: string = "AD";
    selectedUsers: any[] = [];
    @Input()
    set selectedMember(selData: any) {
        this.selectedUsers = selData;
        if (this.selectedUsers.length > 0)
            this.inputText = this.selectedUsers[0].firstName + " " + this.selectedUsers[0].lastName;
        else
            this.inputText = '';
    }
    get selectedMember(): any { return this.selectedUsers; }
    @ViewChild("nameInput") nameInput: any;


    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

    promises: any[] = [];
    errorMessage: string = "";
    showPreLoader: boolean = false;
    showEmptyText: boolean = false;

    public inputText: string = '';
    public filteredUsers: any[] = [];
    public hasMore: boolean = false;

    @Input() appendData: any = false;

    pageLength: number = 10;
    pageNo: number = 1;
    call: boolean = false;

    ngOnInit() {

        if (this.multiSelect === false) {
            this.maxSelectionCount = 1;
        }
    }


    ngAfterViewInit(): void {

        //this.focusInput();
    }

    onPaste() {
        this.filter();
    }

    onKeyPress(ev: any) {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            return;
        } else if (ev.keyCode === 32 || ev.keyCode === 8 || ev.keyCode === 46 || (ev.keyCode >= 48 && ev.keyCode <= 90) || (ev.keyCode >= 96 && ev.keyCode <= 111) || (ev.keyCode >= 186 && ev.keyCode <= 192) || (ev.keyCode >= 219 && ev.keyCode <= 222)) {
            this.filter();
        }
    }

    onKeyDown(ev: any) {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            return;
        }
    }
    getHeight() {
        if (this.filteredUsers.length > 0) {
            if (window.innerHeight >= 800) {
                if (this.filteredUsers.length > 11) {
                    return 340 + 'px';
                } else {
                    if (this.appendData && this.hasMore) {
                        return 300 + 'px';
                    }
                    return (31 * this.filteredUsers.length + 20) + 'px';
                }
            } else {
                if (this.filteredUsers.length > 5) {
                    return 170 + 'px';
                } else {
                    return (31 * this.filteredUsers.length + 20) + 'px';
                }
            }
        } else {
            return 'auto'
        }
    }
    filter() {
        this.showEmptyText = false;
        if (this.inputText === "" || this.inputText.length < this.minimumChars) {
            this.filteredUsers = [];
            this.clearPromises();
        } else {
            this.filteredUsers = [];
            this.clearPromises();

            // Removed last and first space from search keyword as it is searching wrong result.
            let searchWord = this.inputText.trim();
            //############################search users to Share  APi #####################
            this.pageNo = 1;
            if (this.growthPlanId) {
                //let shareParms = { keyword: this.inputText, growthPlanId: this.growthPlanId }
                let shareParms = { keyword: searchWord, growthPlanId: this.growthPlanId, page: this.pageNo, pageSize: this.pageLength }
                let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
                updateProgressBar.showProgressBar = false;
                var promise = this.velocityService.searchMemberShare(shareParms, updateProgressBar).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                );
            }
            else if (this.participantId) {
                console.log("Inside Search Participant ---------- Corporate growth Plan");
                let participantParms = { keyword: searchWord, page: this.pageNo, pageSize: this.pageLength }
                let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
                updateProgressBar.showProgressBar = false;
                var promise = this.velocityService.searchParticipant(participantParms, updateProgressBar).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                );
            }
            else {
                console.log('memberapi');
                //############################3 Search Members APi #####################
                //var params = { keyword: this.inputText }
                var params = { keyword: searchWord, page: this.pageNo, pageSize: this.pageLength }
                var updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
                updateProgressBar.showProgressBar = false;
                var promise = this.velocityService.searchMember(params, updateProgressBar).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                );
            }
            console.log('push');
            this.promises.push(promise);

            //remove
            //this.filterData(this.dummyUsers)

            this.showPreLoader = true;
        }
    }

    filterData(res: any) {
        this.filteredUsers = [];

        for (let user of res.members) {
            this.filteredUsers.push(user);
        }

        //this.filteredUsers = res.filter(
        //    (item: any) => (
        //        (item.GivenName.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //        || (item.Surname.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //        || (item.UserPrincipalName.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //    )
        //);

        if (this.filteredUsers.length < res.total) {
            this.hasMore = true;
        } else {
            this.hasMore = false;
        }


        if (this.filteredUsers.length === 0) {
            this.showEmptyText = true;
        }

        this.clearPromises();
    }

    selectUser(user: any) {
        //  this.inputText = "";
        this.filteredUsers = [];
        this.selectedUsers.push(user);
        this.inputText = this.selectedUsers[0].firstName + " " + this.selectedUsers[0].lastName;
        this.onChange.emit(this.selectedUsers);
    }

    deleteSelectedUser(user: any) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
        this.onChange.emit(this.selectedUsers);
        this.focusInput();
    }

    clearInput() {
        this.inputText = "";
        this.filteredUsers = [];
        this.showEmptyText = false;
    }

    focusInput() {
        /*console.log("focus");*/
        var isTextboxVisible = ((this.multiSelect === false) ? (this.selectedUsers.length < 1) : (this.selectedUsers.length < this.maxSelectionCount));

        if (isTextboxVisible) {
            //window.setTimeout(1000, function () {
            //    this.nameInput.nativeElement.focus();
            //})

            var txtSearchInput = document.getElementById("txtSearchInput");
            if (txtSearchInput) {
                txtSearchInput.focus();
            }
        }
    }

    clearPromises() {
        for (let promise of this.promises) {
            promise.unsubscribe();
        }
        this.promises = [];
        this.showPreLoader = false;
    }

    fireApi(e) {
        if (this.hasMore && !this.call) {
            this.call = true;
            let searchWord = this.inputText.trim();
            this.pageNo++;
            var params = { keyword: searchWord, page: this.pageNo, pageSize: this.pageLength }
            var updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
            updateProgressBar.showProgressBar = false;
            if (this.growthPlanId) {
                console.log('share');
                params['growthPlanId'] = this.growthPlanId;
                var promise = this.velocityService.searchMemberShare(params, updateProgressBar).subscribe(
                    results => this.appendFilteredData(results),
                    error => this.errorMessage = <any>error
                );
            } else if (this.participantId) {
                var promise = this.velocityService.searchParticipant(params, updateProgressBar).subscribe(
                    results => this.appendFilteredData(results),
                    error => this.errorMessage = <any>error
                );
            } else {
                var promise = this.velocityService.searchMember(params, updateProgressBar).subscribe(
                    results => this.appendFilteredData(results),
                    error => this.errorMessage = <any>error
                );
            }
            this.promises.push(promise);
        }
    }

    appendFilteredData(res: any) {
        this.call = false;
        console.log('append');
        if (res.members.length > 0) {
            for (let user of res.members) {
                this.filteredUsers.push(user);
            }
        }

        if (this.filteredUsers.length === 0) {
            this.showEmptyText = true;
        }

        if (this.filteredUsers.length < res.total) {
            this.hasMore = true;
        } else {
            this.hasMore = false;
        }

        this.clearPromises();
    }
}