<!--<div class="gp-action-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled }" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
    {{action.cardNumber}}
</div>-->

<div *ngIf="participant==null">
    <div class="gp-action-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled ,'showBorder':showBorder}" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
        
        {{action.cardNumber}}
    </div>
</div>
<div *ngIf="participant != null">
    <div *ngIf="!action.team" class="gp-action-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled ,'active-part':action.active}" [style.z-index]="getZvalue(action)" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
        {{participant}}
    </div>
    <!--div *ngIf="action.team" class="gp-action-bubble team-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled,'active-part':action.active }" [style.z-index]="getZvalue(action)" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
        &nbsp;&nbsp;{{participant}}&nbsp;&nbsp;
    </div-->
    <div style="background-color: #747480;border-radius: 6px;" *ngIf="action.team" class="gp-action-bubble team-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled,'active-part':action.active }" [style.z-index]="getZvalue(action)" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
</div>

<!--(mouseup)="onMouseup()"  (mousedown)="onMousedown(action)" (mouseleave)="onMouseup()"-->