<div class="modal fade ey-dialog-modal zoom-modal" id="zoom-modal" bsModal #zoomModal="bs-modal" tabindex="-1" role="dialog"
    (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="padding:0px;">
                <img class="whiteClose" (click)="closeBtn()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body {{parentClass}}" #mainContainer1 eyv-update-panel="sevenDriverGP1">
                <div class="seven-driver-GP-container1 {{getbubbleClass()}}" >
                    <div class="quadrant-container" #quadrantContainer1 [style.height.px]="quadrantHeight" [ngClass]="{'corporate-container':isCorporate && !self}">
                        <div class="quadrant-area" id="focus-quadrant" #quadrantArea1 [style.width.px]="quadrantAreaWidth">
                            <div #Quadrant1 class="row quadrants" (click)="clickQuadrants('')">
                                <div class="col-sm-12">
                                    <div class="row draggable-container">
                                        <div class="col-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                            <div class="draggable-box first-box" eyv-droppable (onEYVDropSuccess)="onBubbleDropped($event,1)"
                                                 [eyv-dropEnabled]="dropEnabled">
                                                <div style="width:100%;height:100%;position:relative">
                                                    <!--<ng-container *ngFor="let action of prioritizedActions">
                                        <growth-plan-bubble-test *ngIf="action.quadrant==1 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                 [action]="action" [isDragEnabled]="isDraggable(action)"
                                                                 (onDragStart)="quadrantBubbleDragStart($event)"
                                                                 [size]="getbubbleClass()"
                                                                 (onClick)="onselectedCard($event)"
                                                                 (onClickAndHold)="onClickandHoldBubble($event)"
                                                                 [ngClass]="getClassName(action.rag)"></growth-plan-bubble-test>
                                    </ng-container>-->
                                                    <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                        <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                            <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                <growth-plan-bubble-test *ngIf="action.quadrant==1 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                         [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                         (onClick)="onselectedCard($event)" (onClickAndHold)="onClickandHoldBubble($event)"
                                                                                         [count]="newPrioritizedActions[actions].length"
                                                                                         [ngClass]="getClassName(action)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container>
                                                            <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==1 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                      [action]="newPrioritizedActions[actions][0]"
                                                                                      [actions]="newPrioritizedActions[actions]"
                                                                                      (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                      [hideShow]="hideShowTooltip"
                                                                                      (onClick)="clickQuadrants($event)"
                                                                                      [selected]="countBubbleSelected[actions]"
                                                                                      [highlight]="countBubbleHighlight[actions]"
                                                                                      [view]="'zoom-view'"
                                                                                      [pageContents]="OtherContents['myGrowthPlan']"
                                                                                      [selectedCardParent]="selectedCard"
                                                                                      [size]="getbubbleClass()"></growth-plan-count-bubble>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                            <div class="draggable-box second-box" eyv-droppable (onEYVDropSuccess)="onBubbleDropped($event,2)"
                                                 [eyv-dropEnabled]="dropEnabled">
                                                <div style="width:100%;height:100%;position:relative">
                                                    <!--<ng-container *ngFor="let action of prioritizedActions">
                                        <growth-plan-bubble-test *ngIf="action.quadrant==2 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                 [action]="action" [isDragEnabled]="isDraggable(action)" [size]="getbubbleClass()"
                                                                 (onDragStart)="quadrantBubbleDragStart($event)"
                                                                 (onClick)="onselectedCard($event)"
                                                                 (onClickAndHold)="onClickandHoldBubble($event)"
                                                                 [ngClass]="getClassName(action.rag)"></growth-plan-bubble-test>
                                    </ng-container>-->
                                                    <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                        <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                            <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                <growth-plan-bubble-test *ngIf="action.quadrant==2 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                         [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                         (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                         [count]="newPrioritizedActions[actions].length"
                                                                                         (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container>
                                                            <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==2 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                      [action]="newPrioritizedActions[actions][0]"
                                                                                      [actions]="newPrioritizedActions[actions]"
                                                                                      (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                      [hideShow]="hideShowTooltip"
                                                                                      (onClick)="clickQuadrants($event)"
                                                                                      [selected]="countBubbleSelected[actions]"
                                                                                      [highlight]="countBubbleHighlight[actions]"
                                                                                      [view]="'zoom-view'"
                                                                                      [pageContents]="OtherContents['myGrowthPlan']"
                                                                                      [selectedCardParent]="selectedCard"
                                                                                      [size]="getbubbleClass()"></growth-plan-count-bubble>

                                                            <!-- <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0].quadrant==2 && newPrioritizedActions[actions][0].isSelected==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length" [highLightBubbles]="newPrioritizedActions[actions][0].driverTag==highLightBubbles"
                                                         [action]="newPrioritizedActions[actions][0]" [isDragEnabled]="isDraggable(newPrioritizedActions[actions][0])" (onDragStart)="quadrantBubbleDragStart($event)"
                                                         (onClick)="onselectedCard($event)" [ngClass]="getClassName(action[newPrioritizedActions[actions][0]])"
                                                         (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-count-bubble> -->
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row draggable-container" [style.max-height.px]="quadrantWidth">
                                        <div class="col-6 no-padding-column">
                                            <div class="draggable-box third-box" eyv-droppable (onEYVDropSuccess)="onBubbleDropped($event,3)"
                                                 [eyv-dropEnabled]="dropEnabled">
                                                <div style="width:100%;height:100%;position:relative">
                                                    <!--<ng-container *ngFor="let action of prioritizedActions">
                                        <growth-plan-bubble-test *ngIf="action.quadrant==3 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                 [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)" [size]="getbubbleClass()"
                                                                 (onClickAndHold)="onClickandHoldBubble($event)" (onClick)="onselectedCard($event)"
                                                                [ngClass]="getClassName(action.rag)"></growth-plan-bubble-test>
                                    </ng-container>-->
                                                    <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                        <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                            <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                <growth-plan-bubble-test *ngIf="action.quadrant==3 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                         [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                         (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                         [count]="newPrioritizedActions[actions].length"
                                                                                         (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container>
                                                            <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==3 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                      [action]="newPrioritizedActions[actions][0]"
                                                                                      [actions]="newPrioritizedActions[actions]"
                                                                                      (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                      [hideShow]="hideShowTooltip"
                                                                                      (onClick)="clickQuadrants($event)"
                                                                                      [selected]="countBubbleSelected[actions]"
                                                                                      [highlight]="countBubbleHighlight[actions]"
                                                                                      [view]="'zoom-view'"
                                                                                      [pageContents]="OtherContents['myGrowthPlan']"
                                                                                      [selectedCardParent]="selectedCard"
                                                                                      [size]="getbubbleClass()"></growth-plan-count-bubble>

                                                            <!-- <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0].quadrant==2 && newPrioritizedActions[actions][0].isSelected==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length" [highLightBubbles]="newPrioritizedActions[actions][0].driverTag==highLightBubbles"
                                            [action]="newPrioritizedActions[actions][0]" [isDragEnabled]="isDraggable(newPrioritizedActions[actions][0])" (onDragStart)="quadrantBubbleDragStart($event)"
                                            (onClick)="onselectedCard($event)" [ngClass]="getClassName(action[newPrioritizedActions[actions][0]])"
                                            (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-count-bubble> -->
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 no-padding-column" id="fourZoom" [style.max-height.px]="quadrantWidth">
                                            <div class="draggable-box fourth-box" eyv-droppable (onEYVDropSuccess)="onBubbleDropped($event,4)"
                                                 [eyv-dropEnabled]="dropEnabled">
                                                <div style="width:100%;height:100%;position:relative">
                                                    <!--<ng-container *ngFor="let action of prioritizedActions">
                                        <growth-plan-bubble-test *ngIf="action.quadrant==4 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                 [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)" [size]="getbubbleClass()"
                                                                 (onClickAndHold)="onClickandHoldBubble($event)" (onClick)="onselectedCard($event)"
                                                                 [ngClass]="getClassName(action.rag)"></growth-plan-bubble-test>
                                    </ng-container>-->
                                                    <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                        <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                            <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                <growth-plan-bubble-test *ngIf="action.quadrant==4 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                         [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                         (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                         [count]="newPrioritizedActions[actions].length"
                                                                                         (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container>
                                                            <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==4 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                      [action]="newPrioritizedActions[actions][0]"
                                                                                      [actions]="newPrioritizedActions[actions]"
                                                                                      (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                      [hideShow]="hideShowTooltip"
                                                                                      (onClick)="clickQuadrants($event)"
                                                                                      [selected]="countBubbleSelected[actions]"
                                                                                      [highlight]="countBubbleHighlight[actions]"
                                                                                      [view]="'zoom-view'"
                                                                                      [pageContents]="OtherContents['myGrowthPlan']"
                                                                                      [selectedCardParent]="selectedCard"
                                                                                      [size]="getbubbleClass()"></growth-plan-count-bubble>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class=" impact-scale-y-axis" style="margin-left: -40px;">
                                <p>
                                    <!--<span class="arrow-left"></span>-->
                                    <span class="left lable-text" style="max-width: 90px;">{{PageContents.lowLabel}}</span>
                                    <span class="middle lable-text" style="max-width: 170px;"><div>{{yLabel}}</div></span>
                                    <span class="right lable-text" style="max-width: 90px;">{{PageContents.highLabel}}</span>
                                    <!--<span class="arrow-right"></span>-->
                                </p>
                            </div>
                            <div class=" impact-scale-x-axis" style="margin-top: -20px;">
                                <p>
                                    <!--<span class="arrow-left"></span>-->
                                    <span class="left lable-text" style="max-width: 90px;">{{PageContents.lowLabel}}</span>
                                    <span class="middle lable-text" style="max-width: 170px;"><div>{{xLabel}}</div></span>
                                    <span class="right lable-text" style="max-width: 90px;">{{PageContents.highLabel}}</span>
                                    <!--<span class="arrow-right"></span>-->
                                </p>
                            </div>


                        </div>
                        <div class="action-area" [style.width.px]="newAreaWidth > 300 ?newAreaWidth:300" [style.height.px]="calQuadrantHeight " #actionArea1>
                            <div class="driver-heading">
                                <div>
                                    {{PageContents.breadCrumbOne}}
                                </div>
                            </div>

                            <div [style.height.px]="calQuadrantHeight">
                                <div class="row action-list">
                                    <div class="col-12" (click)="changeList(1)"> {{PageContents.toPrioritizeLabel}}<span class="count">{{subDriverNonPrioritzedCount}}/{{actionCount}}</span></div>
                                    <!--<div class="col-6" [ngClass]="{ 'not-selected-list': selectedType != 2}">{{PageContents.allActionsLabel}}</div>-->
                                </div>

                                <div class="action-list-cards" [style.height.px]="calQuadrantHeight - 42" custom-scroll [scrollPos]="listCardPos">
                                    <div class="card" *ngFor="let action of subDriverAction" [ngClass]="getBorderClassName(action)" (click)="setParticipants(action,false,false,'fromFilter')" id="actionCard{{action.cardNumber}}" eyv-draggable [eyv-dragEnabled]="isDraggable(action)"
                                         [eyv-dragData]="action"
                                         [eyv-dragData-size]="getbubbleClass()"
                                         (onEYVDragStart)="onDragStart($event)">
                                        <div class="action-number">{{action.cardNumber}}</div>
                                        <div class="action-title"> {{action.title}}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="participant-area" custom-scroll [scrollPos]="topPartPos" #participantArea1 [style.height.px]="calQuadrantHeight" [style.width.px]="partcipantWidth" *ngIf="isCorporate && !self">
                            <div>
                                <div class="action-card-details">
                                    <span class="cardNumber" *ngIf="selectedCardNumber && isCorporate && !self"> {{selectedCardNumber}}</span>
                                    <span class="action-title" *ngIf="selectedTitle && isCorporate && !self"> {{selectedTitle}}</span>
                                </div>
                                <div class="quadrant-container" *ngIf="selectedPrioCorpActions">
                                    <div class="quadrantArea" [style.width]="partcipantQuadrantWidth">
                                        <div #PQuadrant1 class="row quadrants" [ngClass]="getbubbleClass(true)">
                                            <div class="col-sm-12">
                                                <div class="row draggable-container">
                                                    <div class="col-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                                        <div class="draggable-box first-box">
                                                            <div style="width:100%;height:100%;position:relative">
                                                                <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                    <growth-plan-bubble-test *ngIf="action.priorityId==1" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="false" [size]="getParticipantBubbleClass()"
                                                                                             [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 no-padding-column">
                                                        <div class="draggable-box second-box">
                                                            <div style="width:100%;height:100%;position:relative">
                                                                <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                    <growth-plan-bubble-test *ngIf="action.priorityId==2" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="false" [size]="getParticipantBubbleClass()"
                                                                                             [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 no-padding-column">
                                                        <div class="draggable-box third-box">
                                                            <div style="width:100%;height:100%;position:relative">
                                                                <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                    <growth-plan-bubble-test *ngIf="action.priorityId==3" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="false" (onDragStart)="quadrantBubbleDragStart($event)" [size]="getParticipantBubbleClass()"
                                                                                             [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 no-padding-column">
                                                        <div class="draggable-box fourth-box">
                                                            <div style="width:100%;height:100%;position:relative">
                                                                <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                    <growth-plan-bubble-test *ngIf="action.priorityId==4" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [size]="getParticipantBubbleClass()" [isDragEnabled]="false"
                                                                                             [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--<br />-->
                                <div class="participant-details row">
                                    <div class="col-6 no-padding-column user-avatar" [ngClass]="{'col-12':participant.team}" (click)="selectAction(participant)" *ngFor="let participant of selectedPrioCorpActions">
                                        <div class="part-inside" [ngClass]="{'sel-part':participant.active}">
                                            <div class="user-avatar-black" style="position: absolute;top: 50%;-ms-transform: translateY(-33%);transform: translateY(-33%);">{{participant.team ? '' : participant.participantAbbrivation}}</div>
                                            <div class="user-info">
                                                <span class="user-info-name">{{participant.team ? OtherContents.growthPlanSession.lblTeamActionBasedOnAgreement : participant.userName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>