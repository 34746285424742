import { Component, OnInit, Input, Injector, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BaseComponent } from './../../component/base.component';
import { VelocityService } from "../../service/velocity.service";
import { GrowthService } from "../../service/growth.service";
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { Constants } from "../../service/constant.service";
import { UtilService } from "../../service/util.service";
import { ComponentContent } from './../../component/component-content.decorator';
import { CONTENTS } from './../../config/content.const';
import { forkJoin } from 'rxjs';

@ComponentContent(CONTENTS.SUB_DRIVER_LANDING)

@Component({
    selector: 'insight-modal-component',
  templateUrl: './insight-component.html',
  styleUrls: ['./insight-component.scss']
})

export class InsightModalComponent extends BaseComponent implements OnInit {

  @ViewChild('insightModal', { static: true }) insightModal: ModalDirective;

  @ViewChild('insightsList', { static: true }) insightsElement: ElementRef;

    observable: Observable<any>;
    subject: Subject<any>;
    returnedValue: string = 'closed';
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    height = 'auto';
    isDriver = true;
   // insightDetail = false;
  //  insightPinned = false;
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    maturityId: any;
    driverId: any;
    insights = [];
    selInsightId: any;
    insightContent: any;
    updateProgressBar: ProgressBarOptions;
    subTheme :any;
    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private growthService: GrowthService,
        private logService: LogService,
        private logTypes: LogTypes,
        public constants: Constants,
        private utilService:UtilService
    ) {
        super(injector);
        this.maturityId = this.injector.get('maturityId');
        this.isDriver = this.injector.get('isDriver');
        if (this.isDriver)
            this.driverId = this.injector.get('driverId');
        else
            this.subTheme = this.injector.get('driverId');

    }

    ngOnInit() {
        this.updateProgressBar = ProgressBarHelper.newUpdateProgressOption("insight-list");
        if (this.isDriver) {
            this.growthService.getInsightsForMaturityLevel(this.maturityId, this.driverId, this.updateProgressBar).subscribe((data: any) => {
                if (data) {

                    this.insights = data.insightCards;
                    this.getHeight();
                }
            });
        } else {
            this.growthService.getInsightsForSubdriver(this.maturityId, this.updateProgressBar).subscribe((data: any) => {
                if (data) {
                    this.insights = data;
                    this.getHeight();
                }
            });
        }
       
    }

    getHeight() {
   // if(!this.insightDetail){
            if (this.insights.length > 4 && window.innerWidth >= 576) {
                this.height = '250px';
                setTimeout(() => {
                    var cards = this.insightsElement.nativeElement.querySelectorAll('.insight-card');
                    this.height = cards[0].offsetHeight + cards[2].offsetHeight + 35 + 'px';
                }
                );
            } else if (this.insights.length > 2 && window.innerWidth < 576) {

                setTimeout(() => {
                    var cards = this.insightsElement.nativeElement.querySelectorAll('.insight-card');
                    this.height = cards[0].offsetHeight + cards[1].offsetHeight + 35 + 'px';
                }
                );
            } else {
                this.height = 'auto';
            }
    //}
    }
    ngAfterViewInit() {
    }

    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }
    @HostListener('window:resize') onResize() {
        this.getHeight();
    }

    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.insightModal.config = this.config;
        this.insightModal.show();
        return this.observable;
    }

    public closeDialog() {

        this.insightModal.hide();
    }
    //goBack() {
    //    this.insightDetail = false;
    //}
    exploreInsight(insightId: number) {
        this.selInsightId = insightId;
      //  this.insightDetail = true;
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("insight-list");
            var insightDetailObs = this.growthService.getInsightCardDetails(insightId, updateProgressBar);
            var insightPinnedObs = this.velocityService.getInsightsPin(insightId, updateProgressBar);

            forkJoin(insightDetailObs, insightPinnedObs)
                .subscribe(
                (data) => {
                    if (data) {
                        var templateData = data[0];
                        templateData["source"] = this.constants.GENERAL.PAGE_HOT_TOPICS;
                        templateData["isPinned"] = data[1];
                        templateData["back"] = true;
                        if (this.subject) {
                            this.subject.next(templateData);
                        }
                       // this.insightDetailModal.show();

                       // this.dialogService.info(TemplateTypes.INSIGHT_POPUP, templateData);
                        //this.insightContent = data[0]["content"];
                        //this.insightPinned = <boolean>data[1];
                        //this.insightDetail = true;

                    }
                }
                )
        
    }
  
    getThemeShape(themeId: number) {
        var selTheme = this.subTheme.filter(item => item.id == themeId);
        if (selTheme)
            return selTheme[0].shape;
        else
            return '';
    }
}
