import { Component, Input, ViewChild, Injector, Output, EventEmitter , NgZone} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { EmailDuplicateValidator } from '../../auth/custom-validator/email.validator';
import { AuthenticationService } from '../../auth/service/auth.service';
import { UtilService } from '../../service/util.service';
//import { ModalModule, Modal } from 'ng2-modal';
 
import {Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
    //moduleId: module.id.toString(),
    selector: "form-dialog",
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})

export class FormDialogComponent {
    title: string = "";
    message: string = "";
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    modalForm: FormGroup;
    data: any;
    emailDuplicate: boolean;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    constructor(
        private injector: Injector, 
        private zone: NgZone, 
        private authService: AuthenticationService,
        private utilService: UtilService) {
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.customize = this.injector.get('config');
    }
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

  @ViewChild('formModal', { static: true }) formModal: any;
    returnedValue: any = {};


    ngOnInit() {
        this.modalForm = new FormGroup({
            email: new FormControl('',[
                Validators.required,
                Validators.email]
                // EmailDuplicateValidator.verifyDupicate(this.authService)
            )
        })
        this.modalForm.get('email').valueChanges.subscribe(
            (validate) =>{
                this.emailDuplicate = false;
            });
     }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }
    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue.action = 'yes';
        this.authService.veriyEmail(this.modalForm.value.email).subscribe(
            (result) => {
                if(result) {
                    this.emailDuplicate = true;
                } else{
                    this.returnedValue.data = this.modalForm.value;
                    this.closeDialog();
                }
            }
        );
    }

    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.formModal.config = this.config;
        this.formModal.show();
        return this.observable;
    }
    
    public closeDialog() {
        this.formModal.hide();
    }
}
