import { Component } from '@angular/core';


@Component({
    //moduleId: module.id.toString(),
    selector: "page-posting-overlay",
  templateUrl: './page-posting.component.html',
  styleUrls: ['./page-posting.component.scss'],
})

export class PagePostingComponent {
    
    state = "inactive";
    constructor() {
    }

    ngOnInit() { }

    
}
