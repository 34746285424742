<div class="modal fade ey-helptopics-modal" bsModal #userProfileModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">

                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />{{SiteContents.lblClose}}
                </div>

                <div class="help-topics-container" eyv-update-panel="help-topics">
                    <div class="row">
                        <div class="col-md-12 help-title">Help Topics</div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-2">

                                    <ul class="menu-list">
                                        <li *ngFor="let topic of helpTopics" [ngClass]="{'active':topic.active}" (click)="onGetHelpSections(topic.id)">{{topic.topic}}</li>
                                    </ul>

                                </div>
                                <div class="col-sm-10 help-section-dialog">

                                    <ul class="topics-details" #subTopicAccordion>
                                        <li class="topics-list" *ngFor="let item of helpSections" [attr.id]="'subTopic_' +item.id">
                                            <div class="topic-title" (click)="onSetActiveSection(item.helpTopicId,item.id, item.active)">
                                                <i class="fa fa-plus icon-style" [ngClass]="{'fa-minus':item.active,'fa-plus':!item.active}" aria-hidden="true"></i>
                                                <span [innerHtml]="item.sectionName"></span>
                                            </div>
                                            <ul class="topic-transition" [style.height]="item.active && setInitialHeight?'auto':''">
                                                <li *ngFor="let subItem of item.details">
                                                    <span [innerHtml]="subItem.content"></span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>