<div class="alert-c" *ngIf="show === true">
    <div class="alert">
        <span class="closebtn" (click)="close()">&times;</span>
        <i *ngIf="flashType != 'accept'" class="fa fa-exclamation-circle exclamation-c" aria-hidden="true"></i>
        <i *ngIf="flashType == 'accept'" class="fa fa-check-circle check-c" aria-hidden="true"></i>

        <!--<svg *ngIf="flashType != 'accept'" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.165 0C4.10592 0 0 4.10592 0 9.165C0 14.2241 4.10592 18.33 9.165 18.33C14.2241 18.33 18.33 14.2241 18.33 9.165C18.33 4.10592 14.2241 0 9.165 0V0Z" fill="#B9251C" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0815 13.7471H8.24854V11.9141H10.0815V13.7471V13.7471Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0815 10.081H8.24854V4.58203H10.0815V10.081V10.081Z" fill="white" />
    </svg>
    <svg *ngIf="flashType == 'accept'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="18" height="18">
        <g transform="matrix(0.8181819 0 0 0.8181819 0 0)">
            <g transform="matrix(0.9166667 0 0 0.9166667 0 0)">
                <path d="M12 0C 5.373 0 0 5.373 0 12C 0 18.626999 5.373 24 12 24C 18.626999 24 24 18.626999 24 12C 24 5.373001 18.626999 0 12 0zM10.75 17.292L6.25 12.9279995L8.107 11.07L10.75 13.576L16.393 7.7920003L18.25 9.649L10.75 17.292z" stroke="none" fill="#B9251C" fill-rule="nonzero" />
            </g>
        </g>
    </svg>-->
        <span class="ms-2" *ngIf="flashType != 'accept'">{{pageContent.lblInviteDeclined}}</span>
        <span class="ms-2" *ngIf="flashType == 'accept'">{{pageContent.lblInviteAccepted}}</span>
    </div>
</div>
