<div class="btn-group filter-dropdown">
    <button style="" (click)="clickFilterfn()" type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="filter-by">{{appContent.lblFilterBy}}:</span> <span class="selected"><span class="bold-font">{{selectedFilterValue.driverName}}&nbsp;({{selectedFilterValue.count}})</span></span>
    </button>
    <div class="dropdown-menu" style="width:100%;">
        <ng-container *ngFor="let driver of drivers; let i = index">
            <div class="dropdown-divider" *ngIf="i != 0"></div>
            <a class="dropdown-item" [ngClass]="{ 'disabled-style':driver.count == 0}" (click)="selectFilter(driver,$event)">{{driver.driverName}}&nbsp;<span class="bold-font">({{driver.count}})</span></a>
        </ng-container>
        
        <!-- <a class="dropdown-item" (click)="selectFilter('Digital, technology and analytics')">Digital, technology and analytics (20)</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="selectFilter('Operations')">Operations (100)</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="selectFilter('Customer')">Customer (13)</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="selectFilter('Funding and finance')">Funding and finance (103)</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item disabled" (click)="selectFilter('Transactions and alliances')">Transactions and alliances (0)</a> -->
    </div>
</div>