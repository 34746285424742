import { Directive, ElementRef, HostListener, AfterViewInit, OnDestroy, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Directive({
    selector: "[custom-scroll]"
})
export class CustomScrollDirective implements AfterViewInit, OnDestroy, OnChanges {

    config: any = { axis: 'y', theme: 'dark', scrollTo: "500", alwaysShowScrollbar: true };
    xconfig: any = { axis: 'x', theme: 'dark', scrollTo: "500", autoHideScrollbar: true };
    xyconfig: any = { axis: 'xy', theme: 'minimal-dark', scrollTo: "500", autoHideScrollbar: true };
    peoplePickConfig: any = {
        axis: 'y',
        theme: 'dark',
        autoHideScrollbar: false,
        scrollTo: "500",
        callbacks: {
            onTotalScroll: () => {
                console.log('1');
                this.fireApi.emit(true);
            },
            onOverflowYNone: () => {
                console.log('2');
            },
            onOverflowY: () => {
                console.log('3');
            },
            onTotalScrollOffset: 100

        }
    }

    element: ElementRef;
    @Input() scrollPos: any;
    @Input() scrollDir: any;
    @Input() notScroll: boolean = false;
    @Input() notIE: any;
    @Input() peoplePick: boolean = false;
    @Output() fireApi: EventEmitter<any> = new EventEmitter<any>();


    constructor(
        private el: ElementRef,
        private mScrollbarService: MalihuScrollbarService
    ) {
        this.element = el;
    }


    ngAfterViewInit() {
        if (!this.notScroll) {
            if (this.notIE) {
                if (!this.isIE()) {

                    if (this.peoplePick) {
                        this.mScrollbarService.initScrollbar(this.element.nativeElement, this.peoplePickConfig);
                    }
                    else if (this.scrollDir == 'x') {
                        this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xconfig);

                    } else if (this.scrollDir == 'xy') {
                        this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xyconfig);

                    } else {
                        this.mScrollbarService.initScrollbar(this.element.nativeElement, this.config);

                    }
                }

            } else {
                if (this.peoplePick) {
                    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.peoplePickConfig);
                }
                else if (this.scrollDir == 'x') {
                    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xconfig);

                } else if (this.scrollDir == 'xy') {
                    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xyconfig);

                } else {
                    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.config);

                }
            }
        }
    }
    scrollTo(position: any) {

    }
    isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
        {
            return true;
        }
        else  // If another browser, return 0
        {
            return false;
        }
    }
    ngOnDestroy() {
        this.mScrollbarService.destroy(this.element.nativeElement);

        //if (this.scrollDir == 'x') {
        //    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xconfig);

        //} else if (this.scrollDir == 'xy') {
        //    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.xyconfig);

        //}else {
        //    this.mScrollbarService.initScrollbar(this.element.nativeElement, this.config);
        //}
    }
    ngOnChanges(changes: any): void {
        if (changes.scrollPos) {
            if (this.scrollPos != '') {
                this.mScrollbarService.scrollTo(this.element.nativeElement, this.scrollPos, { scrollInertia: 1000 });

            }

        }
    }
}