import { Component, OnInit, Injector, Input } from '@angular/core';
import { DataShareService } from './../service/data-share.service';
import { UserService } from './../auth/service/user.service';
import { CONTENTS } from './../config/content.const';



const SITE_CONTENT = CONTENTS.SITE_CONTENT;
@Component({
    template: ""
})
export class BaseComponent implements OnInit {
    public SiteContents: any = {};
    public PageContents: any = {};
    public OtherContents: any = {};
    private pageName: string;
    private pageNames: Array<string>;
    public currentUser: any; 
    private _dataShareService: DataShareService; 
    private _userService: UserService;

    public requiresGrowthPlanId: boolean;

    constructor(private _injector: Injector) {
        
        this._dataShareService = _injector.get(DataShareService);
        this._userService = _injector.get(UserService);

        
        this.SiteContents = this._dataShareService.getSharedData(SITE_CONTENT) || {};
        
        if (this.pageName && this.pageName != "") {
            var contents = this._dataShareService.getSharedData(this.pageName);
            this.PageContents = contents || {};
        }

        if (this.pageNames && this.pageNames.length && this.pageNames.length > 0) {
            for (let pageName of this.pageNames) {
                if (pageName && pageName != "") {
                    var otherContent = this._dataShareService.getSharedData(pageName);
                    if (otherContent) {
                        this.OtherContents[pageName] = otherContent || {};
                    }
                }
            }
        }
        
        this.currentUser = this._userService.getCurrentUser();

    }

    ngOnInit() {
    }
}
