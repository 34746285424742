import { Injectable } from '@angular/core';
import { Subject }  from 'rxjs';

@Injectable()
export class ConsiderationService {

  // Observable string sources
  private announceFromPopover = new Subject<string>();
  private announceFromSubtopic = new Subject<string>();

  // Observable string streams
  announcedFromPopover$ = this.announceFromPopover.asObservable();
  announcedFromSubtopic$ = this.announceFromSubtopic.asObservable();

  // Service message commands
  emitFromPopover(consideration: string) {
    this.announceFromPopover.next(consideration);
  }

  emitFromSubTopic(consideration: string) {
    this.announceFromSubtopic.next(consideration);
  }
}
