import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VelocityService } from './../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
import { UtilService } from '../../service/util.service';
import { CONTENTS } from '../../config/content.const';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { TemplateTypes } from '../../modal-dialog-module/template-types.enum';
import { UserService } from './../../auth/service/user.service';

import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { StorageHelperService } from '../../service/storage-helper.service';
import { Constants } from '../../service/constant.service';
import { DataShareService } from '../../service/data-share.service';

@Component({
    selector: 'notification-list',
  templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationListComponent implements OnInit {
    @Input("contents")
    PageContents: any;
    notificationList: any[] = [];
    pageNo: number = 1;
    hasMoreCards: boolean = false;
    totalNotificationCount: number = 0;
    totalNoOfPages: number = 0;
    isNotificationsFetched = false;
    isNotificationEnabled: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private velocityService: VelocityService,
        private utilService: UtilService,
        private dialogService: ModalDialogService,
        private userService: UserService,
        private logService: LogService,
        private logType: LogTypes,
        private storageHelper: StorageHelperService,
        private dataShareService: DataShareService,
        private constant: Constants) {

        console.log(this.router.url);
    }

    ngOnInit() {
        //window.scrollTo(0, 0);
        console.log(this.PageContents);
        const user = this.userService.getCurrentUser();
        this.isNotificationEnabled = user.notificationStatus;
        if (this.isNotificationEnabled) {
            this.getNotificationList();
        }

    }


    getNotificationList() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("notificationListProgress");
        this.velocityService.getNotificationList(this.pageNo, updateProgressBar).subscribe(
            (data: any) => {
                console.log("Notification List", data);
                this.isNotificationsFetched = true;
                if (data && data.notifications && (data.notifications.length > 0)) {
                    this.getNotificationText(data.notifications);
                    this.notificationList = this.notificationList.concat(data.notifications);
                    console.log('noti: ', this.notificationList);
                    this.hasMoreCards = data.hasMore;
                    this.totalNotificationCount = data.total;

                }
            }
        );
    }

    viewMoreNotifications() {
        this.pageNo = this.pageNo + 1;
        this.getNotificationList();
    }


    onDeleteNotitification(notificationId: number) {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("notificationListProgress");
        this.velocityService.removeNotification(notificationId, updateProgressBar).subscribe(
            (data: any) => {
                var itemIndex = this.notificationList.findIndex(x => x.id == notificationId);
                this.notificationList.splice(itemIndex, 1);
                console.log('Deletion: ', this.notificationList);
                if (this.notificationList.length == 0) {
                    this.viewMoreNotifications();
                }
                this.logEvent(this.logType.REMOVE_NOTIFICATIONS, notificationId);
            }
        );

    }

    getNotificationText(notificationList: any) {

        for (let notification of notificationList) {
            var splitText = '';

            switch (notification.notificationType) {
                case "discussionReply": {
                    notification.notificationText = (notification.notificationText.replace('<threadName>', `<a class="blueText" href ="connect/discussion/${notification.parameters.threadId}" >${notification.parameters.threadName}</a>`))
                        .replace('<threadResponseUser>', `<a class="blueText" href="connect/user-profile/${notification.parameters.userId}">${notification.parameters.userFirstName}</a>`);
                    break;
                }
                case "receiveUpvote":

                    notification.notificationText = (notification.notificationText.replace('<upvoteCount>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.upvoteCount}</a>`))
                        .replace('<threadName>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`);
                    break;
                case "receiveDownvote":
                    //console.log("Downvote Text" + notification.notificationText);
                    notification.notificationText = (notification.notificationText.replace('<downvoteCount>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.downVoteCount}</a>`))
                        .replace('<threadName>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`);
                    break;

                case "actionPrioritization":
                    notification.notificationText = (notification.notificationText.replace('<growthPlanName>', `<a class="blueText">${notification.parameters.growthPlanName}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;

                case "eventSchedule":
                    notification.notificationText = (notification.notificationText.replace('<eventName>', `<a class="blueText" href="connect/event-detail/${notification.parameters.eventId}">${notification.parameters.eventName}</a>`)) // TODO 
                        .replace('<here>', `<a class="blueText" href="connect/event-detail/${notification.parameters.eventId}">here</a>`);
                    break;

                case "followThread":
                    notification.notificationText = (notification.notificationText.replace('<threadCountFollowers>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.followCount}</a>`)) // TODO 
                        .replace('<threadName>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`);
                    break;
                case "incompleteDriverAssessment":
                    notification.notificationText = (notification.notificationText.replace('<driverName>', `<a class="blueText" href="focus/ey.aimaturitymodel/pillar-assessment/${notification.parameters.driverId}">${notification.parameters.driverName}</a>`))
                        .replace('<here>', `<a class="blueText" href="focus/ey.aimaturitymodel/pillar-assessment/${notification.parameters.driverId}">here</a>`);

                    break;

                case "newCommunity":
                    notification.notificationText = (notification.notificationText.replace('<here>', `<a class="blueText" href="connect/community/${notification.parameters.communityId}">here</a>`));// TODO 

                    break;

                case "newDriverAssessment":
                    notification.notificationText = (notification.notificationText.replace('<growthPlanName>', `<a class="blueText">${notification.parameters.growthPlanName}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;

                case "corporateGrowthPlanNotification":
                    notification.notificationText = (notification.notificationText.replace('<OwnerName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    let formatedDate = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<MeetingDate>', `<a class="blueText">${formatedDate}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "leadershipMeetingNominateGrowthPlan":
                    notification.notificationText = (notification.notificationText.replace('<FacilitatorName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    let formatedDate2 = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<MeetingDate>', `<a class="blueText">${formatedDate2}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "corporateGroupSessionCancellation":
                    let cancelMeetingDate = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<OwnerName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<MeetingName>', `<a class="blueText">${notification.parameters.growthPlanName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<MeetingDate>', `<a class="blueText">${cancelMeetingDate}</a>`));
                    break;
                case "leadershipMeetingCancellation":
                    let cancelMeetingDate1 = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<FacilitatorName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<MeetingName>', `<a class="blueText">${notification.parameters.growthPlanName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<MeetingDate>', `<a class="blueText">${cancelMeetingDate1}</a>`));
                    break;
                case "leadershipMeetingDateModification":
                    let oldMeetingDate1 = this.formatDate(notification.parameters.oldMeetingDate.substring(0, 10));
                    let newMeetingDate1 = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<FacilitatorName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<oldMeetingDate>', `<a class="blueText">${oldMeetingDate1}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<newMeetingDate>', `<a class="blueText">${newMeetingDate1}</a>`));
                    break;
                case "corporateGrowthPlanDateChangeNotification":
                    let oldMeetingDate = this.formatDate(notification.parameters.oldMeetingDate.substring(0, 10));
                    let newMeetingDate = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<ownerName>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<oldMeetingDate>', `<a class="blueText">${oldMeetingDate}</a>`));
                    notification.notificationText = (notification.notificationText.replace('<newMeetingDate>', `<a class="blueText">${newMeetingDate}</a>`));
                    break;

                case "corporateGrowthPlanDelegatedOwnerNotification":
                    notification.notificationText = (notification.notificationText.replace('<Owner Name>', `<a class="blueText">${notification.parameters.ownerName}</a>`));
                    let formatedMeetingDate = this.formatDate(notification.parameters.meetingDate.substring(0, 10));
                    notification.notificationText = (notification.notificationText.replace('<Meeting date>', `<a class="blueText">${formatedMeetingDate}</a>`));
                    splitText = (notification.notificationText.split('<b>here<b>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;

                case "newHotTopic":
                    notification.notificationText = (notification.notificationText.replace('<hotTopicName>', `<a class="blueText" href="focus/hot-topics/detail/${notification.parameters.topicId}">${notification.parameters.topicTitle}</a>`));
                    break;
                case "newTool":
                    notification.notificationText = (notification.notificationText.replace('<here>', `<a class="blueText" href="act/tool-details/${notification.parameters.cardId}/1/0/0">here</a>`));
                    break;
                case "moderateThreadNewCommunity":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`));
                    break;
                case "moderateThreadExistingCommunity":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`));
                    break;
                case "moderateAnswerContentChanged":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "moderatePostDeleted":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "moderateThreadDeleted":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<span class="blueText">${notification.parameters.threadName}</span>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "moderateThreadContentChanged":
                    notification.notificationText = (notification.notificationText.replace('<thread name>', `<a class="blueText" href="connect/discussion/${notification.parameters.threadId}">${notification.parameters.threadName}</a>`));
                    splitText = (notification.notificationText.split('<here>'));
                    notification.notificationText = '';
                    notification['text1'] = splitText[0];
                    notification['text2'] = splitText[1];
                    break;
                case "newThread":
                    notification.notificationText = (notification.notificationText.replace('<communityName>', `<a class="blueText" href="connect/community/${notification.parameters.communityId}">${notification.parameters.communityName}</a>`));
                default:
                    break;
            }

        }


    }

    getKeyByValue(value) {
        let object = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return object[value - 1];
    }

    formatDate(meetingDate) {
        let dataArray = meetingDate.split(/-/);
        let monthNo = this.getKeyByValue(dataArray[1]);
        return dataArray[2] + " " + monthNo + " " + dataArray[0];
    }

    // function to set this GP as active and redirect to 7 drivers page  
    goToInsidePage(gpDetails: any, type: any) {

        let param = {
            growthPlanId: gpDetails.growthPlanId
        }
        let gPlan = {
            growthPlanId: gpDetails.growthPlanId,
            growthPlanName: gpDetails.growthPlanName
        }
        this.velocityService.setGrowthPlanId(param).subscribe((result) => {
            if (result) {
                console.log('inside success subscribe: ');
                this.setGrowthPlanSessionStorageData(gPlan);
                // Redirect to Seven Drivers 
                if (type == "actionPrioritization") {
                    if (this.router.url == "/act/my-growth-plan") {
                        this.refreshCurrentRoute();
                    }
                    else {
                        this.router.navigate(['act', 'my-growth-plan']);
                    }
                }
                else if (type == "newDriverAssessment") {
                    if (this.router.url == "/focus/ey.aimaturitymodel") {
                        this.refreshCurrentRoute();
                    }
                    else {
                        this.router.navigate(['focus', 'ey.aimaturitymodel']);
                    }
                }
                else {
                    console.log("no place to redirect");
                }
            }
            else {
                // If GP is setting from DB is false redirect to 404 page 
                this.router.navigate(['general', 'error404']);
            }
        });
    }

    // function to open nominate popup for evanglin  
    openCorporateNominatePopup(corporateGpDetails: any, type: any) {

      var me = this;
      let plan = {
        growthPlanId: corporateGpDetails.growthPlanId
      }
        this.velocityService.getGrowthPlan(corporateGpDetails.growthPlanId, ProgressBarHelper.PageTransition).subscribe((result: any) => {
            if (result.isFinalized == false) {

                console.log(corporateGpDetails.delegatedOwner);
                // If LoggedIn user is a Delegated owner redirect to Seven Drivers 
                if (result.isDelegateOwner == true) {
                    let param = { growthPlanId: corporateGpDetails.growthPlanId }
                    let gPlan = { growthPlanId: corporateGpDetails.growthPlanId, growthPlanName: corporateGpDetails.growthPlanName }
                    this.velocityService.setGrowthPlanId(param).subscribe((result) => {
                        if (result) {
                            console.log('inside success subscribe: ');
                            me.setGrowthPlanSessionStorageData(gPlan);
                            if (me.router.url == "/focus/ey.aimaturitymodel") {
                                me.refreshCurrentRoute();
                            }
                            else {
                                this.router.navigate(['focus', 'ey.aimaturitymodel']);
                            }
                        }
                        else {
                            // If GP is setting from DB is false redirect to 404 page 
                            this.router.navigate(['general', 'error404']);
                        }
                    });
                }
                else if (result.isParticipant == true) {
                
                  this.dialogService.corporateOwner(null, null,plan,0).subscribe(
                        (data) => {
                            //if (data == 'yes') {
                            //    this.storageHelper.ClearSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN);
                            //    if (this.router.url == "/focus/seven-drivers") {
                            //        this.dataShareService.changeGP.next(true);
                            //        //this.refreshCurrentRoute();
                            //    }
                            //    else {
                            //        this.router.navigate(['focus', 'seven-drivers']);
                            //    }
                            //}
                            //else if (data == 'error') {
                            //    this.router.navigate(['general', 'error-404']);
                            //}
                        });

                } else if (result.isOwner == true && result.growthPlanTypeId == 5) {
                  this.dialogService.corporateOwner(null, null,plan,0).subscribe(
                        (data) => {
                           // if (data == 'yes') {
                            //    this.storageHelper.ClearSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN);
                            //    if (this.router.url == "/focus/seven-drivers") {
                            //        this.dataShareService.changeGP.next(true);
                            //        //this.refreshCurrentRoute();
                            //    }
                            //    else {
                            //        this.router.navigate(['focus', 'seven-drivers']);
                            //    }
                            //}
                            //else if (data == 'error') {
                            //    this.router.navigate(['general', 'error-404']);
                            //}
                        });
                }
            } else {
                me.router.navigate(['general', 'error-404']);
            }

        });

    }

    redirectToCorporatePage(corporateGpDetails: any, type: any) {

        var me = this;
        this.velocityService.getGrowthPlan(corporateGpDetails.growthPlanId, ProgressBarHelper.PageTransition).subscribe((result: any) => {
            if (result.isFinalized == false) {
                // If LoggedIn user is a Delegated owner redirect to Seven Drivers 
                if (result.isDelegateOwner) {
                    let param = { growthPlanId: corporateGpDetails.growthPlanId }
                    let gPlan = { growthPlanId: corporateGpDetails.growthPlanId, growthPlanName: corporateGpDetails.growthPlanName }
                    this.velocityService.setGrowthPlanId(param).subscribe((result) => {
                        if (result) {
                            me.setGrowthPlanSessionStorageData(gPlan);
                            if (me.router.url == "/focus/ey.aimaturitymodel") {
                                me.refreshCurrentRoute();
                            }
                            else {
                                me.router.navigate(['focus', 'ey.aimaturitymodel']);
                            }
                        }
                        else {
                            // If GP is setting from DB is false redirect to 404 page 
                            me.router.navigate(['general', 'error404']);
                        }
                    });
                }
                else {
                    // If delegated owner is removed from GP then page will redirect to 404 page
                    me.router.navigate(['general', 'error404']);

                }
            } else {
                // If delegated owner is removed from GP then page will redirect to 404 page
                me.router.navigate(['general', 'error404']);

            }
        });
    }

    private setGrowthPlanSessionStorageData(data) {
        const sessionStorageData = {
            growthPlanId: data.growthPlanId,
            growthPlanName: data.growthPlanName,
            isLocked: false,
            isShared: false
        };
        this.storageHelper.SetSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
        this.storageHelper.SetLocalGrowthPlan(sessionStorageData);
    }
    refreshCurrentRoute(): void {
        this.storageHelper.SetLocalValue("showFlash", true);
        window.location.reload();
    }

    moderatorComment(threadId: any, postId: any) {
        //console.log('moderator', threadId, postId);
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("notificationListProgress");
        this.velocityService.commentNotification(threadId, postId, updateProgressBar).subscribe(
            (data: any) => {
                var comment = data.comments;
                //console.log('comment: ', data);
                this.dialogService.info(TemplateTypes.COMMENT_NOTIFICATION, comment);
            }
        );
    }

    logEvent(actionName: string, notificationId: number) {
        let log = new LogAction();
        log.action = actionName;
        log.notificationId = notificationId;
        this.logService.logAction(log);
    }
}
