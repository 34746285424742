import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../auth/service/auth.service';
import { AlertService } from '../../service/alert.service';

@Component({
    selector: 'login-dialog',
  templateUrl: 'login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})

export class LoginDialogComponent {
    showLogin: boolean = false;

    model: any = {};
    loading = false;
    error = ''; 
    
    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
        show: true
    };

    @ViewChild('loginDialog') loginDialog: any;
    constructor(private authenticationService: AuthenticationService, private alertService:AlertService) { }

    ngOnInit() {
        this.alertService.getLoginStatus().subscribe((x: boolean) => {
            this.showLogin = x;
        });
    }

    onModalClosed(ev: any) {
        this.showLogin = false;
    }

    login() {
        this.loading = true;
        
        this.authenticationService.loginByEmail(this.model.user)
            .subscribe((result: boolean) => {
                if (result === true) {
                    this.error = "";
                    this.loginDialog.hide();
                } else {
                    this.error = "Could not find a user with e-mail id " + this.model.user;
                    this.loading = false;
                }
            });
    }
}
