import { ElementRef, EventEmitter, OnInit, OnChanges, NgModule, Component, Input, forwardRef, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import noUiSlider  from "./nouislider.custom";
export interface NouiFormatter {
  to(value: number): string;
  from(value: string): number;
}
export class DefaultFormatter implements NouiFormatter {
  to(value: number) {
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  };
  from(value: string) {
    return parseFloat(value);
  };
}

@Component({
  selector: 'nouislider',
  host: {
    '[class.ng2-nouislider]': 'true'
  },
  template: '<div [attr.disabled]="disabled ? true : undefined"></div>',
  styles: [`
    :host {
      display: block;
      margin-top: 1rem;
      margin-bottom: 0px;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NouisliderComponent),
      multi: true
    }
  ]
})
export class NouisliderComponent implements ControlValueAccessor, OnInit, OnChanges {
  private el;
  slider: any;
  handles: any[];
  disabled: boolean;
  behaviour: string;
  connect: boolean[];
  limit: number;
  min: number;
  max: number;
  step: number;
  format: NouiFormatter;
  pageSteps: number;
  @Input('ngModel') ngModel: number | number[];
  keyboard: boolean;
  onKeydown: any;
  formControl: FormControl;
  tooltips: Array<any>;
  @Output("change") change: any = new EventEmitter<any>(true);
  @Output("update") update: any = new EventEmitter<any>(true);
  @Output("slide") slide: any = new EventEmitter<any>(true);
  @Output("set") set: any = new EventEmitter<any>(true);
  @Output("start") start: any = new EventEmitter<any>(true);
  @Output("end") end: any = new EventEmitter<any>(true);;
  private value;
  private onChange;
  private onTouched;
  constructor(el: ElementRef) {
    var _this = this;
    this.el = el;
    this.config = {};
    this.average = [];
    this.shadow = [];
    this.texts = [];
    this.ranges = [];
    this.connectSize = [];

    this.onChange = Function.prototype;
    this.onTouched = Function.prototype;
    this.eventHandler = function (emitter, values, handle, unencoded) {
      var v = _this.toValues(values);
      var emitEvents = false;
      if (_this.value === undefined) {
        _this.value = v;
        return;
      }
      if (Array.isArray(v) && _this.value[handle] != v[handle]) {
        emitEvents = true;
      }
      if (!Array.isArray(v) && _this.value != v) {
        emitEvents = true;
      }
      if (emitEvents) {
        emitter.emit(v);
        _this.onChange(v);
      }
      if (Array.isArray(v)) {
        _this.value[handle] = v[handle];
      }
      else {
        _this.value = v;
      }
    }
  }
    ngOnInit() {
      var _this = this;
        var inputsConfig = JSON.parse(JSON.stringify({
            behaviour: this.behaviour,
            connect: this.connect,
            limit: this.limit,
            start: this.formControl !== undefined ? this.formControl.value : this.ngModel,
            step: this.step,
            pageSteps: this.pageSteps,
            keyboard: this.keyboard,
            onKeydown: this.onKeydown,
            range: this.config.range || { min: this.min, max: this.max },
            average: this.average,
            shadow: this.shadow,
            texts: this.texts,
            ranges:this.ranges,
            connectSize: this.connectSize,
            tooltips: this.tooltips,
        }));
      inputsConfig.format = this.format || this.config.format || new DefaultFormatter();
        this.slider = noUiSlider.create(this.el.nativeElement.querySelector('div'), Object.assign(this.config, inputsConfig));
        this.handles = [].slice.call(this.el.nativeElement.querySelectorAll('.noUi-handle'));
        if (this.config.keyboard) {
            if (this.config.pageSteps === undefined) {
                this.config.pageSteps = 10;
            }
            var _loop_1 = function (handle) {
                handle.setAttribute('tabindex', 0);
                handle.addEventListener('click', function () {
                    handle.focus();
                });
                if (this_1.config.onKeydown === undefined) {
                    handle.addEventListener('keydown', this_1.defaultKeyHandler);
                }
                else {
                    handle.addEventListener('keydown', this_1.config.onKeydown);
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.handles; _i < _a.length; _i++) {
                var handle = _a[_i];
                _loop_1(handle);
            }
        }
        this.slider.on('set', function (values, handle, unencoded) {
            _this.eventHandler(_this.set, values, handle, unencoded);
        });
        this.slider.on('update', function (values, handle, unencoded) {
            _this.update.emit(_this.toValues(values));
        });
        this.slider.on('change', function (values, handle, unencoded) {
            _this.change.emit(_this.toValues(values));
        });
        this.slider.on('slide', function (values, handle, unencoded) {
            _this.eventHandler(_this.slide, values, handle, unencoded);
        });
        this.slider.on('start', function (values, handle, unencoded) {
            _this.start.emit(_this.toValues(values));
        });
        this.slider.on('end', function (values, handle, unencoded) {
            _this.end.emit(_this.toValues(values));
        });
    }
  ngOnChanges(changes: any) {
    var _this = this;
    if (this.slider && changes.ranges) {
      setTimeout(function () {
        _this.slider.updateRanges(_this.el.nativeElement, changes.ranges.currentValue);
      });
    }
    if (this.slider && changes.average) {
      setTimeout(function () {
        _this.slider.averageSet(changes.average.currentValue);
      });
    }
    if (this.slider && (changes.min || changes.max || changes.step)) {
      setTimeout(function () {
        _this.slider.updateOptions({
          range: {
            min: _this.min,
            max: _this.max
          },
          step: _this.step
        });
      });
    }
}
  toValues(values: string[]) {
    var v = values.map(this.config.format.from);
    return (v.length == 1 ? v[0] : v);
}
  writeValue(value: any) {
    if (this.slider) {
      this.slider.set(value);
    }
  }
  registerOnChange =  (value: any) => {
    this.onChange = value;
};
  registerOnTouched = (value: any) => {
    this.onTouched = value;
};
  private eventHandler;
  private defaultKeyHandler;
  @Input('config') config: any;
  @Input('average') average: number[];
  @Input('shadow') shadow: number[];
  @Input('texts') texts: string[];
  @Input('ranges') ranges: number[];
  @Input('connectSize') connectSize: string[];
}

@NgModule({
  imports: [],
  exports: [NouisliderComponent],
  declarations: [NouisliderComponent],
})
export class NouisliderModule {
}
