import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VelocityService } from '../../service/velocity.service';
import { GrowthService } from '../../service/growth.service';
import { SaveActionTask } from '../../service/model/save-action-task';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { IMyDpOptions } from 'mydatepicker';

import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { BaseComponent } from './../../component/base.component';

import { ProgressBarHelper, ProgressBarOptions } from '../../progress-bar-module/progress-bar-options';
import { retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.scss'],
})

export class ActionDetailComponent extends BaseComponent implements OnInit  {
    actionDetails: any;
    myGrowthPlanContent: any;
    sourceDetails: any;
    customTitle: any;
    saveTitle: any;
    saveNote: any;
    appContent: any;
    actionCardAppcontent : any;
    siteContents: any;
    dateModal: any; 
    alertMsgBody = "";
    alertMsgYes = "";
    alertMsgNo = "";
    considerations: any[] = [];
    showAddBlock = false;
    showTools = false;
    showTitle = true;
    showNote = true;
    editOverall = false;
    overallCompletionDate: any = '';
    overallStartDate: any = '';
    overallCost: any;
    savedOverallCompletionDate: any = '';
    savedOverallStartDate: any = '';
    savedOverallCost: any;
    showOrginal = false;
    assignedUser: string;
    savedAssignedUser: string;

    showErrorMsgOverall = false;
    warningMsgOverallStart = '';
    warningMsgOverallCompleted = '';
    formattedWarningMsgOverallStart = '';
    formattedWarningMsgOverallCompleted = '';
    showWarningMsgOverallStart = false;
    showWarningMsgOverallEnd = false;

    ownerShipId = 0;
    showErrorMsgTask = false;
    showWarningMsgTask = false;
  @ViewChild('actionDetailModal', { static: true }) actionDetailModal: ModalDirective;
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef;
  @ViewChild("titleTextArea", { static: true }) titleTextArea: ElementRef;
  @ViewChild("noteTextArea", { static: false }) noteTextArea: ElementRef;

    @Output("closeEvent") componentEvent: any = new EventEmitter();
    showWarningMsgCostTask = false;
    isGrowthPlanLocked: boolean;
    currencySymbol: string;
    statusSettings: any = [];

    public configSet = [
        {
            className: 'edit-btns',
            newFont: '12px',
            oldFont: '20px'
        },
        {
            className: 'text',
            newFont: '12px',
            oldFont: '20px'
        }
    ];

    hasApply:boolean = false;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
     dateFormat: 'dd mmm yyyy',
     editableDateField: false,
     showSelectorArrow: false,
     openSelectorTopOfInput: true,
     openSelectorOnInputClick: true,
     showTodayBtn: false,
     showClearDateBtn: true
   //  disableUntil: this.getYesterday()
    };

 getYesterday() {
     let today = new Date();
     let yesterday = new Date(today);
     yesterday.setDate(today.getDate() - 1);
     return {
         year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate()
        }

 }
    completionData: any = "";
    startDate: any = '';
    
    datepickeroptions = {
        displayFormat: "DD MMM YYYY"
};
    
    inputTask = {
        'taskDescription': '', 'assignedTo': '', 'plannedCompletionDate': '','startDate':'', 'estCost': '','taskId':0
    };
    ragValue = {
        'status': '', 'initialCost': 0.0,'growthActionId':0
     };

    actionTitle = "";
    actionNote = '';
    actionDescription = "";
    actionImage = "";
    estimatedCost = 0;    
    tasks: any; 
    tools: any;
    showEditBtn = false;
    confirmAlert: Observer<any>;
    showConfirmAlert = false;
    observable: Observable<any>;
    subject: Subject<any>;
    returnedValue: any = 'closed';
    showActions = false;
    minStartDate = '';
    maxStartDate = '';
    maxCompletionDate = '';
    mincompletionDate = '';
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };
    editedCost = 0;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private velocityService: VelocityService,
        private growthService: GrowthService,
        private injector: Injector,
        private logService: LogService,
        private logTypes: LogTypes,
        private datePipe: DatePipe
    ) {
        super(injector);

        this.actionDetails = this.injector.get('actionDetails');
        this.myGrowthPlanContent = this.injector.get('myGrowthPlanContent');
        this.appContent = this.injector.get('appContent');
        this.siteContents = this.injector.get('siteContents');
        this.sourceDetails = this.injector.get('sourceDetails');
        console.log(this.sourceDetails);
        this.isGrowthPlanLocked = this.injector.get('isGrowthPlanLocked');
    }

    ngOnInit() {
      //  this.getActionCardDetails();
        this.getTaskDetails();
        if (this.actionDetails.actionCardId)
            this.logCardAcceseedEvent(this.actionDetails.actionCardId);
        this.getOverAllDetails();
        this.warningMsgOverallStart = this.appContent.msgStartDateValidation;
        this.warningMsgOverallCompleted = this.appContent.msgEndDateValidation;
        this.formattedWarningMsgOverallStart = this.warningMsgOverallStart;
        this.formattedWarningMsgOverallCompleted = this.warningMsgOverallCompleted;
        this.actionCardAppcontent = { buttonCancel: this.myGrowthPlanContent.buttonCancelTaskActGrowthPlan, buttonDonePopup: this.siteContents.buttonDonePopup, labelSelectActionStatus: this.myGrowthPlanContent.lblActionStatusActGrowthPlan, labelCost: this.myGrowthPlanContent.labelEstimatedActionCost, labelOwner: this.myGrowthPlanContent.lblActionOwner, labelStartDate: this.myGrowthPlanContent.lblActionStartDate, labelCompletionDate: this.myGrowthPlanContent.lblActionCompletionDate, lblSettingDescription: this.myGrowthPlanContent.lblSettingDescription, lblSetting: this.myGrowthPlanContent.lblSetting }
    }

    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.actionDetailModal.config = this.config;
        this.actionDetailModal.show();
        return this.observable;
    }

    public closeDialog() {
        //if (this.isInput()) {
        //    this.showConfirmAlert = true;
        //    this.openConfirmBox(true).subscribe(
        //        (data: any) => {
        //            if (data) {
        //                this.actionDetailModal.hide();
        //            } else {

        //            }
        //            this.showConfirmAlert = false;

        //        });
        //} else {
            this.actionDetailModal.hide();
       // }
    }

    //getActionCardDetails() {
    //    var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("actiondetails");
    //    if (this.actionDetails.cardType != 'GN') {
    //    //    let cardId = { "cardIds": [this.actionDetails.actionCardId] };   
    //    //    this.growthService.getGrowthPlanCardDetails(cardId, updateProgressBar).subscribe(
    //    //        (data: any) => {
    //    //            if (data.growthPlanActions.length > 0) {
    //    //                this.actionTitle = data.growthPlanActions[0].bodyFormatted;
    //    //                this.actionDescription = data.growthPlanActions[0].description;
    //    //                this.actionImage = "";
    //    //                if (data.growthPlanActions[0].imageUrl) {
    //    //                    this.actionImage = data.growthPlanActions[0].imageUrl
    //    //                }
    //    //            }
    //    //        });
    //    //} else {
    //        this.velocityService.getCustomActionDetails(this.actionDetails.customActionId, updateProgressBar).subscribe(
    //            (data: any) => {
    //                this.actionTitle = data.title;
    //                this.actionDescription = data.actionDescription;
    //                this.actionImage = "";
    //                if (data.imageUrl) {
    //                    this.actionImage = data.imageUrl
    //                }
    //            });
    //    }
    //}
    getTaskDetails() {
        if(this.actionDetails.actionId){
            var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("actiondetails");
            this.estimatedCost = 0;
            this.velocityService.getActionTask(this.actionDetails.actionId, updateProgressBar).subscribe(
                (data: any) => {
                    var me = this;
                    setTimeout(function () {
                        me.showActions = true;
                    });

                    if (data.cardDetails) {
                        if (this.actionDetails.cardType == 'GN') {
                            this.actionTitle = data.cardDetails.bodyFormatted;
                        } else {
                            this.actionTitle = data.cardDetails.title;
                        }
                        this.customTitle = data.cardDetails.customTitle;
                        this.saveTitle = this.customTitle ? this.customTitle : this.actionTitle
                        this.actionDescription = data.cardDetails.description;
                        this.actionImage = "";
                        if (data.cardDetails.imageUrl) {
                            this.actionImage = data.cardDetails.imageUrl
                        }
                        this.actionNote = data.cardDetails.note;
                        this.saveNote = data.cardDetails.note;
                    }
                   
                    this.tasks = data.tasks;
                    this.tools = data.tools;
                   
                    for (let i in this.tasks) {
                        this.estimatedCost += this.tasks[i].estCost;
                        this.tasks[i].edited = false;
                        if (this.tasks[i].startDate)
                            this.tasks[i].startDate = new Date(this.tasks[i].startDate);

                        if (this.tasks[i].plannedCompletionDate)
                            this.tasks[i].plannedCompletionDate = new Date(this.tasks[i].plannedCompletionDate); 
                    }
                    var startDateArray = this.tasks.filter(function (obj) {
                        return obj.startDate;
                    }).map(function (obj) { return obj.startDate; });
                    this.minStartDate = this.min_date(startDateArray);
                    this.maxStartDate = this.max_date(startDateArray);
                    var completionDateArray = this.tasks.filter(function (obj) {
                        return obj.plannedCompletionDate;
                    }).map(function (obj) { return obj.plannedCompletionDate; });

                    this.maxCompletionDate = this.max_date(completionDateArray);
                    this.mincompletionDate = this.min_date(completionDateArray)
                    this.ragValue = {
                        'status': data.rag, 'initialCost': data.estCost, 'growthActionId': this.actionDetails.actionId
                    };
                    if(data && data.considerations && (data.considerations.length > 0)){
                        this.considerations = data.considerations;
                    }
            });
        }
    }

    onToolSubscribe(tool) {
        console.log(tool);
        this.router.navigate(['act', 'tool-details', tool.id, 1, 0, 0]);
    }

    addTask(isEdit: boolean) {
                
        var date = undefined;
        var startDate = undefined;

        if (this.completionData) {
            if (this.completionData.date) {
                date = this.completionData.date.year + '/' + this.completionData.date.month + '/' + this.completionData.date.day;
            }
        }
        if (this.startDate) {
            if (this.startDate.date) {
                startDate = this.startDate.date.year + '/' + this.startDate.date.month + '/' + this.startDate.date.day;
            }
        }
        var action = "";
        var taskId = undefined;
        if (isEdit) {
            action = 'U';
            taskId = this.inputTask.taskId;
        } else {
            action = 'A';
        }
        let saveActionTask = new SaveActionTask(0, action, this.actionDetails.actionId, this.inputTask.taskDescription, this.inputTask.assignedTo, parseInt(this.inputTask.estCost), date, startDate,taskId);
        this.velocityService.saveActionTask(saveActionTask).subscribe(
            (data: any) => {
                if (data) {
                    this.getTaskDetails();
                    this.clear();
                    this.showEditBtn = false;
                    if (isEdit) {
                        this.modalBody.nativeElement.scrollTop = 0;

                    }
                }
            });

    }
    editTask(taskId: any) {
        this.showAddBlock = true;
        if (this.isInput()) {
        //    this.showConfirmAlert = true;
            //this.openConfirmBox(true).subscribe(
            //    (data: any) => {
            //        if (data) {
                        this.cancel();
                        this.showEditBtn = true;
                        this.inputTask = Object.assign({}, this.tasks.filter((x: any) => x.taskId == taskId)[0]);
                        if (this.inputTask.plannedCompletionDate) {
                            this.completionData = this.getDate(this.inputTask.plannedCompletionDate); 
                        }
                        if (this.inputTask.startDate) {
                            this.startDate = this.getDate(this.inputTask.startDate);
                        }
                        this.tasks.filter((x: any) => x.taskId == taskId)[0].edited = true;
                    //} else {

                    //}
                    //this.showConfirmAlert = false;

               // });
        } else {
            this.showEditBtn = true;
            this.inputTask = Object.assign({}, this.tasks.filter((x: any) => x.taskId == taskId)[0]);
            if (this.inputTask.plannedCompletionDate) {
                this.completionData = this.getDate(this.inputTask.plannedCompletionDate);
            }
            if (this.inputTask.startDate) {
                this.startDate = this.getDate(this.inputTask.startDate);
            }
            this.tasks.filter((x: any) => x.taskId == taskId)[0].edited = true;

        }
        
        this.editedCost = this.tasks.filter((x: any) => x.taskId == taskId)[0].estCost;
    }
    clear() {
        this.inputTask.taskDescription = "";
        this.inputTask.assignedTo = "";
        this.inputTask.estCost = '';
        this.inputTask.plannedCompletionDate = "";
        this.completionData = "";
        this.startDate = "";

        this.inputTask.taskId = 0;
        this.showWarningMsgCostTask = false;
        this.showWarningMsgTask = false;
        this.showErrorMsgTask = false;
    }
    deleteTask(taskId: any) {
        this.showEditBtn = false;
        this.showConfirmAlert = true;
        
        this.openConfirmBox(false).subscribe(
            (data: any) => {
                if (this.inputTask.taskId == taskId) {
                    this.clear();
                }
                if (data) {
                    this.estimatedCost -= this.tasks.filter((x: any) => x.taskId == taskId)[0].estCost;

                    this.tasks = this.tasks.filter((x: any) => x.taskId != taskId);
                    let saveActionTask = new SaveActionTask(0, 'D', this.actionDetails.actionId, undefined, undefined, undefined, undefined,undefined,taskId);
                    this.velocityService.saveActionTask(saveActionTask).subscribe(
                        (data: any) => {
                            if (data) {
                            }
                        });
                }
                this.showConfirmAlert = false;

            });
    }
    cancel() {
        this.showEditBtn = false;
        if (this.tasks.filter((x: any) => x.taskId == this.inputTask.taskId)[0])
        this.tasks.filter((x: any) => x.taskId == this.inputTask.taskId)[0].edited = false;
        this.clear();
       
    }
    confirmYes() {
        this.confirmAlert.next(true);
        this.confirmAlert.complete();
    }
    confirmNo() {
        this.confirmAlert.next(false);
        this.confirmAlert.complete();

    }
    openConfirmBox(isUnsaveMsg: boolean): Observable<boolean>  {
        this.modalBody.nativeElement.scrollTop = 0;
        if (isUnsaveMsg) {

            this.alertMsgBody = this.appContent.textUnsavedMessage;
            this.alertMsgNo = this.siteContents.buttonNo;
            this.alertMsgYes = this.siteContents.buttonYes;
        } else {
            this.alertMsgBody = this.appContent.textDeleteMessage ;
            this.alertMsgNo = this.siteContents.buttonBack;
            this.alertMsgYes = this.siteContents.buttonDelete;
        }
        return new Observable((observer: Observer<boolean>) => {
            this.confirmAlert = observer;
            });
    }

    chkEmpty() {
        if (this.inputTask.plannedCompletionDate == "")
            this.inputTask.plannedCompletionDate = new Date().toString();
    }
    getDate(date: string) {
        let formattedDate:any;
        if (date) {
            var selDate = new Date(date);
            formattedDate = { date: { year: selDate.getFullYear(), month: selDate.getMonth()+1, day: selDate.getDate() } };
            return formattedDate; 
        }
        return "";
    }
    isInput() {

        if (this.inputTask.taskDescription != "" || this.inputTask.assignedTo != "" || this.inputTask.estCost || this.completionData != "", this.startDate != '') {
            return true;
        }
        else{
            return false;
        }
    }

    changeRag(ragStatus: string) {
        if (this.subject) {
            this.subject.next({'rag': ragStatus });
        }

    }

    openRag(returnValue: any) {
        if (this.subject) {
            this.subject.next(returnValue);
        }
        if (returnValue) {
            this.savedOverallCost = returnValue.cost;
            this.savedOverallStartDate = returnValue.startDate;
            this.savedOverallCompletionDate = returnValue.completionDate;
        }

    }

    onPinChange(event: any) {
        if (this.subject) {
            this.subject.next(event);
        }
    }

    logCardAcceseedEvent(cardId: number) {
        let log = new LogAction();
        log.action = this.logTypes.CARD_ACCESSED;
        log.cardId= cardId;
        this.logService.logAction(log);
    }

    viewTools() {
        this.showTools = true;
    }
    back() {
        this.showTools = false;
    }
    autogrow() {
        var textArea = this.titleTextArea.nativeElement;
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }
    autogrowNote() {
        var textArea = this.noteTextArea.nativeElement;
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }
    editTitle() {
        this.showTitle = !this.showTitle;
        this.saveTitle = this.customTitle ? this.customTitle : this.actionTitle;
        var me = this;
        setTimeout(function () {
            me.autogrow();
            me.hasApply = true;
        });

    }
    editNote() {
        this.showNote = !this.showNote;;
        this.saveNote = this.actionNote;
        setTimeout( () => {
                this.autogrowNote();
        });
    }
    showOrginalTitle(orginal) {
        if (orginal) {
            this.showOrginal = true;
         //   this.saveTitle = this.actionTitle;
        } else {
            this.showOrginal = false;
          //  this.saveTitle = this.customTitle;

        }
    }
    saveEditedTitle() {
        let params = {
            'growthPlanActionId': this.actionDetails.actionId,
            'customTitle': this.saveTitle
        }
        this.velocityService.saveActionTitle(params).subscribe(
            (data: any) => {
                this.showTitle = true;
                this.showOrginal = false;
                this.customTitle = this.saveTitle;
                if (this.subject) {
                    this.subject.next({ 'title': this.saveTitle });
                }
                this.hasApply = false;
            });
    }
    saveEditedNote() {
        let params = {
            'growthPlanActionId': this.actionDetails.actionId,
            'actionNote': this.saveNote,
            'mode':1
        }
        this.velocityService.saveActionNote(params).subscribe(
            (data: any) => {
                this.showNote = true;
                this.actionNote = this.saveNote;
              
            });
    }
    getOverAllDetails() {
      
        this.velocityService.getActionOwnership(this.actionDetails.actionId).subscribe(
            (data: any) => {
                console.log(data);
                if (data) {
                    this.ownerShipId = data.growthPlanActionOwnerId;
                    this.assignedUser = data.ownerName;
                    this.savedAssignedUser = data.ownerName;
                    this.savedOverallCompletionDate = this.getDate(data.plannedCompletionDate);
                    this.overallCompletionDate = this.getDate(data.plannedCompletionDate);
                    this.savedOverallCost = data.cost ? data.cost:null;
                    this.overallCost = data.cost ? data.cost : null;
                    this.overallStartDate = this.getDate(data.startDate);
                    this.savedOverallStartDate = this.getDate(data.startDate);
                    this.currencySymbol = data.currencySymbol;
                    this.statusSettings = data.actionStatusSettings;
                    if (this.currencySymbol == undefined || this.currencySymbol == '') {
                        this.currencySymbol = "$";
                    }
                }

            });
    }
    getFormattedDate(selDate) {
        var formattedDate = '';
        if (selDate) {
            formattedDate = selDate.date.year + '/' + selDate.date.month + '/' + selDate.date.day;

        }
        return formattedDate;
    }
    saveOverAllDetails() {
        if (this.ownerShipId || this.savedOverallCost || this.assignedUser || this.overallCompletionDate || this.overallStartDate || this.overallCost) {
            var date = undefined;
            var startDate = undefined;
            if (this.overallCompletionDate) {
                if (this.overallCompletionDate.date) {
                    date = this.overallCompletionDate.date.year + '/' + this.overallCompletionDate.date.month + '/' + this.overallCompletionDate.date.day;
                }
            }
            if (this.overallStartDate) {
                if (this.overallStartDate.date) {
                    startDate = this.overallStartDate.date.year + '/' + this.overallStartDate.date.month + '/' + this.overallStartDate.date.day;
                }
            }
            let params = {
                growthPlanActionOwnershipId: this.ownerShipId,
                growthPlanActionId: this.actionDetails.actionId,
                ownerName: this.assignedUser,
                plannedCompletionDate: date,
                startDate: startDate,
                cost: this.overallCost
            }
            this.velocityService.saveActionOwnership(params).subscribe(
                (data: any) => {
                    this.editOverall = false;
                    this.savedAssignedUser = this.assignedUser;
                    this.savedOverallCompletionDate = this.overallCompletionDate;
                    this.savedOverallCost = this.overallCost;
                    this.showErrorMsgOverall = false;
                    this.showWarningMsgOverallStart = false;
                    this.showWarningMsgOverallEnd = false;
                    this.savedOverallStartDate = this.overallStartDate;
                    if (this.subject) {
                        this.subject.next({ 'cost': this.savedOverallCost });
                    }
                });
        }
    }
    cancelOverAllDetails() {
        //  this.overallCompletionDate = this.getDate(this.inputTask.plannedCompletionDate);
        this.assignedUser = this.savedAssignedUser;
        this.overallCompletionDate = this.savedOverallCompletionDate;
        this.overallCost = this.savedOverallCost;
        this.overallStartDate = this.savedOverallStartDate;
        this.editOverall = false;
        this.showErrorMsgOverall = false;
        this.showWarningMsgOverallStart = false;
        this.showWarningMsgOverallEnd = false;
    }

    numberOnly(event) {
        var key = window.event ? event.keyCode : event.which;
        if (event.keyCode == 8 || event.keyCode == 3 || event.charCode == 0) {
            return true;
        }
        else if (key < 48 || key > 57) {
            return false;
        }
        else if ((key >= 48 || key <= 57) && parseInt(this.inputTask.estCost) > 999999999) {
            return false;
        }
        else return true;
    }
    onDateChanged(selectedDate: any, type: string, compareDate:any,from:string) {
        var completionDate;
        var startDate;
      //  if (selectedDate.jsdate) {
            if (type == 'start') {
                 completionDate = new Date(this.getFormattedDate(compareDate));
                 startDate = new Date(this.getFormattedDate(selectedDate));
            }
            else if (type == 'completed') {       
                  completionDate = new Date(this.getFormattedDate(selectedDate));
                  startDate = new Date(this.getFormattedDate(compareDate));                
            }
                if (compareDate && startDate > completionDate) {
                    if (from == 'action')
                        this.showErrorMsgOverall = true;
                    else
                        this.showErrorMsgTask = true;
                return;
                } else {
                    if (from == 'action')
                        this.showErrorMsgOverall = false;
                    else
                        this.showErrorMsgTask = false;
            }
            if (from == 'action') {

                if (startDate) {
                    if (this.minStartDate && (startDate > this.minStartDate)) {
                        this.formattedWarningMsgOverallStart = this.warningMsgOverallStart.replace('#Date', this.datePipe.transform(this.minStartDate, 'dd-MMMM-yyyy'));

                        this.showWarningMsgOverallStart = true;
                    } else if (this.mincompletionDate && (startDate > this.mincompletionDate)) {
                        this.formattedWarningMsgOverallStart = this.warningMsgOverallStart.replace('#Date', this.datePipe.transform(this.mincompletionDate, 'dd-MMMM-yyyy'));

                        this.showWarningMsgOverallStart = true;
                    }else {
                        this.showWarningMsgOverallStart = false;

                    }
                } else {
                    this.showWarningMsgOverallStart = false;

                }
                if (completionDate) {
                    if (this.maxCompletionDate && (completionDate < this.maxCompletionDate)) {
                        this.formattedWarningMsgOverallCompleted = this.warningMsgOverallCompleted.replace('#Date', this.datePipe.transform(this.maxCompletionDate, 'dd-MMMM-yyyy'));

                        this.showWarningMsgOverallEnd = true;
                    } else if (this.maxStartDate && (completionDate < this.maxStartDate)) {
                        this.formattedWarningMsgOverallCompleted = this.warningMsgOverallCompleted.replace('#Date', this.datePipe.transform(this.maxStartDate, 'dd-MMMM-yyyy'));

                        this.showWarningMsgOverallEnd = true;
                    }else {
                        this.showWarningMsgOverallEnd = false;
                    }
                } else {
                    this.showWarningMsgOverallEnd = false;
                }
            } else {
                var comDat = this.savedOverallCompletionDate ? new Date(this.getFormattedDate(this.savedOverallCompletionDate)) : '';
                var staDat = this.savedOverallStartDate ? new Date(this.getFormattedDate(this.savedOverallStartDate)):'';

                if (startDate && staDat && ((startDate < staDat))) {
                    this.showWarningMsgTask = true;
                }
                else if (startDate && comDat && (startDate > comDat)) {
                    this.showWarningMsgTask = true;
                }
                else if (completionDate && staDat && (completionDate < staDat)) {
                    this.showWarningMsgTask = true;
                }
                else if (completionDate && comDat && (completionDate > comDat)) {
                    this.showWarningMsgTask = true;
                } else {
                     this.showWarningMsgTask = false;
                }
            }           
      //  }
    }
    min_date(all_dates) {
        var min_dt = all_dates[0];
        var min_dtObj = new Date(all_dates[0]);
    all_dates.forEach(function (dt, index) {
        if (new Date(dt) < min_dtObj) {
            min_dt = dt;
            min_dtObj = new Date(dt);
        }
    });
    return min_dt;
}
    max_date(all_dates) {
        var max_dt = all_dates[0];
        var max_dtObj = new Date(all_dates[0]);
        all_dates.forEach(function (dt, index) {
        if (new Date(dt) > max_dtObj) {
            max_dt = dt;
            max_dtObj = new Date(dt);
        }
    });
    return max_dt;
    }
    validateCost(value) {
        var newEstCost = this.estimatedCost;
        if (!this.showEditBtn) {
            newEstCost += parseInt(value);
        } else {
            newEstCost = newEstCost - this.editedCost + parseInt(value);
        }

        if (newEstCost > this.savedOverallCost) {
            this.showWarningMsgCostTask = true;
        } else {
            this.showWarningMsgCostTask = false;
        }
    }
}
