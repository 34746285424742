export class SaveActionTask {
    constructor(
       public userId: number,
       public action: string,
       public growthPlanActionId: number,
       public taskDescription?: string,
       public assignedTo?: string,
       public estCost?: number,
       public plannedCompletionDate?: Date,
       public startDate?: Date,
       public taskId?: number
    ) { }
 }
