<div class="modal fade ey-dialog-modal zoom-modal" id="zoom-modal"
     bsModal #zoomModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="padding:0px;">
                <!--<h4 class="modal-title pull-left">Growth Plan zoomed</h4>-->
                <img class="whiteClose" (click)="closeBtn()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body {{parentClass}}" #mainContainer1 eyv-update-panel="mygrowthplan1">
                <div class="row growth-plan">
                    <div class="col-sm-12 mygp-pad-lr">
                        <!-- Growth Plan 0ontent-->
                        <div class="col-sm-12 assessment-content plan-content {{getbubbleClass()}}">

                            <div class="quadrant-container" #quadrantContainer1 [style.height.px]="quadrantHeight " [style.width.px]="quadrantAreaWidth + actionArea1" [ngClass]="{'corporate-container':isCorporate && !self}">
                                <!--<div class="extra-space" #extraArea></div>-->
                                <div class="quadrant-area" #quadrantArea1 [style.width.px]="quadrantAreaWidth">
                                    <div #Quadrant1 class="row quadrants" (click)="clickQuadrants('')">
                                        <div class="quadrants-highlight" *ngIf="highLightBubbles!=''">
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="row draggable-container">
                                                <i class="fa fa-layer-group"></i>
                                                <div class="col-sm-6  no-padding-column" [style.max-height.px]="quadrantWidth">
                                                    <div eyv-droppable class="draggable-box first-box" (onEYVDropSuccess)="onBubbleDropped1($event,1)"
                                                         [eyv-dropEnabled]="dropEnabled">
                                                        <div style="width:100%;height:100%;position:relative">
                                                            
                                                            <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                                <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                                    <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                        <growth-plan-bubble-test *ngIf="action.quadrant==1 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                                 [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                                 (onClick)="onselectedCard($event)" (onClickAndHold)="onClickandHoldBubble($event)"
                                                                                                 [count]="newPrioritizedActions[actions].length"
                                                                                                 [ngClass]="getClassName(action)" 
                                                                                                 [showBorder]="showBorder(action)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container>
                                                                    <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==1 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                              [action]="newPrioritizedActions[actions][0]"
                                                                                              [actions]="newPrioritizedActions[actions]"
                                                                                              (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                              [hideShow]="hideShowTooltip"
                                                                                              (onClick)="clickQuadrants($event)"
                                                                                              [selected]="countBubbleSelected[actions]"
                                                                                              [highlight]="countBubbleHighlight[actions]"
                                                                                              [view]="'zoom-view'"
                                                                                              [pageContents]="pageContents"
                                                                                              [changeFilter]="changeFilter"
                                                                                              [selectedCardParent]="selectedCard"
                                                                                              [size]="getbubbleClass()" [showBorder]="showAll"
                                                                                              [selectedDriver]="selectedFilterDriver"></growth-plan-count-bubble>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-sm-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                                    <div eyv-droppable class="draggable-box second-box" (onEYVDropSuccess)="onBubbleDropped1($event,2)" [eyv-dropEnabled]="dropEnabled">
                                                        <!--<ng-container *ngFor="let action of prioritizedActions">
                                                            <growth-plan-bubble-test *ngIf="action.quadrant==2 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                     [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                     (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                     (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                        </ng-container>-->
                                                        <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                            <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                                <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                    <growth-plan-bubble-test *ngIf="action.quadrant==2 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                             (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                             [count]="newPrioritizedActions[actions].length"
                                                                                             (onClickAndHold)="onClickandHoldBubble($event)" 
                                                                                             [showBorder]="showBorder(action)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container>
                                                                <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==2 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                          [action]="newPrioritizedActions[actions][0]"
                                                                                          [actions]="newPrioritizedActions[actions]"
                                                                                          (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                          [hideShow]="hideShowTooltip"
                                                                                          (onClick)="clickQuadrants($event)"
                                                                                          [selected]="countBubbleSelected[actions]"
                                                                                          [highlight]="countBubbleHighlight[actions]"
                                                                                          [view]="'zoom-view'"
                                                                                          [pageContents]="pageContents"
                                                                                          [changeFilter]="changeFilter"
                                                                                          [selectedCardParent]="selectedCard"
                                                                                          [size]="getbubbleClass()" [showBorder]="showAll"
                                                                                              [selectedDriver]="selectedFilterDriver"></growth-plan-count-bubble>

                                                                <!-- <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0].quadrant==2 && newPrioritizedActions[actions][0].isSelected==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length" [highLightBubbles]="newPrioritizedActions[actions][0].driverTag==highLightBubbles"
                                                                [action]="newPrioritizedActions[actions][0]" [isDragEnabled]="isDraggable(newPrioritizedActions[actions][0])" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                (onClick)="onselectedCard($event)" [ngClass]="getClassName(action[newPrioritizedActions[actions][0]])"
                                                                (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-count-bubble> -->
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row draggable-container">
                                                <div class="col-sm-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                                    <div eyv-droppable class="draggable-box third-box" (onEYVDropSuccess)="onBubbleDropped1($event,3)"
                                                         [eyv-dropEnabled]="dropEnabled">

                                                        <!--<ng-container *ngFor="let action of prioritizedActions">
                                                            <growth-plan-bubble-test *ngIf="action.quadrant==3 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                     [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                     (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                     (onClickAndHold)="onClickandHoldBubble($event) " [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                        </ng-container>-->
                                                        <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                            <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                                <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                    <growth-plan-bubble-test *ngIf="action.quadrant==3 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                             (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                             [count]="newPrioritizedActions[actions].length"
                                                                                             (onClickAndHold)="onClickandHoldBubble($event)" 
                                                                                             [showBorder]="showBorder(action)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container>
                                                                <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==3 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                          [action]="newPrioritizedActions[actions][0]"
                                                                                          [actions]="newPrioritizedActions[actions]"
                                                                                          (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                          [hideShow]="hideShowTooltip"
                                                                                          (onClick)="clickQuadrants($event)"
                                                                                          [selected]="countBubbleSelected[actions]"
                                                                                          [highlight]="countBubbleHighlight[actions]"
                                                                                          [view]="'zoom-view'"
                                                                                          [pageContents]="pageContents"
                                                                                          [changeFilter]="changeFilter"
                                                                                          [selectedCardParent]="selectedCard"
                                                                                          [size]="getbubbleClass()" [showBorder]="showAll"
                                                                                              [selectedDriver]="selectedFilterDriver"></growth-plan-count-bubble>

                                                                <!-- <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0].quadrant==2 && newPrioritizedActions[actions][0].isSelected==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length" [highLightBubbles]="newPrioritizedActions[actions][0].driverTag==highLightBubbles"
                                                                [action]="newPrioritizedActions[actions][0]" [isDragEnabled]="isDraggable(newPrioritizedActions[actions][0])" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                (onClick)="onselectedCard($event)" [ngClass]="getClassName(action[newPrioritizedActions[actions][0]])"
                                                                (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-count-bubble> -->
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 no-padding-column" id="fourZoom" [style.max-height.px]="quadrantWidth">
                                                    <div eyv-droppable class="draggable-box fourth-box" (onEYVDropSuccess)="onBubbleDropped1($event,4)"
                                                         [eyv-dropEnabled]="dropEnabled">

                                                        <!--<ng-container *ngFor="let action of prioritizedActions">
                                                            <growth-plan-bubble-test *ngIf="action.quadrant==4 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                     [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                     (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                     (onClickAndHold)="onClickandHoldBubble($event)" [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                        </ng-container>-->
                                                        <ng-container *ngFor="let actions of prioritizedActionsKeys">
                                                            <ng-container *ngIf="newPrioritizedActions[actions].length == 1">
                                                                <ng-container *ngFor="let action of newPrioritizedActions[actions]">
                                                                    <growth-plan-bubble-test *ngIf="action.quadrant==4 && action.isSelected==true && this.dropEnabled" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                             [action]="action" [isDragEnabled]="isDraggable(action)" (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                             (onClick)="onselectedCard($event)" [ngClass]="getClassName(action)"
                                                                                             [count]="newPrioritizedActions[actions].length"
                                                                                             (onClickAndHold)="onClickandHoldBubble($event)" [showBorder]="showBorder(action)"
                                                                                             [size]="getbubbleClass()"></growth-plan-bubble-test>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container>
                                                                <growth-plan-count-bubble *ngIf="newPrioritizedActions[actions][0]['quadrant']==4 && newPrioritizedActions[actions][0]['isSelected']==true && this.dropEnabled" [count]="newPrioritizedActions[actions].length"
                                                                                          [action]="newPrioritizedActions[actions][0]"
                                                                                          [actions]="newPrioritizedActions[actions]"
                                                                                          (onDragStart)="quadrantBubbleDragStart($event)"
                                                                                          [hideShow]="hideShowTooltip"
                                                                                          (onClick)="clickQuadrants($event)"
                                                                                          [selected]="countBubbleSelected[actions]"
                                                                                          [highlight]="countBubbleHighlight[actions]"
                                                                                          [view]="'zoom-view'"
                                                                                          [pageContents]="pageContents"
                                                                                          [changeFilter]="changeFilter"
                                                                                          [selectedCardParent]="selectedCard"
                                                                                          [size]="getbubbleClass()" [showBorder]="showAll"
                                                                                              [selectedDriver]="selectedFilterDriver"></growth-plan-count-bubble>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class=" impact-scale-y-axis" style="margin-left: -40px;">
                                        <p>
                                            <!--<span class="arrow-left"></span>-->
                                            <span class="left lable-text" style="max-width: 90px;">{{pageContents.lowLabel}}</span>
                                            <span class="middle lable-text"><div>{{yLabel}}</div></span>
                                            <span class="right lable-text" style="max-width: 90px;">{{pageContents.highLabel}}</span>
                                            <!--<span class="arrow-right"></span>-->
                                        </p>
                                    </div>
                                    <div class=" impact-scale-x-axis" style="margin-top: -20px;">
                                        <p>
                                            <!--<span class="arrow-left"></span>-->
                                            <span class="left lable-text" style="max-width: 90px;">{{pageContents.lowLabel}}</span>
                                            <span class="middle lable-text"><div>{{xLabel}}</div></span>
                                            <span class="right lable-text" style="max-width: 90px;">{{pageContents.highLabel}}</span>
                                            <!--<span class="arrow-right"></span>-->
                                        </p>
                                    </div>
                                    <!--<div class="thrashIcon">
                                        <div class="remove-button" eyv-droppable (onEYVDropSuccess)="onDeleteDropSuccess($event)" (click)="showRestorePopup();">
                                            <div class="trash-container">
                                                <img src="../../../assets/images/icon-recyclebin.png" />-->
                                    <!--<i class="fa fa-trash-o"></i>-->
                                    <!--</div>
                                    <div class="deleted-bubble" *ngIf="deletedCount()>0">{{deletedCount()}}</div>-->
                                    <!--<span>{{pageContents.removeActionsLabel}}</span>-->
                                    <!--</div>
                                    </div>-->
                                </div>


                                <div class="action-area" [style.width.px]="(newAreaWidth) > 300 ?newAreaWidth:300" [style.height.px]="calQuadrantHeight" #actionArea1>
                                    <div [style.height.px]="calQuadrantHeight" class="a_container">
                                        <act-filter-dropdown style="margin:15px;margin-bottom:0px;margin-top:0px;margin-right:0px"
                                                             [drivers]="drivers" [actionList]="actionsToBePrioritized"
                                                             [appContent]="pageContents"
                                                             (slectedFilter)="selectedDriver($event)"
                                                             [selectedDriver]="selectedFilterDriver"
                                                             (clickFilter)="clickFilter()"></act-filter-dropdown>
                                        <!--<div class="row action-list">
        <div class="col-sm-6" (click)="changeList(1)" [ngClass]="{ 'has-selected-list': selectedType == 1}">{{pageContents.lblHIHU}}</div>
        <div class="col-sm-6" (click)="changeList(2)" [ngClass]="{ 'has-selected-list': selectedType == 2}">{{pageContents.allActionsLabel}}</div>
    </div>-->

                                        <div class="checkbox-container" *ngIf="selectedFilterDriver != 0">
                                            <label>
                                                <input class="form-check-input" style="display: none;" type="checkbox" [checked]="showAll" name="check">
                                                <span class="cr" (click)="changeShowAll()">
                                                    <i class="cr-icon fa fa-check"></i>
                                                </span>
                                            </label>{{pageContents.lblShowAllActions}}
                                        </div>

                                        <div class="action-list-cards" id="zoom-al" [ngClass]="{'no-padding-list':selectedFilterDriver != 0}" custom-scroll [scrollPos]="listCardPos" #accordion1>
                                            <!--<accordion [closeOthers]="true">
        <accordion-group *ngFor="let driver of drivers;let i = index" #group [isDisabled]="chkDisableList(driver.id)" [isOpen]="selectedId == driver.id"
                         [ngClass]="{ 'disable-list': chkDisableList(driver.id)}" (click)="scrollAccordion($event)">-->
                                            <!--<div accordion-heading>
            {{driver.driverName}}
            <span>{{actionsToBePrioritized[driver.id]?actionsToBePrioritized[driver.id].length:0}}</span>

        </div>-->
                                            <div (click)="$event.stopPropagation();" *ngFor="let action of getFilterAction(); let i = index" id="zoom-actionCard{{action.cardNumber}}">
                                                <!--<div id="actionCard{{action.cardNumber}}" class="action-cards action-draggable" eyv-draggable [eyv-dragEnabled]="isDraggable(action)"
                 [eyv-dragData]="action" [ngClass]="{'selected-action':(selectedCard.driverTag==action.driverTag && selectedCard.cardNumber==action.cardNumber)}"
                 (onEYVDragStart)="onDragStart($event)">
                <div class="action-title">{{action.customTitle?action.customTitle:action.title}}</div>
                <div class="ac-footer">
                    <div class="action-card-bottom-left" [ngClass]="{'priorotized':!isDraggable(action)}" [style.background-color]="getBgColor(action.quadrant)">{{action.cardNumber}}</div>
                    <div class="action-card-bottom-next">{{action.estCost | currency}}</div>
                    <div class="action-card-bottom-right">

                        <div (click)="goToActionDetail(driver.id,action.cardId,action.id,action.cardType,action.customActionId)">
                            <i class="fa fa-list-ul"></i>
                        </div>
                        <div (click)="openRag(action.id,driver.id)">
                            <div class="rag-status" [ngClass]="getClassName(action.rag)">
                            </div>
                            <div>
                                <i class="fa fa-angle-down down" *ngIf="!action.open"></i>
                                <i class="fa fa-angle-up up" *ngIf="action.open"></i>

                            </div>
                        </div>
                    </div>

                    <div class="clear-fix"></div>
                </div>
            </div>-->

                                                <div class="a_card" [ngClass]="getBorderClassName(action)">
                                                    <div class="card_draggable" [ngClass]="{'closed':!action.open}" eyv-draggable [eyv-dragEnabled]="isDraggable(action)"
                                                         [eyv-dragData]="action"
                                                         [eyv-dragData-size]="getbubbleClass()" (click)="setParticipants(action,'fromFilter')"
                                                         (onEYVDragStart)="onDragStart($event)">
                                                        <div class="action-number">{{action.cardNumber}}</div>
                                                        <div class="action-title"> {{action.customTitle?action.customTitle:action.title}}</div>
                                                        <!--<div class="action_cost" ><span *ngIf="!action.open && action.estCost">${{action.estCost | number}}</span></div>-->
                                                        <div class="action_cost">
                                                            <span *ngIf="!action.open && action.estCost">
                                                                <span class="currency-block-font">{{currencySymbol}}</span>
                                                                <span class="currency-block-amount">{{action.estCost | number}}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="card_arrow" (click)="openRag(action.id)">
                                                        <i class="fa fa-angle-up up" *ngIf="action.open"></i>
                                                        <i class="fa fa-angle-down down" *ngIf="!action.open"></i>
                                                    </div>
                                                    <div *ngIf="action.open">
                                                        <action-rag [ngClass]="{'corporate':isCorporate}" [isCorporate]="isCorporate" [isGrowthPlanLocked]="isLocked" [fromCard]="'zoom'" [appContent]="actionCardAppcontent" [estimatedCost]="action.estCost" [owner]="action.ownerName" [startDate]="action.startDate" [completionDate]="action.completionDate" [ragValue]="{'status':action.rag,'growthActionId':action.id}" [currencySymbol]="currencySymbol"
                                                                    (close)="openRag(action.id,$event)"></action-rag>

                                                        <div class="actionDetail-nav">
                                                            {{pageContents.lblViewResourcesAndTask}}
                                                        </div>
                                                    </div>
                                                    <!--<div *ngIf="action.open">
                    <action-rag [fromCard]="'true'" [appContent]="actionCardAppcontent" [estimatedCost]="action.estCost" [ragValue]="{'status':action.rag,'growthActionId':action.id}"
                                (close)="openRag(action.id,driver.id,$event)"></action-rag>
                </div>-->
                                                </div>


                                            </div>
                                            <!--</accordion-group>
        </accordion>-->
                                            <!--<div class="gp-accordion-scroll-spacer"></div>-->
                                        </div>
                                    </div>
                                    <!--<div class="continue">
                                        <button class="btn button-coninue" (click)="continue()">
                                            {{SiteContents["buttonContinue"]}}
                                        </button>
                                    </div>-->
                                </div>
                                <div class="participant-area" #participantArea1 custom-scroll [scrollPos]="topPartPos" *ngIf="isCorporate && !self" [style.height.px]="calQuadrantHeight" [style.max-width.px]="partcipantWidth">
                                    <div>
                                        <div class="action-card-details">
                                            <span class="cardNumber" *ngIf="selectedCardNumber && isCorporate && !self"> {{selectedCardNumber}}</span>
                                            <span class="action-title" *ngIf="selectedTitle && isCorporate && !self"> {{selectedTitle}}</span>
                                        </div>
                                        <div class="quadrant-container" *ngIf="selectedPrioCorpActions">
                                            <div class="quadrantArea" [style.width]="partcipantQuadrantWidth">
                                                <div #PQuadrant1 class="row quadrants" [ngClass]="getbubbleClass(false,true)">
                                                    <div class="col-sm-12">
                                                        <div class="row draggable-container">
                                                            <div class="col-sm-6 no-padding-column" [style.max-height.px]="quadrantWidth">
                                                                <div class="draggable-box first-box">
                                                                    <div style="width:100%;height:100%;position:relative">
                                                                        <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                            <growth-plan-bubble-test *ngIf="action.priorityId==1" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                                     [action]="action" [isDragEnabled]="false"
                                                                                                     [size]="getbubbleClass()"
                                                                                                     [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6 no-padding-column">
                                                                <div class="draggable-box second-box">
                                                                    <div style="width:100%;height:100%;position:relative">
                                                                        <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                            <growth-plan-bubble-test *ngIf="action.priorityId==2" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                                     [action]="action" [size]="getbubbleClass()" [isDragEnabled]="false"
                                                                                                     [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row draggable-container">
                                                            <div class="col-sm-6 no-padding-column">
                                                                <div class="draggable-box third-box">
                                                                    <div style="width:100%;height:100%;position:relative">
                                                                        <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                            <growth-plan-bubble-test *ngIf="action.priorityId==3" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                                     [action]="action" [isDragEnabled]="false" [size]="getbubbleClass()"
                                                                                                     [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6 no-padding-column">
                                                                <div class="draggable-box fourth-box">
                                                                    <div style="width:100%;height:100%;position:relative">
                                                                        <ng-container *ngFor="let action of selectedPrioCorpActions">
                                                                            <growth-plan-bubble-test *ngIf="action.priorityId==4" [highLightBubbles]="action.driverTag==highLightBubbles"
                                                                                                     [action]="action" [size]="getbubbleClass()" [isDragEnabled]="false"
                                                                                                     [ngClass]="getClassName(action.rag)" [participant]="action.participantAbbrivation"></growth-plan-bubble-test>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="participant-details row">
                                            <div class="col-6 user-avatar no-padding-column" [ngClass]="{'col-12':participant.team}" (click)="selectAction(participant)" *ngFor="let participant of selectedPrioCorpActions">
                                                <div class="part-inside" [ngClass]="{'sel-part':participant.active}">
                                                    <!--<div class="col-6 user-avatar" *ngFor="let participant of participantDataDummy">-->
                                                    <div class="user-avatar-black" style="position: absolute;top: 50%;-ms-transform: translateY(-33%);transform: translateY(-33%);">{{ participant.team ? '' : participant.participantAbbrivation}}</div>
                                                    <div class="user-info">
                                                        <span class="user-info-name">{{participant.team ? OtherContents.growthPlanSession.lblTeamActionBasedOnAgreement : participant.userName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <!--<div class="modal-footer">
                <div class="action-buttons">
                    <button class="btn btn-outline" (click)="cancelClicked();" type="button"
                            [innerHTML]="'CANCEL'"></button>
                </div>
            </div>-->
        </div>
    </div>
</div>