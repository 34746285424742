import { Component, Input, ViewChild, Injector, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { VelocityService } from '../../service/velocity.service';
import { SaveCustomAction } from './../../focus/model/save-newCustomAction.model';
import { UtilService } from './../../service/util.service';

@Component({
    //moduleId: module.id.toString(),
    selector: "restore-action-dialog",
  templateUrl: './restore-action.component.html',
  styleUrls: ['./restore-action.component.scss']
})

export class RestoreActionComponent{
    
    actions: any = {};
    pageContents: any;
    isGrowthPlanLocked: boolean;
    //TODO - if edit is needed
    isEdit: boolean = false;
  @ViewChild('restoreActionModal', { static: true }) restoreActionModal: ModalDirective;
    observable: Observable<any>;
    subject: Subject<any>;
    returnedValue: any = [];

    
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    public configSet = [
        {
            className: 'btn-restore',
            newFont: '12px',
            oldFont: '20px'
        }
    ];

    hasApply:boolean = false;

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private utilService: UtilService
    ) {
        this.actions = this.injector.get('actions');
        this.pageContents = this.injector.get('pageContents');
        this.isGrowthPlanLocked = this.injector.get('isGrowthPlanLocked');
    }

    ngOnInit() {
        //restoreActionsContainer
        setTimeout(() => {
            this.hasApply = true;
        },200);
    }

    ngAfterViewInit() {
        
    }


    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next([]);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
    }


    openDialog() {
        this.returnedValue = [];
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.restoreActionModal.config = this.config;
        this.restoreActionModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.restoreActionModal.hide();
    }

    restoreAction(action: any) {
        //var currentAction = this.actions.filter((x: any) => x.id == currentAction.id);
        this.subject.next([action]);
        if (this.getActionsCount() == 0) {
            this.closeDialog();

        }

    }

    getActionsCount() {
        var deletedActions = this.actions.filter((x: any) => x.isSelected == false);
        if (deletedActions && deletedActions.length) {
            return deletedActions.length;
        }
        else {
            return 0;
        }
    }

    getBgColor( rag: any) {
        if (rag) {
            switch (rag) {
                case 'R': return "red";
                case 'A': return "amber";
                case 'G': return "green";
            }
        }

    }

    restoreAllActions() {
        var allActions = this.actions.filter((x: any) => x.isSelected == false);
        this.subject.next(allActions);
        this.closeDialog();
    }

}
