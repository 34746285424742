import { Component, Input, ViewChild, Injector, Output, EventEmitter, NgZone, ElementRef} from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
 
import {Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    //moduleId: module.id.toString(),
    selector: "ok-cancel-dialog",
  templateUrl: './ok-cancel-dialog.component.html',
  styleUrls: ['./ok-cancel-dialog.component.scss']
})

export class OkCancelDialogComponent {
    title: string = "";
    message: string = "";
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    backdropClose: boolean = false;
    constructor(private injector: Injector, private zone: NgZone,private el: ElementRef) {
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.customize = this.injector.get('config');
        this.backdropClose = this.injector.get('backdropClose');
    }

  @ViewChild('okCancelModal', { static: true }) okCancelModal: any;
    returnedValue: string = 'no';

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {
        this.okCancelModal.config.ignoreBackdropClick = !this.backdropClose;
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue = "yes";
        this.closeDialog();
    }

    openDialog() {
        const dom: HTMLElement = this.el.nativeElement.parentNode;
        const elements = dom.getElementsByClassName('corporate-owner-info-dialog-modal show') as HTMLCollectionOf<HTMLElement>;
        if (elements.length) {
            let element: HTMLElement = elements[0];
            element.style.setProperty("z-index", '1050');
        }
        //console.log(elements[0].nativeElement.style.setProperty("z-index", 1050, "important"));

        //const element = this.el.nativeElement.getElementsByClassName('corporate-owner-info-dialog-modal'); // first
        //console.log(element);
        //element.style.setProperty("z-index", 1050, "important")
        this.returnedValue = "no";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.okCancelModal.config = this.config;
        this.okCancelModal.show();
        return this.observable;
    }
    
    public closeDialog() {
        const dom: HTMLElement = this.el.nativeElement.parentNode;
        const elements = dom.getElementsByClassName('corporate-owner-info-dialog-modal show') as HTMLCollectionOf<HTMLElement>;
        if (elements.length) {
            let element: HTMLElement = elements[0];
            element.style.setProperty("z-index", '1051');
        }
        this.okCancelModal.hide();
        this.componentEvent.emit({ value: "closed" });


    }
}
