
import { environment } from '../../environments/environment';

export class Constants {
    APP: any = {
        TITLE: 'Velocity',
        ERROR: 'Error',
        INFO: 'Info',
        SUCCESS: 'Success',
        WARN: 'Warning',
        EY_TITLE: 'EY Velocity'
    };
    FOCUS: any = {
        SEVEN_DRIVERS_API: 'growthDrivers',
        SAVE_ASSESSMENT_MODE: 'SaveAssessmentMode',
        SEVEN_DRIVER_GRID_SUMMARY: 'getDriverGridSummary',
        DRIVER_MATURITY_API: 'driverMaturityLevels',
        DRIVER_INSIGHT_API: 'driverInsights',
        GET_THEME_INSIGHT:'GetDriverThemeInsights',
        INSIGHT_POPUP_API: 'cardMediaUri',
        NEED_HELP_POPUP_API: 'getHelpUri/7driversofgrowth',
        LOGIN_HELP_POPUP_API: 'getHelpUri/login',
        SUB_DRIVER_INSIGHT_API: 'subDrivers',
        SUB_DRIVER_API: 'driverThemes',
        THEME_NAVIGATOR_API: 'driverSubThemes',
        SUB_THEME_MATURITY_LEVEL_API: 'driverSubThemeMaturityLevels',
        GET_DRIVER_ASSESSMENT: 'getDriverAssessment',
        GET_THEME_ASSESSMENT: 'getDriverThemeAssessment',
        GET_SUB_THEME_ASSESSMENT: 'getDriverSubThemeAssessment',
        SAVE_ASSESSMENT: 'assessment',
        SUB_THEME_INSIGHT_API: 'driverSubThemeInsights',
        DRIVER_THEME_ACTIONS: "driverThemeActions",
        GET_ACTION_CARD_DETAILS: "getActionCardDetails",
        SAVE_SELECTED_ACTION_CARDS: "growthplanaction",
        SAVE_NEW_CUSTOMACTION_CARD: "customAction",
        MODIFY_CUSTOMACTION_CARD: "ModifyCustomAction",
       // GET_HOT_TOPICS: "hottopiclist",
        GET_HOT_TOPICS_FILTER: "driverFilters",
        GET_GROWTH_PLAN_ACTIONS: "getGrowthPlanActions",
        //GET_GROWTH_PLAN_ACTIONS_DASHBOARD: "getGrowthPlanForDashboard",
        //GROWTH_PLAN: "growthPlan",
        GROWTH_PLAN_Action: "GrowthPlanAction",
        PRIORITIZE: "Prioritize",
       // GET_HT_DETAILS: "getHotTopicDetails",
      //  GET_HT_SURVEY: "getHTSurvey",
        GET_HT_VIDEO: "getHTVideo",
      //  GET_HT_CARDS: 'getHotTopicCards',
        GET_CARD_DETAILS: 'CardDetails',
        GT_HT_CARD_DETAILS: 'cardList/filter',
       // GET_HT_SUB_TOPICS: "getSubTopics",
      //  GET_HT_SUB_TOPIC_DETAILS: "getSubTopicDetails",
        GET_HT_SURVEY_RESULTS: "getHTSurveyResult",
       // SAVE_HT_SURVEY_RESULT: "setHTSurvey/result",
      //  GET_RELATIVE_EVENTS: "getHotTopicEvents",
       // LIKE_HT: 'HotTopic/like',
      //  HT_ACTION_SUMMARY: 'getTopicActionCardSummary',
        //GET_HT_COMMUNITIES_LIST: 'topicDiscussions',
        //FOLLOW_HT_COMMUNITY_QUESTION: 'followCommunityThread/thread',

        GET_INSIGHT_PIN: "cards/pin",
        SET_INSIGHT_PIN: "cards/isPinned",
      //  SAVE_HT_PIN: 'HotTopic/pin',
        //  CUSTOM_ACTION_DETAILS: 'customActionDetails',
        SAVE_ACTION_TASK: 'GrowthPlanAction/Task',
        GET_ACTION_TASK: 'GrowthPlanAction/TaskList',
        SAVE_RAG_ESTIMATE: 'GrowthPlanAction/Estimate',
       // REMOVE_CONSIDERATIONS: 'growthPlanAction/RemoveConsideration',
        SAVE_ACTION_TITLE: 'GrowthPlanAction/SaveCustomTitle',
        SAVE_ACTION_OWNERSHIP: 'GrowthPlanAction/SaveGrowthPlanActionOwnership',
        GET_ACTION_OWNERSHIP: 'GrowthPlanAction/GetGrowthPlanActionOwnership',
        GET_THEME_MATURITY: 'GetDriverThemeMaturityContents',
        GET_SUBDRIVER_ACTIONS: 'getSubDriverGrowthPlanActions',
        GET_GROWTH_PLAN_LIST: 'growthPlan/getRecentGrowthPlans',
        SET_GROWTH_PLAN_ID: 'growthPlan/SetCurrentGrowthPlan',
        SAVE_GROWTH_PLAN: 'growthPlan/save',
        GET_GROWTH_PLAN_DETAILS: 'getGrowthPlanDetails',
        GET_CORPORATE_OWNER_GP_DETAILS:'growthPlanDetails/corporateOwner',
        SHARE_GROWTH_PLAN: 'growthPlan/share',
        GET_ALL_GROWTH_PLANS: 'growthPlan/getAllGrowthPlans',
        GET_GROWTH_PLAN_SUMMARY: 'growthPlan/summary',
        RENAME_GROWTH_PLAN: 'growthPlan/Rename',
        DELETE_GROWTH_PLAN: 'growthPlan/DeletGrowthPlan',
        GET_GROWTH_PLAN: 'getGrowthPlan',
        DULPICATE_GROWTH_PLAN: 'GrowthPlan/Duplicate',
        EDIT_ATTENDEE: 'growthPlanSession/attendees',
        GET_CORPORATE_GP_NOMINATION: 'growthPlan/getCorporateGrowthplanDetailsForNomination',
        GET_GP_NOMINATION_ELIGIBLE_DELETION: 'growthPlan/IsEligibleForDeletion',
        SAVE_NOMINATION:'growthPlan/SaveNomination',
        GET_SELF_NOMINATION: 'growthPlan/getselfgrowthplansfornomination',
        GET_SELF_FACILITATED_NOMINATIONS: 'growthPlan/getselfandfacilitatednominations',
        UPDATE_CORPORATE_GROWTH_PLAN: 'growthPlan/updateCorporateGrowthPlanDetails',
        UPDATE_NOMINATEDGROWTH_PLAN: 'growthPlan/UpdateNominatedGrowthPlan',
        DELETE_FACILITATOR_AND_ATTENDEES: 'growthPlan/DeleteFacilitatorAndAttendees',
        GET_PARTICIPANT_DRIVER_ASSSESSMENT: 'getCorporateTeamDriverThemeAssessment',
        GET_REAL_PARTICIPANT_ASSESSMENT: 'GetRealTimeParticipantAssessment',
        GET_REAL_PARTICIPANT_ACTIONS:'GetParticipantActions',
        GET_PARTICIPANT_PRIORITIZATION: 'growthPlan/GetParticipantPrioritization',
        ADD_CGP_FACILITATOR: 'growthPlan/AddCorporateGrowthPlanFacilitator',
        REMOVE_CGP_FACILITATOR: 'growthPlan/RemoveFacilitatorFromCorporateGrowthplan',
        GET_FIELDS_OF_PLAY_MATRICS: 'fieldsOfPlayMetrics',
        GET_DRIVER_METRICS: 'driverMetrics',
        GET_FIELDS_OF_PLAY_ACTIONS: 'fieldsOfPlayActions',
        GET_FIELDS_OF_PLAY_REPORT: 'fieldsOfPlayReport',
        SAVE_TEAM_MEETING_OWNER: 'growthPlan/saveTeamMeetingOwner',
        GET_PARTICIPANT: 'growthPlan/getGrowthPlanParticipants',
        ACCEPT_DECLINE: 'growthPlan/acceptRejectInvitationRequest',
        RESEND_INVITE: 'growthPlan/resendInvitationRequest',
        GET_DUMMY_USERS: 'growthPlan/getDemoUsers',
        GET_DRIVER_NOTES: 'growthPlan/getDriverNotes',
        GET_SUB_DRIVER_NOTES:'growthPlan/getSubdriverNotes',
        DELETE_DRIVER_NOTE: 'growthPlan/deleteDriverNote',
        DELETE_SUB_DRIVER_NOTE: 'growthPlan/deleteSubDriverNote',
        SAVE_NOTE_ASSESMENT: "growthPlan/addDriverNote",
        SAVE_NOTE_SUB_DRIVER_ASSESMENT: "growthPlan/addSubDriverNote",
        GET_GROWTHPLAN_ACTION_STATUS:"growthPlan/getgrowthplanactionstatus",
        GET_SOLUTION_METRICS: 'solutionMetrics',
        GROWTH_MATRIX_DISCREPANCY: 'growthPlan/getgrowthmatrixdiscrepancy',
        GET_GROWTH_PLAN_INVITATIONS: 'growthPlan/getGrowthPlanInvitations'
    };
    AUTH: any = {
        API_LOGIN: 'login',
        API_LOGIN_BY_USER: 'user',
        API_VERIFY_EMAIL: 'user/IsRegisteredUser',
        API_AUTHENTICATE_External_User: 'user/authenticateExternalUser',
        API_REGISTER: 'registerUser',
        API_HOT_TOPIC_LIST: 'hottopics/interest',
        API_INTERNAL_LOGIN: 'user/authenticate',
        API_CHECK_AUTH: 'user/CheckAuth',
        API_REFRESH_TOKEN: 'user/RefreshToken',
        API_MEMBERSHIP: 'IsValidMembershipId',
        API_AUTHORIZED_URL: 'User/IsAuthorizetoAccessUrl',
        AUTO_LOGOUT_API_URL: 'user/Logout',//edit
        GET_MY_EY_USER_DETAILS: 'user/getMyEYUserDetails',
    }
    ACT: any = {
       // GET_TOOLS_LIST: 'tools/listing',
       // GET_TOOL_DETAILS: 'cards/toolDetails',
       // GET_RELATED_TOOLS: 'tools/related',
        GET_TOOL_SUBSCRIPTION: 'getSubscribtion',
        GET_TOOL_MEDIA: 'getToolMedia',
       // SUBSCRIBE_TO_TOOL: 'tools/subscribe',
      //  IS_CARD_PINNED: 'cards/isAnyPinned',
      //  GET_DASHBOARD_TOOLS: 'tools/listing/dashboard',
        GET_SUMMARY_REPORTS: 'GrowthSummary/Reports',
        CREATE_REPORT: 'GrowthSummary/CreateReport',
        DELETE_REPORT:'GrowthSummary/DeleteReport',
        //DOWNLOAD_REPORT_AS_BLOB:'DownloadReportAsBlob',
        REPORT_IMAGES: 'GrowthSummary/ReportImages',
        GET_COVER_PAGE: 'GrowthSummary/ReportCover',
        UPLOAD_COMPANY_LOGO: 'GrowthSummary/uploadCompanyLogo',
        SET_REPORT_FIELDS:'GrowthSummary/SetReportFields',
        GET_READNINESS_REPORT: 'GrowthSummary/GetReadinessSummaryReport',
        GET_DRIVER_ASSESSMENT_REPORT: 'GrowthSummary/DriverAssessment',
        GET_DRIVER_ACTION_REPORT: 'GrowthSummary/GetDriverActionSummaryReport',
        GET_REPORT_CUSTOMIZATION: 'GrowthSummary/Customization',
        SAVE_REPORT_CUSTOMIZATION: 'GrowthSummary/SaveCustomization',
        FINALIZE_REPORT: 'GrowthSummary/Finalize',
        SAVE_DRAFT_REPORT:'GrowthSummary/SaveAsDraft',
        GET_SUB_DRIVER_PROGRESS: 'GrowthSummary/GetGrowthSummarySubDriverAssessment',
		SET_ACTION_PROPERTY: "GrowthPlanAction/SetActionProperty",
       // GET_DASHBOARD_RESOURCES: "resources/dashboard",
        SAVE_ACTION_NOTES: 'GrowthPlanAction/SaveGrowthPlanActionNote',
       // ACT_OVERVIEW: 'GrowthPlanAction/GetUserStatusOverview', 
      //  GET_RESOURCE_LIST: 'resources/list',
     //   GET_ACT_RESOURCE_LIST: 'resources/act',
       // GET_ALL_RESOURCE_LIST: 'resources/GetAllResourcesWithAction',
        GET_REPORT_CREATE_PERMISSION: 'growthPlan/canCreateReport',
        //GET_THREE_YEAR_COST: 'growthPlan/GetThreeYearActionCost',
        SET_REPORT_HIDE_STATUS_DRIVER: 'GrowthSummary/setGrowthSummaryReportHideStatus',
        GET_COVER_IMAGES: 'growthPlan/getCoverImages',
        UPDATE_COVER_IMAGE: 'growthPlan/updateReportCoverImage',
        DUPLICATE_REPORT_NAME: 'growthPlan/checkReportNameExists',
        GET_TEMPLATES: 'growthSummary/getReportTemplates',
        GET_TEMPLATE: 'growthSummary/getReportTemplateDetails',
        GER_DEIVER_SUMMARY:'growthSummary/getSubDriverActionSummaryReport',
        GET_NOTES: 'growthPlan/getgrowthplannotes',
        EXPORT_ACTION_DETAILS:"growthPlanActionReport"
        
    }
    GENERAL: any = {
        USER_EMAIL_ID: 'userEmailID',
        PRIVACY_ACCEPTED: 'privacyAcceped',
        REGISTRATION_COMPLETE: 'registrationComplete',
        LANDING_ACCESS_TOKEN: 'land_access_token',
        GET_TERMS_CONDITION: "getBlobTemplateContent/termsconditions",
        GET_USER_TERMS_CONDITION: "gettermsconditions",
        CHECK_TERMS_CONDITION:"TC/Needed",
        GET_PRIVACY_POLICY: "getBlobTemplateContent/privacypolicy",
        GET_PRIVACY_POLICY_SHORT: "getBlobTemplateContent/privacypolicyshort",
        GET_CODE_OF_CONDUCT: "getBlobTemplateContent/codeofconduct",
        GET_WHY_EY: "getBlobTemplateContent/whyey",
        GET_ABOUT_EY_VELOCITY: "getBlobTemplateContent/abouteyvelocity",
        GET_LOOK_UP_DATA: "lookupData",
        YEAR_PATTERN: /^\d{4}$/,
        PATTERN_WEB_URL: /^(http:\/\/www\.| https:\/\/www\.|www\.|ww4\.|http:\/\/|https:\/\/)[a-zA-z0-9]+([\-\.]{1}[a-zA-z0-9]+)*\.[a-zA-z0-9]+(:[0-9]{1,5})?(\/.*)?$/i,
        PATTERN_POSTAL_CODE: /^[a-zA-Z0-9\- ]*$/,
        PATTERN_CHARACTERS: /^[a-zA-Z\s]+$/,
        PATTERN_NUMBER: /^[0-9]+$/,
        DUNS_NUMBER: /^\d{9}$/,
        PATTERN_ALPHANUMERIC: /^[\w\-\s]+$/,
        PATTERN_ALPHANUMERIC_DOT: /^[\w\-\.\_\s]+$/,
        PATTERN_ALPHANUMERIC_EXCLUDED_ONLY_SPACE: /^[\w\-\.\_][\w\-\.\_\s]*$/,
        PATTERN_MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
        PATTERN_REGISTRATION_SPECIAL_CHARACTERS: /^[a-zA-Z0-9\s.,&']*$/,
        PATTERN_NON_NUMBER: /^([^0-9]*)$/,
        PATTERN_NON_NUMBER_NEW: /^([a-zA-Z .'()]+)$/,
        PATTERN_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
        MAX_LENGTH: 100,
        MAX_LENGTH_75: 75,
        IS_PAGE_ACCESSED: 'user/IsPageAccessed',
        SET_PAGE_ACCESSED: 'user/SetPageAccessed',
       // SET_PAGE_ACCESSED_CONNECT: 'User/SetPageAccessed/-1/connect',
        PAGE_NAME_SEVEN_DRIVERS: 'sevendrivers',
        PAGE_NAME_HOT_TOPICS: 'hottopics',
        PAGE_NAME_TOOLS: 'tools',
        PAGE_NAME_DASHBOARD: 'dashboard',
        PAGE_NAME_CONNECT: 'connect',
        PAGE_HOT_TOPICS: 'Hot Topics Page',
        PAGE_SEVEN_DRIVERS: '7 Drivers Page',
        PAGE_DASHBOARD: 'Dashboard Page',
        PAGE_SEARCH: 'Search Page',
        SEARCH_CATEGORY: 'lookup/searchCategory',
        SEARCH_GLOBAL: 'search/global',
        NEED_HELP_USERPROFILE_POPUP_API: 'getHelpUri/userprofile',
        USER_DATA: 'userData',
        RETURN_URL: 'returnUrl',
        LINKEDIN_ACTION: 'linkedinAction',
        GET_INDUSTRYID: 'lookup/industryid',
        GET_COUNTRY_TERMS_CONDITION: 'termsconditions/regional',
        ACCEPT_TERMS_CONDITION:'TC/Accept',
        HELP_TOPICS: 'help/topics',
        HELP_SECTIONS: 'help/sections',
        SAVE_CONTACT: 'contact',
        ACCOUNT_DELETION: 'user/DeleteUserRequest',
        GET_TIMEZONE_LIST: 'lookup/timezones',
        GET_USER_SETTINGS: 'User/Settings',
        SAVE_NOTIFICATION_STATUS: 'User/SetNotificationStatus',
        GET_RESET_GN_INFO: 'GrowthPlan/getLastResetDate',
        RESET_GROWTH_NAVIGATOR: 'GrowthPlan/Reset',
        SAVE_CONTACTUS: 'Marketing/ContactUs',
        GET_REPORT_NAME: 'GrowthPlan/getReportName',
        GET_DRIVER_ACTION_STATUS: 'GrowthSummary/DriverAssessmentActionDetails',
        //IS_FIRST_TIME_USER: 'user/IsFirstTimeUser',
        GET_CURRENCIES_LIST: 'lookup/currencies',
        CHECK_ACTION_COST: 'growthPlan/checkActionCostAvailable',
        SAVE_RAG_VALUE:'SaveUserRAGValues',
    }
    DASHBOARD: any = {
        API_OVERVIEW: 'community/overview',
        MY_CONTACT_LIST: 'myContacts',
        MY_COMMUNITIY_LIST: 'Community/MyCommunities',
        GET_ANNOUNCEMENTS: 'getAnnouncementList',
        GET_FOP_MATRICS: 'growthPlanFieldsOfPlayMetrics',
        GET_GP_STATUS: 'getMyGrowthPlanStatusDashboard',
        GET_GP_ACTIONS: 'getGrowthPlanActionForDashboard',
        GET_GROWTHPLANS: 'growthPlan/getMyGrowthPlans',
        GET_QUICK_LINKS: 'getQuickLinks'
        //MY_INSIGHT_LIST: 'cards/PinnedCards'
    };
    USER: any = {
        PROFILE: 'user/myprofile',
        PROFILE_IMAGE: 'upload/profileImage',
        UPDATE_PROFILE_INFO: 'User/UpdateProfileInfo',
        COMPANY_PROFILE_IMAGE: 'upload/companyProfileImage',
        UPDATE_TIMEZONE: 'User/UpdateTimezone',
        IS_FACILITATOR: 'user/IsFacilitator',
        FACILITATOR_CODE_VALIDATE: 'user/facilitator/code',
        SEARCH_MEMBER: 'user/member',
        SEARCH_MEMBER_SHARE: 'user/share',
        UPDATE_CURRENCY: 'User/UpdateCurrency',
        UPDATE_GP_CURRENCY: 'growthPlan/currency',
        SEARCH_PARTICIPENT: 'user/participant',
        SAVE_LANGUAGE: 'user/switchLanguage',
        LIST_LANGUAGE: 'languages',
        GET_PROFILE_DETAILS: 'user/getProfile',
        GET_RAG_VALUES:'user/getRAGValues',
        GET_GROWTH_PLAN_RAG_VALUES: 'user/getGrowthPlanRAGValues',
        GET_AXIS_VALUES :'user/getXYAxisValues',
        GET_GP_AXIS_VALUES :'user/getGrowthPlanXYAxisValues',
        SAVE_AXIS_VALUES: 'SaveUserGrowthMatrixValues',
        GET_USER_AXES_VALUES: 'user/getUserXYAxisValues',
    };

    NOTIFICATION: any = {
        GET_NOTIFICATIONS_COUNT: 'notification/count',
        GET_NOTIFICATION_LIST: 'notification/list',
        REMOVE_NOTIFICATIONS: 'notification/remove',
        COMMENT_NOTIFICATIONS: 'notification/comments',
        REMOVE_ALL_NOTIFICATIONS: 'notification/removeAll',
        GET_NOTIFICATION_SETTINGS:'notification/GetUserNotificationPreference',
        SAVE_NOTIFICATION_SETTINGS: 'notification/SetUserNotificationPreference'
    };

    HELP_SECTION: any = {
        LOGIN: 1,
        COMMUNITY: 100,
        DRIVER_ASSESSMENT: 200,
        SUB_DRIVERS: 300,
        THEMES: 400,
        THEME_ASSESSMENT: 500,
        ACTION_CARDS: 600,
        HOT_TOPICS: 700,
        MY_GROWTH_PLAN: 800,
        TOOLS_RESOURCES: 900,
        MY_STORY: 1000,
        DASHBOARD: 1100,
        NOTIFICATIONS: 1200,
        RECENT_ACTIVITY: 1300,
        USER_INFO: 1400,
        COMPANY_INFO: 1500,
        COMPANY_DEMOGRAPHICS: 1600,
        COMPANY_INDEPENDENCE: 1700,
        GROWTH_SUMMARY_REPORT:1800
    };

    LOGGING: any = {
        LOG_ACTIONS: 'eventLog',
    }

    readonly USE_MYEY_LOGIN = environment.USE_MYEY_LOGIN;

    TIMEOUT_HANDLER_OPTIONS: any = {
        //in seconds
        WARNING_DURATION: 1500,//25 minutes //1500
        LOGOUT_DURATION: 1740, // 29 minutes //1740
        FORCED_LOGOUT_DURATION: 35940//10 hours - 60 secondsGROWTH //35940

        //WARNING_DURATION: 420,
        //LOGOUT_DURATION: 600,
        //FORCED_LOGOUT_DURATION: 900

        //WARNING_DURATION: 60,
        //LOGOUT_DURATION: 90,
        //FORCED_LOGOUT_DURATION: 180
    };


    DB_TIMEZONE: any = {
        HOUR_OFFSET: 0,
        MIN_OFFSET:0
    }

    EVENTS: any = {
        GPR_CUSTOMIZATION_APPLIED: 'growth-plan-report-customization-applied',
        MY_STORY_SLIDER_CHANGED: 'my-story-slider-changed'
    }

    RESOURCES_STYLE: any = {
        R_COLOR: '#FC272A',
        A_COLOR: '#FD8526',
        G_COLOR: '#2AAD45',
        W_COLOR: '#FFFFFF',
        B_COLOR: '#000000',
        DEFAULR_BORDER_COLOR: '#61606D',
        BORDER_SYLE: '2px solid '
    };
    SEVEN_DRIVER_GROWTH_PLAN: string = 'sevenDriverGrowthPlan';
   
    constructor() {

    }
}

