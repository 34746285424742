import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: "[showEllipsis]"
})
export class ShowEllipsisDirective {
    hasMoreText = false;
  gvenValue = '';

    element: ElementRef;
    constructor(private el: ElementRef) {
        this.element = el;
        this.truncateText(this.element);
    }
    //ngOnChanges(changes: SimpleChanges) {
    //    console.log("chane"+changes);
    //}
    @HostListener('window:resize') onResize() {
        this.truncateText(this.element);
    }

    truncateText(el: ElementRef) {
        let descEl = el.nativeElement.getElementsByTagName('p')[0];
        this.hasMoreText = false;
        let me = this;
        el.nativeElement.style.visibility = 'hidden';
        setTimeout(() => {

            let descEl = el.nativeElement.getElementsByTagName('p')[0];
            if (descEl) {
              if (this.gvenValue == '') {
                this.gvenValue = descEl.innerHTML;
                } else {
                descEl.innerHTML = this.gvenValue;
                }

              var truncateTextParts = this.gvenValue.split(' ');
                //    brLine = (this.text.match(/<br/g) || []).length * lineHeight;

                if (descEl.offsetHeight >= descEl.parentNode.offsetHeight) {
                    //  console.log("Greater:"+this.lineNo);
                    this.hasMoreText = true;
                    while (descEl.parentNode.offsetHeight <= (descEl.offsetHeight)) {
                        if (truncateTextParts.length > 0) {
                            let t_text = truncateTextParts[truncateTextParts.length - 1];
                            if (t_text && (t_text.includes("/>") || t_text.includes("</"))) {
                                truncateTextParts.pop();

                                let s_text = t_text.split(">");
                                while (descEl.parentNode.offsetHeight <= (descEl.offsetHeight) && s_text.length > 0) {
                                    descEl.innerHTML = truncateTextParts.join(' ') + s_text.join('>');
                                    descEl.innerHTML = descEl.innerHTML + '...'
                                    s_text.pop();
                                }
                            } else {
                                truncateTextParts.pop();
                                descEl.innerHTML = truncateTextParts.join(' ') + '...';
                            }
                        } else {
                            break;
                        }
                    }
                } else {
                }
            }
            el.nativeElement.style.visibility = 'visible';

        }, 1000);
    }


}
