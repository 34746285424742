import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "amount", pure: false })


export class amountFormatter {
    transform(num: number, decimalPoint?: number): string {
        //.replace(/\.0$/, '')
        const numericValue = Number(num);
        if (num == 0) {
            return '0';
        }
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "B" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "Q" }
        ];
      
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        if(decimalPoint === 0) {
            return (num / si[i].value).toFixed(decimalPoint) + si[i].symbol;
        } else {
            let number = (num / si[i].value).toFixed(decimalPoint || 1);

            if (number.match(/\./)) {
                number = number.replace(/\.?0+$/, '');
            }
            return number + si[i].symbol;

        }

    }
}