import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,

    OnChanges,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { BaseComponent } from './../../component/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LogTypes, LogAction } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';
import { AuthenticationService } from '../../auth/service/auth.service';
import { GrowthPlanListDto, DuplicateGrowthPlanData, GrowthPlanSessionDto } from '../../focus/model/face-to-face.model';
import { FaceToFaceService } from '../../service/face-to-face.service';
import { StorageHelperService } from '../../service/storage-helper.service';
import { Constants } from '../../service/constant.service';
import { DataShareService } from '../../service/data-share.service';
import { IMyDpOptions } from 'mydatepicker';

//import { ModalDialogService } from "../../modal-dialog-module/modal-dialog.service";
@Component({
    selector: "f2f-info-dialog",
  templateUrl: "./f2f-info-dialog-component.html",
  styleUrls: ['./f2f-info-dialog-component.scss'],
    providers: [],
    encapsulation: ViewEncapsulation.None
})

export class F2FInfoDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

    observable: Observable<any>;
    subject: any;
    PageContents: any = {};
  //  facDetails: any = {};
 //   attendeeInfo: any = {};
  //  selMember: any = {};
  //  confirmInfo: any = {};
   // private ModalDialogService: ModalDialogService;
    isFacilitator: boolean;
    growthPlanId: any;
    typeId: any;
    share: boolean = false;
    unShare: boolean = false;
    selfAssessment: boolean = true;
    existingmember: boolean = false;
    facilitatedSession: boolean = false;
    showGpInfoDialog: boolean = true;
    growthPlanDetailsArr: any = {};
    ownerDetailsArr: any = {};
    facilitatorDetailsArr: any = {};
  //  cAttendeesArr: any = {};
  //  eyAttendeesArr: any = {};
    attendeeInfo: any = {};
    sharedListArr: any = [];

    showInfoPopupDetails: boolean = false;
    showAttendees: boolean = false;
    //showEyAttendees: boolean = false;
    showOwnerDetails: boolean = false;
    showFacilitatorDetails: boolean = false;
    showSharedDetails: boolean = true;

    showGpDelete: boolean = false;
    showGpDuplicate: boolean = false;

    @Output("closeEvent") componentEvent: any = new EventEmitter()
    @ViewChild('f2fInfoDialogModal', { static: true }) f2fInfoDialogModal: ModalDirective;
    //  activeStep: number = 1;
    isLoaded: boolean = false;

    showButtonSection: boolean = true;
    showUnShareSection: boolean = false;
    showShareSection: boolean = false;

    showGpShareButton: boolean = true;
    showGpUnShareButton: boolean = true;
    isGpEditable: boolean = true;
    isEditAttendee: boolean = true;
    showGpDeleteButton: boolean = true;

    selUser: any[] = [];
    profileselUser: any[] = [];
    errorMessage: boolean = false;
    errorMessageUnshare: boolean = false;
    errorMsgDelete: boolean = false;
    errorMessageRename: boolean = false;
    errorGPEmpty: boolean = false;
    selectedItems = [];
    selectedMemberList: any = {};
    gpNameSectionEdit: boolean = false;
    gpTitle: any;
    prevGpTitle: any;
    duplicateGrowthPlanName: string = '';
    growthPlanName: string = '';
    errorMessageData: string = '';  
    errorresponseMessageData: string = '';
    errorGPEmptyDuplicate: boolean = false;
    growthPlan: any;
    isFacilitatedSession: boolean = false;
    cgpFacCode: string = '';
    clientName: string = '';
    clientEmail: string = '';
    companyName: string = '';
    IsFacilitator: boolean = false;
    facNonfaclabel: string = '';
    cgpFacErrorSection: boolean = false;
    cgpFacErrorTxt: string = '';
    isFacSuccesswithImage: boolean = false;
    delOwnerDetails: any = {};
    is_edit: boolean = false;
    meetingDate: any;
    formSubmitted: boolean = false;
    formSubmission_GP_IsValid: boolean = false;
    formSubmission_FAC_IsValid: boolean = false;
    formSubmission_SPECIALCHAR_IsValid: boolean = false;
    formSubmission_ExeMember_IsValid: boolean = false;
    formSubmission_ClientName_IsValid: boolean = false;
    formSubmission_ClientEmail_IsValid: boolean = false;
    formSubmission_ClientCompany_IsValid: boolean = false;
    duplicate_meetingDate: any;
    duplicate_growthPlanTypeId: any;
    duplicate_facilitatorCode: any;
    duplicate_memberId: any;
    duplicate_clientName: any;
    duplicate_clientEmail: any;
    duplicate_companyName: any;
    duplicate_facilitatorFlow: any;
    isExistingMember: boolean = false;
    profileImageValue: any;
    profileNameValue: any;
    profileCompanyValue: any;
    isExistingEmail: boolean = false;
    isNamerequired: boolean = false;
    isCompanyrequired: boolean = false;
    emailValidationError: any;
    emailValErrorAlreadyexist: boolean = false;
    emailValErrorFormat: boolean = false;
    isUserequired: boolean = false;
    showGpalreadyNominated: boolean = false;
    private currentGrowthPlan: GrowthPlanSessionDto = new GrowthPlanSessionDto(0,'',false,false);
    scrollPos = '';
    growthPlanSessionContent: any;
    hasAxisDifference: boolean = false;
    isContinued: boolean = false;
    otherContents: any;
    axesDifferenceModalContent: any;
    isLoading: boolean = false;

    getYesterday() {
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return {
            year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate()
        }
    }
    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'dd-mmm-yyyy',
        editableDateField: false,
        showSelectorArrow: false,
        openSelectorTopOfInput: true,
        openSelectorOnInputClick: true,
        showTodayBtn: false,
        showClearDateBtn: false,
        //disableUntil: this.getYesterday()
    };

    constructor(
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private injector: Injector,
        private router: Router,
        private logTypes: LogTypes,
        private logService: LogService,
        private faceToFaceService: FaceToFaceService,
        private storageHelper: StorageHelperService,    
        private constants: Constants,
        private dataShareService: DataShareService,
        private authService: AuthenticationService,
        private route: ActivatedRoute
    ) {
        super(injector);
        this.PageContents = this.injector.get('PageContents');
        this.growthPlan = this.injector.get('growthPlan');
        this.typeId = this.injector.get('typeId');
        this.share = this.injector.get('share');
        this.unShare = this.injector.get('unshare');
        this.otherContents = this.injector.get('otherContents');
        this.currentGrowthPlan = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
    }
    //setModalService(ModalDialogService) {
    //    this.ModalDialogService = ModalDialogService;
    //}


    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    returnedValue: any = {};

    getTodayDate() {
        let today = new Date();
        return {
            date: { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }
        }
    }
    ngOnInit() {
        this.growthPlanId = this.growthPlan.growthPlanId;
        this.duplicate_meetingDate = "";
        this.duplicate_growthPlanTypeId = "";
        this.duplicate_facilitatorCode = "";
        //this.formSubmission_SPECIALCHAR_IsValid = false;
        if (this.typeId == 3) {
            this.deleteGP();
        } else if (this.typeId == 4) {
            this.duplicateGP();
        }
        else {
            this.getAllGPDetails();
        }

        this.PageContents.lblSearchByNameEmailCompany = this.PageContents.lblEnterNameOrEmail;
        if (this.share == true) {
            this.shreGP();
        }
        else if (this.unShare == true) {
            this.unShreGP();
        }

        this.isFacilitatedSession = false;
        this.isFacSuccesswithImage = false;
        this.is_edit = false;
        this.clearProfileValues();
        this.velocityService.checkIsFacilitator().subscribe((data) => {
            if (data.IsFacilitator) {

                this.selfAssessment = false;
                this.facilitatedSession = true;
                this.isFacilitatedSession = true;
                this.errorGPEmptyDuplicate = false;
                this.errorMessageData = null;
                this.errorresponseMessageData = null;
                this.duplicateGrowthPlanName = "";
                this.meetingDate = this.getTodayDate();
                this.isFacSuccesswithImage = false;
                this.IsFacilitator = true;
                this.existingmember = true;
                this.isExistingMember = true;
                this.facNonfaclabel = this.PageContents.lblSelectaMember;
            }
            else {

                this.isFacilitatedSession = false;
                this.selfAssessment = true;
                this.facilitatedSession = false;
                this.errorGPEmptyDuplicate = false;
                this.errorMessageData = null;
                this.errorresponseMessageData = null;
                this.duplicateGrowthPlanName = "";
                this.isFacSuccesswithImage = false;
                this.IsFacilitator = false;
                this.facNonfaclabel = this.PageContents.lblFacilitatorCode;
            }
        });

       
    }

    getAllGPDetails() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("f2fInfoDialog");
        this.velocityService.getGrowthPlanDetails(this.growthPlanId, updateProgressBar).subscribe((data) => {
            //console.log("kkpp---------------------------");
            //console.log(data.GrowthPlanDetails);
            if (data) {
                this.growthPlanDetailsArr = data.GrowthPlanDetails;
                this.growthPlanName = data.GrowthPlanDetails.growthPlanName;

                if (this.growthPlanDetailsArr.isFacilitator) {
                    this.isEditAttendee = true;
                } else {
                    this.isEditAttendee = false;
                }
                // Check for owner of the GP, if not share is blocked : Ref. Bug 518731
                if (!this.growthPlanDetailsArr.isOwner) {
                    this.showGpShareButton = false;
                    this.showSharedDetails = false;
                    this.showGpUnShareButton = false;
                }
                else {
                    this.showGpShareButton = true;
                    this.showSharedDetails = true;
                    this.showGpUnShareButton = true;
                }

                if (this.growthPlanDetailsArr.isFacilitated && !this.growthPlanDetailsArr.isLocked) {
                    this.showGpShareButton = false;
                    this.showSharedDetails = false;
                    this.showGpUnShareButton = false;
                }
                // Bug Delete option is not available for finalised F2F Facilitator User 
                if ((this.growthPlanDetailsArr.isFacilitated && this.growthPlanDetailsArr.isLocked) || (this.growthPlanDetailsArr.growthPlanTypeId === 3 && this.growthPlanDetailsArr.isOwner)) {
                    this.showGpDeleteButton = false;
                }

                // When GP is Shared and That User Logged in 
                if (this.growthPlanDetailsArr.isShared) {
                    this.growthPlanDetailsArr.growthPlanType = this.PageContents.lblShared;
                    this.isGpEditable = false;
                    this.showGpDeleteButton = true;
                }
                // When GP is Locked No more rename
                if (this.growthPlanDetailsArr.isLocked) {
                    this.isGpEditable = false;                   
                }
                if (data.ClientAttendees != null) {
                 //   this.showClientAttendees = true;
                  //  this.cAttendeesArr = data.ClientAttendees;
                    this.attendeeInfo.ClientAttendees = data.ClientAttendees;
                }
                if (data.EYAttendees != null) {
                   // this.showEyAttendees = true;
                    this.attendeeInfo.EYAttendees = data.EYAttendees;
                }
                this.showAttendees = true
                if (data.GrowthPlanFacilitatorDetails != null) {
                    this.showFacilitatorDetails = true;
                    this.facilitatorDetailsArr = data.GrowthPlanFacilitatorDetails;
                }
                if (data.GrowthPlanOwnerDetails != null) {
                    this.showOwnerDetails = true;
                    this.ownerDetailsArr = data.GrowthPlanOwnerDetails;
                }
                if (data.GrowthPlanSharedWith != null) {
                    //this.showSharedDetails = true;
                    this.sharedListArr = data.GrowthPlanSharedWith;
                }
                this.returnedValue = {
                    growthPlanName: data.GrowthPlanDetails.growthPlanName, lstGrowthPlanSahredWith: []
                };
                if (data.GrowthPlanDetails.isOwner == true) {
                    if (data.GrowthPlanSharedWith != null) {
                        for (let shared of data.GrowthPlanSharedWith) {
                            this.returnedValue.lstGrowthPlanSahredWith.push({
                                id: shared.id,
                                sharedWithAbbrivation: shared.sharedWithAbbrivation,
                                sharedWithCompanyName: shared.sharedWithCompanyName,
                                sharedWithImageURL: shared.sharedWithImageURL,
                                sharedWithName: shared.sharedWithName
                            });
                        }
                    }
                }
            }
        });
    }

        onDuplicationRadioChange(val) {
        if (val == "selfAssessment")
        {
            this.isFacilitatedSession = false;
            this.selfAssessment = true;
            this.facilitatedSession = false;
            this.errorGPEmptyDuplicate = false;
            //this.errorMessageData = null;
            this.errorresponseMessageData = null;
            //this.duplicateGrowthPlanName = "";
            this.clientEmail = "";
            this.clientName = "";
            this.companyName = "";
            this.isUserequired = false;
            this.PageContents.PeoplepickerError = false;
            this.removeFacilitator();
        }
        else if (val == "demoSession")
        {
            this.isFacilitatedSession = false;
            this.selfAssessment = true;
            this.facilitatedSession = false;
            this.errorGPEmptyDuplicate = false;
            //this.errorMessageData = null;
            this.errorresponseMessageData = null;
            //this.duplicateGrowthPlanName = "";
            this.clientEmail = "";
            this.clientName = "";
            this.companyName = "";
            this.isUserequired = false;
            this.PageContents.PeoplepickerError = false;
            this.removeFacilitator();
        }
        else {
            this.selfAssessment = false;
            this.facilitatedSession = true;
            this.isFacilitatedSession = true;
            this.errorGPEmptyDuplicate = false;
            //this.errorMessageData = null;
            this.errorresponseMessageData = null;
            //this.duplicateGrowthPlanName = "";
            this.isExistingMember = true;
            this.meetingDate = this.getTodayDate();

        }
    }

        onMemberRadioChange(val) {
            if (val == "existingmember") {
                this.isExistingMember = true;
                this.isFacSuccesswithImage = false;
                this.isExistingEmail = false;
                this.emailValidationError = "";
                this.clientEmail = "";
                this.clientName = "";
                this.companyName = "";
                this.isNamerequired = false;
                this.isCompanyrequired = false;
                this.errorresponseMessageData = null;
            }
            else {
                this.isExistingMember = false;
                this.errorresponseMessageData = null;
                this.isUserequired = false;
                this.PageContents.PeoplepickerError = false;
                this.emailValidationError = "";
                this.clientEmail = "";
                this.clientName = "";
                this.companyName = "";
                this.isNamerequired = false;
                this.isCompanyrequired = false;
                this.isExistingEmail = false;
                this.removeFacilitator();

            }
        }

        //isFieldValid(field: string) {
        //    console.log()
        //    return !this.userForm.get(field).valid && this.userForm.get(field).touched;
        //}
        //displayFieldCss(field: string) {
        //    return {
        //        'red-border-class': this.isFieldValid(field)
        //    };
        //}
        //isEmailAlreadyExists() {
        //    let mail = this.userForm.get('clientEmail').value;
        //    if (mail.length > 0) {
        //        this.authService.veriyEmail(mail).subscribe(
        //            (result) => {
        //                if (result) {
        //                    this.isExistingMember = true;
        //                } else {
        //                    this.isExistingMember = false;
        //                }
        //            }
        //        );

        //    } else {
        //        this.isExistingMember = false;
        //    }
        //}

        //onKeyUPFacCode() {
        //    if (this.cgpFacCode.trim() != '') {
        //        let params;
        //        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("f2finfoduplicategrowthplan");
        //        params = { code: this.cgpFacCode.trim() };
        //        this.velocityService.validateFacilitatorCode(params, updateProgressBar).subscribe((data: any) => {
        //            if (data) {
        //                this.delOwnerDetails = data;
        //                if (this.delOwnerDetails.Id !== 0) {
        //                    this.is_edit = true;
        //                    if (this.delOwnerDetails.profileUrl !== '' || this.delOwnerDetails.profileUrl !== null)
        //                    {
        //                        this.isFacSuccesswithImage = true;
        //                        this.profileImageValue = this.delOwnerDetails.profileUrl;
        //                        this.profileNameValue = this.delOwnerDetails.name;
        //                        this.profileCompanyValue = this.delOwnerDetails.company;
        //                        //alert(this.delOwnerDetails.firstName.charAt(0)+(this.delOwnerDetails.lastName.charAt(0)));
        //                    }
        //                    else {
        //                        this.isFacSuccesswithImage = false;
        //                    }
                            

        //                //this.cancelFacCode();

        //                } else {
        //                    this.is_edit = false;
        //                    this.isFacSuccesswithImage = false;
        //                    this.cgpFacErrorTxt = this.PageContents.InvalidFacilitatorMessage;
        //                    this.cgpFacErrorSection = true;
        //                    console.log("Please enter valid code");
        //                }
        //            }
        //        });

        //    } else {
        //        this.cgpFacErrorTxt = this.PageContents.EnterFacilitatorMessage;
        //        this.cgpFacErrorSection = true;
        //    }
        //}

    cancelFacCode() {
        this.cgpFacCode = "";
        this.cgpFacErrorSection = false;
        this.is_edit = false;
        this.delOwnerDetails = {};
    }
    cancelMemCode() {
        this.isFacSuccesswithImage = false;
        this.profileselUser = [];
        this.selUser = [];
        this.isUserequired = false;
        this.PageContents.PeoplepickerError = false;
    }
    clearProfileValues() {
        this.profileImageValue = "";
        this.profileNameValue = "";
        this.profileCompanyValue = "";
    }
    clearFacError(event: any) {
        this.cgpFacErrorSection = false;
        this.formSubmitted = false;
    }
    removeFacilitator() {
        this.isFacSuccesswithImage = false;
        this.clearProfileValues();
        if (this.IsFacilitator == false) {
            this.cancelFacCode();
        }
        else {
            this.cancelMemCode();
        }
    }

    isEmailAlreadyExists() {
        this.isExistingEmail = false;
        this.emailValErrorAlreadyexist = false;
        this.emailValErrorFormat = false;
        this.emailValidationError = "";
        let isValidGp = this.validateEmailformat(this.clientEmail);
        let mail = this.clientEmail;
        if (isValidGp) {
            if (mail.length > 0) {
                this.authService.veriyEmail(mail).subscribe(
                    (result) => {
                        if (result) {
                            this.isExistingEmail = true;
                            this.emailValidationError = this.PageContents.msgExistingmember;
                            this.emailValErrorAlreadyexist = true;
                        } else {
                            this.emailValErrorAlreadyexist = false;
                            this.isExistingEmail = false;
                            this.emailValidationError = "";
                        }
                    }
                );

            } else {
                this.isExistingEmail = false; 
                //this.emailValidationError = this.PageContents.msgClientEmailRequired;
            }
        }
        else {
            if (mail.length > 0) {
                this.emailValErrorFormat = true;
                    this.isExistingEmail = true;
                    this.emailValidationError = this.PageContents.msgClientEmailValid;
                }
                else {
                this.isExistingEmail = false;
                this.emailValidationError = "";
                this.emailValErrorFormat = false;
                }
        }

        
    }

    goToDriverAssessment() {
        this.router.navigate(['focus', 'ey.aimaturitymodel']);
    }

    resetAllGP() {
       // this.showResetAll = true;
       // this.showGpInfoDialog = false;
                    this.returnedValue['growthPlanId'] = this.growthPlanId;
                    const rGP = { isresetAll: true }
                    this.returnedValue = { ...this.returnedValue, ...rGP };
                    this.closeDialog();
    }


    gotoReport() {
        this.router.navigate(['act', 'summary']);
    }

    resetSomeGP() {
        this.router.navigate(['user-detail/settings/'+this.growthPlanId]);
    }

    shreGP() {
        this.showButtonSection = false;
        this.showShareSection = true;
        this.errorMessage = false;

        //setTimeout(() => {
        //    let peoplePickerInputElmnt = document.getElementById('txtSearchInput');
        //    peoplePickerInputElmnt.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

        //   // window.scrollTo(0, 0);
        //}, 1500);
    }

    cancelShreGP() {
        this.showShareSection = false;
        this.showButtonSection = true;
        this.removeMember();
        this.closeDialog();
    }
    onSelectionChange(user: any) {
        this.errorMessage = false;
        let inputPicker = document.getElementById('txtSearchInput');
        inputPicker.classList.remove('red-border-class');
        this.profileselUser = user;
        this.profileImageValue = this.profileselUser[0].profileUrl;
        this.profileNameValue = this.profileselUser[0].firstName + " " + this.profileselUser[0].lastName;
        this.profileCompanyValue = this.profileselUser[0].company;
        this.isFacSuccesswithImage = true;
    }
    removeMember() {
        this.selUser = [];
    }

    shareGrowthplan() {
        if (this.selUser && this.selUser.length > 0 && this.selUser[0].userId) {
            let params;
            let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
            params = { growthPlanId: this.growthPlanId, isShare: true, sharedTo: this.selUser[0].userId }
            this.velocityService.shareGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
               // console.log(data);
                if (data) {
                    if (data.result) {
                        let log = new LogAction();
                        log.action = this.logTypes.GROWTH_PLAN_SHARED;
                        log.growthPlanId = data.growthPlanId;
                        this.logService.logAction(log);

                        this.showButtonSection = true;
                        this.showShareSection = false;
                        this.getAllGPDetails();
                        this.removeMember();
                    } else {
                        console.log("API Error In Share");
                        // Handle Error Here if sharing fails
                    }
                }
            });
        }
        else {
            this.errorMessage = true;
            let inputPicker = document.getElementById('txtSearchInput');
            inputPicker.classList.add('red-border-class');
        }
    }

    unShreGP() {
        this.showUnShareSection = true;
        this.showButtonSection = false;
        this.showSharedDetails = false;
        this.errorMessageUnshare = false;
    }
    cancelUnshreGP() {
        this.showUnShareSection = false;
        this.showButtonSection = true;
        this.showSharedDetails = true;
        this.selectedMemberList = {};
        this.closeDialog();
    }

    unShareGrowthplan() {
        this.selectedItems = Object.keys(this.selectedMemberList).filter((item, index) => {
            return this.selectedMemberList[item];
        });
        if (this.selectedItems.length > 0) {
            let params;
            let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
            let selectedUserforUS = this.selectedItems.map(item => { return parseInt(item, 10); })
            params = { growthPlanId: this.growthPlanId, isUnshare: true, unshared: selectedUserforUS }
            this.velocityService.shareGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
                if (data) {
                    if (data.result) {
                        let log = new LogAction();
                        log.action = this.logTypes.GROWTH_PLAN_UNSHARED;
                        log.growthPlanId = data.growthPlanId;
                        this.logService.logAction(log);

                        this.showUnShareSection = false;
                        this.showSharedDetails = true;
                        this.showButtonSection = true;
                        this.selectedMemberList = {};
                        this.sharedListArr = [];
                        this.getAllGPDetails();
                    } else {
                        console.log("API Error In Share");
                        // Handle Error Here if sharing fails
                    }
                }
            });
        }
        else {
            this.errorMessageUnshare = true;
        }
    }


    reNameGp() {
        this.gpNameSectionEdit = true;
        this.gpTitle = this.growthPlanDetailsArr.growthPlanName;
        this.prevGpTitle = this.growthPlanDetailsArr.growthPlanName;
        this.errorMessageRename = false;
        this.errorGPEmpty = false;
    }
    cancelRenameGp() {
        this.errorMessageRename = false;
        this.errorGPEmpty = false;
        this.gpNameSectionEdit = false;
    }

    duplicateGP() {
        // When clicked duplicate  Button

        //let log = new LogAction();
        //log.action = this.logTypes.GROWTH_PLAN_DUPLICATED;
        //log.growthPlanId = data.growthPlanId;
        //this.logService.logAction(log);

        this.showGpDuplicate = true;
        this.showGpInfoDialog = false;



        if (this.IsFacilitator) {

            this.selfAssessment = false;
            this.facilitatedSession = true;
            this.isFacilitatedSession = true;
            this.errorGPEmptyDuplicate = false;
            this.errorMessageData = null;
            this.errorresponseMessageData = null;
            this.duplicateGrowthPlanName = "";
            this.meetingDate = this.getTodayDate();
            this.isFacSuccesswithImage = false;
            //this.IsFacilitator = true;
            this.existingmember = true;
            this.isExistingMember = true;
            this.facNonfaclabel = this.PageContents.lblSelectaMember;
        }
        else {

            this.isFacilitatedSession = false;
            this.selfAssessment = true;
            this.facilitatedSession = false;
            this.errorGPEmptyDuplicate = false;
            this.errorMessageData = null;
            this.errorresponseMessageData = null;
            this.duplicateGrowthPlanName = "";
            this.isFacSuccesswithImage = false;
            //this.IsFacilitator = false;
            this.facNonfaclabel = this.PageContents.lblFacilitatorCode;
        }

        this.checkForDuplicate();
    }
    cancelDuplicateGP() {
        if (this.typeId == 4) {
            this.closeDialog();
        } else {
            this.showGpInfoDialog = true;
            this.showGpDuplicate = false;
            this.getAllGPDetails();
            this.clearErrorMsg();
            this.clearDuplicateform();
        }        
    }

    clearDuplicateform()
    {
        this.removeFacilitator();
        this.isFacilitatedSession = false;
        this.selfAssessment = true;
        this.facilitatedSession = false;
        this.is_edit = false;
        this.errorMessageData = null;
        this.errorresponseMessageData = null;
        this.errorGPEmptyDuplicate = false;
        this.duplicateGrowthPlanName = "";
    }

    deleteGP() { 



        this.velocityService.getGPEligibleforDeletion(this.growthPlanId).subscribe(data => {
            if (data) {

                this.showGpInfoDialog = false;
                this.showGpDelete = true;
                this.errorMsgDelete = false;
            }
            else {
                this.showGpInfoDialog = false;
                //this.showGpDelete = true;
                this.errorMsgDelete = false;
                this.showGpalreadyNominated = true;
                //this.ModalDialogService.alert(this.PageContents.lblDelegate, this.PageContents.msgMaximumParticipant, true);
            }

        });
    }
    closeshowGpalreadyNominated() {

        this.showGpalreadyNominated = false;
        this.showGpInfoDialog = true;
        this.getAllGPDetails();
    }

    cancelDeleteGP() {
        if (this.typeId == 3) {
            this.closeDialog();
        }
        else {
            this.showGpDelete = false;
            this.showGpInfoDialog = true;
            this.getAllGPDetails();
        }
    }
    clearclientnameErrorMsg()
    {
        this.isNamerequired = false;
    }
    clearcompanyErrorMsg()
    {
        this.isCompanyrequired = false;
    }
    deleteGrowthPlan() {
        console.log("################## inside delete GP#############################");
        let params;
        let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
        params = { growthPlanId: this.growthPlanId}
        this.velocityService.deleteGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
            if (data === 1) {
                console.log("inside api success");
                this.setGrowthPlan();
                const dGP = { isDeleted: true }
                this.returnedValue = { ...this.returnedValue, ...dGP };

                let log = new LogAction();
                log.action = this.logTypes.GROWTH_PLAN_DELETED;
                log.growthPlanId = this.growthPlanId;
                this.logService.logAction(log);

                this.closeDialog();
                // Need to relaod current route 
            }
            else if (data === 2) {
                console.log("inside deleteGrowthPlan api fails");
                this.errorMsgDelete = true;
            }
            else {
                console.log("inside deleteGrowthPlan api fails for 0 ");
            }
        });
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    ngAfterViewInit() {

    }
    
    openDialog() {
        this.returnedValue = {};
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.f2fInfoDialogModal.config = this.config;
        this.f2fInfoDialogModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.f2fInfoDialogModal.hide();
        this.subject.next(this.returnedValue);
        //this.componentEvent.emit({ value: "closed" });
    }

    //getFacDetails(facDetails: any) {
    //    this.facDetails = facDetails;
    //    this.activeStep++;
    //}
    //getAttDetails(attDetails: any) {
    //    this.attendeeInfo = attDetails;
    //    this.activeStep++;
    //}
    //getSelDetails(selDetails: any) {
    //    this.selMember = selDetails;
    //    this.activeStep++;
    //}
    //getConfirmDetails(getConfirmDetails: any) {

   // }
    //back(value: any) {
    //    this.activeStep--;
    //}

    onKeyUPTitle() {
        if (this.gpTitle.trim() != '') {
            let params;
            let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
            params = { growthPlanId: this.growthPlanId, growthPlanName: this.gpTitle.trim() }
            this.velocityService.renameGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
                if (data) {
                    if (data.result) {
                        this.setRenamedGrowthPlan();
                        this.cancelRenameGp();
                        this.getAllGPDetails();
                    }
                    else {
                        if (data.errorCode === 3003) {
                            //Duplicate growth plan. The same growthplan already exists
                            this.errorMessageRename = true;
                        }
                        // Else scenaario Need to handle Here
                    }
                }
            });
        } else {
            this.errorGPEmpty = true;
        }
        //let log = new LogAction();
        //log.action = this.logTypes.GROWTH_PLAN_EDITED;
        //log.growthPlanId = data.growthPlanId;
        //this.logService.logAction(log);

    }

    validateAlphanumericName(planName) {
        let pattern = /^([a-zA-Z0-9 .\-'()[\]_]+)$/;
        if (pattern.test(planName)) { return true; } else { return false; }
    }
    validateEmailformat(clientEmail) {
        let pattern = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]*(\.([_a-zA-Z0-9-]{1,}))*\.([a-zA-Z]{2,4})$/;
        if (pattern.test(clientEmail)) { return true; } else { return false; }
    }
    public clearErrorMsg() {
        this.errorMessageData = null;
        this.errorGPEmptyDuplicate = false;
        let isValidGp = this.validateAlphanumericName(this.duplicateGrowthPlanName);
        if (isValidGp) {
            this.errorMessageData = null;
            this.formSubmission_SPECIALCHAR_IsValid = true;
        }
        else {
            if (this.duplicateGrowthPlanName.trim() != '') {
                this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                this.formSubmission_SPECIALCHAR_IsValid = false;
            }
            else {
                this.errorMessageData = null;
                this.formSubmission_SPECIALCHAR_IsValid = true;
            }
          
        }
    }

    duplicateGrowthPlanSubmission()
    {
        this.errorresponseMessageData = null;
        if (this.selfAssessment == true)
        {
            if (this.duplicateGrowthPlanName.trim() != '' && this.formSubmission_SPECIALCHAR_IsValid == true) {
                this.errorGPEmptyDuplicate = false;
                this.duplicate_meetingDate = "";
                if(this.IsFacilitator){
                    this.duplicate_growthPlanTypeId = 4;
                } else {
                    this.duplicate_growthPlanTypeId = 1;
                }
                this.duplicate_facilitatorCode = "";
                this.duplicate_facilitatorFlow = 1;
                this.duplicate_memberId = null;
                this.duplicate_clientName = "";
                this.duplicate_clientEmail = "";
                this.duplicate_companyName = "";
                this.duplicateGrowthPlan();
            }
            else {
                if (this.duplicateGrowthPlanName.trim() == '') {
                    this.formSubmission_GP_IsValid = false;
                    this.errorGPEmptyDuplicate = true;
                }
                else if (this.formSubmission_SPECIALCHAR_IsValid == true) {
                    this.errorGPEmptyDuplicate = true;
                    this.errorMessageData = null;
                }
                else {
                    this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                    this.errorGPEmptyDuplicate = false;
                }
                
            }
        }


        else {

            if (this.IsFacilitator == false) {

                if (this.duplicateGrowthPlanName.trim() != '') {
                    this.formSubmission_GP_IsValid = true;
                    this.errorGPEmptyDuplicate = false;
                }
                else {
                    if (this.duplicateGrowthPlanName.trim() == '') {
                        this.formSubmission_GP_IsValid = false;
                        this.errorGPEmptyDuplicate = true;
                    }
                    else if (this.formSubmission_SPECIALCHAR_IsValid == true) {
                        this.errorGPEmptyDuplicate = true;
                        this.formSubmission_GP_IsValid = false;
                        this.errorMessageData = null;
                    }
                    else {
                        this.errorGPEmptyDuplicate = false;
                        this.formSubmission_GP_IsValid = true;
                        this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                    }
                }

                if (this.cgpFacCode.trim() != '') {
                    this.formSubmission_FAC_IsValid = true;
                    this.cgpFacErrorSection = false;
                }
                else {
                    this.formSubmission_FAC_IsValid = false;
                    this.cgpFacErrorTxt = this.PageContents.EnterFacilitatorMessage;
                    this.cgpFacErrorSection = true;
                }



                if (this.delOwnerDetails.Id == 0 || this.delOwnerDetails.Id == undefined) {
                    if (this.cgpFacCode.trim() == '') {

                        this.formSubmission_FAC_IsValid = false;
                        this.cgpFacErrorTxt = this.PageContents.EnterFacilitatorMessage;
                        this.cgpFacErrorSection = true;

                    }
                    else {
                        this.formSubmission_FAC_IsValid = false;
                        this.cgpFacErrorTxt = this.PageContents.InvalidFacilitatorMessage;
                        this.cgpFacErrorSection = true;
                    }
                }
                else {
                    if (this.cgpFacCode.trim() == '') {
                        this.formSubmission_FAC_IsValid = false;
                        this.cgpFacErrorTxt = this.PageContents.EnterFacilitatorMessage;
                        this.cgpFacErrorSection = true;
                    }
                    else {
                        this.formSubmission_FAC_IsValid = true;
                        this.cgpFacErrorSection = false;
                    }
                }

                if (this.formSubmission_GP_IsValid == true && this.formSubmission_FAC_IsValid == true && this.formSubmission_SPECIALCHAR_IsValid == true) {
                    this.errorGPEmptyDuplicate = false;
                    this.cgpFacErrorSection = false;
                    this.duplicate_growthPlanTypeId = 2;
                    this.duplicate_facilitatorCode = this.delOwnerDetails.facilitatorCode;
                    this.duplicate_facilitatorFlow = 3;
                    this.duplicate_memberId = null;
                    this.duplicate_clientName = "";
                    this.duplicate_clientEmail = "";
                    this.duplicate_companyName = "";
                    this.duplicate_meetingDate = this.meetingDate.date.year + '-' + this.meetingDate.date.month + '-' + this.meetingDate.date.day;
                    this.duplicateGrowthPlan();

                }
            }

            else {
                        if (this.duplicateGrowthPlanName.trim() != '') {
                            this.formSubmission_GP_IsValid = true;
                            this.errorGPEmptyDuplicate = false;
                        }
                        else {
                            if (this.duplicateGrowthPlanName.trim() == '') {
                                this.formSubmission_GP_IsValid = false;
                                this.errorGPEmptyDuplicate = true;
                            }
                            else if (this.formSubmission_SPECIALCHAR_IsValid == true) {
                                this.errorGPEmptyDuplicate = true;
                                this.formSubmission_GP_IsValid = false;
                                this.errorMessageData = null;
                            }
                            else {
                                this.errorGPEmptyDuplicate = false;
                                this.formSubmission_GP_IsValid = true;
                                this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                            }
                        }

                        if (this.isExistingMember == true) {
                            if (this.profileselUser.length == 0) {
                                this.isUserequired = true;
                                this.PageContents.PeoplepickerError = true;
                                this.formSubmission_ExeMember_IsValid = false;
                            }
                            else {
                                this.isUserequired = false;
                                this.PageContents.PeoplepickerError = false;
                                this.formSubmission_ExeMember_IsValid = true;

                            }

                            if (this.formSubmission_GP_IsValid == true && this.formSubmission_ExeMember_IsValid == true) {
                                this.isUserequired = false;
                                this.PageContents.PeoplepickerError = false;
                                this.errorGPEmptyDuplicate = false;
                                this.duplicate_growthPlanTypeId = 2;
                                this.duplicate_facilitatorCode = "";
                                this.duplicate_facilitatorFlow = 2;
                                this.duplicate_memberId = this.profileselUser[0].userId;
                                this.duplicate_clientName = "";
                                this.duplicate_clientEmail = "";
                                this.duplicate_companyName = "";
                                this.duplicate_meetingDate = this.meetingDate.date.year + '-' + this.meetingDate.date.month + '-' + this.meetingDate.date.day;
                                this.duplicateGrowthPlan();
                            }

                        }
                        else {
                            if (this.clientName.trim() == '')
                            {
                                this.formSubmission_ClientName_IsValid = false;
                                this.isNamerequired = true;
                            }
                            else {
                                this.formSubmission_ClientName_IsValid = true;
                                this.isNamerequired = false;
                            }

                            if (this.companyName.trim() == '') {
                                this.formSubmission_ClientCompany_IsValid = false;
                                this.isCompanyrequired = true;
                            }
                            else {
                                this.formSubmission_ClientCompany_IsValid = true;
                                this.isCompanyrequired = false;
                            }
                            if (this.clientEmail.trim() == '')
                            {
                                this.formSubmission_ClientEmail_IsValid = false;
                                this.isExistingEmail = true;
                                this.emailValidationError = this.PageContents.msgClientEmailRequired;
                            }
                            else {
                                if (this.emailValErrorAlreadyexist == true)
                                {
                                    this.isExistingEmail = true;
                                    this.emailValidationError = this.PageContents.msgExistingmember;
                                }
                                else {
                                    this.isExistingEmail = false;
                                    this.emailValidationError = "";
                                    this.formSubmission_ClientEmail_IsValid = true;
                                }
                                if (this.emailValErrorFormat == true)
                                {
                                    this.isExistingEmail = true;
                                    this.emailValidationError = this.PageContents.msgClientEmailValid;
                                }
                                else {
                                    if (this.emailValErrorAlreadyexist == true) {
                                        this.isExistingEmail = true;
                                        this.emailValidationError = this.PageContents.msgExistingmember;
                                    }
                                    else {
                                        this.isExistingEmail = false;
                                        this.emailValidationError = "";
                                    }
                                }
                            }
                            if (this.formSubmission_GP_IsValid == true && this.formSubmission_ClientName_IsValid == true && this.formSubmission_ClientCompany_IsValid == true && this.formSubmission_ClientEmail_IsValid == true && this.emailValErrorFormat == false && this.emailValErrorAlreadyexist == false) {
                                this.isExistingEmail = false;
                                this.isNamerequired = false;
                                this.isCompanyrequired = false;
                                this.emailValidationError = "";
                                this.errorGPEmptyDuplicate = false;
                                this.duplicate_growthPlanTypeId = 2;
                                this.duplicate_facilitatorCode = "";
                                this.duplicate_facilitatorFlow = 2;
                                this.duplicate_memberId = null;
                                this.duplicate_clientName = this.clientName.trim();
                                this.duplicate_clientEmail = this.clientEmail.trim();
                                this.duplicate_companyName = this.companyName.trim();
                                this.duplicate_meetingDate = this.meetingDate.date.year + '-' + this.meetingDate.date.month + '-' + this.meetingDate.date.day;
                                this.duplicateGrowthPlan();
                            }

                        }
                        
            }
        }


    }
    public duplicateGrowthPlan() {
        
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("f2finfoduplicategrowthplan");

            let member = { "memberId": this.duplicate_memberId };
            let nonMember = { "clientName": this.duplicate_clientName, "clientEmail": this.duplicate_clientEmail, "companyName": this.duplicate_companyName}
            this.faceToFaceService.duplicateGrowthPlan(this.growthPlan, this.duplicateGrowthPlanName.trim(), this.duplicate_growthPlanTypeId, this.duplicate_facilitatorCode, this.duplicate_meetingDate, this.duplicate_facilitatorFlow, member, nonMember, updateProgressBar).subscribe((response: DuplicateGrowthPlanData) => {
                if (response.growthPlanId) {
                    this.returnedValue['growthPlanId'] = response.growthPlanId;
                    const dGP = { isDuplicated: true }
                    this.returnedValue = { ...this.returnedValue, ...dGP };
                    this.closeDialog();
                } else {
                    if (response.errorCode === '80001') {
                        this.errorresponseMessageData = this.PageContents.messageDuplicategrowthPlan;
                    } else {
                        this.errorresponseMessageData = response.message;
                    }
                    setTimeout(() => {
                    const element = document.getElementById('errorresponseMessageDataID');
                    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }, 100);
                }
            });
    }

    private setRenamedGrowthPlan() {
        if (this.growthPlanId === this.currentGrowthPlan.growthPlanId) {
            this.currentGrowthPlan.growthPlanName = this.gpTitle;
            this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, this.currentGrowthPlan);
            this.dataShareService.activePlan.next(true);
        }
    }

    private setGrowthPlan() {
        if (this.growthPlanId === this.currentGrowthPlan.growthPlanId) {   
            this.storageHelper.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
            this.faceToFaceService.getSessionGrowthPlanData().subscribe();           
        }
    }

    scrollValue(value:any) {
        this.scrollPos = value;
    }
    clearError() {
        this.errorGPEmpty = false;
        this.errorMessageRename = false;
    }

    checkForDuplicate() {
        this.isLoading = true;
        this.velocityService.getAxisValuesForGrowthPlan(this.growthPlanId                                                                                                          ).subscribe(
            (data: any) => {
                if (data) {
                    this.hasAxisDifference = data.isAxisValueUpdated;
                    this.axesDifferenceModalContent = this.otherContents.lblDuplicateGPAxisWarning
                                                        .replace("#Risk", data.xAxisLabel)
                                                        .replace("#Impact", data.yAxisLabel);
                    this.isLoading = false;
                }
            }
        );
    }
}


