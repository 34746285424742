import { NgModule } from '@angular/core';
import { Replace } from "./replaceText.pipe";
import { SortPipe } from "./sortBy.pipe";
import { SelectFilterPipe } from "./select-filter.pipe";
import { currencyFormatter } from "./currency-formatter.pipe";
import { dateFormatter } from './date-formatter.pipe'
import { CountFormatter } from './count-formatter.pipe';
import { DatexPipe } from './custom-date.pipe';
import { DatexOnlyPipe } from './custom-date-only.pipe';
import { Ellipsis } from "./ellipsis.pipe";
import { amountFormatter } from "./amount-formatter.pipe";

@NgModule({
    imports: [],
    declarations: [
        Replace, 
        SortPipe,
        SelectFilterPipe,
        currencyFormatter,
        dateFormatter,
        CountFormatter,
        DatexPipe,
        Ellipsis,
        DatexOnlyPipe,
        amountFormatter
    ],
    exports: [
        Replace, 
        SortPipe,
        SelectFilterPipe,
        currencyFormatter,
        dateFormatter,
        CountFormatter,
        DatexPipe,
        Ellipsis,
        DatexOnlyPipe,
        amountFormatter
    ]
})
export class PipeModule { }