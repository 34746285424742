import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'replace' })
export class Replace implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: string, hashTags: string[], newValue: string[]) {
        //return value.replace(hashTags, newValue);
        if (hashTags.length !== newValue.length) {
            return value;
        }
        else {
            for (let index in hashTags) {
                value = value.replace(hashTags[index], newValue[index]);
            }
            return value;
        }
    }
} 