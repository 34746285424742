export enum TemplateTypes {
    DEFAULT = 1,
    ASSESSMENT_COMPLETED = 2,
    SIMPLE = 3,
    //MATURITY_LEVEL = 4,
    INSIGHT_POPUP=5,
    SUB_DRIVER_ASSESSMENT_COMPLETED = 6,
    THEME_ASSESSMENT_COMPLETED = 7,
    COPYRIGHT_POPUP=8,
    NEED_HELP_POPUP = 9,
    GROWTH_PLAN_NO_ACTION = 10,
    IMAGE_VIDEO_POPUP = 11,
    COMMENT_NOTIFICATION = 12
}