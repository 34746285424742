export class SaveRagCost {
    constructor(
        public userId: number,
        public growthPlanActionId: number,
        public hasRag: boolean,
        public rag: string,
        public estCost: number,
        public hasCost: boolean
    ) { }
}
