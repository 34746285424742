import { Component, OnInit, Input, Output,EventEmitter, ElementRef, ViewChild,AfterViewInit, Renderer2, SimpleChange } from '@angular/core';
import { VelocityService } from '../../service/velocity.service';
import { SaveRagCost } from '../../service/model/save-rag-cost';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { IMyDpOptions } from 'mydatepicker';



@Component({
    selector: 'action-rag',
  templateUrl: './action-rag.component.html',
  styleUrls: ['./action-rag.component.scss'],
})

export class ActionRagComponent implements OnInit, AfterViewInit {
    
    @Input("ragValue") ragValue: any;
    @Input("appContent") appContent: any;
    @Input("estimatedCost") estimatedCost: any;
    @Input("owner") owner: any;
    @Input("startDate") startDate: any;
    @Input("completionDate") completionDate: any;
    @Input("fromCard") fromCard: string;
    @Input("isGrowthPlanLocked") isGrowthPlanLocked: boolean;
    @Input("currencySymbol") currencySymbol: string = "$";
    @Input("statusSettings") statusSettings: any = [];
    @Input("isCorporate") isCorporate: boolean = false;
    @Output("close") close: any = new EventEmitter<boolean>();
    @Output("changeRag") changeRagValue: any = new EventEmitter<string>();
    
    hasCost = false;
    oRagStatus: string;
    oValue: string;
    costContainer: string = '';
    colorContainer: string = '';


    public myDatePickerOptionsStart: IMyDpOptions = {
    // other options...
    dateFormat: 'dd mmm yyyy',
     editableDateField: false,
    showSelectorArrow: false,
    openSelectorTopOfInput: false,
     openSelectorOnInputClick: true,
     showTodayBtn: false,
     showClearDateBtn: true
    //  disableUntil: this.getYesterday()
  };
    public myDatePickerOptionsComplete: IMyDpOptions = {
    // other options...
    dateFormat: 'dd mmm yyyy',
     editableDateField: false,
    showSelectorArrow: false,
    openSelectorTopOfInput: false,
      openSelectorOnInputClick: true,
     showTodayBtn: false,
     showClearDateBtn: true
    //  disableUntil: this.getYesterday()
  };
    public myDatePickerOptionsCompleteRight: IMyDpOptions = {
    // other options...
    dateFormat: 'dd mmm yyyy',
    editableDateField: false,
    showSelectorArrow: false,
    openSelectorTopOfInput: false,
     openSelectorOnInputClick: true,
     showTodayBtn: false,
     showClearDateBtn: true,
    alignSelectorRight: true
    //  disableUntil: this.getYesterday()
  };
 // Initialize selector state to false
    //private selectorStart: IMySelector = {
    //    open: false
    //};
    //private selectorCom: IMySelector = {
    //    open: false
    //};
    invalidStartDate: boolean = false;
    invalidCompletionDate: boolean = false;

    @ViewChild('textStatus', {'static':false}) textStatus: ElementRef;
    @ViewChild('cost', { 'static': false }) cost: ElementRef;
    @ViewChild('start', { 'static': false }) start: ElementRef;
    @ViewChild('end', {'static':false}) end: ElementRef;
    @ViewChild('startContainer', { 'static': true }) startContainer: ElementRef;
    @ViewChild('endContainer', { 'static': true }) endContainer: ElementRef;
    calledOnce = false;
    settingLabel1: string = '';
    settingLabel2: string = '';
    constructor(
        private velocityService: VelocityService,
        private logService: LogService,
        private logTypes: LogTypes,
        private elementRef:ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.oRagStatus = this.ragValue.status;
        if(this.estimatedCost)
            this.oValue = this.estimatedCost;
        else{
            this.oValue = '';
            this.estimatedCost ='';
        }

        if (this.startDate && !this.completionDate) {
            let copy = this.getCopyOfOptions('start');
            let receivedDate = new Date(this.getFormattedDate(this.startDate));
            copy.disableUntil = this.getDisableDate('start', receivedDate);
            this.myDatePickerOptionsComplete = copy;
        } else if (!this.startDate && this.completionDate){
            let copy = this.getCopyOfOptions('complted');
            let receivedDate = new Date(this.getFormattedDate(this.completionDate));
            copy.disableSince = this.getDisableDate('complted', receivedDate);
            this.myDatePickerOptionsStart = copy;
        }

        let result = document.getElementsByClassName("participant-area");
        //document.getElementById('actCardListCont').offsetHeight;
        if (result.length) {
            this.costContainer = 'cost-container';
            this.colorContainer = 'rag-container';
        }

    }

    ngAfterViewInit() {
        this.adjustFields();
    }

    init(){
        if(!this.calledOnce) {
            setTimeout(() => {
                this.adjustFields();
                this.calledOnce = true;
            },100);
        }
    }
    getAmount(cost) {
        return parseInt(cost);
    }
    adjustFields() {
        setTimeout(() => {
            if (this.textStatus != undefined && this.textStatus.nativeElement.clientWidth < this.textStatus.nativeElement.scrollWidth) {
                this.cost.nativeElement.classList.add('col-12');
                this.cost.nativeElement.classList.remove('col-6');
            }
            console.log([this.end.nativeElement.clientWidth ,this.end.nativeElement.scrollWidth]);
            if(this.start.nativeElement.clientWidth < this.start.nativeElement.scrollWidth || this.end.nativeElement.clientWidth < this.end.nativeElement.scrollWidth) {
                this.startContainer.nativeElement.classList.add('col-12');
                this.startContainer.nativeElement.classList.remove('col-6');
                this.endContainer.nativeElement.classList.add('col-12');
                this.endContainer.nativeElement.classList.remove('col-6');
                this.endContainer.nativeElement.classList.remove('p-0');
                this.endContainer.nativeElement.classList.add('pr-0');
            }
        });
    }  

    ngOnChanges(change:SimpleChange) {
        console.log(change);
        console.log(change['fromCard']);
        if(change['fromCard'] != undefined && !change['fromCard']['currentValue']) {
            console.log('here');
            this.adjustFields();
        }

        if (change['appContent'] != undefined && change['appContent']['currentValue'] != undefined && change['appContent']['currentValue']['lblSettingDescription'] != undefined) {
            let subtitle = this.appContent.lblSettingDescription.split('#Setting');
            this.settingLabel1 = subtitle.length > 1 ? subtitle[0] : '';
            this.settingLabel2 = subtitle.length > 1 ? subtitle[1] : '';
        }
    }
   
    changeRag(value: string) {

        if (this.ragValue.status == value) {
            value = "";
        }
        //if (!this.fromCard) {
            let saveRag = new SaveRagCost(0, this.ragValue.growthActionId, true, value, 0, false);
            this.velocityService.saveActionRagEstimate(saveRag).subscribe(
                (data: any) => {
                    if (data && this.fromCard) {

                        this.oRagStatus = this.ragValue.status;
                        this.oValue = this.estimatedCost;
                        this.logCompletedCardEvent(this.ragValue.growthActionId);
                        this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
                    }
                    else {
                        //this.changeRagValue.emit(value);
                        this.oRagStatus = this.ragValue.status;
                        this.oValue = this.estimatedCost;
                        this.logCompletedCardEvent(this.ragValue.growthActionId);
                        this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
                    }
                });
        //}
            this.ragValue.status = value;
    } 

    //saveRag() {
    //    if (this.fromCard) {
    //        this.hasCost =  true;
    //    } else {
    //        this.hasCost = false;
    //    }
        
    //    let saveRag = new SaveRagCost(0, this.ragValue.growthActionId, true, this.ragValue.status, this.estimatedCost, this.hasCost);
    //    this.velocityService.saveActionRagEstimate(saveRag).subscribe(
    //        (data: any) => {
    //            if (data && this.fromCard) {

    //                this.oRagStatus = this.ragValue.status;
    //                this.oValue = this.estimatedCost;
    //                this.logCompletedCardEvent(this.ragValue.growthActionId);
    //                this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
    //            }
    //            else {
    //                this.oRagStatus = this.ragValue.status;
    //                this.oValue = this.estimatedCost;
    //                this.logCompletedCardEvent(this.ragValue.growthActionId);
    //                this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
    //            }
    //        });

    //}

    setOwner() {
        let actionProperty = { growthPlanActionId: this.ragValue.growthActionId, propertyName: 'Owner', propertyValue: this.owner };
        this.velocityService.saveActionProperty(actionProperty).subscribe(
            (data: any) => {
                this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
            });
    }

    setActionCost() {
        let estCost = this.estimatedCost;
        if (this.estimatedCost === null || this.estimatedCost == '') {
            estCost = 0;
        }
        let actionProperty = { growthPlanActionId: this.ragValue.growthActionId, propertyName: 'Cost', propertyValue: estCost };
        this.velocityService.saveActionProperty(actionProperty).subscribe(
            (data: any) => {
                    this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });           
            });
    }
    
    getFormattedDate(selDate) {
        var formattedDate = '';
        if (selDate) {
            formattedDate = selDate.date.year + '/' + selDate.date.month + '/' + selDate.date.day;

        }
        return formattedDate;
    }

    getDisableDate(type: string, selDate: any) {
        let disableDate = new Date();
        disableDate = selDate;

        if (type == 'start') {
            disableDate.setDate(disableDate.getDate() - 1);
        } else {
            disableDate.setDate(disableDate.getDate() + 1);
        }
        return {
            year: disableDate.getFullYear(), month: disableDate.getMonth() + 1, day: disableDate.getDate()
        }
    }

    getCopyOfOptions(type: string): IMyDpOptions {
        if (type != 'start') {
            return JSON.parse(JSON.stringify(this.myDatePickerOptionsStart));
        }
        return JSON.parse(JSON.stringify(this.myDatePickerOptionsComplete));

    }
    calendarOpen(event,isStart){
        if(event == 1){
            //if(isStart){
            //    this.selectorCom = {
            //            open: false
            //        };
            //}else{
            //    this.selectorStart = {
            //                open: false
            //            };
            //    }
        }
    }
    onDateChanged(selectedDate: any, type: string, compareDate: any, from: string) { 
        var completionDate;
        var startDate;

        this.invalidStartDate = false;
        this.invalidCompletionDate = false;
        let copy = this.getCopyOfOptions(type);

       //  if (selectedDate.jsdate) {
        if (type == 'start') {
            completionDate = new Date(this.getFormattedDate(compareDate));
            startDate = new Date(this.getFormattedDate(selectedDate));

            copy.disableUntil = this.getDisableDate(type, startDate);
            this.myDatePickerOptionsComplete = copy;

            if (startDate != 'Invalid Date' && completionDate != 'Invalid Date')
            {
                if (startDate > completionDate) {
                    this.invalidStartDate = true;
                    return;
                }
            }
        }
        else if (type == 'completed') {
            completionDate = new Date(this.getFormattedDate(selectedDate));
            startDate = new Date(this.getFormattedDate(compareDate));
            copy.disableSince = this.getDisableDate(type, completionDate);
            this.myDatePickerOptionsStart = copy;
            if (startDate != 'Invalid Date' && completionDate != 'Invalid Date')
            {
                if (startDate > completionDate) {
                    console.log(completionDate);
                    console.log(startDate);
                    this.invalidCompletionDate = true;
                    return;
                }
            }
        }

        let actionProperty = { growthPlanActionId: this.ragValue.growthActionId, propertyName: type == 'completed' ? 'CompletionDate' : 'StartDate', propertyValue: this.getFormattedDate(selectedDate)};
        this.velocityService.saveActionProperty(actionProperty).subscribe(
            (data: any) => {
                this.close.emit({ ragStatus: this.ragValue.status, cost: this.estimatedCost, owner: this.owner, startDate: this.startDate, completionDate: this.completionDate });
            });
    }

    clear() {
        this.estimatedCost = this.oValue;
        this.ragValue.status = this.oRagStatus;
    } 

    logCompletedCardEvent(growthPlanId: number) {
        let log = new LogAction();
        log.action = this.logTypes.COMPLETED_CARD;
        log.growthPlanId = growthPlanId;
        this.logService.logAction(log);
    }

    numberOnly(event) {
        var key = window.event ? event.keyCode : event.which;
        if (event.keyCode == 8 || event.keyCode == 3 || event.charCode == 0) {
            return true;
        }
        else if (key < 48 || key > 57) {
            return false;
        }
        else if ((key >= 48 || key <= 57) && this.estimatedCost > 999999999) {
            return false;
        }
        else return true;
    }

}
