import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
//import { ModalModule, Modal } from 'ng2-modal';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
    //moduleId: module.id.toString(),
    selector: "terms-dialog",
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})

export class TermsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    countryId: number;
    countryTermsAndCondition: string = "";
    PageContents: any = {};
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    modalForm: FormGroup;
    isAcceptDisabled: boolean = true;
    data: any;
    titleText: string = "";
    scrollText: string = "";
    height = 0;
    fromPage = '';
    policyId = '';
    updatedDate = '';
    @Output("closeEvent") componentEvent: any = new EventEmitter();
  @ViewChild('container', { static: true }) container: ElementRef;

    constructor(
        private injector: Injector,
        private mScrollbarService: MalihuScrollbarService,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocity: VelocityService) {
        this.fromPage = this.injector.get('from');
        this.countryId = this.injector.get('countryId');
        this.getCountryTermsAndCondition();
    }

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

  @ViewChild('termsModal', { static: true }) termsModal: any;
    returnedValue: any = {};


    ngOnInit() {
        this.utilService.getContent(CONTENTS.TERMS_AND_CONDITIONS).subscribe(
            (data: any) => {
                this.PageContents = data;
                this.titleText = this.PageContents.titleTermsConditions;
                this.scrollText = this.PageContents.lblScrollText
            }
        );

    }

    getCountryTermsAndCondition() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("termsAndCondition");
        if (this.countryId) {
            this.velocity.getCountryTermsAndCondition(this.countryId, updateProgressBar).subscribe(
                (data: any) => {
                    if (data) {
                        this.countryTermsAndCondition = data.content;
                        this.updatedDate = data.dateUpdated;
                        this.policyId = data.policyVersionId;
                    }
                }
            );
        } else {
            this.velocity.getUserTermsAndCondition(updateProgressBar).subscribe(
                (data) => {
                    if (data) {
                        this.countryTermsAndCondition = data.content;
                        this.updatedDate = data.dateUpdated;
                        this.policyId = data.policyVersionId;
                    }
                }
            )
        }
    }

    ngAfterViewInit() {
        const vm = this;

        this.mScrollbarService.initScrollbar('#terms-condition', {
            axis: 'y', theme: 'minimal-dark',
            callbacks: {
                onTotalScroll: () => {
                    this.updateAccpet();
                },
                onOverflowYNone: () => {
                    this.isAcceptDisabled = false;
                },
                onOverflowY: () => {
                    this.isAcceptDisabled = true;

                }
            }
        });
    }

    ngOnDestroy() {
        this.mScrollbarService.destroy('#terms-condition');
    }

    updateAccpet() {
        this.isAcceptDisabled = false;
        this.change.detectChanges();
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        if (this.fromPage != 'register') {
            let params = {
                'userId': -1,
                'policyVersionId': this.policyId
            }
            this.velocity.acceptTC(params).subscribe(
                (data) => {
                    if (data) {
                        this.returnedValue.action = 'yes';
                        this.closeDialog();
                    }
                }
            )
        } else {
            this.returnedValue.action = 'yes';
            this.closeDialog();
        }
    
    }

    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.termsModal.config = this.config;
        this.termsModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.termsModal.hide();
    }

    scrollPage() {
        this.height = this.container.nativeElement.offsetHeight - 45;
        this.mScrollbarService.scrollTo('#terms-condition', "-="+this.height, { scrollInertia: 1000 });
    }
}
