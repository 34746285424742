import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AppComponent } from './app.component';
import { ComponentModule } from './component/component.module';
import { ServiceModule } from './service/service.module';
import { DirectiveModule } from './directive/directive.module';
import { AppRouteModule } from './app.route';
import { RouterModule } from '@angular/router';

import { ModalDialogModule } from './modal-dialog-module/modal-dialog.module';
import { DynamicModalContainerComponent } from './modal-dialog-module/dynamic-modal-loader.component';
import { ModalDialogService } from './modal-dialog-module/modal-dialog.service';

import { ProgressBarModule } from './progress-bar-module/progress-bar.module';
import { ProgressBarService } from './progress-bar-module/progres-bar.service';

import { UserService } from './auth/service/user.service';

import { AuthenticationService } from './auth/service/auth.service';
import { AuthGuard } from './auth/guard/auth.guard';
import { HTTPService } from './service/http.service';
import { UtilService } from './service/util.service';
import { VelocityService } from './service/velocity.service';
import { GrowthService } from './service/growth.service';
import { DataShareService } from './service/data-share.service';
import { ContentResolve } from './service/content.resolve';
import { Constants } from './service/constant.service';
import { AlertService } from './service/alert.service';
import { SubMenuService } from './service/sub-menu.service';
import { StartupService } from './service/startup.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NotificationComponent } from './notification/notification.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CanDeactivateGuard } from './auth/guard/can-deactivate.guard';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';

//Code to load initial data - exporting as a function to remove AOT dependency
export function StartupDataLoader(startupService: StartupService) {
    //Note: this factory need to return a function (that return a promise)

    return () => startupService.loadInitialData();
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        DynamicModalContainerComponent,
        NotificationComponent
    ],
    imports: [
      HttpClientModule,
        DirectiveModule,
        ModalDialogModule,
        ServiceModule,
        ProgressBarModule,
        ComponentModule,
        BrowserModule,
        AppRouteModule,
        TooltipModule.forRoot(),
        BrowserAnimationsModule,
      AngularMultiSelectModule,
      CommonModule,
      FormsModule,
      RouterModule,
      AuthModule.forRoot({
        domain: environment.DOMAIN,
        clientId: environment.CLIENT_ID_AUTH0,
        redirect_uri: window.location.origin,
        cacheLocation: 'localstorage',
        useRefreshTokens: true
      })
       ],
    providers: [

        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: StartupDataLoader,
            deps: [StartupService],
            multi: true
      },
        ProgressBarService,        
        CanDeactivateGuard        
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    

    constructor(
    //    public oidcSecurityService: OidcSecurityService, private constants: Constants
    ) {
        //Set up Open ID Connect 
        //if (this.constants.USE_LINKEDIN_LOGIN) {
        //    this.setUpOIDC();
        //}
    }

}
