import { Component, Input, ViewChild, Injector, Output, EventEmitter, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { GrowthPlanTypeListDto, CreateGrowthPlanDto } from '../../focus/model/face-to-face.model';
import { UserService } from '../../auth/service/user.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
import { VelocityService } from '../../service/velocity.service';
import { LogTypes, LogAction } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';

@Component({
    //moduleId: module.id.toString(),
    selector: "create-new-growth-plan",
  templateUrl: './create-new-growth-plan.component.html',
  styleUrls: ['./create-new-growth-plan.component.scss']
})

export class CreateNewGrowthPlanComponent  {
    observable: Observable<any>;
    subject: any;   
  @ViewChild('createNewGrowthPlanModal', { static: true }) createNewGrowthPlanModal: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    returnedValue: number = 0;
    PageContents: any = {};
    public config = {
        animated: true,
        keyboard: false,
        backdrop: false,
        ignoreBackdropClick: true
    };
    growthPlan: CreateGrowthPlanDto = new CreateGrowthPlanDto(); 
    growthPlanType: any;  
    growthPlanTypeList: GrowthPlanTypeListDto[] = [];
    user: any = {};
    errorMessage: string = null;
    invalidErrorMessage: boolean = false;
    errorEmptyGP: boolean = false;
    isFacilitator: boolean;
    //isCorporateUser: boolean = false;
    subTitle1: string = '';
    subTitle2: string = '';
    selectByDefault: any = '';

    constructor(
        private injector: Injector,
        private userService: UserService,
        private velocityService: VelocityService,
        private logTypes: LogTypes,
        private logService: LogService
    ) {    
        this.PageContents = this.injector.get('PageContents');
        this.selectByDefault = this.injector.get('selectByDefault');
    }
    ngOnInit() {             
        this.setGrowthPlanTypeBasedOnRole();
        this.user = this.userService.getCurrentUser();
    }


    setGrowthPlanTypeBasedOnRole()
    {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("createNewGrowthPlan");
        this.velocityService.checkIsFacilitator(updateProgressBar).subscribe((data) => {
            this.isFacilitator = data.IsFacilitator;
            //this.isCorporateUser = data.IsCorporateFacilitator;
            if (this.isFacilitator) {
                this.growthPlanType = 2; // Facilitator growth plan type
            }
            else {
                this.growthPlanType = 1; // Self growth plan type
            }

            if (this.selectByDefault != '') {
                this.growthPlanType = this.selectByDefault;
            }
            this.setGrowthPlanType();
        }); 
    }

    onModalClosed($event: any) {
        //this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }

    openDialog() {
        this.returnedValue = null;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.createNewGrowthPlanModal.config = this.config;
        this.createNewGrowthPlanModal.show();
        return this.observable;
    }

    private closeDialog() {        
        this.createNewGrowthPlanModal.hide();   
        this.componentEvent.emit({ value: "closed" });
        this.subject.next(this.returnedValue);        
    }

    public closeBtn() {     
        this.closeDialog();       
    }

    public createGrowthPlan() {
        if (this.growthPlanValidation()) {
            this.growthPlan.userId = this.user.userId;
            this.growthPlan.growthPlanType = this.growthPlanType;
            this.growthPlan.facilitatorId = 0;
            this.growthPlan.facilitatorCode = null;
            this.growthPlan.member = null;
            this.growthPlan.nonMember = null;
            this.growthPlan.meetingDate = null;
            this.growthPlan.clientAttendees = null;
            this.growthPlan.eyAttendees = null; 
            this.growthPlan.growthPlanName = this.growthPlan.growthPlanName.trim();
            this.saveGrowthPlan(this.growthPlan);
        } else {
            this.errorEmptyGP = true;
        }       
    }

    private setGrowthPlanType() {
        if(this.isFacilitator) 
        this.growthPlanTypeList.push(new GrowthPlanTypeListDto(2, this.PageContents.lblFacilitatedSession));
        if(this.isFacilitator) {
            this.growthPlanTypeList.push(new GrowthPlanTypeListDto(4, this.PageContents.lblDSSelectionLabel));
        } else  {
            this.growthPlanTypeList.push(new GrowthPlanTypeListDto(1, this.PageContents.lblSelfSession));
        }

        if (this.isFacilitator) {
            this.growthPlanTypeList.push(new GrowthPlanTypeListDto(5, this.PageContents.lblCorporateGroupSession));
            //6 is used for ui use but internally it is 5 only
            this.growthPlanTypeList.push(new GrowthPlanTypeListDto(6, this.PageContents.lblDemoLeadershipTeamMeetingTitle));
            //new leadership team meeting for Facilitator
        } else {
            this.growthPlanTypeList.push(new GrowthPlanTypeListDto(3, this.PageContents.lblCorporateGroupSession));
        }

        //To get the subtitle
        let subtitle = this.PageContents.lblPremiumFeature.split('#support');
        this.subTitle1 = subtitle.length > 1 ? subtitle[0] : '';
        this.subTitle2 = subtitle.length > 1 ? subtitle[1] : ''

    }

    public growthPlanTypeChange(id: number) {       
        this.growthPlanType = id;
    }

    public startFacilitated() {
        this.returnedValue = this.growthPlanType;
        this.closeDialog();

    }

    private growthPlanValidation() {
        if (!this.growthPlan.growthPlanName.trim()) {
            return false;
        }
        return true;
    }

    private saveGrowthPlan(data: CreateGrowthPlanDto) {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("createNewGrowthPlan");
        this.velocityService.saveGrowthPlan(data, updateProgressBar).subscribe((data: any) => {         
            if (data.growthPlanId) {
                let log = new LogAction();
                log.action = this.logTypes.GROWTH_PLAN_CREATED;
                log.growthPlanId = data.growthPlanId;
                this.logService.logAction(log);
                this.returnedValue = this.growthPlanType;//Instead return {"growthPlanType":1, "growthPlanId":data.growthPlanId }
                this.closeDialog();
            } else {                
                if (data.errorCode === '3003') {
                    this.errorMessage = this.PageContents.lblPlanAlreadyExist;
                } else {
                    this.errorMessage = data.message;
                }
                
            }
        });
    }

    public clearErrorMsg() {  
        this.errorMessage = null;
        this.errorEmptyGP = false;
    }

    onKeyUp(event: any) {
        this.errorEmptyGP = false;
        this.errorMessage = null;
        let isValidGp = this.validateAlphanumericName(this.growthPlan.growthPlanName);
        if (isValidGp) {
            this.invalidErrorMessage = false;
        }
        else {
            if(this.growthPlan.growthPlanName.trim() != "")
            {
            this.invalidErrorMessage = true;
            }
            else{
                this.invalidErrorMessage = false;
            }
        }
    }

    validateAlphanumericName(planName) {
        let pattern = /^([a-zA-Z0-9 .\-'()[\]_]+)$/;
        if (pattern.test(planName)) { return true; } else { return false; }
    }
   
}
