import { NgModule } from '@angular/core';

import { AuthenticationService } from '../auth/service/auth.service';
import { UserService } from '../auth/service/user.service';
import { AuthGuard } from '../auth/guard/auth.guard';
import { HTTPService } from './http.service';
import { UtilService } from './util.service';
import { VelocityService } from './velocity.service';
import { GrowthService } from './growth.service';
import { DataShareService } from './data-share.service';
import { ContentResolve } from './content.resolve';
import { Constants } from './constant.service';
import { AlertService } from './alert.service';
import { SubMenuService } from './sub-menu.service';
import { StorageHelperService } from './storage-helper.service';
import { LookUpService } from './look-up.service';
import { ConsiderationService } from './consideration.service';
import { NotificationService } from './notification.service';
import { Broadcaster } from '../auth/service/auth-broadcast';
import { LogService } from "./log.service";
import { LogTypes } from "./model/log-models";
import { TimerService } from './timer.service';
import { BroadcastService } from './broadcast.service';
import { FaceToFaceService } from './face-to-face.service';
import { GrowthPlanResolve } from './growth-plan.resolve';
import { ModalDialogModule } from '../modal-dialog-module/modal-dialog.module';

@NgModule({
  declarations: [],
  imports: [ModalDialogModule],
providers: [
        AuthGuard,
        AuthenticationService,
        HTTPService,
        UserService,
        UtilService,
        VelocityService,
        GrowthService,
        DataShareService,
    ContentResolve,
    GrowthPlanResolve,
        Constants,
        AlertService,
        SubMenuService,
        StorageHelperService,
        LookUpService,
        ConsiderationService,
        NotificationService,
        Broadcaster,
        LogService,
        LogTypes,
        TimerService,
        BroadcastService,
        FaceToFaceService
  ]
})
export class ServiceModule { }
