<div class="modal fade insight-modal" bsModal #insightModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" #insightsList eyv-update-panel="insight-list">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png">{{SiteContents.lblClose}}
                </div>
                <div>
                    <h2>{{SiteContents.lblInsights}}</h2>
                    <div class="insight-container" [style.height]="height" custom-scroll *ngIf="isDriver">
                        <div class="card-list insight-list">
                            <div class="row">
                                <div class="col-sm-6" style="margin-bottom:15px" *ngFor="let insight of insights">
                                    <div class="insight-card">
                                        <div class="i_card-body">
                                            <p [innerHTML]="insight.bodyFormatted"></p>
                                        </div>
                                        <div class="i_card-footer">
                                            <button class="btn-card-clear" (click)="exploreInsight(insight.id)">
                                                <div class="text">{{SiteContents.buttonExploreInsight}}</div>
                                                <div class="icon-container">
                                                    <img src="../../../assets/images/arrow-icon2.png" />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="theme" *ngIf="!isDriver">
                        <div class="subThemeHead">{{PageContents.lblTheme}}:</div>
                        <div  class="subThemeNames" >
                            <div *ngFor="let theme of subTheme;let i = index">
                                <div class="themeIconContainer" [ngClass]="theme.shape"></div><div class="subThemeText">{{theme.subThemeName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="insight-container" [style.height]="height" custom-scroll *ngIf="!isDriver">
                        <div class="card-list insight-list">
                            <div class="row">
                                <div class="col-sm-6" style="margin-bottom:15px" *ngFor="let insight of insights">
                                    <div class="insight-card">
                                        <div class="i_card-body">
                                            <div>
                                                <div class="themeIconContainer themeIcon1" [ngClass]="getThemeShape(insight.driverSubThemeId)">
                                                </div>
                                                <p [innerHTML]="insight.title"></p>
                                            </div>
                                            <div class="i_card-footer">
                                                <button class="btn-card-clear" (click)="exploreInsight(insight.insightId)">
                                                    <div class="text">{{SiteContents.buttonExploreInsight}}</div>
                                                    <div class="icon-container">
                                                        <img src="../../../assets/images/arrow-icon2.png" />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--<div *ngIf="insightDetail" class="insight-popup-content">
                    <button class="btn insight-back pull-left" (click)="goBack()">
                        <i class="fa fa-arrow-left fa-sm"></i>
                        <span>{{SiteContents.lblInsights}}</span>
                    </button>
                    <div class="pull-right link pin-title" (click)="onPinInsights()">
                        <img class="image-pinned" *ngIf="insightPinned" src="../../../assets/images/pinned-icon.png" />
                        <img class="image-pinned" *ngIf="!insightPinned" src="../../../assets/images/pin-icon.png" />
                        {{insightPinned  ? SiteContents.labelPinnedThisInsight :SiteContents.labelPinThisInsight }}
                    </div>
                    <iframe width="100%" height="700" frameborder="0" [src]="insightContent | safeUrl"></iframe>
                </div>-->
            </div>
        </div>
    </div>
</div>

