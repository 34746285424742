export function ComponentContent(pageName: string = "", pageNames: Array<string> = []): ClassDecorator {
    return function (constructor: any) {
        //Object.defineProperty(constructor.prototype, 'pageName', { value: () => pageName })
        Object.defineProperty(constructor.prototype, 'pageName', { value: pageName });
        Object.defineProperty(constructor.prototype, 'pageNames', { value: pageNames });

    }

}

