import { Component, OnInit ,Injector } from '@angular/core';
import { CONTENTS } from '../../config/content.const';
import { BaseComponent } from './../base.component';
import { UtilService } from '../../service/util.service';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";



@Component({
    selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss']
})
export class AppNotificationComponent extends BaseComponent implements OnInit {

    pageContent: any;
    tabNotification: string;

    constructor(private injector: Injector,
        private utilService: UtilService,
        private logService: LogService,
        private logTypes: LogTypes) {
        super(injector);
    } 

    ngOnInit() {
        this.getComponentTitle();
     }

    getComponentTitle() {
        this.utilService.getContent(CONTENTS.NOTIFICATIONS).subscribe((content: any) => {
            this.PageContents = content;
            console.log('app',this.PageContents);
        });
    }

    logMenuNavigation(menu: string) {
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + menu;
        this.logService.logAction(log);
    }
}

