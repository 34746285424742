import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Router, NavigationEnd } from '@angular/router';
import { VelocityService } from './velocity.service';
import { UserService } from '../auth/service/user.service';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Constants } from './constant.service';
import { UtilService } from './util.service';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { ErrorHandlerHelper, AjaxErrorOptions } from './model/ajax-error-options';
import { Response } from './model/response';

import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { filter } from 'rxjs/operators';


const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class NotificationService {

    private notificationCount: number = 0;
    public notificationCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.notificationCount);
    public notificationCountObservable = this.notificationCountSubject.asObservable();

    constructor(private velocityService: VelocityService, private userService: UserService, private constants: Constants,
    private utilService: UtilService, private router : Router
    ) {

    }

    getNotificationCount() {
        //Dummy code to test the notification count - This will be replaced with the actual observable http API call
        
        //this.notificationCount = (Math.ceil(Math.random() * 100)) % 10;
        //this.notificationCountSubject.next(this.notificationCount < 0 ? 0 : this.notificationCount);
        const user = this.userService.getCurrentUser();
            if (user) {
                this.velocityService.getNotificationsCount(user.userId).subscribe((count: number) => {
                    this.notificationCount = count || 0;
                    this.notificationCountSubject.next(this.notificationCount);
                });
            }
    }

    setNotificationCount() {
        this.notificationCount = (this.notificationCount < 5) ? 0 : this.notificationCount-5;
        this.notificationCountSubject.next(this.notificationCount);
    }

    showNotificationOnRoute(router: Router) {
        router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((x: any) => {
            this.getNotificationCount();
        });
    }

    removeAllNotifications(progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokePostAPI(`${this.constants.NOTIFICATION.REMOVE_ALL_NOTIFICATIONS}/${user.userId}`, {}, progressBarOptions);
    }

    invokePostAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
           return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {
        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (errorCode == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
            this.utilService.showLoginPopup();
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {
        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (error.status == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
            this.utilService.showLoginPopup();
        }
        else if (error.status == "404" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }
}
