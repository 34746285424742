<div class="app-footer" *ngIf="isLoggedIn">
    <!--<div class="container-outer">-->
    <div class="main-container footer-container">
        <div class="row bottom-menubar">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <div>
                            <img class="footer-logo" src="../../../assets/logo/footer-logo.png">
                            <!--<div class="label-velocity"><p class="footer-velocity">{{constants.APP.TITLE}}</p></div>-->
                             
                        </div>
                    </div>
                    <div class="offset-lg-1 col-md-8 col-sm-12 d-none d-sm-none d-md-block">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4 col-6" *ngFor="let link of headerLinks">
                                        <div class="footer-header">
                                            {{getMenuValue(link)}}
                                        </div>
                                        <ng-container *ngIf='link.children && (link.children.length >0)'>
                                            <div class="footer-content" *ngFor="let sublink of link.children">
                                                <div>
                                                    <a class="footer-link">
                                                        <span *ngIf="sublink.menuName != 'BenchmarkingReport'" [routerLink]="sublink.routes[0]" (click)="logMenuNavigation(sublink)">{{getMenuValue(sublink)}}</span>
                                                        <span *ngIf="sublink.menuName == 'BenchmarkingReport'" (click)="logMenuNavigation(sublink)">{{getMenuValue(sublink)}}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-sm-block d-md-none">
                        <div class="row">
                            <div class="col-sm-12 accordian-container">
                                <ng-container *ngFor="let link of headerLinks">
                                    <button class="accordion" (click)="showAccordianMenu($event)">{{getMenuValue(link)}}</button>
                                    <div class="panel accordion-panel" *ngIf='link.children && (link.children.length >0)'>
                                        <div class="footer-content" *ngFor="let sublink of link.children">
                                            <div>
                                                <a class="footer-link">
                                                    <span *ngIf="sublink.menuName != 'BenchmarkingReport'" [routerLink]="sublink.routes[0]" (click)="logMenuNavigation(sublink)">{{getMenuValue(sublink)}}</span>
                                                    <span *ngIf="sublink.menuName == 'BenchmarkingReport'" (click)="logMenuNavigation(sublink)">{{getMenuValue(sublink)}}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--</div>-->
    </div>
    <!--<hr>-->
    <div class="main-container footer-container">
        <div class="row footer-menubar">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3 col-sm-12 copyright-row-style">
                        <div>
                          <a class="footer-email" href="mailto:{{footerContents.footer.linkSupportEmail}}">{{footerContents.footer.linkSupportEmail}}</a>
                            <a href="javascript:void(0)" (click)="showCopyrightPopup()" class="copy-right">{{footerContents.footer.copyright | replace:['#year']:[currentYear]}}</a>
                        </div>
                    </div>
                    <div class="offset-lg-1 col-md-8 col-sm-12">
                        <div class="row">
                            <!--<div class="col-md-4 d-sm-none d-md-block"><a class="footer-link" routerLink="/general/terms-and-condition">{{footerContents.footer.termsConditions}}</a></div>-->


                            <div class="col-md-4 col-12"><a class="footer-link" routerLink="/general/terms-and-condition">{{_menuContent ? _menuContent.termsconditionsText:'Terms and Conditions'}}</a></div>
                            <div class="col-md-4 col-12"><a class="footer-link" routerLink="/general/privacy-policy">{{_menuContent ? _menuContent.privacyPolicyText:'Privacy Policy'}}</a></div>
                            <div class="col-md-4 col-12">
                            <!--<a class="footer-link" (click)="showCodeOfConduct()" style="margin-right:15px;">{{siteContents.lblCodeofConduct}}</a>-->
                            <!--</div>
                            <div class="col-md-3 col-12">-->
                                <div class="row no-margin" style="display:inline-block">
                                    <div class="col-md-12 col-12 social-media">
                                        <div class="icon-circle" *ngIf="footerContents.footer.eyFacebookUri != '' ">
                                            <a href="{{footerContents.footer.eyFacebookUri}}" target="_blank" class="fa fa-facebook-square fa-lg"></a>
                                        </div>
                                        <div class="icon-circle" *ngIf="footerContents.footer.eyLinkedInUri != '' ">
                                            <a href="{{footerContents.footer.eyLinkedInUri}}" target="_blank" class="fa fa-linkedin fa-lg"></a>
                                        </div>
                                        <div class="icon-circle" *ngIf="footerContents.footer.eyTwitterUri != '' ">
                                            <a href="{{footerContents.footer.eyTwitterUri}}" target="_blank" class="fa fa-twitter fa-lg"></a>
                                        </div>

                                    </div>
                                </div>                            </div>


                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>
