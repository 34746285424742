import { Injectable } from "@angular/core";
import { Constants } from "./constant.service";
import { Router } from "@angular/router";
import { StorageHelperService } from "./storage-helper.service";
import { VelocityService } from "./velocity.service";
import { GrowthPlanSessionDto, GrowthPlanListDto, DuplicateGrowthPlanData, DuplicateGrowthPlanRequestDto } from "../focus/model/face-to-face.model";
import { Observable } from "rxjs";
import { ProgressBarOptions } from "../progress-bar-module/progress-bar-options";
import { UserService } from "../auth/service/user.service";
import { LogTypes, LogAction } from "./model/log-models";
import { LogService } from "./log.service";

@Injectable()
export class FaceToFaceService {
    constructor(
        private constants: Constants,
        private router: Router,
        private storageHelper: StorageHelperService,
        private velocityService: VelocityService,
        private userService: UserService,
        private logTypes: LogTypes,
        private logService: LogService

    ) {

    }

    public getSessionGrowthPlanData() {       
        return Observable.create(observer => {
            const sessionGrowthplan: GrowthPlanSessionDto = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
            if (sessionGrowthplan && sessionGrowthplan.growthPlanId) {             
                observer.next(sessionGrowthplan);
                observer.complete();
            } else {
                this.velocityService.getGrowthPlanList().subscribe((result) => {
                    const currentGrowthPlan: GrowthPlanListDto = result.growthPlans.find(el => el.isCurrent);
                    if (currentGrowthPlan && currentGrowthPlan.growthPlanId) {
                        const sessionStorageData: GrowthPlanSessionDto = {
                            growthPlanId: currentGrowthPlan.growthPlanId,
                            growthPlanName: currentGrowthPlan.growthPlanName,
                            isLocked: currentGrowthPlan.isLocked,
                            isShared: currentGrowthPlan.isShared
                        };
                        this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);                
                        observer.next(sessionStorageData);
                        observer.complete();
                    } else {
                        observer.next(null);
                        observer.complete();
                    }
                });
            }
        });
    }

    //public duplicateGrowthPlan(growthPlanData: GrowthPlanListDto, duplicateGrowthPlanName: string, duplicategrowthPlanTypeId: any, duplicatefacilitatorCode: any, duplicatemeetingDate: any, duplicate_facilitatorFlow: any, duplicate_memberId: any , duplicate_clientName: any, duplicate_clientEmail: any, duplicate_companyName: any , progressBarOptions?: ProgressBarOptions) {
    public duplicateGrowthPlan(growthPlanData: GrowthPlanListDto, duplicateGrowthPlanName: string, duplicategrowthPlanTypeId: any, duplicatefacilitatorCode: any, duplicatemeetingDate: any, duplicate_facilitatorFlow: any, member: any, nonMember:any, progressBarOptions?: ProgressBarOptions) {
        console.log(member);
        const
        user = this.userService.getCurrentUser(),
        duplicateGrowthPlanDataParam: DuplicateGrowthPlanRequestDto = new DuplicateGrowthPlanRequestDto(),
        duplicateGrowthPlanObservableData: DuplicateGrowthPlanData = new DuplicateGrowthPlanData();  

        duplicateGrowthPlanDataParam.existingGrowthPlanId = growthPlanData.growthPlanId;
        duplicateGrowthPlanDataParam.growthPlanName = duplicateGrowthPlanName;

        duplicateGrowthPlanDataParam.growthPlanName = duplicateGrowthPlanName;
        duplicateGrowthPlanDataParam.growthPlanTypeId  = duplicategrowthPlanTypeId;
        duplicateGrowthPlanDataParam.facilitatorCode = duplicatefacilitatorCode;
        duplicateGrowthPlanDataParam.meetingDate = duplicatemeetingDate;
        duplicateGrowthPlanDataParam.facilitatorFlow = duplicate_facilitatorFlow;
        duplicateGrowthPlanDataParam.member = member;
        duplicateGrowthPlanDataParam.nonMember = nonMember;
        //duplicateGrowthPlanDataParam.memberId = duplicate_memberId;
        //duplicateGrowthPlanDataParam.clientName = duplicate_clientName;
        //duplicateGrowthPlanDataParam.clientEmail = duplicate_clientEmail;
        //duplicateGrowthPlanDataParam.companyName = duplicate_companyName;
        duplicateGrowthPlanDataParam.typeId = 2;
        duplicateGrowthPlanDataParam.userId = user.userId;
   
        return Observable.create(observer => {             
            this.velocityService.duplicateGrowthPlan(duplicateGrowthPlanDataParam, progressBarOptions).subscribe((duplicateRes) => {              
                if (duplicateRes.growthPlanId) {    
                    this.duplicateAuditLog(duplicateRes.growthPlanId);
                    duplicateGrowthPlanObservableData.growthPlanId = duplicateRes.growthPlanId;
                    duplicateGrowthPlanObservableData.errorCode = duplicateRes.errorCode;
                    duplicateGrowthPlanObservableData.message = duplicateRes.message;
                    duplicateGrowthPlanObservableData.growthPlanName = duplicateGrowthPlanName;
                    this.velocityService.setGrowthPlanId({ growthPlanId: duplicateRes.growthPlanId }, progressBarOptions).subscribe((setGrowthPlanIdResult) => {
                        if (setGrowthPlanIdResult) {                          
                            this.storageHelper.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
                            const sessionStorageData: GrowthPlanSessionDto = {
                                growthPlanId: duplicateGrowthPlanObservableData.growthPlanId,
                                growthPlanName: duplicateGrowthPlanObservableData.growthPlanName,
                                isLocked: growthPlanData.isLocked,
                                isShared: growthPlanData.isShared
                            };
                            this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);                          
                        } 
                        observer.next(duplicateGrowthPlanObservableData);
                        observer.complete();
                    });
                } else {
                    duplicateGrowthPlanObservableData.growthPlanId = 0;
                    duplicateGrowthPlanObservableData.growthPlanName = '';
                    duplicateGrowthPlanObservableData.errorCode = duplicateRes.code;
                    duplicateGrowthPlanObservableData.message = duplicateRes.message;
                    observer.next(duplicateGrowthPlanObservableData);
                    observer.complete();
                }
            });
        });
    }

    private duplicateAuditLog(growthPlanId) {
        let log = new LogAction();
        log.action = this.logTypes.GROWTH_PLAN_DUPLICATED;
        log.growthPlanId = growthPlanId;
        this.logService.logAction(log);
    }



}
