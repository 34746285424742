
<div class="modal fade ey-aca-modal" bsModal #customActionModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <!--Not sure if close is needed, but the user does not have any option left other than adding an action-->
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />close
                </div>
                    <div class="addActionDialog">
                        <div class="addActionPlaceholder">
                            <textarea class="userText" placeholder={{templateData.placeHolderText}}  [(ngModel)]="actionName" maxlength="200" ></textarea>
                            <!--<div class="countCheck">{{actionName ? (actionName.length) : '0'}} {{templateData.ofText}} 200</div>-->
                        </div>
                        <button class="addActionDialogButton" (click)="saveAction()" [disabled]="actionName.length < 1">{{templateData.buttonText}}</button>
                    </div>
            </div>
        </div>
    </div>
</div>