
<div class="modal fade ey-video-modal ovideo" bsModal #videoModalHottopic="bs-modal" 
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <!--<div *ngIf="showLoaderForVideo">Loading</div>-->
                <!--<div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png"/>close
                </div>-->
               

                <div class="video-modal-content">
                    <div>
                        <div class="video-title"> 
                            {{title}}
                        </div>
                        <span class="dialog-close" (click)="closeDialog()">
                            <img src="../../../assets/images/close-bt.png" />
                        </span>
                    </div>
                    <video width="100%" controls controlsList="nodownload" autoplay >
                        <source src="{{videoUrl}}">
                    </video>
                </div>

                <!--<div class="video-modal-content">
                    <div class="video-title">
                        {{title}}&nbsp;
                        <span class="dialog-close" (click)="closeDialog()">
                            <img src="../../../assets/images/close-bt.png" />
                        </span>
                    </div>
                    <video width="100%" controls controlsList="nodownload" autoplay>
                        <source src="{{videoUrl}}">
                    </video>
                </div>-->

                </div>
            
        </div>
    </div>
</div>