import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BaseComponent } from './../component/base.component';
import { GrowthPlanSessionDto } from '../focus/model/face-to-face.model';
import { StorageHelperService } from './storage-helper.service';
import { Constants } from './constant.service';
import { VelocityService } from './velocity.service';
import { Observable } from 'rxjs';
//import { error } from 'util';
import { UtilService } from './util.service';

import { environment } from '../../environments/environment';

const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class GrowthPlanResolve implements Resolve<any> {
    constructor(private router: Router, private utilService: UtilService, private velocityService: VelocityService, private storageHelper: StorageHelperService, private constants: Constants) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var me = this;
        return Observable.create(observer => {
            var currentComponent: any;
            currentComponent = route.component;
            var requiresGrowthPlanId = (currentComponent && currentComponent.prototype && currentComponent.prototype.requiresGrowthPlanId) ? currentComponent.prototype.requiresGrowthPlanId : false;
            var notFoundAction = (currentComponent && currentComponent.prototype && currentComponent.prototype.notFoundAction) ? currentComponent.prototype.notFoundAction : 'redirectTo404';
            if (requiresGrowthPlanId) {
                var sessionGrowthplan: GrowthPlanSessionDto = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
                if (sessionGrowthplan && sessionGrowthplan.growthPlanId) {
                    this.utilService.doGet(ApiUrl + `${this.constants.FOCUS.GET_GROWTH_PLAN}/` + (sessionGrowthplan.growthPlanId), {}, null).subscribe((response: any) => {
                        if (response.code == 1000) {
                            var data = response.result;
                            var sessionStorageData: GrowthPlanSessionDto = {
                                growthPlanId: sessionGrowthplan.growthPlanId,
                                growthPlanName: data.growthPlanName,
                                isLocked: data.isLocked,
                                isShared: data.isShared,
                                growthPlanTypeId: data.growthPlanTypeId
                            };
                            this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
                            observer.next(true);
                            observer.complete();
                        }
                        else if (response.code == 400001) {
                            if (notFoundAction == 'getCurrent') {
                                this.velocityService.getGrowthPlanList().subscribe((result: any) => {

                                    var growthPlanData = result;

                                    const currentGrowthPlan = growthPlanData.growthPlans.find(el => el.isCurrent);
                                    var growthPlan = currentGrowthPlan ? currentGrowthPlan : ((result.growthPlans && result.growthPlans.length)) ? result.growthPlans[0] : {};

                                    var sessionStorageData: GrowthPlanSessionDto = {
                                        growthPlanId: growthPlan.growthPlanId,
                                        growthPlanName: growthPlan.growthPlanName,
                                        isLocked: growthPlan.isLocked,
                                        isShared: growthPlan.isShared,
                                        growthPlanTypeId: growthPlan.growthPlanTypeId
                                    };
                                    this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
                                    observer.next(true);
                                    observer.complete();

                                });
                            }
                            else {
                                observer.next(false);
                                observer.complete();
                                me.router.navigate(['general', 'error-404']);
                            }
                        }
                    }, (error: any) => {
                        observer.next(false);
                        observer.complete();
                        //me.router.navigate(['general', 'error-404']);
                    });

                }
                else {
                    if (notFoundAction == 'getCurrent') {
                        this.velocityService.getGrowthPlanList().subscribe((result: any) => {

                            var growthPlanData = result;

                            const currentGrowthPlan = growthPlanData.growthPlans.find(el => el.isCurrent);
                            var growthPlan = currentGrowthPlan ? currentGrowthPlan : ((result.growthPlans && result.growthPlans.length)) ? result.growthPlans[0] : {};

                            var sessionStorageData: GrowthPlanSessionDto = {
                                growthPlanId: growthPlan.growthPlanId,
                                growthPlanName: growthPlan.growthPlanName,
                                isLocked: growthPlan.isLocked,
                                isShared: growthPlan.isShared,
                                growthPlanTypeId: growthPlan.growthPlanTypeId
                            };
                            this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
                            observer.next(true);
                            observer.complete();

                        });
                    }
                    else {
                        observer.next(false);
                        observer.complete();
                        me.router.navigate(['general', 'error-404']);
                    }
                }
            }
            else {
                observer.next(true);
                observer.complete();
            }

        });
    }
}
