import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { BaseComponent } from './../../component/base.component';
import { LogTypes, LogAction } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';
import { CreateGrowthPlanDto } from '../../focus/model/face-to-face.model';


@Component({
    selector: "f2f-code-verification",
  templateUrl: './f2f-code-verification.component.html',
  styleUrls: ['./f2f-code-verification.component.scss']
})

export class F2FCodeVerificationComponent extends BaseComponent implements OnInit, AfterViewInit {

    observable: Observable<any>;
    subject: any;
    PageContents: any = {};
    growthPlanId: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter()
    @ViewChild('f2fVerificationModal') f2fVerificationModal: ModalDirective;
    isLoaded: boolean = false;
    @ViewChild('fc') form: any;
    isSubmit: boolean = false;
    emptyCodeErrorMessage: boolean = false;
    errorMessage: boolean = false;
    notMatchingMessage: boolean = false;
    facCodeText: string;
    facilitatorDetails: any;
    validFac: boolean = false;
    @ViewChild('fCInput') fcInput: any;

    constructor(
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private injector: Injector,
        private logTypes: LogTypes,
        private logService: LogService
    ) {
        super(injector);
        this.PageContents = this.injector.get('PageContents');
        this.growthPlanId = this.injector.get('growthPlanId');
    }

    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


    returnedValue: any = {};
    ngOnInit() {
        
        

    }
    
    chkFACode() {
        if (this.facCodeText && this.form.valid) {
            this.emptyCodeErrorMessage = false;
            let params = { code: this.facCodeText }
            this.velocityService.validateFacilitatorCode(params).subscribe((data) => {
                if (data.Id) {
                    let params = { code: this.facCodeText, growthPlanId: this.growthPlanId }
                    this.velocityService.validateFacilitatorCode(params).subscribe((data) => {
                        if (data.Id) {
                            this.notMatchingMessage = false;
                            this.validFac = true;
                            this.facilitatorDetails = data;
                            //this.finalizereport();
                        } else {
                            this.notMatchingMessage = true;
                            this.validFac = false;
                        }
                    });
                    this.errorMessage = false;
                    
                } else {
                    this.errorMessage = true;
                    this.validFac = false;
                }
            });
        }
    }
    
    finalizereport() {
        if (this.validFac) {
            this.returnedValue = 1;
            this.closeDialog();
        }
    }
    cancelClicked() {

        this.returnedValue = "no";
        this.closeDialog();
    }
    onModalClosed($event: any) {
        //this.subject.next(this.returnedValue);
        //this.componentEvent.emit({ value: "closed" });

    }
    ngAfterViewInit() {
        this.fcInput.nativeElement.focus();
       
    }
    okayClicked() {
        
      
    }
    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.f2fVerificationModal.config = this.config;
        this.f2fVerificationModal.show();
        return this.observable;
    }

    public closeDialog() {

        this.f2fVerificationModal.hide();
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
}


