<div class="modal fade ey-dialog-modal growth-matrix-axes-modal" bsModal #growthMatrixAxesModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed()">
    <div class="modal-dialog" update-panel-action="click">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{pageContents.lblAxisDifference}}</h4>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body" *ngIf="matrixDetails.length > 0">
                <div class="matrix-details-table">
                    <div class="row table-header">
                        <div class="col-6">
                            {{pageContents.lblParticipantName}}
                        </div>
                        <div class="col-3">
                            {{pageContents.lblXAxis}}
                        </div>
                        <div class="col-3">
                            {{pageContents.lblYAxis}}
                        </div>
                    </div>
                    <div class="table-content">
                        <div class="row table-data" *ngFor="let detail of matrixDetails">
                            <div class="col-6">
                                {{detail.userName}}
                            </div>
                            <div class="col-3">
                                {{detail.xAxisValue}}
                            </div>
                            <div class="col-3">
                                {{detail.yAxisValue}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" (click)="closeDialog();" type="button">{{pageContents.lblOk}}</button>
            </div>
        </div>
    </div>
</div>