
<div class="cookie-container" *ngIf="enableCookieBanner && noTC" [ngClass]="{'showCookie':showCookie}">
    <div style="margin: 0 auto; width:80%">
        <div class="cookie-title">{{siteContents.labelCookieSettings}}</div>
        <div class="row">
            <div class="col-xs-12 col-sm-9" [innerHtml]="siteContents.textCookiePolicy">
            </div>
            <div class="col-xs-12 col-sm-3">
                <button type="button" class="btn btn-outline" (click)="onAccept()">{{siteContents.buttonYesIAccept}}</button>
              
               <a class="cookie-link" routerLink='/general/privacy-policy'>{{siteContents.linkFindoutMore}}</a>
             
            </div>
        </div>
    </div>
</div>