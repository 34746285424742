import { NgModule } from '@angular/core';
import { ScrollTrackerDirective } from './scroll-track.directive';
import { CustomScrollDirective } from './custom-scroll.directive';
import { MatchHeightDirective } from './match-height.directive';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { MouseWheelDirective } from './mouse-wheel.directive';
import { ShowMoreDirective } from './show-more.directive';
import { ShowMoreDashboardDirective } from './show-more-dashboard.directive';
import { ShowEllipsisDirective } from './show-ellipsis.directive';
import { ShowEllipsisPopUPDirective } from './show-ellipsis-popup.directive';
import { handleOverFlowDirective } from './handle-overFlow.directive';

@NgModule({
    declarations: [
        ScrollTrackerDirective,
        CustomScrollDirective,
        MatchHeightDirective,
        MouseWheelDirective,
        ShowMoreDirective,
        ShowEllipsisDirective,
        ShowEllipsisPopUPDirective,
        handleOverFlowDirective,
        ShowMoreDashboardDirective
    ],
    imports: [
        MalihuScrollbarModule.forRoot()
    ],
    exports: [
        ScrollTrackerDirective,
        CustomScrollDirective,
        MatchHeightDirective,
        MouseWheelDirective,
        ShowMoreDirective,
        ShowEllipsisDirective,
        ShowEllipsisPopUPDirective,
        handleOverFlowDirective,
        ShowMoreDashboardDirective
    ],
    providers: []
})
export class DirectiveModule { }
