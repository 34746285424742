import { Component, Input, ViewChild, Injector, Output, EventEmitter  } from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';

import {Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from "../../component/base.component";

@Component({
    //moduleId: module.id.toString(),
    selector: "alert-dialog",
    templateUrl: './alert-dialog.component.html'
})

export class AlertDialogComponent extends BaseComponent{
    title: string = "";
    message: string = "";
    showCloseButton: boolean = true
    closeOthers: boolean = false;

  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective ;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'ok';
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    siteContents: any;

    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(private injector: Injector) {
        super(injector);
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.showCloseButton = this.injector.get('showCloseButton');
        this.closeOthers = this.injector.get('closeOthers')
    }

    ngOnInit() { 
        this.siteContents = this.SiteContents;
        if (this.closeOthers) {
            this.componentEvent.emit({ value: "others" });
        }
    }

    onModalClosed($event:any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }
        this.componentEvent.emit({ value: "closed" });

    }

    okayClicked() {
        this.returnedValue = "ok";
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = "ok";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.alertModal.config = this.config;
        this.alertModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.alertModal.hide();
    }
}
