

<div class="modal fade notes-modal"
     bsModal #addNotesModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog">

        <div class="modal-content rowWithoutFlex">

            <!--<div class="floating-dialog-close float-right" (click)="closeDialog()">
                <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />close
            </div>-->

            <div class="modal-header">
                <h4 class="modal-title pull-left">{{card.customTitle?card.customTitle:(card.bodyFormatted || card.title)}}</h4>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />

            </div>
            <div class="modal-body">
               
                <div class="row">
                  
                   
                    <div class="col-12 notes">
                        <p *ngIf="showNote && noteValue">{{noteValue}}</p>
                        <div *ngIf="showNote && noteValue && !isLocked">
                            <a  (click)="showNote=false;">{{lblEditNote}}</a>
                        </div>
                        <textarea *ngIf="!showNote" (keyup)="autogrow()" [placeholder]="lblNotes" #titleTextArea class="form-control value-text-subtitle" maxlength="250" type="text" [(ngModel)]="noteValue" (ngModelChange)="onChange($event)"></textarea>
                        <span class="custom-error" *ngIf="isContainingSpecialCharacter">{{customActionCardRequired}}</span>
                    </div>

                </div>
                <!--<div class="row bottom">
                    <div class="col-12 col-sm-3 key-label">
                        {{subtitleLabel}}

                    </div>
                    <div class="col-12 col-sm-9 ">
                        <textarea class="form-control value-text-subtitle" [maxLength]="maxLengthSubtitle" type="text" [(ngModel)]="subtitleValue"></textarea>
                    </div>
                </div>-->
            </div>
            <div class="modal-footer" *ngIf="!showNote">
            
                        <div class="action-buttons">
                            <button *ngIf="!isLocked" class="btn btn-outline"  [disabled]="isSubmitDisabled()" (click)="okayClicked();" type="button"
                                    [innerHTML]="btnSubmitText"></button>

                        </div>
                   
            </div>
        </div>
    </div>
</div>


