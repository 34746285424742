    <div class="modal fade coc-modal bullet-style"
     bsModal #codeOfConductModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content" eyv-update-panel="termsAndCondition">
            <div>
                <div class="floating-dialog-close" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-bt.png" />
                </div>

                <div class="modal-header" [innerHTML]="titleText">
                </div>
            </div>
            <div class="modal-body" custom-scroll>
                <p [innerHTML]="codeOfConduct"></p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="action-buttons">
                        <button class="btn btn-outline" (click)="okayClicked();" type="button"
                                [innerHTML]="SiteContents.buttonOk"></button>
                        <div class="btn-black-back" (click)="closeDialog()">
                            {{SiteContents.lblClose}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    

