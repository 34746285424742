import { Component, Input, ViewChild, Injector, Output, EventEmitter , NgZone} from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
import { Router } from '@angular/router';
import { BaseComponent } from './../../component/base.component';

 
import {Observable,of } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
    //moduleId: module.id.toString(),
    selector: "save-cancel-dialog",
  templateUrl: './save-cancel-dialog.component.html',
  styleUrls: ['./save-cancel-dialog.component.scss']
})

export class SaveCancelDialogComponent extends BaseComponent{
    title: string = "";
    message: string = "";
    customize: any = {};
    clickGrowthPlan = false;
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    part1 = "";
    part2 = '';
    part3 = '';

    constructor(private injector: Injector,
        private zone: NgZone,
        private router: Router) {

        super(injector);
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.customize = this.injector.get('config');

    }

  @ViewChild('okCancelModal',{ static: true }) okCancelModal: any;
    returnedValue: boolean = false;

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {
        this.clickGrowthPlan = this.customize.clickGrowthPlan;
        if (this.customize.clickGrowthPlan) {
            var splittedContent = this.message.split("<a>")
            if (splittedContent.length > 1 )
                this.part1 = splittedContent[0];
                this.part2 = splittedContent[1].split("</a>")[0];
                this.part3 = splittedContent[1].split("</a>")[1];

        }
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }

    cancelClicked() {
        this.returnedValue = false;
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue = true;
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = false;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.okCancelModal.config = this.config;
        this.okCancelModal.show();
        return this.observable;
    }
    
    private closeDialog() {
       
        this.okCancelModal.hide();
        return of(this.returnedValue);
    }
    goToSummaryPage() {
        this.router.navigate(["act", "summary"]);
    }

    closeBtn() {
        if (this.customize.close && this.customize.close == 'ok') {
            this.okayClicked();
        } else {
            this.closeDialog();
        }
    }
}
