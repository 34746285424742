<div class="modal fade ey-info-modal" bsModal #modalBody #actionDetailModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog ad-container">
      <div class="modal-content">
          <div class="modal-body" handleOverflow [configSet]="configSet" [hasApply]="hasApply">
          <div *ngIf="!showConfirmAlert" class="floating-dialog-close float-right" (click)="closeDialog()">
            <img class="whiteClose" src="../../../assets/images/close-bt.png" />
          </div>
          <div class="action-detail-container" eyv-update-panel="actiondetails">
            <div class="row" *ngIf="!showTools && showActions">
              <div class="col-sm-12 col-lg-12 left-side">
                <p class="ad-heading" *ngIf="showTitle && (showOrginal || customTitle == '')">{{actionTitle}}</p>
                <p class="ad-heading" *ngIf="showTitle && !showOrginal">{{customTitle}}</p>

                <!--<textarea *ngIf="!showTitle" class="textArea" type="text"
                    rows="1" [(ngModel)]="saveTitle" maxlength="500" (keyup)="autogrow()" #titleTextArea></textarea>
          <div *ngIf="showTitle">
              <a [routerLink]="" *ngIf="showTitle && !isGrowthPlanLocked" (click)="editTitle()">{{myGrowthPlanContent.lblEdit}}</a>
              <a [routerLink]="" class="border-left" *ngIf="showTitle && customTitle && !showOrginal" (click)="showOrginalTitle(true)">{{myGrowthPlanContent.lblViewOriginal}}</a>
              <a [routerLink]="" class="border-left" *ngIf="showTitle && customTitle && showOrginal" (click)="showOrginalTitle(false)">{{myGrowthPlanContent.lblViewCustomize}}</a>
          </div>-->
              </div>

                        <!--<div class="col-sm-6 col-lg-4" style="padding-top: 14px;">
            <div *ngIf="!showTitle" class="row">
                <button *ngIf="!isGrowthPlanLocked" class="col-md-4 btn btn-outline edit-btns"
                        [disabled]="saveTitle.trim()==''"
                        (click)="saveEditedTitle()">
                    {{myGrowthPlanContent.buttonSaveActGrowthPlan}}
                </button>
                <span style="padding-top: 5px;white-space: nowrap;" class="col-md-3 or edit-btns">{{myGrowthPlanContent.lblOrActGrowthPlan}}</span>
                <a  style="padding-top: 5px;white-space: nowrap;" [routerLink]="" class="col-md-4 lnk-cancel edit-btns"
                   (click)="showTitle = true;showOrginal = false;hasApply=false;">
                    {{myGrowthPlanContent.buttonCancelHeadingActGrowthPlan}}
                </a>
            </div>
        </div>-->
            </div>

            <div class="row" *ngIf="!showTools && showActions">
              <div class="col-sm-12 col-md-12  col-lg-12 left-side">
                <div class="note-container">
                  <div class="note tasks note-color" *ngIf="!showNote || saveNote.length">
                    <p *ngIf="showNote">{{actionNote}}</p>
                    <div class="row" *ngIf="!showNote">
                      <div class="col-md-8">
                        <textarea class="textArea" type="text"
                                  rows="1" [(ngModel)]="saveNote" maxlength="250" (keyup)="autogrowNote()" #noteTextArea></textarea>
                      </div>
                      <div class="col-md-4 pt-3 pl-0">
                        <div *ngIf="!showNote && !isGrowthPlanLocked">
                          <button class="btn btn-outline"
                                  [disabled]="saveNote.trim()==''"
                                  (click)="saveEditedNote()">
                            {{myGrowthPlanContent.buttonSaveActGrowthPlan}}
                          </button>
                          &nbsp;<span class="or">{{myGrowthPlanContent.lblOrActGrowthPlan}}</span>&nbsp;
                          <a [routerLink]=""
                             (click)="showNote = true">
                            {{myGrowthPlanContent.buttonCancelTaskActGrowthPlan}}
                          </a>
                        </div>
                      </div>
                    </div>


                    <div *ngIf="showNote && actionNote" style="float:right;">
                      <a [routerLink]="" *ngIf="showNote && !isGrowthPlanLocked" (click)="editNote()">{{appContent.lblEditNote}}</a>
                    </div>
                  </div>
                  <div *ngIf="!saveNote.length && showNote">
                    <a [routerLink]="" *ngIf="!isGrowthPlanLocked" (click)="editNote()">{{myGrowthPlanContent.lblAddNote}}</a><br /><br />
                  </div>

                  <!--<h3 *ngIf="!showTools && showActions && tools.length > 0" class="tools-header">{{appContent.lblResourceHeader}}</h3>-->
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-12 rag-cont">
                <action-rag [isGrowthPlanLocked]="isGrowthPlanLocked" [ragValue]="ragValue" [appContent]="actionCardAppcontent" [estimatedCost]="overallCost" [owner]="assignedUser" [startDate]="overallStartDate" [completionDate]="overallCompletionDate" [currencySymbol]="currencySymbol" (changeRag)="changeRag($event)" (close)="openRag($event)" [statusSettings]="statusSettings"></action-rag>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <hr style="background-color: #F4F4F4;" />
              </div>
            </div>

            <!--<div class="row" *ngIf="!showTools && showActions">
      <div class="col-sm-6 tools" *ngFor="let tool of tools | slice:0:2">
          <div class="tool-card">-->
            <!--<div class="tool-title">{{tool.subTitle}}</div>-->
            <!--<div class="tool-title" showEllipsis>
                      <p [innerHTML]="tool.subTitle"></p>
                  </div>
                  <div class="tool-body" showEllipsis>
                      <p [innerHTML]="tool.description"></p>
                  </div>
                  <p>
                      <button class="btn btn-read btn-outline" (click)="onToolSubscribe(tool)">
                          >&nbsp; {{appContent.lblRead}}
                      </button>
                  </p>
              </div>
          </div>
      </div>

      <div class="row" *ngIf="!showTools && showActions">
          <div class="col-12">
              <div class="float-right view-text" *ngIf="tools && tools.length>2">
                  <a [routerLink]="" (click)="viewTools()">{{myGrowthPlanContent.labelViewAll}}</a>
              </div>
          </div>
      </div>

      <div class="row" *ngIf="!showTools && showActions">
          <div class="col-12">
              <hr />
          </div>
      </div>-->

            <div class="row" *ngIf="!showTools && showActions">
              <div class="col-8">
                <span class="task-header">{{myGrowthPlanContent.lblTasks}}</span>
              </div>
              <div class="col-4" *ngIf="tasks && tasks.length ==0 && !showAddBlock && !isGrowthPlanLocked">
                <button class="btn btn-add-task btn-outline" (click)="showAddBlock=!showAddBlock">
                  {{myGrowthPlanContent.buttonAddTask}}
                </button>
              </div>
            </div>

            <div class="row rowWithoutFlex" *ngIf="!showTools && showActions">
              <div class="col-md-12  left-side">
                <div *ngFor="let task of tasks">
                  <div class="tasks">
                    <div class="row">
                      <div class="col-12 task-desc">
                        {{task.taskDescription}}
                      </div>
                    </div>
                    <div class="row task-details">
                      <div class="col-sm-6 col-md-3 self-alignment">
                        <img class="taskAssignedUser" src="../../../assets/images/community-user-icon.png"> {{task.assignedTo}}
                      </div>
                      <div class="col-sm-6 col-md-3 px-0">
                        <!--<i class="fa fa-calendar-o left-float calender-icon" aria-hidden="true"></i> -->
                        <img class="left-float calender-icon" src="../../../assets/images/calendar-start.png">
                        <div class="label-group left-float">
                          <span class="task-label">
                            {{myGrowthPlanContent.lblTaskActionStartDate}}
                          </span><br />
                          {{task.startDate | dateFormatter}}
                        </div>

                      </div>
                      <div class="col-sm-6 col-md-3 px-0">
                        <!--<i class="fa fa-calendar-check-o left-float calender-icon" aria-hidden="true"></i> -->
                        <img class="left-float calender-icon" src="../../../assets/images/calendar-end.png">
                        <div class="label-group left-float">
                          <span class="task-label">{{myGrowthPlanContent.lblTaskCompletionDate}}</span><br />
                          {{task.plannedCompletionDate | dateFormatter}}
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-2 px-0 self-alignment">

                                            <!--{{appContent.labelEstimatedCost}} - {{task.estCost | currency}}-->
                                            {{myGrowthPlanContent.labelEstimatedTaskCost}} - <span class="currency-block-font">{{currencySymbol}}</span> <span class="currency-block-amount">{{task.estCost | amount}}</span>
                      </div>
                      <div *ngIf=" !isGrowthPlanLocked" class="col-sm-2 col-md-1 self-alignment" style="text-align:right !important;">
                        <i class="fa fa-pencil" (click)="editTask(task.taskId)"></i>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="!showTools && showActions && tasks && tasks.length >0 && !isGrowthPlanLocked">
                  <div class="col-12" *ngIf="!showAddBlock">
                    <button class="btn btn-add-task btn-outline" (click)="showAddBlock=!showAddBlock">
                      {{myGrowthPlanContent.buttonAddTask}}
                    </button>
                  </div>
                </div>

                <div class="add-task" *ngIf="showAddBlock">
                  <div class="row">
                    <div class="col-12">
                      <div class="custom-error-box error-msg" *ngIf="showErrorMsgTask">
                        <span class="custom-error-info-icon">
                          <i class="fa fa-exclamation-triangle"></i>
                        </span>
                        <span class="custom-error-message">{{appContent.msgActionStartDateValidation}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="custom-error-box" *ngIf="showWarningMsgTask">
                        <span class="custom-error-info-icon">
                          <i class="fa fa-info-circle"></i>
                        </span>
                        <span class="custom-error-message">{{myGrowthPlanContent.msgTaskDateValidation}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="custom-error-box" *ngIf="showWarningMsgCostTask">
                        <span class="custom-error-info-icon">
                          <i class="fa fa-info-circle"></i>
                        </span>
                        <span class="custom-error-message">{{myGrowthPlanContent.msgActionCostValidation}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="text">{{myGrowthPlanContent.lblTaskTitle}}</div>
                      <input autocomplete="off" style="width:100%;height: 32px;" type="text" [(ngModel)]="inputTask.taskDescription" maxLength="200" />
                    </div>
                  </div>

                  <div class="row second-row">
                    <div class="col-md-3">

                      <div class="text">{{myGrowthPlanContent.lblTaskOwner}}</div>
                      <input autocomplete="off" style="width:100%;height: 32px;" type="text" [(ngModel)]="inputTask.assignedTo" maxLength="100" />
                    </div>
                    <div class="col-md-3 calendar-icon" [ngClass]="{'padding':startDate}">

                      <div class="text">{{myGrowthPlanContent.lblTaskActionStartDate}}</div>

                      <my-date-picker [options]="myDatePickerOptions"
                                      [(ngModel)]="startDate"
                                      (dateChanged)="onDateChanged($event,'start',completionData,'task')"
                                      required></my-date-picker>
                    </div>
                    <div class="col-md-3 calendar-icon" [ngClass]="{'padding':completionData}">



                      <div class="text">{{myGrowthPlanContent.lblTaskCompletionDate}}</div>

                      <my-date-picker [options]="myDatePickerOptions"
                                      [(ngModel)]="completionData"
                                      (dateChanged)="onDateChanged($event,'completed',startDate,'task')"
                                      required></my-date-picker>

                    </div>

                    <div class="col-md-3 doller-icon">

                      <!--<div class="text">{{appContent.labelEstimatedCost}} [ $ ] </div>-->
                      <div class="text">{{myGrowthPlanContent.labelEstimatedTaskCost}} [ <span class="currency-block-font">{{currencySymbol}}</span> ] </div>
                      <input type="number" style="width:100%;height: 32px;" autocomplete="off" (keypress)="numberOnly($event)" (input)="validateCost($event.target.value)" min="0" [(ngModel)]="inputTask.estCost">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 add-btn" style="text-align:right">
                      <button class="btn btn-outline"
                              [disabled]="inputTask.taskDescription.trim()=='' || showErrorMsgTask"
                              (click)="addTask(false)"
                              *ngIf="!showEditBtn">
                        {{myGrowthPlanContent.buttonSubmitActGrowthPlan}}
                      </button>
                      <button class="btn btn-outline"
                              [disabled]="inputTask.taskDescription.trim()=='' || showErrorMsgTask"
                              (click)="addTask(true)"
                              *ngIf="showEditBtn">
                        {{myGrowthPlanContent.buttonSubmitActGrowthPlan}}
                      </button>
                      &nbsp;<span class="or">{{myGrowthPlanContent.lblOrActGrowthPlan}}</span>&nbsp;
                      <a [routerLink]="" *ngIf="!showEditBtn" class="lnk-cancel"
                         (click)="clear();showAddBlock=false;">
                        {{myGrowthPlanContent.buttonCancelTaskActGrowthPlan}}
                      </a>
                      <a [routerLink]="" *ngIf="showEditBtn" class="lnk-cancel"
                         (click)="cancel();showAddBlock=false;">
                        {{myGrowthPlanContent.buttonCancelTaskActGrowthPlan}}
                      </a>

                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row tools-container" *ngIf="showTools">
              <div class="col-12">
                <a [routerLink]="" (click)="back()" class="back-arrow">
                  <img alt="back arrow" src="../../../assets/images/back-icon.png">
                  <span class="back-arrow">{{appContent.labelBack}}</span>
                </a>
                <p class="ad-heading" *ngIf="showTitle && (showOrginal || customTitle == '')">{{actionTitle}}</p>
                <p class="ad-heading" *ngIf="showTitle && !showOrginal">{{customTitle}}</p>

                <p class="related-head">{{appContent.textRelatedTools}}</p>
                <div custom-scroll class="related-tools">
                  <div class="row" myMatchHeight="tcard-body">
                    <div class="tools col-sm-6" *ngFor="let tool of tools">
                      <div class="tool-card">
                        <div class="tool-title">{{tool.subTitle}}</div>
                        <div class="tool-body" showEllipsis>
                          <p [innerHTML]="tool.description"></p>
                        </div>
                        <p>
                          <button class="btn btn-read btn-outline" (click)="onToolSubscribe(tool)">
                            >&nbsp; {{appContent.lblRead}}
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-modal-window" *ngIf="showConfirmAlert">
              <div class="custom-modal-body">
                <h3>{{alertMsgBody}}</h3>
                <div class="action-buttons">
                  <button class="btn btn-cancel" type="button" (click)="confirmNo()">{{alertMsgNo}}</button>
                  <button class="btn btn-ey-default btn-ok" type="button" (click)="confirmYes()">{{alertMsgYes}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
