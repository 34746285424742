export class GrowthPlanDto {
    public growthPlans = new Array<GrowthPlanListDto>();
    public total: number;
}

export class GrowthPlanListDto {
    constructor(
        public dateModified: string,
        public growthPlanId: number,
        public growthPlanName: string,
        public growthPlanTypeId: number,
        public growthPlanTypeName: string,
        public isCurrent: boolean,
        public isFacilitated: boolean,
        public isLocked: boolean,
        public isOwner: boolean,
        public isShared: boolean,     
        public openInfoDialog: boolean,
        public ownerName?: string,
        public company?: string
    ) { }
}

export class CreateGrowthPlanDto {
    public userId: number; 
    public facilitatorId: number;
    public facilitatorCode: string;
    public growthPlanType: number;
    public member = new GrowthPlanMember ();
    public nonMember = new GrowthPlanNonMember();
    public growthPlanName: string;
    public meetingDate: string;
    public clientAttendees = new Array<GrowthPlanClientAttendees>();
    public eyAttendees = new Array<GrowthPlanEyAttendees>();
}

export class GrowthPlanMember {
    public memberId: number;
}

export class GrowthPlanNonMember {
    public clientName: string;
    public clientEmail: string;
    public companyName: string;
}

export class GrowthPlanClientAttendees {
    public name: string;
    public email: string;
}

export class GrowthPlanEyAttendees {
    public userId: number;
}

export class GrowthPlanTypeListDto {
    constructor(
        public id: number,
        public growthPlanText: string
    ) { }
}

export class GrowthPlanSessionDto {
    constructor(
        public growthPlanId: number,
        public growthPlanName: string,
        public isLocked?: boolean,
        public isShared?: boolean,
        public growthPlanTypeId?: number,

    ) { }
}

export class DuplicateGrowthPlanData {
    public growthPlanId: number;
    public growthPlanName: string;
    public errorCode: string;
    public message: string;
}

export class DuplicateGrowthPlanRequestDto {
    public userId: number;
    public growthPlanName: string;
    public growthPlanTypeId: any;
    public facilitatorCode: any;
    public meetingDate: any;
    public facilitatorFlow: any;
    public member: any;
    public nonMember: any;
    public existingGrowthPlanId: number;
    public typeId: number;
}

export class CreateGrowthPlanReturnDto {
    public growthPlanId: number;
    public growthPlanType: number;
}