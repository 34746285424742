import { Component, Injector, Input, ElementRef, Output, EventEmitter, OnInit, OnChanges,ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../base.component';
//import { OnInit, OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { VelocityService } from './../../service/velocity.service';
import { DataShareService } from './../../service/data-share.service';

import { Router } from '@angular/router';


@Component({
    selector:'search-dropdown',
  templateUrl: 'search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
    host: {
        '(document:click)': 'handleClick($event)',
    },
    encapsulation: ViewEncapsulation.None
})

export class SearchDropDownComponent extends BaseComponent implements OnInit,OnChanges{
    searchOptions:string[] =[];
    showSearch = false;
    public elementRef;
    @Output("selectSearch") selectSearch: any = new EventEmitter<boolean>();

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private router: Router,
        private dataShareService: DataShareService,
        myElement: ElementRef
    ){
        super(injector);
        this.elementRef = myElement;

    }
    @Input ('searchString') searchString: any;
    @Input('close') close = false;
    ngOnInit() {
        this.velocityService.getSearchCategories().subscribe(
            (data: any) => {
                this.searchOptions = data;
            }
        );

    }
    ngOnChanges(changes: any) {
        if (changes.searchString) {
            this.showSearch = true;
        }
    }
    goToSearch(searchId: number) {

      this.dataShareService.mainSearchString.next({ 'searchStr': this.searchString, 'categoryId': searchId });
        if (searchId)
            this.router.navigate(["search", searchId]);
        else
            this.router.navigate(["search"]);
        this.showSearch = false;
        this.selectSearch.emit(true);
    }
    //To close the insight when clicking outside the card
    handleClick(event) {
        let clickedComponent = event.target;
        let inside = false;
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
                break;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);

        if (inside == false) {
                if (event.target.name == "searchString")
                     inside = true;
        }

        if (!inside) {
            this.showSearch = false;
        }
    }
}
