<div class="notificationListContainer">
    <div eyv-update-panel="notificationListProgress" *ngIf="isNotificationEnabled">
        <div *ngFor="let notification of notificationList; let i=index">
            <div class="row notification-item-container" [ngClass]="{'noBorderContainer': i==0}">
                <div class="col-sm-2 profileImgContainer">
                    <img class="profile-thumbnail" [src]="notification.imageUrl" />
                    <span class="yellow_circle" *ngIf="!notification.isRead"></span>
                </div>
                <div class="col-sm-10 notificationDescriptionContainer">
                    <div class="topContainerNotification">
                        <span class="recommended" *ngIf="notification.isRecommended">{{PageContents.labelRecommended}} </span>
                        <span class="removeText" (click)="onDeleteNotitification(notification.id)"> <u>{{PageContents.linkRemove}}</u></span>
                    </div>
                    <div class="clear-fix"> </div>
                    <!--<div class="title" [innerHtml]="getFormattedText(notification)"></div>-->
                    <div class="title" *ngIf="notification.notificationText.length > 0" [innerHtml]="notification.notificationText"></div>

                    <div class="title" *ngIf="notification.notificationType == 'actionPrioritization'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="goToInsidePage(notification.parameters, notification.notificationType)">here</span>
                        <span>{{notification.text2}}</span>
                    </div>

                    <div class="title" *ngIf="notification.notificationType == 'newDriverAssessment'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="goToInsidePage(notification.parameters, notification.notificationType)">here</span>
                        <span>{{notification.text2}}</span>
                    </div>

                    <div class="title" *ngIf="notification.notificationType == 'corporateGrowthPlanNotification'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="openCorporateNominatePopup(notification.parameters, notification.notificationType)">here</span>
                        <span>{{notification.text2}}</span>
                        <span class="blueText">{{notification.parameters.notes}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'leadershipMeetingNominateGrowthPlan'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="openCorporateNominatePopup(notification.parameters, notification.notificationType)">here</span>
                        <span>{{notification.text2}}</span>
                        <span class="blueText">{{notification.parameters.notes}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'corporateGrowthPlanDelegatedOwnerNotification'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="redirectToCorporatePage(notification.parameters, notification.notificationType)">here</span>
                        <span>{{notification.text2}}</span>
                        <span class="blueText">{{notification.parameters.notes}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'moderateThreadContentChanged'">
                        <span [innerHtml]="notification.text1"></span>
                        <span class="moderatorPopup" (click)="moderatorComment(notification.parameters.threadId,notification.parameters.postId)">here</span>
                        <span>{{notification.text2}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'moderateAnswerContentChanged'">
                        <span [innerHtml]="notification.text1"></span><span class="moderatorPopup" (click)="moderatorComment(0,notification.parameters.postId)">here</span><span>{{notification.text2}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'moderateThreadDeleted'">
                        <span [innerHtml]="notification.text1"></span><span class="moderatorPopup" (click)="moderatorComment(notification.parameters.threadId,notification.parameters.postId)">here</span><span>{{notification.text2}}</span>
                    </div>
                    <div class="title" *ngIf="notification.notificationType == 'moderatePostDeleted'">
                        <span [innerHtml]="notification.text1"></span><span class="moderatorPopup" (click)="moderatorComment(0,notification.parameters.postId)">here</span><span>{{notification.text2}}</span>
                    </div>
                    <div class="timeSinceTheNotifcation">{{notification.timeAgo}}</div>
                </div>
            </div>

        </div>
    </div>
    
    <a class="viewMoreText" *ngIf="hasMoreCards" (click)="viewMoreNotifications()">{{PageContents.buttonViewMoreNotifications}}</a>

    <div class="noNotificationContainer" *ngIf="notificationList.length == 0 && isNotificationsFetched">
        <div class="noNotifcationText">{{PageContents.labelNoNotifications}}</div>
    </div>
    <div class="noNotificationContainer" *ngIf="notificationList.length == 0 && !isNotificationsFetched && !isNotificationEnabled">
        <div class="noNotifcationText">{{PageContents.msgNotification}}</div>
    </div>
</div>




