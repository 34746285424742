 <div class="modal fade corporate-owner-info-dialog-modal"
     bsModal #corporateOwnerInfoDialogModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog modal-dialog-centered">
        <!--<div>-->
        <div class="modal-content rowWithoutFlex" *ngIf="showGpInfoDialog">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{showNominationTitle?PageContents.lblNominateTitle:growthPlanDetailsArr.growthPlanName}}</h4>

                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
                <!-- <span *ngIf="!gpNameSectionEdit">
                    <h4 class="modal-title pull-left">{{growthPlanDetailsArr.growthPlanName}}</h4>
                    <i class="fa fa-pencil editIcon" (click)="reNameGp()" *ngIf="isGpEditable"></i>
                </span>

                <div class="gp-title-edit" *ngIf="gpNameSectionEdit">
                    <form #form="ngForm">
                        <div class="row">
                            <div class="col-7">
                                <div class="gp-edit-title">
                                    <input type="text" name="gp_title"
                                        [ngClass]="{'red-border-class': errorMessageRename || errorGPEmpty}"
                                        [placeholder]="PageContents.lblEnterAssessmentName"
                                        class="form-control" maxlength="50"
                                        (keyup)="clearError()"
                                        (keyup.enter)="onKeyUPTitle()" [(ngModel)]="gpTitle">
                                </div>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-outline" (click)="onKeyUPTitle()">{{SiteContents.buttonSave}}</button>
                                <span class="edit-gp-btn-cancel">{{PageContents.lblCreateGrowthPlanOr}}</span>
                                <span class="edit-gp-btn-cancel" (click)="cancelRenameGp()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                            </div>
                        </div>
                    </form>
                </div> -->

            </div>
            <div class="modal-body" eyv-update-panel="COInfoDialog" custom-scroll>

                <div class="info-dialog-tag">
                    <span *ngIf="!isPracticeDemoMeeting" class="dialog-tag">{{isShared?PageContents.lblShared:PageContents.lblCorporate}}</span>
                    <span *ngIf="growthPlanTypeId==5 && isPracticeDemoMeeting" class="dialog-tag">{{PageContents.lblLeadershipTeamDemoMeetingTag}}</span>
                   
                </div>

                <div class="container info-dialog-container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 label-style">{{PageContents.lblViewAllSortCreateddate}}</div>
                        <div class="col-8 label-value">{{growthPlanDetailsArr.createdDate}}</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 label-style">{{PageContents.lblMeetingDate}}</div>
                        <div class="col-8 label-value">{{growthPlanDetailsArr.meetingDate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-4 label-style">{{PageContents.lblViewAllSortbyLastSavedDate}}</div>
                        <div class="col-8 label-value">{{growthPlanDetailsArr.modifiedDate}}</div>
                    </div>
                </div>

                <!--<div class="row" *ngIf="showGPbutton && !checkisAssessmentStarted && isOwner && growthPlanTypeId != 5">
        <div class="col-12 chooseGrowthplan-action-btn">
            <p>
                {{PageContents.lblNominateGrowthPlan}}
            </p>
            <button class="btn btn-outline" (click)="showDropdown()">{{PageContents.lblChooseGrowthPlan}}</button>
        </div>
    </div>-->

                <div class="row" *ngIf="showGPselectbox && isOwner && growthPlanTypeId != 5">
                    <div class="col-12 chooseGrowthplan-action-btn">
                        <select [(ngModel)]="selectedGP" class="selectform-control" (ngModelChange)="onChange($event)">
                            <option [ngValue]="undefined" disabled="disabled">{{PageContents.lblChooseGrowthPlan}}</option>
                            <option *ngFor="let nomination of selfFacilitateNominations" [ngValue]="nomination.growthPlanId">
                                {{ nomination.growthPlanName }}
                            </option>
                        </select>
                        <span class="changeGP-spanSpace"><button class="btn btn-outline" (click)="ChangeGrowthPlan()">{{SiteContents.buttonSubmit}}</button></span>
                        <span class="changeGP-spancancellabel" style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="changeGP-spancancellabel" (click)="hidechangeGPSection()"><label class="changeGP-spanSpace-cancel"> {{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                    </div>
                    <div *ngIf="showalreadyNominatedError" class="error-block gpselect">
                        <p>{{PageContents.msgAlreadyNominated}}</p>
                    </div>
                    <div *ngIf="showunexpectedError" class="error-block gpselect">
                        <p>{{PageContents.msgGenericError}}</p>
                    </div>
                </div>

                <div class="container info-dialog-container clearfix">
                    <div class="row">
                        <div class="col-4" *ngIf="growthPlanTypeId == 5">
                            <div class="facilitator-name">{{PageContents.lblFacilitator}}</div>
                            <div class="row">
                                <div class="col user-avatar">
                                    <div class="user-avatar-black">{{faciliatorArr.facilitatorAbbrivation}}</div>
                                    <div class="user-info">
                                        <span class="user-avatar-name">{{faciliatorArr.facilitatorName}}</span>
                                        <span class="user-avatar-desc">{{faciliatorArr.facilitatorCompanyName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div *ngIf="ownerDetailsArr.name != ''">
                                <div class="owner-name"> 
                                    {{PageContents.lblViewAllOwner}}<i *ngIf="growthPlanTypeId == 5 && isFacilitator && !isPracticeDemoMeeting" class="fa fa-info-circle" [tooltip]="PageContents.lblOwnerInfo" placement="bottom" containerClass="tooltip-style"></i>
                                    <i *ngIf="growthPlanTypeId == 5 && isFacilitator && isPracticeDemoMeeting" class="fa fa-info-circle" [tooltip]="PageContents.msgForRealOwnerSelection" placement="bottom" containerClass="tooltip-style"></i>
                                </div>
                                <div class="row">
                                    <div class="col user-avatar">
                                        <img class="user-avatar-image" src="{{ownerDetailsArr.imageURL}}" />
                                        <div class="user-info">
                                            <span class="user-avatar-name"> {{ownerDetailsArr.name}}</span>
                                            <span class="user-avatar-desc">{{ownerDetailsArr.companyName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ownerDetailsArr.name == '' && isFacilitator && growthPlanTypeId == 5">
                                <div class="owner-name">
                                    {{PageContents.lblViewAllOwner}}<i *ngIf="growthPlanTypeId == 5 && isFacilitator && !isPracticeDemoMeeting" class="fa fa-info-circle" [tooltip]="PageContents.lblOwnerInfo" placement="bottom" containerClass="tooltip-style"></i>
                                    <i *ngIf="growthPlanTypeId == 5 && isFacilitator && isPracticeDemoMeeting" class="fa fa-info-circle" [tooltip]="PageContents.msgForRealOwnerSelection" placement="bottom" containerClass="tooltip-style"></i>
                                </div>
                                <div>
                                    <div class="col-lg-6 owner-dropdown">
                                        <select [(ngModel)]="selectedOwner" class="form-select select-box">
                                            <option value="" [disabled]="true">{{PageContents.lblLTMParticipantList}}</option>
                                            <ng-container *ngFor="let participant of participantsArr">
                                                <option *ngIf="participant.isInvitationAccepted !== false && !participant.isDemoUser" [ngValue]="participant">{{participant.participantName}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <button [disabled]="selectedOwner == ''" class="col-lg-2 save-btn btn btn-outline" (click)="saveOwnerSelection()">{{SiteContents.buttonSave}}</button>
                                    <div class="col-lg-2 cancel-btn" (click)="clearOwnerSelection()">{{SiteContents.buttonCancel}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" *ngIf="growthPlanTypeId != 5 && delegatedOwnerArr && delegatedOwnerArr.name">
                            <div class="owner-name">{{PageContents.lblDelegatedOwnerText}}</div>
                            <div class="row">
                                <div class="col user-avatar">
                                    <img class="user-avatar-image" src="{{delegatedOwnerArr.imageURL}}" />
                                    <div class="user-info">
                                        <span class="user-avatar-name"> {{delegatedOwnerArr.name}}</span>
                                        <span class="user-avatar-desc">{{delegatedOwnerArr.companyName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <!--<div class="row" *ngIf="ChangedGrowthPlandetails && isOwner">
      <div class="col-12 .info-changeGP-btn">
        <span class="changeGP-spanGPgray"><label>{{PageContents.lblNominatedGrowthPlan}}</label></span>
        <span class="changeGP-spanGPblack"><label>{{growthPlanDetailsArr.nominatedGrowthPlanName}}</label></span>
      </div>
    </div>-->

                <div class="accordion mb-3" id="accordionExample" *ngIf="(growthPlanTypeId == 5 || growthPlanTypeId == 3) && participantsArr != null">
                    <div class="accordion-item">
                        <h2 class="accordion-header mt-0" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div class="row">
                                    <div class="col-6">{{onlyParticipantArr.length}} {{PageContents.lblParticipants}}<ng-container *ngIf="(demoParticipantArr && demoParticipantArr.length > 0)">, {{ demoParticipantArr.length}} {{PageContents.lblDemoUsers}}</ng-container>
                                                                                                                     <ng-container *ngIf="(EYParticipantaArr && EYParticipantaArr.length > 0)">, {{ EYParticipantaArr.length}} {{PageContents.lblFacilitatorAndAttendees}}</ng-container>
                                    </div>
                                    <div class="col-6 text-end" style="color: #155CB4;">
                                        {{PageContents.lblViewAllParticipants}}
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#2E2E38'>
                                            <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body" style="height:185px;overflow-y:scroll;padding-right:0 !important;" custom-scroll [scrollPos]="scrollPos">

                                <div class="container info-dialog-container clearfix" *ngIf="onlyParticipantArr && onlyParticipantArr.length > 0">
                                    <div [ngClass]="growthPlanTypeId == 5 ? 'participant-sub-title' : 'participant-name'">{{PageContents.lblParticipants}}</div>

                                    <div class="row">
                                        <div class="col-6" *ngFor="let participant of onlyParticipantArr">
                                            <div class="row">
                                                <div class="col user-avatar">
                                                    <div class="user-avatar-black">{{participant.participantAbbrivation}}</div>
                                                    <div class="user-info">
                                                        <span class="user-avatar-name extrapadding">
                                                            {{participant.participantName}}&nbsp;&nbsp;&nbsp;
                                                            <svg containerClass="tooltip-style" [tooltip]="PageContents.lblGreenIcon" *ngIf="participant.isInvitationAccepted && (!isParticipant || isOwner)" width="20" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.58438 9.5H5.41563C2.42531 9.5 0 11.925 0 14.9156C0 15.5156 0.485 16 1.08312 16H12.9156C13.5156 16 14 15.5156 14 14.9156C14 11.925 11.575 9.5 8.58438 9.5ZM7 8C9.20938 8 11 6.20906 11 4C11 1.79094 9.20938 0 7 0C4.79063 0 3 1.79094 3 4C3 6.20906 4.79063 8 7 8ZM19.7594 4.2C19.4562 3.91875 18.9809 3.93584 18.6994 4.23956L15.9791 7.16925L14.7794 5.96956C14.4864 5.67659 14.0119 5.67659 13.7188 5.96956C13.4256 6.26253 13.4258 6.73706 13.7188 7.03019L15.4688 8.78019C15.6094 8.92188 15.8 9 16 9H16.0137C16.2178 8.99609 16.4112 8.90966 16.5499 8.76025L19.7999 5.26025C20.0812 4.95625 20.0625 4.48125 19.7594 4.2Z" fill="#168736" />
                                                            </svg>
                                                            <svg containerClass="tooltip-style" [tooltip]="PageContents.lblOrangeIcon" *ngIf="participant.isInvitationAccepted == null && (!isParticipant || isOwner)" width="20" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.5 7C13.0125 7 11 9.0125 11 11.5C11 13.9875 13.0125 16 15.5 16C17.9875 16 20 13.9875 20 11.5C20 9.0125 17.9875 7 15.5 7ZM17.5 11.6969C17.5 11.8625 17.3625 12 17.1969 12H15.3031C15.1375 12 15 11.8625 15 11.6969V9.30313C15 9.1375 15.1375 9 15.3031 9H15.6969C15.8625 9 16 9.1375 16 9.30313V11H17.1969C17.3625 11 17.5 11.1375 17.5 11.3031V11.6969ZM10 11.5C10 10.6313 10.2094 9.80937 10.5688 9.07812C10.3188 9.03125 10.0625 9 9.8 9H9.27812C8.58437 9.31875 7.8125 9.5 7 9.5C6.1875 9.5 5.41875 9.31875 4.72188 9H4.2C1.88125 9 0 10.8813 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.3469C10.9313 15.0031 10 13.3594 10 11.5ZM7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8Z" fill="#ED6500" />
                                                            </svg>
                                                            <svg containerClass="tooltip-style" [tooltip]="PageContents.lblRedIcon" *ngIf="participant.isInvitationAccepted === false && (!isParticipant || isOwner)" width="20" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.425 7.5L19.85 6.075C20.0469 5.87812 20.0469 5.55938 19.85 5.3625L19.1375 4.65C18.9406 4.45312 18.6219 4.45312 18.425 4.65L17 6.075L15.575 4.65C15.3781 4.45312 15.0594 4.45312 14.8625 4.65L14.15 5.3625C13.9531 5.55938 13.9531 5.87812 14.15 6.075L15.575 7.5L14.15 8.925C13.9531 9.12187 13.9531 9.44063 14.15 9.6375L14.8625 10.35C15.0594 10.5469 15.3781 10.5469 15.575 10.35L17 8.925L18.425 10.35C18.6219 10.5469 18.9406 10.5469 19.1375 10.35L19.85 9.6375C20.0469 9.44063 20.0469 9.12187 19.85 8.925L18.425 7.5ZM7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM9.8 9H9.27812C8.58437 9.31875 7.8125 9.5 7 9.5C6.1875 9.5 5.41875 9.31875 4.72188 9H4.2C1.88125 9 0 10.8813 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.2C14 10.8813 12.1187 9 9.8 9Z" fill="#B9251C" />
                                                            </svg>

                                                        </span>
                                                        <!--<span class="user-avatar-desc">{{participant.participantEmail}}</span>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <hr *ngIf="(demoParticipantArr && demoParticipantArr.length > 0)" />
                                <div class="container info-dialog-container clearfix" *ngIf="(demoParticipantArr && demoParticipantArr.length > 0)">
                                    <div class="participant-sub-title" *ngIf="demoParticipantArr && demoParticipantArr.length > 0">{{PageContents.lblDemoParticipants}}</div>

                                    <div class="row demo-user-row" style="display: inline-flex !important; width: 100%;">
                                        <div class="user-div" *ngIf="dummyUsers[0]?.isDemoUser">
                                           
                                                <p class="user-name">{{dummyUsers[0]?.userName}} </p>
                                                <span class="user-profile">{{PageContents.lblDummyProfile}}</span>
                                           
                                        </div>

                                        <div class="user-div" *ngIf="dummyUsers[1]?.isDemoUser">
                                           
                                                <p class="user-name">{{dummyUsers[1]?.userName}} </p>
                                                <span class="user-profile">{{PageContents.lblDummyProfile}}</span>
                                           
                                        </div>

                                        <div class="user-div" *ngIf="dummyUsers[2]?.isDemoUser">
                                            
                                                <p class="user-name">{{dummyUsers[2]?.userName}} </p>
                                                <span class="user-profile">{{PageContents.lblDummyProfile}}</span>
                                            
                                        </div>

                                        <div class="user-div" *ngIf="dummyUsers[3]?.isDemoUser">
                                           
                                                <p class="user-name">{{dummyUsers[3]?.userName}} </p>
                                                <span class="user-profile">{{PageContents.lblDummyProfile}}</span>
                                           
                                        </div>

                                        <div class="user-div" *ngIf="dummyUsers[4]?.isDemoUser">
                                          
                                                <p class="user-name">{{dummyUsers[4]?.userName}} </p>
                                                <span class="user-profile">{{PageContents.lblDummyProfile}}</span>
                                           
                                        </div>
                                    </div>
                                </div>



                                <hr *ngIf="(EYParticipantaArr && EYParticipantaArr.length > 0)" />
                                <div class="container info-dialog-container clearfix" *ngIf="(EYParticipantaArr && EYParticipantaArr.length > 0)">
                                    <div *ngIf="growthPlanTypeId != 5" class="participant-name">{{PageContents.lblFacilitatorAndAttendees}}</div>
                                    <div class="row" *ngIf="faciliatorArr && faciliatorArr.facilitatorName && growthPlanTypeId != 5">
                                        <div class="col-6">
                                            <div class="participant-sub-title">{{PageContents.lblFacilitator}}</div>
                                            <div class="row">
                                                <div class="col user-avatar">
                                                    <div class="user-avatar-black">{{faciliatorArr.facilitatorAbbrivation}}</div>
                                                    <div class="user-info">
                                                        <span class="user-avatar-name">{{faciliatorArr.facilitatorName}}</span>
                                                        <span class="user-avatar-desc">{{faciliatorArr.facilitatorCompanyName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="participant-sub-title" *ngIf="EYParticipantaArr && EYParticipantaArr.length > 0">{{growthPlanTypeId == 5 ? PageContents.lblEYAttendees : PageContents.lblAttendees}}</div>

                                    <div class="row" *ngIf="EYParticipantaArr && EYParticipantaArr.length > 0">

                                        <div class="col-6" *ngFor="let participant of EYParticipantaArr">

                                            <div class="row">
                                                <div class="col user-avatar">
                                                    <div class="user-avatar-black">{{getIinitalsIcon(participant)}}</div>
                                                    <div class="user-info">
                                                        <span class="user-avatar-name extrapadding"> {{participant.name}}</span>
                                                        <span class="user-avatar-desc">{{participant.email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <p class="mb-3 invite-instruct" *ngIf="!showNomination && isLoaded && !growthPlanDetailsArr2.nominatedGrowthPlanId && isInvitationAccepted == null && !isLocked && ((growthPlanTypeIdNominate == 3 && !this.isOwner) || (growthPlanTypeIdNominate == 5 && !isFacilitator))">{{PageContents.lblInviteInstruction}}</p>

                <span class="mb-3 nominate-msg" *ngIf="!showNomination && isLoaded && !growthPlanDetailsArr2.nominatedGrowthPlanId && isInvitationAccepted == true && isOwnerSelfStarted && !checkisAssessmentStarted && isOwner && !isLocked && ((growthPlanTypeIdNominate == 3) || (growthPlanTypeIdNominate == 5 && !isFacilitator))">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{PageContents.msgOverwriteSelfScore}}
                </span>

                <span class="mb-3 nominate-msg" *ngIf="!showNomination && isLoaded && !growthPlanDetailsArr2.nominatedGrowthPlanId && isInvitationAccepted == true && isLocked && !isOwner && !isOwnerSelfStarted && ((growthPlanTypeIdNominate == 3) || (growthPlanTypeIdNominate == 5 && !isFacilitator))">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{PageContents.lblNoNominateMeetingComplete}}
                </span>

                <div class="nominate-container" *ngIf="!showNomination && isLoaded && !growthPlanDetailsArr2.nominatedGrowthPlanId && !isLocked && ((isInvitationAccepted && !(isOwnerSelfStarted && growthPlanDetailsArr2.isAssessmentStarted && this.isOwner)) || (!(isOwnerSelfStarted && growthPlanDetailsArr2.isAssessmentStarted) && growthPlanTypeIdNominate == 3 && this.isOwner))">
                    <!--<p>{{PageContents.lblNominateGrowthPlanDescription}}</p>-->
                    <p style="font-size:14px;" *ngIf="!isOwnerSelfStarted || !isOwner">
                        <svg width="20" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.58438 9.5H5.41563C2.42531 9.5 0 11.925 0 14.9156C0 15.5156 0.485 16 1.08312 16H12.9156C13.5156 16 14 15.5156 14 14.9156C14 11.925 11.575 9.5 8.58438 9.5ZM7 8C9.20938 8 11 6.20906 11 4C11 1.79094 9.20938 0 7 0C4.79063 0 3 1.79094 3 4C3 6.20906 4.79063 8 7 8ZM19.7594 4.2C19.4562 3.91875 18.9809 3.93584 18.6994 4.23956L15.9791 7.16925L14.7794 5.96956C14.4864 5.67659 14.0119 5.67659 13.7188 5.96956C13.4256 6.26253 13.4258 6.73706 13.7188 7.03019L15.4688 8.78019C15.6094 8.92188 15.8 9 16 9H16.0137C16.2178 8.99609 16.4112 8.90966 16.5499 8.76025L19.7999 5.26025C20.0812 4.95625 20.0625 4.48125 19.7594 4.2Z" fill="#168736" />
                        </svg> &nbsp;&nbsp;{{PageContents.lblNominateInstruction}}
                    </p>

                    <button class="btn btn-outline mb-2" [disabled]="growthPlanDetailsArr2.isFinalized" (click)="showNominate()">{{PageContents.btnNominateGrowthPlan}}</button>
                </div>

                <div class="selected-gp-cont mb-2" *ngIf="!showNomination && isLoaded && growthPlanDetailsArr2.nominatedGrowthPlanId && isInvitationAccepted  && isInvitationAccepted && !isLocked">
                    <p class="instruction">{{PageContents.lblNominatedGrowthPlanInstruction}}</p>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 label-style">{{PageContents.lblSelectedGrowthplan}}</div>
                        <div class="col-8 label-value">
                            <span class="nominated_gp_link" (click)="activeGP()">{{selectedGPName}}</span>
                            <span *ngIf="!growthPlanDetailsArr2.isAssessmentStarted" class="create-link ms-2" [ngClass]="{'dis':growthPlanDetailsArr2.isAssessmentStarted}" placement="bottom" containerClass="tooltip-style" [tooltip]="getContent()" (click)="changeGP()">{{PageContents.lblChangeGrowthPlan}}</span>
                        </div>
                    </div>
                </div>

                <div class="nominate-gp" *ngIf="showNomination && isInvitationAccepted">
                    <div *ngIf="showSelectGP">
                        <!--[ngClass]="{'red-border-class': !contactForm.get('reason').valid && contactForm.get('reason').touched}"-->
                        <div class="facilitator-name mb-1">
                            {{growthPlanDetailsArr2.nominatedGrowthPlanId ? PageContents.lblChangeGrowthPlan:(growthPlanDetailsArr2.nominatedGrowthPlanId == 0 ? PageContents.lblSelectGrowthPlan:'')}}
                        </div>
                        <select [(ngModel)]="selectedGP" class="form-select" (change)="selectGP(selectedGP)">
                            <option *ngFor="let gp of selfGP" [ngValue]="gp.growthPlanId">
                                {{ gp.growthPlanName }}
                            </option>
                        </select>
                        <button class="btn btn-outline" (click)="saveGrowthPlan(1)">{{SiteContents.buttonContinue}}</button>
                        <span class="edit-gp-btn-or" style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="edit-gp-btn-cancel" (click)="cancelGpName()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        <div *ngIf="errorExist" class="error-block">{{PageContents.msgGrowthPlanAlreadyNominated}}</div>
                        <div *ngIf="count4error" class="error-block">{{PageContents.msgFifthTimeNominatingGrowthPlan}}</div>
                        <p class="c-or">{{PageContents.lblCreateGrowthPlanOr}}</p>
                        <p class="create-link" (click)="showSelectGP = false" id="createLinkId">{{PageContents.lblCreateNewGrowthplanLink}}</p>
                    </div>
                    <div *ngIf="!showSelectGP">
                        <p>{{PageContents.lblNewGrowthplanName}}</p>
                        <input type="text" name="gp_title" autocomplete="off"
                               [ngClass]="{'red-border-class': errorMessageRename || errorGPEmpty}"
                               [placeholder]="PageContents.lblEnterAssessmentName"
                               maxlength="50"
                               (keyup)="clearError()"
                               (keypress)="onKeyValidate($event)"
                               (keyup.enter)="saveGrowthPlan(2)" [(ngModel)]="gpTitle">

                        <button class="btn btn-outline" (click)="saveGrowthPlan(2)">{{SiteContents.buttonContinue}}</button>
                        <span class="edit-gp-btn-or">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="edit-gp-btn-cancel" (click)="cancelGpName()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        <div *ngIf="errorMessageRename" class="error-block">{{errorMsg}}</div>
                        <div *ngIf="errorGPEmpty" class="error-block">{{PageContents.lblEnterAssessmentName}}</div>
                        <p class="c-or">{{PageContents.lblCreateGrowthPlanOr}}</p>
                        <p class="create-link" (click)="showSelectGP = true">{{PageContents.lnkSelectaGrowthPlan}}</p>
                    </div>
                </div>



                <ng-container *ngIf="isParticipantCheck && isInvitationAccepted == null && !isLocked && ((growthPlanTypeIdNominate == 3 && !this.isOwner) || growthPlanTypeIdNominate == 5)">
                    <app-flash-message [show]="showMessage" [flashType]="flashType" [pageContent]="PageContents" (showHideChange)="showHideChange($event)"></app-flash-message>

                    <button class="btn btn-outline" (click)="acceptDecline('accept')">{{PageContents.lblAcceptInvite}}</button>
                    <div (click)="acceptDecline('declined')" class="ms-5" style="display: inline-block; cursor: pointer;">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 0C11.3273 0 12 0.767411 12 1.71429V10.2857C12 11.2312 11.3273 12 10.5 12H1.5C0.671484 12 0 11.2312 0 10.2857V1.71429C0 0.767411 0.671484 0 1.5 0H10.5ZM4.10156 4.71696L5.20547 5.97589L4.10156 7.25893C3.88359 7.51071 3.88359 7.91786 4.10156 8.14554C4.32188 8.41875 4.67813 8.41875 4.87734 8.14554L5.97891 6.90804L7.10156 8.14554C7.32188 8.41875 7.67813 8.41875 7.87734 8.14554C8.11641 7.91786 8.11641 7.51071 7.87734 7.25893L6.79453 5.97589L7.87734 4.71696C8.11641 4.48929 8.11641 4.08214 7.87734 3.83036C7.67813 3.58125 7.32188 3.58125 7.10156 3.83036L5.97891 5.09196L4.87734 3.83036C4.67813 3.58125 4.32188 3.58125 4.10156 3.83036C3.88359 4.08214 3.88359 4.48929 4.10156 4.71696Z" fill="#2E2E38" />
                        </svg>
                        &nbsp;{{PageContents.lblDeclineInvite}}
                    </div>
                </ng-container>
                <a class="pull-right margin_bottom manage_link " *ngIf="showMangeBtn()" (click)="goToCorporatePage()">{{PageContents.lblManageGrowthPlanDetails}}</a>
                <button class="btn btn-outline pull-right margin_bottom" [ngClass]="{'pull-left clear-fix mt-2': isInvitationAccepted == null && !isLocked}" style="margin-right: 3%;" *ngIf="showDuplicateBtn()" (click)="duplicateGP()">{{PageContents.lblDuplicate}}</button>
                <button class="btn btn-outline" (click)="deleteGP()" *ngIf="showGpDeleteButton">
                    <img src="../../../assets/images/delete-icon.png" alt="share" />{{PageContents.lblDelete}}
                </button>
            </div>
        </div>
        <div class="modal-content rowWithoutFlex delete-gp-section" *ngIf="showGpDelete">
            <div class="modal-header">
                <span> <h4 *ngIf="!errorMsgDelete" class="modal-title pull-left">{{PageContents.lblConfirmDeleteHeader}}</h4> </span>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
            </div>

            <div class="modal-body" *ngIf="!errorMsgDelete">
                <p> {{PageContents.lblConfirmDeleteMessage}}</p>
                <p> {{PageContents.msgConfirmDelete}}</p>

                <div class="row button-rows">

                    <div class="col-12 share-action-btn">
                        <button class="btn btn-outline" (click)="deleteGrowthPlan()"> {{PageContents.lblDelete}}</button>
                        <span>{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="share-action-btn-cancel" (click)="cancelDeleteGP()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="errorMsgDelete">
                <p> {{PageContents.msgDeleteLastGrowthPlan}}</p>
                <div class="row button-rows">
                    <div class="col-12 share-action-btn btn-delete-error-margin-gp">
                        <button class="btn btn-outline" (click)="closeDialog()">{{SiteContents.buttonOk}}</button>
                    </div>
                </div>
            </div>


        </div>
        <!--Edit-->
        <div class="modal-content rowWithoutFlex f2f-info-duplicate-growth-plan" *ngIf="showGpDuplicate && !isLoading" eyv-update-panel="f2finfoduplicategrowthplan" update-panel-action="click">
            <div class="modal-header">
                <h4 class="modal-title pull-left plan-name" *ngIf="!hasAxisDifference || isContinued">{{PageContents.lblDuplicate}} - {{growthPlan.growthPlanName}}</h4>
                <h4 class="modal-title pull-left plan-name" *ngIf="hasAxisDifference && !isContinued">
                    {{this.otherContents.lblGPAxisWarning}}
                    <img class="matrix-icon" [src]="this.otherContents.lblDuplicateGPAxisPopup" />
                </h4>
                <span class="whiteClose">
                    <img (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
                </span>
            </div>
            <div class="modal-body" *ngIf="hasAxisDifference && !isContinued">
                <p class="create-desc" [innerHtml]="axesDifferenceModalContent" style="margin-top: 15px;"></p>
                <div class="axes-difference-content">
                    <button class="btn btn-outline" (click)="isContinued = !isContinued">{{this.otherContents.lblDuplicateGPAxisContinue}}</button>
                </div>
            </div>
            <div class="modal-body" custom-scroll [scrollPos]="scrollPos" *ngIf="!hasAxisDifference || isContinued">
                <p class="create-desc ellipsis" [innerHtml]="PageContents.lblDuplicateGrowthPlanTagLine"></p>
                <form name="form" #duplicate_GP="ngForm">
                    <div class="plan-name-container">
                        <label class="plan-name-lbl">{{PageContents.lblLTMNewGrowthPlanName}}</label>
                        <input class="plan-name" autocomplete="off" type="text" name="planName"
                               [ngClass]="{'red-border-class': errorMessageData || errorGPEmptyDuplicate}"
                               [(ngModel)]="duplicateGrowthPlanName" #planName="ngModel" maxlength="50"
                               required (keyup)="clearErrorMsg()" placeholder="{{PageContents.lblGrowthPlanName}}" />
                        <div *ngIf="errorGPEmptyDuplicate" class="help-block">{{PageContents.messageGrowthPlanPleaseEnter}} {{PageContents.lblNewGrowthPlanNameForDuplicate | lowercase }}</div>
                        <div *ngIf="errorMessageData" class="help-block">{{errorMessageData}}</div>
                    </div>
                    <p class="create-desc ellipsis" [innerHtml]="PageContents.lblLTMGrowthPlanDupInfo"></p>

                    <div class="growth-plan-footer">
                        <button class="btn-outline" (click)="duplicateGrowthPlanSubmission()">{{PageContents.lblCreateGrowthPlanSubmit}}</button>
                        <span class="or">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="back" (click)="closeDialog()">{{PageContents.lblCreateGrowthPlanCancel}}</span>
                        <div *ngIf="errorresponseMessageData" class="help-block errorresponseMessageDataHeight" id="errorresponseMessageDataID">{{errorresponseMessageData}}</div>
                    </div>
                </form>
            </div>
        </div>
        <!--</div>-->
    </div>
</div>
<!-- Delete button popup-->
