

<div class="modal fade f2f-verify-modal"
     bsModal #f2fVerificationModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog modal-dialog-centered">

        <div class="modal-content rowWithoutFlex">

            <div class="modal-header">
                <h4 class="modal-title pull-left">{{PageContents.btnDownLoad}}</h4>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />

            </div>
            <div class="modal-body">
                <p class="f-title">{{PageContents.msgEnterFacilitatorCode}}</p>
                <form name="form" #fc="ngForm" class="f-code" onsubmit="return false;">
                    <label class="form-label">{{PageContents.lblFacilitatorCode}}</label>
                    <!--<input [readonly]="validFac" [ngClass]="{'red-border-class': ((facCode.dirty || facCode.touched) && facCode.errors) || errorMessage || notMatchingMessage}"
                           type="password" name="facCode" #facCode="ngModel" [(ngModel)]="facCodeText"
                           class="form-value" maxlength="5" pattern="^$|^[A-Za-z0-9]+" required
                           (keyup)="errorMessage = false;notMatchingMessage = false" onDrag="return false" onDrop="return false" onPaste="return false" />
                    <button class="btn btn-outline btn-code" type="submit" (click)="chkFACode()">{{SiteContents.buttonSubmit}}</button>-->

                    <input autofocus #fCInput [readonly]="validFac" [ngClass]="{'red-border-class': ((facCode.dirty || facCode.touched) && facCode.errors) || errorMessage || notMatchingMessage || emptyCodeErrorMessage}"
                           type="password" name="facCode" #facCode="ngModel" [(ngModel)]="facCodeText" autocomplete="off"
                           class="form-value" maxlength="5" pattern="^$|^[A-Za-z0-9]+" required
                          (keyup)="errorMessage = false;notMatchingMessage = false; emptyCodeErrorMessage = false"  onDrag="return false" onDrop="return false" onPaste="return false" />&nbsp;
                    <button class="btn btn-outline btn-code" type="submit" *ngIf="!validFac" (click)="chkFACode()">{{SiteContents.buttonSubmit}}</button>
                    <div class="valid" *ngIf="validFac">
                        <img src="../../../assets/images/tick-icon.png" />
                    </div>

                    <!--<div *ngIf="!facCode.valid && (facCode.dirty || facCode.touched || fc.submitted)" class="error-block">
                        <p *ngIf="facCode.errors.required">{{PageContents.msgValidCode}}</p>
                        <p *ngIf="facCode.errors.pattern">{{PageContents.msgValidCode}}</p>
                    </div>-->

                    <div *ngIf="!facCode.valid && (facCode.dirty || facCode.touched || fc.submitted)" class="error-block">
                        <p *ngIf="facCode.errors.required">{{PageContents.msgNeedFacilitatorCode}}</p>
                        <p *ngIf="facCode.errors.pattern">{{PageContents.msgNeedFacilitatorCode}}</p>
                    </div>

                    <div *ngIf="emptyCodeErrorMessage" class="error-block">{{PageContents.msgNeedFacilitatorCode}}</div>
                    <div *ngIf="errorMessage" class="error-block">{{PageContents.msgValidCode}}</div>
                    <div *ngIf="notMatchingMessage" class="error-block">{{PageContents.msgCodeNotMatching}}</div>
                    <br />
                    
                    <div class="profile-contents" *ngIf="validFac && facilitatorDetails">
                        <div class="profile">
                            <div class="left">
                                <img [src]="facilitatorDetails.profileUrl" />
                            </div>
                            <div class="right">
                                <p class="name">{{facilitatorDetails.firstName}}&nbsp;{{facilitatorDetails.lastName}}</p>
                                <p class="company">{{facilitatorDetails.company}}</p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <button class="btn btn-outline btn-code" id="btnFinalizeReport" type="submit" *ngIf="validFac" (click)="finalizereport()">{{PageContents.btnDownLoad}}</button>
                </form>
                
            </div>
    </div>
</div>
    </div>

