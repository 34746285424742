<div *ngFor="let alert of alerts">
    <ng-container *ngIf="alert.message != undefined">
        <!--add class to apply custom syles [class]='getAlertType(alert)+"-box"'-->
        <alert [type]="getAlertType(alert)" dismissible="true" [dismissOnTimeout]='alert.timeout || defaultTimeout' *ngIf="alert.type!==4">
            <span *ngIf="alert.type===0" class='fa-lg glyphicon glyphicon-remove-sign'></span>
            <span *ngIf="alert.type===1" class='fa-lg glyphicon glyphicon-info-sign'></span>
            <span *ngIf="alert.type===2" class='fa-lg glyphicon glyphicon-ok-sign'></span>
            <span *ngIf="alert.type===3" class='fa-lg glyphicon glyphicon-warning-sign'></span>
            <span *ngIf="alert.title" class="title">{{alert.title}} :</span>
            <span class="message">{{alert.message}}</span>
        </alert>
    </ng-container>
    <error-dialog *ngIf="alert.type===4" [title]="alert.title" [message]="alert.message"></error-dialog>
</div>