import { Injectable, Inject } from '@angular/core';


import { HTTPService } from './http.service';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { Constants } from './constant.service';
import { LogAction } from "./model/log-models";
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
const LOG_API = environment.VELOCITY_API;

@Injectable()
export class LogService {
    constructor(
        private httpService: HTTPService,
        private constant: Constants
    ) { }
    

    doGet(baseurl: string) {
        
        return this.httpService.request({
            url: baseurl,
            method: 'GET'
        }).pipe(tap((x: any) => {
           
        }))
          .pipe(catchError((err: any, caught: any) => {
                return throwError(err);
            }));
    }

    doPost(url: string, params?: any) {
        params.isExternal=true;
        
        return this.httpService.request({
            url: url,
            method: 'POST',
            params: params
        })
          .pipe(tap((x: any) => {
            }))
            .pipe(catchError((err: any, caught: any) => {
                return throwError(err);
            }));
    }

    logAction(log: LogAction) {
 
            this.doPost(LOG_API + this.constant.LOGGING.LOG_ACTIONS,
                log).subscribe((response: Response) => {
                    //console.log(response.message);
                }, (err: any) => {
                    //console.log(err);
                });
        
    }
}

