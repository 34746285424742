import { Component, Input, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';

import {Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';

@Component({
    //moduleId: module.id.toString(),
    selector: "video-dialog-landingPage",
    templateUrl: './video-dialog-landingPage.component.html', 
    styleUrls: ['./video-dialog-landingPage.component.css']
})

export class VideoDialogLandingPageComponent {
    videoUrl: string = "";
  @ViewChild('videoModalHottopic', { static: true }) videoModal: ModalDirective ;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
@Output("closeEvent") componentEvent: any = new EventEmitter();

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(
        private injector: Injector)
    {
        this.videoUrl = this.injector.get('videoUrl');
    }

    ngOnInit() {
      
    }



    onModalClosed($event:any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }
    

    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.videoModal.config = this.config;
        this.videoModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.videoModal.hide();
    }
 
}
