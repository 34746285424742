import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { NotificationComponent } from './notification/notification.component';
const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: '', loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule) },
  { path: 'focus', loadChildren: () => import('src/app/focus/focus.module').then(m => m.FocusModule) },
  { path: 'act', loadChildren: () => import('src/app/act/act.module').then(m => m.ActModule) },
  { path: 'search', loadChildren: () => import('src/app/search/search.module').then(m => m.SearchModule) },
  { path: 'about', loadChildren: () => import('src/app/about/about.module').then(m => m.AboutModule) },
  { path: 'general', loadChildren: () => import('src/app/general/general.module').then(m => m.GeneralModule) },
  { path: 'user-detail', loadChildren: () => import('src/app/user/user.module').then(m => m.UserModule) },
  //  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  ////  { path: 'connect', loadChildren: './connect/connect.module#ConnectModule' },
  //  { path: 'search', loadChildren: './search/search.module#SearchModule' },
  //  { path: 'focus', loadChildren: './focus/focus.module#FocusModule' },
  //  { path: '', loadChildren: './auth/auth.module#AuthModule' },
  //  { path: 'act', loadChildren: './act/act.module#ActModule' },
  //  /** Commeted my story route for Future stories */
  //  // { path: 'my-story', loadChildren: './my-story/my-story.module#MyStoryModule' },
  //  { path: 'about', loadChildren: './about/about.module#AboutModule' },
  //  { path: 'general', loadChildren: './general/general.module#GeneralModule' },
  //  { path: 'user-detail', loadChildren: './user/user.module#UserModule' },
    {
        path: 'notification', component: NotificationComponent,
        canActivate: [AuthGuard],
        data: { permission: "General" }
    },
    // otherwise redirect to error page
    { path: '**', redirectTo: '/general/error-404' }
];

@NgModule({
    imports: [
    RouterModule.forRoot(routes,{
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
    })],
    exports: [
        RouterModule
    ]
})

export class AppRouteModule { }
