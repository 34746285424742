import { Component, OnInit, ViewChild, HostListener, ViewEncapsulation  } from '@angular/core';
import { ModalDialogService } from './modal-dialog-module/modal-dialog.service';
import { DynamicModalContainerComponent } from './modal-dialog-module/dynamic-modal-loader.component';

import { UtilService } from './service/util.service';
import { DataShareService } from './service/data-share.service';

import { UserService } from './auth/service/user.service';
import { CONTENTS } from './config/content.const';

import { Router } from '@angular/router';
import { NotificationService } from './service/notification.service';

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html', 
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  @ViewChild('dynamicModalContainer', { static: true }) dynamicModalContainer: DynamicModalContainerComponent;
  title = 'app';
  isIframe = false;
  loginDisplay = false;
    currentUser: any = null;
    subscription: Subscription;
    userLoggin: Subscription;
    changeLang: Subscription;
    callMenu: boolean = false;
    menuContent: any;
    constructor(
        private dialogService: ModalDialogService,
        private router:Router,
        private utilService: UtilService,
        private notificationService: NotificationService,
      private userService: UserService,
        private dataShareService: DataShareService
    ) {
        //this.adalService.init(this.secretService.adalConfig);
    }
    ngOnInit() {
     
      this.isIframe = window !== window.parent && !window.opener;

        this.dialogService.containerElementRef = this.dynamicModalContainer;
        this.utilService.scrollToTopOnNavigation(this.router);

        //This will close all the modal dialogues when the user clicks on back button
        this.dialogService.closeDialogOnNavigationStart(this.router);

        this.notificationService.showNotificationOnRoute(this.router);
        this.userLoggin = this.userService.isLoggedIn().subscribe((x: boolean) => {
            if (x) {
                if (!this.callMenu) {
                    this.utilService.getContent(CONTENTS.MENUCONTENT).subscribe((x: any) => {
                        this.menuContent = x;
                    });
                    this.callMenu = true;
                }
            }
        });
        this.changeLang = this.dataShareService.callChangeLanguage.subscribe((res) => {
            if (res) {

                this.utilService.getContent(CONTENTS.MENUCONTENT).subscribe((x: any) => {
                    this.menuContent = x;
                });
            }
        });

        //Disable all the console.log
        if (environment.production) {
            console.log = function (): void { };
        }
    }

    onActivate(event) {
        window.scroll(0, 0);
    }

    onRightClick(event: Event) {
        //if (process.env.ENV != "dev") {
        //    event.preventDefault();
        //}
        
  }
   
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        
        if (event.shiftKey && event.ctrlKey && event.which == 73) {
            //console.log('inspect')
            event.preventDefault();
        }
        if (event.ctrlKey && event.which == 83) {
            //console.log('save')
            event.preventDefault();
        }
        if (event.ctrlKey && event.which == 80) {
            //console.log('print')
            event.preventDefault();
        }
        if (event.keyCode == 123) {
            //console.log('inspect')
            event.preventDefault();
           // event.preventDefault();
            
        }
        
    }
    ngOnDestroy() {
        // disconnect from broadcast service on component destroy
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.userLoggin) {
            this.userLoggin.unsubscribe();
        }
        if (this.changeLang) {
            this.changeLang.unsubscribe();
        }
        
    }
}
