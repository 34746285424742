import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { ModalDialogService } from '../modal-dialog.service';


@Component({
    selector: "add-notes-assesment",
  templateUrl: './add-notes-assesment.component.html',
  styleUrls: ['./add-notes-assesment.component.scss']
})

export class addNotesAssesmentComponent implements OnInit, AfterViewInit {

    //SiteContents: any = {};
    observable: Observable<any>;
    //codeOfConduct: string = "";
    subject: any;
    //data: any;
    inputObj: any;
    btnSubmitText: string = "";
    lblNotes: string = ""
    noteValue: string = '';
    lblEditNote: string;
    @Output("closeEvent") componentEvent: any = new EventEmitter()
  @ViewChild('addNotesModal', { static: true }) addNotesModal: ModalDirective;
  @ViewChild("titleTextArea", { static: false }) titleTextArea: ElementRef;
   
    isLocked: boolean;
    driverId:number;
    gpId:number;
    driverName:string;
    noteTitle:string;
    editNoteTitle:string;
    notePlaceholder:string;
    submitBtnlbl:string;
    orLbl:string;
    cancelLbl:string;
    isContainingSpecialCharacter:boolean=false;
    customActionCardRequired :string;
    fromView:boolean = false;
    note:any;
    title:string;
    isEdit:boolean = false;
    isSubDriver:boolean = false;
    constructor(
     
        private injector: Injector,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private dialogService: ModalDialogService,
    ) {
        this.inputObj = this.injector.get('inputObj');
        this.isLocked = this.inputObj.isLocked;
        this.driverId = this.inputObj.driverId;
        this.gpId = this.inputObj.gpId;
        this.driverName = this.inputObj.driverName;
        this.noteTitle = this.inputObj.noteTitle;
        this.editNoteTitle = this.inputObj.editNoteTitle;
        this.notePlaceholder = this.inputObj.notePlaceholder;
        this.submitBtnlbl = this.inputObj.submitBtnlbl;
        this.orLbl = this.inputObj.orLbl;
        this.cancelLbl = this.inputObj.cancelLbl;
        this.fromView = this.inputObj.fromView;
        this.note = this.inputObj.note;
        this.title = this.noteTitle;
        if(this.note != undefined) {
          this.noteValue = this.note.note;
          this.title = this.editNoteTitle;
          this.isEdit = true;
        }
        this.title = this.title.replace('#pillarname',this.driverName);
        this.title = this.title.replace('#subpillarname',this.driverName);
        if(this.inputObj.isSubDriver != undefined) {
          this.isSubDriver = this.inputObj.isSubDriver;
        }
        
        // this.btnSubmitText = this.inputObj.btnSubmit;
        // this.lblNotes = this.inputObj.lblNote;
        // this.noteValue = this.card.note ? this.card.note : '';
        // this.lblEditNote = this.inputObj.lblEditNote;
        this.customActionCardRequired = this.inputObj.lblcustomActionCardRequired
        // //this.getCodeOfConduct();
    }

    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


  //  @ViewChild('addNotesModal') termsModal: any;
    returnedValue: any = {};
    // offsetTop = 0;
    ngOnInit() {
    }

    autogrow() {
        var textArea = this.titleTextArea.nativeElement;
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';

        let isValidGp = this.dialogService.validateAlphanumericName(this.noteValue);
        if (!isValidGp) {
          this.isContainingSpecialCharacter = false;
        }
        else {
          if (this.noteValue.trim() != "") {
            this.isContainingSpecialCharacter = true;
        
          }
          else {
            this.isContainingSpecialCharacter = false;
          }
        }
    }

    cancelClicked() {
        this.returnedValue.action = 'no';
        this.closeDialog();
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });

    }

    ngAfterViewInit() {
    }

    okayClicked() {
        // restrict the readonly growth plan changes
        if (this.isLocked) {
            return;
        }

        let params = {
            'note': this.noteValue,
            growthPlanId:this.gpId
        }
        if(this.note != undefined) {
          params['noteId'] = this.note.noteId;
        }

        if(this.isSubDriver) {
          params['subDriverId'] = +this.driverId;
        } else {
          params['driverId'] = +this.driverId;
        }
        console.log(params);
        this.velocityService.saveAssesmentNote(params).subscribe(
            (data: any) => {
                this.returnedValue.action = 'yes';
                this.closeDialog();
            });
    }
    
    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.addNotesModal.config = this.config;
        this.addNotesModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.addNotesModal.hide();
    }

    onChange(e){
        let isValidGp = this.dialogService.validateAlphanumericName(this.noteValue);
        if (!isValidGp) {
          this.isContainingSpecialCharacter = false;
        }
        else {
          if (this.noteValue.trim() != "") {
            this.isContainingSpecialCharacter = true;
        
          }
          else {
            this.isContainingSpecialCharacter = false;
          }
        }
      }
      isSubmitDisabled(){
        if(this.noteValue.trim()=='' || this.isContainingSpecialCharacter)
            return true;

        if(this.isEdit && this.note.note == this.noteValue) {
          return true;
        }
        return false;    
      }
}


