import { Directive, Input, Output, EventEmitter, Renderer2, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from './../../service/util.service';
import { DragDataService } from './service/drag-data.service';
import { DropEvent } from './models/dragevent';
declare var $: any;

@Directive({
    selector: '[eyv-droppable]'

})
export class DroppableDirective implements OnInit, OnDestroy, OnChanges {

    @Input('eyv-dropEnabled') dropEnabled?: boolean = true;
    @Output('onEYVDropSuccess') onDropSuccess: EventEmitter<any> = new EventEmitter<any>();

    dragData: any = null;



    constructor(private renderer: Renderer2, private el: ElementRef, private utilService: UtilService, private dragDataService: DragDataService) {
        let me: DroppableDirective = this;
        //$(el.nativeElement).draggable({ helper: "clone" });
        //if (this.dropEnabled == false) {
            //$(el.nativeElement).droppable({ disabled: true });
        //}
        //else {
            $(el.nativeElement).droppable({
                drop: function (event, ui) {
                    console.log("drop", event, ui);
                    me.dragData = me.dragDataService.dragData;
                    var offset = me.getOffset(ui, me);
                    var mouseEvent = { offsetX: offset.offsetX, offsetY: offset.offsetY, target: event.target };
                    var dropEvent: DropEvent = { event: event, ui: ui, mouseEvent: mouseEvent, dragData: me.dragData };
                    
                    me.onDropSuccess.emit(dropEvent);
                }
            });
        //}


    }

    ngOnInit() {

    }






    ngOnDestroy() {

    }



    ngOnChanges(changes: SimpleChanges) {
        //console.log("changes", changes);
        for (let prop in changes) {
            if (prop == 'dropEnabled') {
                var change = changes[prop];
                if (change.currentValue == true) {
                    $(this.el.nativeElement).droppable({ disabled: false });
                    //console.log("enabled")
                }
                else {
                    $(this.el.nativeElement).droppable({ disabled: true });
                    //console.log("disabled")
                }
            }
        }
    }


    getOffset(draggable: any, component: DroppableDirective) {
        
        var offsetX: number = (draggable.position.left - $(component.el.nativeElement).offset().left) || 0;
        var offsetY: number = (draggable.position.top - $(component.el.nativeElement).offset().top) || 0
        return { offsetX: offsetX, offsetY: offsetY };
    }

}