import { Directive, ElementRef, OnInit, AfterViewInit, Input, Renderer2 } from '@angular/core';
import { ProgressBarService } from './../progres-bar.service';
import { Observable } from 'rxjs';

@Directive(
    {
        selector:"[eyv-update-panel]"
    }
)
export class InPageUpdateDirective implements OnInit, AfterViewInit{
    nativeElementReference: any;
    progressBarContainer: any;
    observable: Observable<any>;
    @Input("eyv-update-panel") panelName: string;
    @Input("update-panel-action") action?: string="init";
    constructor(private el: ElementRef, private renderer: Renderer2, private progressBarService: ProgressBarService) {

        this.nativeElementReference = el.nativeElement;
        this.observable = this.progressBarService.updatePanelObservable;
        
        
    }
    ngOnInit() {
        //set the style for parent element
        this.renderer.setStyle(this.nativeElementReference, "position", "relative");
        //this.renderer.setStyle(this.nativeElementReference, "min-height", "150px");

        //create child divs that are inserted to the parent element. 
        var createdProgressBarContainerElement = this.progressBarContainer = this.renderer.createElement("div");
        this.renderer.addClass(createdProgressBarContainerElement, "in-page-progressbar-container", );
        if (this.action == "click") {
            this.renderer.setStyle(this.progressBarContainer, "display", "none");
        }

        var createdProgressBarElement = this.renderer.createElement("div");
        this.renderer.addClass(createdProgressBarElement, "in-page-progressbar", );

        //add the progressbar elemnt as the first child
        if (this.nativeElementReference.children && this.nativeElementReference.children.length > 0) {
            this.renderer.insertBefore(this.nativeElementReference, createdProgressBarContainerElement, this.nativeElementReference.children[0]);
        }
        else {
            this.renderer.appendChild(this.nativeElementReference, createdProgressBarContainerElement);
        }

        this.renderer.appendChild(createdProgressBarContainerElement, createdProgressBarElement);

        this.observable.subscribe((x: any) => {
            if (x.updatePanelName == this.panelName) {
                this.renderer.setStyle(this.progressBarContainer, "display", x.show ? "block" : "none");
            }
        });
    }
    ngAfterViewInit() {

       
    }
}