<div class="modal fade error-dialog-modal" bsModal #errorDialog="bs-modal"
     tabindex="-1" role="dialog" [config]="config">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="errorDialog.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{message}}
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-ey-default" (click)="errorDialog.hide()" type="button">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>