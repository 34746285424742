<div class="modal fade terms-modal"
     bsModal #termsModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog container">
        <div class="modal-content" eyv-update-panel="termsAndCondition">
            <div class="modal-header">
                {{fromPage == 'register'?titleText:PageContents.lblupdatedTc}}<span>{{scrollText}}</span>
            </div>
            <div class="date" *ngIf="fromPage != 'register' && updatedDate">
                <span class="dateText">{{PageContents.lblDate}}</span>
                <span>
                    {{updatedDate | datex:'dd-MMM-yyyy hh:mm a' }}
                </span>
            </div>
            <div class="modal-body" id="terms-condition" #container>
                <p [innerHTML]="countryTermsAndCondition"></p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="action-buttons">
                        <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button"
                                [innerHTML]="PageContents.buttonAccept" [disabled]="isAcceptDisabled"></button>
                        <button class="btn btn-cancel" (click)="cancelClicked();" type="button"
                                [innerHTML]="fromPage != 'userProfile'?PageContents.lblCancelLogout:PageContents.buttonCancel"></button>
                    </div>
                </div>
            </div>
            <div class="tc-down-arrow" (click)="scrollPage()" *ngIf="isAcceptDisabled">
<img src="../../../assets/arrow-terms-icon.png"/>
            </div>
        </div>
    </div>
</div>
