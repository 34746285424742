import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({ name: "dateFormatter", pure: false })


export class dateFormatter extends DatePipe {
    transform(selDate: any): any {
        if (selDate) {
            selDate = new Date(selDate);
            var dayValue = selDate.getDate();

            var j = dayValue % 10,
                k = dayValue % 100;
            var appender = "th";
            if (j == 1 && k != 11) {
                appender = "st";
            }
            if (j == 2 && k != 12) {
                appender = "nd";
            }
            if (j == 3 && k != 13) {
                appender = "rd";
            }

            const datePipe = new DatePipe("en-US");
            var monthAppender = datePipe.transform(selDate, 'MMMM');

            return selDate.getDate() + appender + " " + monthAppender + " " + selDate.getFullYear();
        } else {
            return selDate;
        }
        //if (selDate) {
        //    var dayValue = selDate.getDate();
        //    var appender = "";
        //    if (dayValue % 10 == 1) {
        //        appender = "st";
        //    } else if (dayValue % 10 == 2) {
        //        appender = "nd";
        //    } else if (dayValue % 10 == 3) {
        //        appender = "rd";
        //    } else {
        //        appender = "th";
        //    }
        //    const datePipe = new DatePipe("en-US");
        //    var monthAppender = datePipe.transform(selDate, 'MMM');
        //    return selDate.getDate() + appender + " " + monthAppender + " " + selDate.getFullYear();
        //} else {
        //    return selDate;
        //}
    }
} 