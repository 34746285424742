<div class="modal fade corporate-info-dialog-modal"
     bsModal #corporateParticipantInfoDialogModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" >
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{growthPlanDetailsArr.meetingName}}</h4>
                <!--<!--<div class="gp-title-edit" *ngIf="gpNameSectionEdit">
                    <form #form="ngForm">
                        <div class="row">
                            <div class="col-7">
                                <div class="gp-edit-title">
                                    <input type="text" name="gp_title" 
                                           [ngClass]="{'red-border-class': errorMessageRename || errorGPEmpty}"
                                           [placeholder]="PageContents.lblEnterAssessmentName" 
                                           class="form-control" maxlength="50" 
                                           (keyup)="clearError()"
                                           (keyup.enter)="onKeyUPTitle()" [(ngModel)]="gpTitle">
                                </div>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-outline" (click)="onKeyUPTitle()">{{SiteContents.buttonSave}}</button>
                                <span class="edit-gp-btn-cancel">{{PageContents.lblCreateGrowthPlanOr}}</span>
                                <span class="edit-gp-btn-cancel" (click)="cancelRenameGp()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                            </div>
                        </div>
                    </form>
                </div>-->
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />

            </div>
            <!--<div *ngIf="errorMessageRename" class="col-12 error-block">{{PageContents.lblPlanAlreadyExists}}</div>
            <div *ngIf="errorGPEmpty" class="col-12 error-block">{{PageContents.lblEnterAssessmentName}}</div>-->

            <div class="modal-body"  [style.height]="height" eyv-update-panel="CPInfoDialog" custom-scroll>
                <div #body>
                <div class="info-dialog-tag">
                    <span class="dialog-tag">{{PageContents.lblCorporate}}</span>
                </div>
                <div class="nominate-container" *ngIf="!showNomination && isLoaded && !growthPlanDetailsArr.nominatedGrowthPlanId">
                    <p>{{PageContents.lblNominateGrowthPlanDescription}}</p>

                    <button class="btn btn-outline" [disabled]="growthPlanDetailsArr.isFinalized" (click)="showNomination = true">{{PageContents.btnNominateGrowthPlan}}</button>
                </div>
                <div class="selected-gp-cont" *ngIf="!showNomination && isLoaded && growthPlanDetailsArr.nominatedGrowthPlanId">
                    <p>{{selectedMessage}}</p>
                    <div class="row">
                        <div class="col-lg-2 col-md-3 label-style">{{PageContents.lblSelectedGrowthplan}}</div>
                        <div class="col-8 label-value">
                            {{selectedGPName}}
                            <span class="create-link pull-right" [ngClass]="{'dis':growthPlanDetailsArr.isAssessmentStarted}" placement="bottom" containerClass="tooltip-style" [tooltip]="getContent()" (click)="!growthPlanDetailsArr.isAssessmentStarted && showNomination = true">{{PageContents.lblChangeGrowthPlan}}</span>
                        </div>
                    </div>
                </div>
                <div class="nominate-gp" *ngIf="showNomination">
                    <div *ngIf="showSelectGP">
                        <!--[ngClass]="{'red-border-class': !contactForm.get('reason').valid && contactForm.get('reason').touched}"-->

                        <select [(ngModel)]="selectedGP" class="form-control">
                            <option *ngFor="let gp of selfGP" [ngValue]="gp.growthPlanId">
                                {{ gp.growthPlanName }}
                            </option>
                        </select>
                        <button class="btn btn-outline" (click)="saveGrowthPlan(1)">{{SiteContents.buttonContinue}}</button>
                        <span class="edit-gp-btn-or" style="margin-left:8px;margin-right:8px;">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="edit-gp-btn-cancel" (click)="cancelGpName()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        <div *ngIf="errorExist" class="error-block">{{PageContents.msgGrowthPlanAlreadyNominated}}</div>
                        <p class="c-or">{{PageContents.lblCreateGrowthPlanOr}}</p>
                        <p class="create-link" (click)="showSelectGP = false">{{PageContents.lblCreateNewGrowthplanLink}}</p>
                    </div>
                    <div *ngIf="!showSelectGP">
                        <p>{{PageContents.lblNewGrowthplanName}}</p>
                            <input type="text" name="gp_title" autocomplete="off"
                                   [ngClass]="{'red-border-class': errorMessageRename || errorGPEmpty}"
                                   [placeholder]="PageContents.lblEnterAssessmentName"
                                   maxlength="50"
                                   (keyup)="clearError()"
                                   (keypress)="onKeyValidate($event)"
                                   (keyup.enter)="saveGrowthPlan(2)" [(ngModel)]="gpTitle">

                        <button class="btn btn-outline" (click)="saveGrowthPlan(2)">{{SiteContents.buttonContinue}}</button>
                        <span class="edit-gp-btn-or">{{PageContents.lblCreateGrowthPlanOr}}</span>
                        <span class="edit-gp-btn-cancel" (click)="cancelGpName()"><label>{{PageContents.lblCreateGrowthPlanCancel}}</label></span>
                        <div *ngIf="errorMessageRename" class="error-block">{{errorMsg}}</div>
                        <div *ngIf="errorGPEmpty" class="error-block">{{PageContents.lblEnterAssessmentName}}</div>
                        <p class="c-or">{{PageContents.lblCreateGrowthPlanOr}}</p>
                        <p class="create-link" (click)="showSelectGP = true">{{PageContents.lnkSelectaGrowthPlan}}</p>
                    </div>
                </div>
                <div class="container info-dialog-container">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 label-style">{{PageContents.lblViewAllSortCreateddate}}</div>
                        <div class="col-8 label-value">{{growthPlanDetailsArr.createdDate}}</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-3 label-style">{{PageContents.lblMeetingDate}} </div>
                        <div class="col-8 label-value">{{growthPlanDetailsArr.meetingDate}}</div>
                    </div>
                </div>

                <div class="container info-dialog-container clearfix">
                    <div class="row">
                        <div class="col-6 owner-name" *ngIf="growthPlanDetailsArr.growthPlanTypeId == 5">{{PageContents.lblFacilitator}}</div>
                        <div class="col-6 owner-name" *ngIf="growthPlanDetailsArr.ownerName != ''">{{PageContents.lblViewAllOwner}}</div>
                        <div class="col-6 owner-name" *ngIf="growthPlanDetailsArr.delegatedOwnerName !=''">{{PageContents.lblDelegatedOwnerText}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6 user-avatar" *ngIf="growthPlanDetailsArr.growthPlanTypeId == 5">
                            <img class="user-avatar-image" src="{{growthPlanDetailsArr.facilitatorImageUrl}}" />
                            <div class="user-info">
                                <!--ownerImageURL -->
                                <span class="user-avatar-name"> {{growthPlanDetailsArr.facilitatorName}}</span>
                                <span class="user-avatar-desc">{{growthPlanDetailsArr.facilitatorCompanyName}}</span>
                            </div>
                        </div>

                        <div class="col-6 user-avatar" *ngIf="growthPlanDetailsArr.ownerName != ''">
                            <img class="user-avatar-image" src="{{growthPlanDetailsArr.ownerImageUrl}}" />
                            <div class="user-info">
                                <!--ownerImageURL -->
                                <span class="user-avatar-name"> {{growthPlanDetailsArr.ownerName}}</span>
                                <span class="user-avatar-desc">{{growthPlanDetailsArr.ownerCompanyName}}</span>
                            </div>
                        </div>

                        <div class="col-6 user-avatar" *ngIf="growthPlanDetailsArr.delegatedOwnerName !=''">
                            <img class="user-avatar-image" src="{{growthPlanDetailsArr.delegatedOwnerImageUrl}}" />
                            <div class="user-info">
                                <!--ownerImageURL -->
                                <span class="user-avatar-name"> {{growthPlanDetailsArr.delegatedOwnerName}}</span>
                                <span class="user-avatar-desc">{{growthPlanDetailsArr.delegatedOwnerCompanyName}}</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        </div>
            </div>
        </div>

        <!-- Delete button popup-->


