import { Component } from '@angular/core';


@Component({
    ////moduleId: module.id.toString(),
    selector: "page-transition-progess",
  templateUrl: './page-transition.component.html',
  styleUrls: ['./page-transition.component.scss'],
})

export class PageTransitionComponent {
    
    state = "inactive";
    constructor() {
    }

    ngOnInit() { }

    
}
