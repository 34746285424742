export class SaveGrowthPlanActionPrioritize {
    constructor(
        public id: number,
        public growthPlanId: number,
        public IsSelected: boolean,
        public actionPriority?: number,
        public xPos?: number,
        public yPos?: number,
        public xCoordinate?: number,
        public yCoordinate?: number
    ) { }
}

export class SavePrioritizeActionsModel {
    constructor(
        public userId: number,
        public growthPlanId: number,
        public actions: Array<SaveGrowthPlanActionPrioritize> = [],
        public isDriverAssessed: boolean
    ) { }
}

export class ParticipantPrioritizeModel {
    constructor(
        public userId: number,
        public growthPlanId: number,
        public actionType: number,
        public cardId: number
    ) { }
}
