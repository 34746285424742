

<div class="modal fade notes-modal"
     bsModal #addNotesModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">

    <div class="modal-dialog modal-dialog-centered">

        <div class="modal-content rowWithoutFlex">

            <!--<div class="floating-dialog-close float-right" (click)="closeDialog()">
                <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />close
            </div>-->

            <div class="modal-header">
                <div class="modal-title pull-left">
                    <svg *ngIf="fromView" (click)="closeDialog()" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.70508 1.41L3.12508 6L7.70508 10.59L6.29508 12L0.295078 6L6.29508 -6.16331e-08L7.70508 1.41Z" fill="#2E2E38"/>
                    </svg>                        
                    {{ title }}
                </div>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />

            </div>
            <div class="modal-body">
                <div class="row">
                  
                   
                    <div class="col-12 notes">
                        <textarea (keyup)="autogrow()" [placeholder]="notePlaceholder" #titleTextArea class="form-control value-text-subtitle" maxlength="400" type="text" [(ngModel)]="noteValue" (ngModelChange)="onChange($event)"></textarea>
                        <span class="custom-error" *ngIf="isContainingSpecialCharacter">{{customActionCardRequired}}</span>
                    </div>

                </div>
                <!--<div class="row bottom">
                    <div class="col-12 col-sm-3 key-label">
                        {{subtitleLabel}}

                    </div>
                    <div class="col-12 col-sm-9 ">
                        <textarea class="form-control value-text-subtitle" [maxLength]="maxLengthSubtitle" type="text" [(ngModel)]="subtitleValue"></textarea>
                    </div>
                </div>-->
            </div>
            <div class="modal-footer">
            
                            <button *ngIf="!isLocked" class="btn btn-outline"  [disabled]="isSubmitDisabled()" (click)="okayClicked();" type="button"
                                    [innerHTML]="submitBtnlbl"></button>&nbsp; {{orLbl}} &nbsp;<span class="edit-gp-btn-cancel"><label (click)="cancelClicked()">{{cancelLbl}} </label></span>

                   
            </div>
        </div>
    </div>
</div>


