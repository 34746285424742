export class LogAction {
    constructor() { }

    public action: string;
    public driverId: number;
    public themeId: number;
    public subThemeId: number;
    public threadId: number;
    public postId: number;
    public eventId: number;
    public cardId: number;
    public growthPlanId: number;
    public communityId: number;
    public searchText: string;
    public searchHits: number;
    public surveyQuestionId: number;
    public topicId: number;
    public eventUserId: number;
    public eventRoleId: number;
    public otherInfo: string;
    public isExternal: boolean;
    public tagId: number;
    public categoryId: number;
    public reportId: number;
    public labelId: number;
    public enquiryId: number;
    public helpId: number;
    public privacyId: number;
    public videoId: number;
    public notificationId: number;
}

export class LogTypes {

    LOGGED_IN: string = "Logged in";
    LOGGED_OUT: string = "Logged out";
    TIME_OUT: string = "Timed out";
    COOKIE_ACCEPTED: string = "Accepted Cookie";
    AUTHORIZATION_FAILED: string = "Failed authorization";
    SEARCHED: string = "Searched";

    ASSESMENT_COMPLETED: string = "Completed Assessment";
    GROWTH_PLAN_MODIFIED: string = "Modified growth plan";
    COMPLETED_CARD: string = "Completed Card Action";

    HOT_TOPIC_ACCESSED: string = "Accessed a hot topic";   //May be Duplicate 
    HOT_TOPIC_LIKED: string = "Liked a hot topic";
    HOT_TOPIC_UNLIKED: string = "unliked a hot topic";
    HOT_TOPIC_VIEWED: string = "Viewed a hot topic";
    HOT_TOPIC_PINNED: string = "Pinned a hot topic";
    HOT_TOPIC_CREATED: string = "Created Hot Topic";
    HOT_TOPIC_MODIFIED: string = "Modified Hot Topic";
    HOT_TOPIC_DEACTIVATED: string = "Deactivated Hot Topic";

    SURVEY_ANSWERD: string = "Answered a survey";
    SURVEY_CREATED: string = "Created Survey";
    SURVEY_MODIFIED: string = "Modified Survey";
    SURVEY_ACTIVATED: string = "Activated Survey";
    SURVEY_DEACTIVATED: string = "Deactivated Survey";

    COMMUNITY_JOINED: string = "Joined Community";
    COMMUNITY_LEFT: string = "Left community";

    DISCUSSION_POSTED: string = "Posted a discussion";
    DISCUSSION_LIKED: string = "Liked a discussion";
    DISCUSSION_UNLIKED: string = "Unliked a discussion";
    DISCUSSION_FOLLOWED: string = "Followed a discussion";
    DISCUSSION_UNFOLLOWED: string = "Unfollowed a discussion";
    DISCUSSION_CREATED: string = "Created a discussion";    //May be Duplicate 
    DISCUSSION_MODIFIED: string = "Modified a discussion";
    DISCUSSION_ACTIVATED: string = "Activated a discussion";
    DISCUSSION_DEACTIVATED: string = "Deactivated a discussion";

    INSIGHT_PINNED: string = "Pinned a Insight";
    TOOL_PINNED: string = "Pinned a tool";
    TOOL_SUBSCRIBED: string = "Subscribed a tool";

    CARD_ACCESSED: string = "Accessed a card";
    CARD_CREATED: string = "Created card";
    CARD_MODIFIED: string = "Modified card";
    CARD_ACTIVATED: string = "Activated card";
    CARD_DEACTIVATED: string = "Deactivated card";

    EVENT_SUBSCRIBED: string = "Subscribed an Event";
    EVENT_CREATED: string = "Created Event";
    EVENT_MODIFIED: string = "Modified Event";
    EVENT_ACTIVATED: string = "Activated Event";
    EVENT_DEACTIVATED: string = "Deactivated Event";

    THREAD_COMMENTED: string = "Commented on a thread";     //May be Duplicate 
    ANSWER_POSTED: string = "Posted an answer";
    ANSWER_LIKED: string = "Liked an answer";
    ANSWER_UNLIKED: string = "Unliked an answer";
    ANSWER_MODIFIED: string = "Modified an answer";
    ANSWER_ACTIVATED: string = "Activated an answer";
    ANSWER_DEACTIVATED: string = "Deactivated an answer";

    USER_CREATED: string = "Created User";
    USER_MODIFIED: string = "Modified User";
    USER_ACTIVATED: string = "Activated User";
    USER_DEACTIVATED: string = "Deactivated User";

    ROLE_CREATED: string = "Created Role";
    ROLE_MODIFIED: string = "Modified Role";
    ROLE_ACTIVATED: string = "Activated Role";
    ROLE_DEACTIVATED: string = "Deactivated Role";

    NAVIGATION: string = "Navigation";

    DELETION_REQUEST_CREATED: string = "Created Deletion Request";
    DELETION_REQUEST_REJECTED: string = "Rejected Deletion Request";
    DELETION_REQUEST_APPROVED: string = "Approved Deletion Request";

    TIMEZONE_CHANGED: string = "Changed Timezone";
    NOTIFICATION_ENABLED: string = "Enabled Notification";
    NOTIFICATION_DISABLED: string = "Disabled Notification";
    REMOVE_NOTIFICATIONS: string = "Removed Notification";
    REMOVE_ALL_NOTIFICATIONS: string = "Removed All Notifications"

    GROWTHPLAN_RESET: string = "Reset GrowthPlan";
    GROWTHPLAN_REPORT_CREATED: string = "Created GrowthPlan Report";
    GROWTHPLAN_REPORT_FINALIZED: string = "Finalized GrowthPlan Report";
    GROWTHPLAN_REPORT_DELETED: string = "Deleted Draft Report";
    CONTACTUS_CREATED: string = "Created ContactUs";
    GROWTH_PLAN_CREATED: string = "Created Growthplan";
    GROWTH_PLAN_EDITED: string = "Edited GrowthPlan";
    GROWTH_PLAN_SHARED: string = "Shared Growthplan";
    GROWTH_PLAN_UNSHARED: string = "Unshared Growthplan";
    GROWTH_PLAN_DELETED: string = "Deleted Growthplan";
    GROWTH_PLAN_DUPLICATED: string = "Duplicated Growthplan";
    GROWTH_PLAN_FACILITATED_CREATED: string = "Created facilitated session";
    CORPORATE_GROWTH_PLAN_CREATED: string = "Created team session";
    LEADERSHIP_GROWTH_PLAN_CREATED: string = "Created team session(EY)";

    CURRENCY_CHANGED: string = "Changed Currency";
    constructor() {

    }
}