import { Injectable, Inject } from '@angular/core';

import { HTTPService } from './http.service';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { DataShareService } from './data-share.service';
//import { ModalDialogService } from '../modal-dialog-module/modal-dialog.service';
import { Alert, AlertType } from '../component/alert/model/alert.model';
import { AlertService } from './alert.service';
import { Constants } from './constant.service';
import { ProgressBarService } from './../progress-bar-module/progres-bar.service';
import { ProgressBarTypes } from './../progress-bar-module/progress-bar-types.enum';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { forkJoin } from 'rxjs';
import { filter, tap, catchError } from 'rxjs/operators';

const CONTENT_API = environment.VELOCITY_API + 'appcontent/';
const ASSESSMENT_API = environment.VELOCITY_API + 'assessment/';

@Injectable()
export class UtilService {
    constructor(
        private httpService: HTTPService,
        private dataShareService: DataShareService,
        //private dialog: ModalDialogService,
        private alert: AlertService,
        private constant: Constants,
        private progressBarService: ProgressBarService
    ) { }

    getFullURL(url: string): string {
        let fullURL: string;
        fullURL = '/' + url;
        return fullURL ;
    }

    doGet(baseurl: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.NoProgressBar);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }
        return this.httpService.request({
            url: baseurl,
            method: 'GET'
        }).pipe(tap((x: any) => {
            if (progressBarOptions && progressBarOptions.showProgressBar) {
                this.progressBarService.disable(progressBarOptions);
            }
        }))
          .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    doPost(url: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }
        return this.httpService.request({
            url: url,
            method: 'POST',
            params: params
        })
          .pipe(tap((x: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
            }))
          .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    doUpload(url: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }

        return this.httpService.uploadRequest({
            url: url,
            params: params

        })
          .pipe(tap((x: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
            }))
          .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    doDownload(url: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }

        return this.httpService.downloadRequest({
            url: url,
            params: params

        })
          .pipe(tap((x: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
            }))
            .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    getContent(pageName: string): Observable<any> {
        this.progressBarService.enable(ProgressBarHelper.PageTransition);
        return Observable.create(observer => {
            const content = this.dataShareService.getSharedData(pageName);
            if (content) {
                observer.next(content);
                observer.complete();
                this.progressBarService.disable(ProgressBarHelper.PageTransition);
            } else {
                this.doGet(CONTENT_API + pageName).subscribe(
                    (response: Response) => {
                        if (response && response.status && (response.code === '1000') && response.result['Content']) {
                            this.dataShareService.setSharedData(pageName, response.result['Content']);
                            observer.next(response.result['Content']);
                            observer.complete();
                            this.progressBarService.disable(ProgressBarHelper.PageTransition);
                        } else {
                            this.progressBarService.disable(ProgressBarHelper.PageTransition);
                            throwError(response.message);
                        }
                    }, (error: any) => {
                        this.progressBarService.disable(ProgressBarHelper.PageTransition);
                        this.errorHandler(error);
                    });
            }
        });
    }

    getContents(pageNames: Array<string>):Observable<any> {
        return Observable.create(observer => {

            if (pageNames.length == 0) {
                observer.next({});
                observer.complete();
            }
            else {

                var pageContentsObservable: Array<Observable<any>> = new Array<Observable<any>>();
                for (let pageName of pageNames) {
                    if (pageName && pageName != "") {
                        pageContentsObservable.push(this.getContent(pageName));
                    }
                }



                forkJoin(pageContentsObservable).subscribe((results: any) => {
                    if (pageNames && pageNames.length && pageNames.length > 0) {
                        if (pageNames[0] != "") {
                            var pageContent = this.dataShareService.getSharedData(pageNames[0]);
                            observer.next(pageContent);
                        }
                        else {
                            observer.next({});
                        }
                        observer.complete();
                    }
                });
            }

        });
    }

    saveAssessment(parameters:any) {
        return Observable.create(observer => {
            this.doPost(ASSESSMENT_API, parameters).subscribe(
                (response: Response) => {
                    //console.log(response);
                    if (response && response.status && (response.code === '1000') && response.result['Content']) {
                        //console.log(response);
                        observer.next(response);
                        //  observer.next(response.result['Content']);
                        observer.complete();
                    } else {
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.errorHandler(error);
                });

        });
    }
    errorHandler(error: any) {
        let message ='';
        message += (error.status || '')+ ' ';
        message += (error.statusText ||'Network issue. Please try later')
        this.showError(message);
    }

    //showDialog(options: any) {
    //    const message = options.message || options;
    //    const title = options.title || 'EY Velocity';
    //    this.dialog.alert(title, message);
    //}

    //showConfirmDialog(options: any) {
    //    const message = options.message || options;
    //    const title = options.title || 'EY Velocity';
    //    return this.dialog.confirm(title, message);
    //}

    //showInfoDialog(options: any) {
    //    const message = options.message || options;
    //    this.dialog.info(message, {});
    //}

    showWarning(message: string) {
        this.alert.warn( this.constant.APP.WARN, message || 'An error occured while processing your request');
    }
    showSuccess(message: string) {
        this.alert.success(this.constant.APP.SUCCESS, message || 'An error occured while processing your request');
    }
    showInfo(message: string) {
        this.alert.info(this.constant.APP.INFO, message || 'An error occured while processing your request');
    }
    showError(message: string, showErrorAsDialog?: boolean) {
        if (!showErrorAsDialog) {
            this.alert.error(this.constant.APP.ERROR, message || 'An error occured while processing your request');
        }
        else {
            this.alert.dialog(this.constant.APP.ERROR, message || 'An error occured while processing your request');
        }
        this.scrollToTop();
    }

    showLoginPopup() {
        this.alert.showLoginPopup();
    }

    scrollToPosition(element: HTMLElement, to: number, duration: number): void {

        if (duration <= 0) return;
        let difference = to - element.scrollTop;
        let pertick = difference / duration * 10;

        let timeoutId = setTimeout(() => {
            element.scrollTop = element.scrollTop + pertick;
            if (element.scrollTop == to) return;
            this.scrollToPosition(element, to, duration - 10);
        }, 10);
    }

    scrollToTop(): void {

        //if (window.scrollY != undefined) {
        //    this.scrollToPosition(document.body, 0, 100);
        //} else {
        //    this.scrollToPosition(document.documentElement, 0, 100);
        //}
        this.scrollToPosition(document.documentElement, 0, 100);
    }

    scrollToTopOnNavigation(router) {

        router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((x: any) => {
            this.scrollToTop();
        });
    }

    getOffset(element: any): any {
        var offset = { top: 0, left: 0, width: 0, height: 0 };
        if (element) {
            var offsetRectangle = element.getBoundingClientRect();
            if (offsetRectangle.width || offsetRectangle.height || element.getClientRects.length) {
                var clientTop = document.documentElement.clientTop;
                var clientleft = document.documentElement.clientLeft;
                offset = { top: offsetRectangle.top + window.pageYOffset + clientTop, left: offsetRectangle.left + window.pageXOffset + clientleft, width: offsetRectangle.width, height: offsetRectangle.height };
            }

        }
        return offset;

    }

    getSelectValueForId(id: any, array: any[]): any{
        return array.filter((item: any) => item.id === id);
    }

    //getIconographicBackground(icon: string) {
    //    icon = icon.toLowerCase();
    //    switch (icon) {
    //        case "a": { return "#FFB46A"; };
    //        case "b": { return "#8CE8AD" };
    //        case "c": { return "#87D3F2" };
    //        case "d": { return "#CACAD1" };
    //        case "e": { return "#C981B2" };
    //        case "f": { return "#95959C" };
    //        case "g": { return "#FF9A91" };

    //        case "h": { return "#FFB46A" };
    //        case "i": { return "#8CE8AD" };
    //        case "j": { return "#87D3F2" };
    //        case "k": { return "#CACAD1" };
    //        case 'l': { return "#C981B2" };
    //        case 'm': { return "#95959C" };
    //        case 'n': { return "#FF9A91" };

    //        case 'o': { return "#FFB46A" };
    //        case 'p': { return "#8CE8AD" };
    //        case 'q': { return "#87D3F2" };
    //        case 'r': { return "#CACAD1" };
    //        case 's': { return "#C981B2" };
    //        case 't': { return "#95959C" };
    //        case 'u': { return "#FF9A91" };

    //        case 'v': { return "#FFB46A" };
    //        case 'w': { return "#8CE8AD" };
    //        case 'x': { return "#87D3F2" };
    //        case 'y': { return "#CACAD1" };
    //        case 'z': { return "#C981B2" };


    //        default: { return "#FFB46A";}
    //    }

    //}
    //getIconographicShape(index: number) {
        
    //    switch (index) {
    //        case 0: { return "themeIcon1"; };
    //        case 1: { return "themeIcon2" };
    //        case 2: { return "themeIcon3" };
    //        case 3: { return "themeIcon4" };
    //        case 4: { return "themeIcon5"; };
    //        case 5: { return "themeIcon6" };
    //        case 6: { return "themeIcon7" };
    //        case 7: { return "themeIcon8" };
    //        case 8: { return "themeIcon9"; };
    //        case 9: { return "themeIcon10" };
            

    //        default: { return "themeIcon1"; }
    //    }

    //}

    getIinitals(value: any, lastName: string, isName?: boolean) {

        if (value) {
            if (isName) {
                if (lastName) {
                    let initials = value.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
                    return initials;
                } else {
                    let initials = value.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    return initials;
                }
            }
            else {
                let initials = value.charAt(0).toUpperCase();
                return initials;
            }
        }
        return '';
    }
}

