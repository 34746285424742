import { Injectable, Inject} from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';


import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { ErrorHandlerHelper, AjaxErrorOptions } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';


const ApiUrl = environment.GROWTH_NAVIGATOR_API;

@Injectable()
export class GrowthService {
    constructor (
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router:Router
    ) { }

    invokeGrowthAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.handleError(error, errorHandler);
                });
            });
    }

    invokePostAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    }else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
            });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {
        // Growth plan validation
        if (errorCode == "400001") {
            this.router.navigate(['general', 'error404']);
        }
        else if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }         
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {
        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (error.status == "404" && errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }

    getGrowthContent(url: string, progressBarOptions?: ProgressBarOptions, isMainAPI?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
        if (isMainAPI) {
            errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        }
        return this.invokeGrowthAPI(url, {}, progressBarOptions, errorHandler);
    }

    getInsightsForMaturityLevel(maturityId: any, driverId: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokeGrowthAPI(this.constants.FOCUS.DRIVER_INSIGHT_API + '/' + driverId + '/' + maturityId, {}, progressBarOptions);
    }
    getInsightsForSubdriver(params, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostAPI(this.constants.FOCUS.GET_THEME_INSIGHT, params, progressBarOptions);
    }
    getInsightCardDetails(cardid: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.FOCUS.INSIGHT_POPUP_API + '/' + cardid, {}, progressBarOptions);
    }

    getSubDriver(driverId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
        if (isMainApi) {
            errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        }
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';
        return this.invokeGrowthAPI(this.constants.FOCUS.SUB_DRIVER_API + '/' + driverId + growthPlanId, {}, progressBarOptions, errorHandler);       
    }

    getThemeNavigators(subDriverId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
        if (isMainApi) {
            errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        }
        return this.invokeGrowthAPI(this.constants.FOCUS.THEME_NAVIGATOR_API + '/' + subDriverId, {}, progressBarOptions, errorHandler);    
    }

    getSubThemeMaturityLevel(subThemeId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.Default;
        if (isMainApi) {
            errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        }
        return this.invokeGrowthAPI(this.constants.FOCUS.SUB_THEME_MATURITY_LEVEL_API + '/' + subThemeId, {}, progressBarOptions, errorHandler);   
    }

    getSubThemeInsights(subThemeId: any, maturityId: any) {
        return this.invokeGrowthAPI(this.constants.FOCUS.SUB_THEME_INSIGHT_API + '/' + subThemeId + '/' + maturityId);
    }

    getActionCard(driverId: any, themeId: any, currentGrowthPlanId:any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.FOCUS.DRIVER_THEME_ACTIONS + '/' + driverId + '/' + themeId + '/' + currentGrowthPlanId, {}, progressBarOptions);
    } 

    getHotTopicFilters(progressBarOption?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.FOCUS.GET_HOT_TOPICS_FILTER, {}, progressBarOption);
    }

    getGrowthDrivers(progressBarOptions?: ProgressBarOptions) {
        const growthPlanId = this.userService.getGrowthplanId() ? '/' + String(this.userService.getGrowthplanId()) : '';    
        return this.invokeGrowthAPI(this.constants.FOCUS.SEVEN_DRIVERS_API + growthPlanId + '/true', {}, progressBarOptions);
    }

    //getGrowthPlanCardDetails(params: any, progressBarOption?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.FOCUS.GROWTH_PLAN, params, progressBarOption);
    //}

    getCardList(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.GET_CARD_LIST, params, progressBarOption);
    }

    getHTCardDetails(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.FOCUS.GT_HT_CARD_DETAILS, params, progressBarOption);
    }

    getThemeMaturityContents(subDriverId: any, progressBarOption?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.FOCUS.GET_THEME_MATURITY + '/' + subDriverId, {}, progressBarOption);
    }

    //Connect

    //getEcosystemCards(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(this.constants.CONNECT.GET_ECOSYSTEM_CARDS, params, progressBarOptions);
    //}

    //getEcosystemCardDetails(cardID: any, progressBarOptions?: ProgressBarOptions) {
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGrowthAPI(this.constants.CONNECT.GET_ECOSYSTEM_CARD_DETAILS + '/' + user.userId + '/' + cardID, {}, progressBarOptions);

    //}

    /**************** TOOL & RESOURCES - Start *********************/

    //getToolDetails(toolId: any, progressBarOptions?: ProgressBarOptions) {
    //    var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGrowthAPI(`${this.constants.ACT.GET_TOOL_DETAILS}/${user.userId}/${toolId}`, {}, progressBarOptions, errorHandler);
    //}

    //getRelatedTools(toolId: any, progressBarOptions?: ProgressBarOptions){
    //    const user = this.userService.getCurrentUser();
    //    return this.invokeGrowthAPI(`${this.constants.ACT.GET_RELATED_TOOLS}/${user.userId}/${toolId}`, {}, progressBarOptions);
    //}
    
    /**************** TOOL & RESOURCES - End *********************/
    /*For Language Dropdown */

   listLanguage(progressBarOptions?: ProgressBarOptions) {
       return this.invokeGrowthAPI(this.constants.USER.LIST_LANGUAGE + '/true', {}, progressBarOptions);
    }

    //To save preferred language
    saveLanguage(languageId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.USER.SAVE_LANGUAGE}/${languageId}`, {}, progressBarOptions);
    }
}
