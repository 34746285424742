<div class="notification-component">
    <div class="row baseContainerRow">
        <div class="col-sm-12">
            <div class="tab-container">
                <div class="notification-heading">
                    {{PageContents.tabNotifications}}
                </div>
                <notification-list [contents]="PageContents"></notification-list>
            </div>
        </div>
    </div>
</div>