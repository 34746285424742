import { NgModule } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal'; import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//import { BaseRequestOptions, HttpModule } from '@angular/http';
import { NouisliderModule } from '../component/custom-slider/ng2-silder';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BaseComponent } from './base.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppNotificationComponent } from './app-notification/app-notification.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { BarometerComponent } from './barometer/barometer.component';
import { SearchDropDownComponent } from './search-dropdown/search-dropdown.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { AlertComponent } from './alert/alert.component';
import { PipeModule } from '../pipe/pipe.module';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { EYVTooltip } from './eyv-tooltip-directive/eyv-tooltip.directive';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { DirectiveModule } from '../directive/directive.module';
import { ActionRagComponent } from './action-rag/action-rag.component';
import { ToolCardComponent } from './tool-card/tool-card.component';
import { ErrorDialogComponent } from './dialog/dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { ProgressBarModule as EYVProgressBarModule } from '../progress-bar-module/progress-bar.module';
import { WINDOW_PROVIDERS } from "./window.service";
import { CookieBannerComponent } from "./cookie-banner/cookie-banner.component";
import { TimeoutHandlerComponent } from './timeout-dialog/timeout-handler.component';
import { DraggableDirective } from './drag-drop/draggable.directive';
import { DroppableDirective } from './drag-drop/droppable.directive';
import { DragDataService } from './drag-drop/service/drag-data.service';
import { GrowthPlanBubbleTestComponent } from './growth-plan-bubble-test/growth-plan-bubble-test.component';
import { GrowthPlanCountBubbleComponent } from './growth-plan-count-bubble/growth-plan-count-bubble.component';
import { InsightCalloutComponent } from './insight-callout/insight-callout.component';
import { MyDatePickerModule } from 'mydatepicker';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ActiveGrowthPlanComponent } from './active-growth-plan/active-growth-plan.component';
import { FlashMessageComponent } from './flash-message/flash-message.component'

import { PeoplePickerComponent } from './people-picker/people-picker.component';
import { ActionFilterDropdownComponent } from './action-filter-dropdown/action-filter-dropdown.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HttpClientModule } from '@angular/common/http';
import { DisplayVieworassessmentComponent } from './display-vieworassessment/display-vieworassessment.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
  declarations: [
        AppHeaderComponent,
        AppNotificationComponent,
        NotificationListComponent,
        BarometerComponent,
        SubMenuComponent,
        AlertComponent,
        BaseComponent,
        AppFooterComponent,
        EYVTooltip,
        ActionFilterDropdownComponent,
        ActionRagComponent,
        ToolCardComponent,
        ErrorDialogComponent,
        LoginDialogComponent,
        SearchDropDownComponent,
        CookieBannerComponent,
        TimeoutHandlerComponent,
        DraggableDirective,
        DroppableDirective,
        GrowthPlanBubbleTestComponent,
        GrowthPlanCountBubbleComponent,
        InsightCalloutComponent,
        BreadcrumbComponent,
        ActiveGrowthPlanComponent,
        PeoplePickerComponent,
        FilterDropdownComponent,
        UserProfileComponent,
        FlashMessageComponent,
        DisplayVieworassessmentComponent,

  ],
    imports: [
       // HttpModule,
        FormsModule,
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        NouisliderModule,
        EYVProgressBarModule,
        PipeModule,
        DirectiveModule,
        MyDatePickerModule,
        TabsModule.forRoot(),
        TooltipModule,
    ],
    exports: [
        BaseComponent,
        AppHeaderComponent,
        AppNotificationComponent,
        NotificationListComponent,
        BarometerComponent,
        AlertComponent,
        SubMenuComponent,
      HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        //NouisliderModule,
        PipeModule,
        AppFooterComponent,
        EYVTooltip,
        //NgxChartsModule,
        ProgressbarModule,
        FilterDropdownComponent,
        ActionRagComponent,
        ToolCardComponent,
        ErrorDialogComponent,
        LoginDialogComponent,
        CookieBannerComponent,
        TimeoutHandlerComponent,
        DraggableDirective,
        DroppableDirective,
        GrowthPlanBubbleTestComponent,
        GrowthPlanCountBubbleComponent,
        InsightCalloutComponent,
        BreadcrumbComponent,
        ActiveGrowthPlanComponent,
        PeoplePickerComponent,
        ActionFilterDropdownComponent,
        UserProfileComponent,
        FlashMessageComponent,
        DisplayVieworassessmentComponent
    ],
  providers: [WINDOW_PROVIDERS,
    DragDataService
  ]
})
export class ComponentModule { }
