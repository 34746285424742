<div class="modal fade ViewNoteModel" bsModal #ViewNoteModel="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)"  [ngClass]="{'blur':showBlur}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" [ngClass]="noNotesAvailable? 'no-notes-available':'notes-available'">
            <div class="modal-header">
                <h2 class="modal-title pull-left">{{title}}</h2>

                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body" [ngClass]="noNotesAvailable? 'no-notes-available-body':'notes-available-body'">

                <div class="view-note-modal-content">
                    <div class="notes">
                        <ng-container *ngIf="!noNotesAvailable; else noNotesToDisplayBlock">
                            <div *ngFor="let note of notes" class="note height-settings">
                                {{note.note}}
                                <div class="controls" *ngIf="!disabled" >
                                    <a [routerLink]="" (click)="editNote(note)">{{PageContents.lblEditNotebtn}}</a>
                                    <a [routerLink]="" (click)="deleteNote(note)">{{SiteContents.buttonDelete}}</a>

                                </div>
                            </div>
                        </ng-container>  

                        <ng-template #noNotesToDisplayBlock>
                            <div class="note no-notes-available">
                                {{PageContents.lblNoNotesAvailableToDisplay}}
                            </div>
                        </ng-template>     
                    </div> 
                </div>

            </div>
            <div class="modal-footer" *ngIf="!disabled">
                <button class="btn btn-outline"
                        (click)="addNote()">
                    {{PageContents.lblAddNotebtn}}
                </button>
            </div>
        </div>
        </div>
</div>