<div class="modal fade ey-dialog-modal fop-quadrant" bsModal #fopQuadrantModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed()">
    <div class="modal-dialog" update-panel-action="click">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{currentGrowthPlanName}}</h4>
                <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
            </div>
            <div class="modal-body">
                <div class="quadrant-container">
                    <div class="quadrant-area" #quadrantArea>
                        <div class="row quadrants">
                            <div class="col-sm-12">
                                <div class="row qu-container">
                                    <div class="col-sm-6 no-padding-column">
                                        <div class="qu-box first-box" #Quadrant>
                                            <ng-container *ngFor="let action of actionData">
                                                <div class="dgp-bubble" id="bubble{{action.id}}" (click)="triggerTooltip($event,action,'first')" *ngIf="action.priorityId==1"
                                                     [style.left]="getBubblePosition(action).left+'px'"
                                                     [style.top]="getBubblePosition(action).top+'px'"
                                                     [ngClass]="{'sel-bubble':action.id == selActionId, 'tooltip-select': showTooltip[action.id]}">
                                                    {{action.cardNumber}}

                                                    <div class="tooltiptext {{class}} {{topcClass}}" id="{{action.id}}" *ngIf="showTooltip[action.id]">
                                                        <div style="width:100%">
                                                            <div class="tooltip-label" id="tooltip-label{{action.id}}">
                                                                {{contents.lblTooltipTitle}}
                                                            </div>
                                                            <div class="close-btn close-tooltip pl-1 pt-1" (click)="closeTooltip(action)">
                                                                <i class="fa fa-times" (click)="closeTooltip(action)"></i>
                                                                <!--<img class="close-btn" style="height:10px;" src="/assets/images/close-btn.png">-->
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div class="row-container" [style.max-height.px]="maxHeight" [style.overflow-y]="overflow" id="bubble-c-{{action.id}}">
                                                            <ng-container *ngFor="let fop of action.fopList; let i = index">
                                                                <div *ngIf="i == 0" id="{{i}}" class="first-row">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <div *ngIf="i != 0" id="{{i}}" class="no-first-row mb-2">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <hr *ngIf="i+1 != action.fopList.length" id="hr{{i}}" />
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 no-padding-column">
                                        <div class="qu-box second-box" style="position:relative;">
                                            <ng-container *ngFor="let action of actionData">
                                                <div class="dgp-bubble" id="bubble{{action.id}}" (click)="triggerTooltip($event,action,'second')" *ngIf="action.priorityId==2"
                                                     [style.left]="getBubblePosition(action).left+'px'"
                                                     [style.top]="getBubblePosition(action).top+'px'"
                                                     [ngClass]="{'sel-bubble':action.id == selActionId, 'tooltip-select': showTooltip[action.id]}">
                                                    {{action.cardNumber}}

                                                    <div class="tooltiptext {{class}} {{size}} {{topcClass}}" id="{{action.id}}" *ngIf="showTooltip[action.id]">
                                                        <div style="width:100%">
                                                            <div class="tooltip-label" id="tooltip-label{{action.id}}">
                                                                {{contents.lblTooltipTitle}}
                                                            </div>
                                                            <div class="close-btn close-tooltip pl-1 pt-1" (click)="closeTooltip(action)">
                                                                <i class="fa fa-times" (click)="closeTooltip(action)"></i>
                                                                <!--<img class="close-btn" style="height:10px;" src="/assets/images/close-btn.png">-->
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div class="row-container" [style.max-height.px]="maxHeight" [style.overflow-y]="overflow" id="bubble-c-{{action.id}}">
                                                            <ng-container *ngFor="let fop of action.fopList; let i = index">
                                                                <div *ngIf="i == 0" id="{{i}}" class="first-row">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <div *ngIf="i != 0" id="{{i}}" class="no-first-row mb-2">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <hr *ngIf="i+1 != action.fopList.length" id="hr{{i}}" />
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="row qu-container">
                                    <div class="col-sm-6 no-padding-column">
                                        <div class="qu-box third-box" style="position:relative;">
                                            <ng-container *ngFor="let action of actionData">
                                                <div class="dgp-bubble" id="bubble{{action.id}}" (click)="triggerTooltip($event,action,'third')" *ngIf="action.priorityId==3"
                                                     [style.left]="getBubblePosition(action).left+'px'"
                                                     [style.top]="getBubblePosition(action).top+'px'"
                                                     [ngClass]="{'sel-bubble':action.id == selActionId, 'tooltip-select': showTooltip[action.id]}">
                                                    {{action.cardNumber}}

                                                    <div class="tooltiptext {{class}} {{size}} {{topcClass}}" id="{{action.id}}" *ngIf="showTooltip[action.id]">
                                                        <div style="width:100%">
                                                            <div class="tooltip-label" id="tooltip-label{{action.id}}">
                                                                {{contents.lblTooltipTitle}}
                                                            </div>
                                                            <div class="close-btn close-tooltip pl-1 pt-1" (click)="closeTooltip(action)">
                                                                <i class="fa fa-times" (click)="closeTooltip(action)"></i>
                                                                <!--<img class="close-btn" style="height:10px;" src="/assets/images/close-btn.png">-->
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div class="row-container" [style.max-height.px]="maxHeight" [style.overflow-y]="overflow" id="bubble-c-{{action.id}}">
                                                            <ng-container *ngFor="let fop of action.fopList; let i = index">
                                                                <div *ngIf="i == 0" id="{{i}}" class="first-row">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <div *ngIf="i != 0" id="{{i}}" class="no-first-row mb-2">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <hr *ngIf="i+1 != action.fopList.length" id="hr{{i}}" />
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 no-padding-column">
                                        <div class="qu-box fourth-box" id="four" style="position:relative;">
                                            <ng-container *ngFor="let action of actionData">
                                                <div class="dgp-bubble" id="bubble{{action.id}}" (click)="triggerTooltip($event,action,'fourth')" *ngIf="action.priorityId==4"
                                                     [style.left]="getBubblePosition(action).left+'px'"
                                                     [style.top]="getBubblePosition(action).top+'px'"
                                                     [ngClass]="{'sel-bubble':action.id == selActionId, 'tooltip-select': showTooltip[action.id]}">
                                                    {{action.cardNumber}}

                                                    <div class="tooltiptext {{class}} {{size}} {{topcClass}}" id="{{action.id}}" *ngIf="showTooltip[action.id]">
                                                        <div style="width:100%">
                                                            <div class="tooltip-label" id="tooltip-label{{action.id}}">
                                                                {{contents.lblTooltipTitle}}
                                                            </div>
                                                            <div class="close-btn close-tooltip pl-1 pt-1" (click)="closeTooltip(action)">
                                                                <i class="fa fa-times" (click)="closeTooltip(action)"></i>
                                                                <!--<img class="close-btn" style="height:10px;" src="/assets/images/close-btn.png">-->
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div class="row-container" [style.max-height.px]="maxHeight" [style.overflow-y]="overflow" id="bubble-c-{{action.id}}">
                                                            <ng-container *ngFor="let fop of action.fopList; let i = index">
                                                                <div *ngIf="i == 0" class="first-row">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <div *ngIf="i != 0" id="{{i}}" class="no-first-row mb-2">
                                                                    <div class="img-container"><img src="{{fop.imageURL}}" /></div><div class="name-container">{{fop.name}}</div>
                                                                </div>
                                                                <hr *ngIf="i+1 != action.fopList.length" id="hr{{i}}" />
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" impact-scale-y-axis" style="margin-left: -40px;">
                            <p>
                                <span class="arrow-left"></span>
                                <span class="left lable-text"
                                      style="max-width: 90px;">{{contents.lblFopLow}}</span>
                                <span class="middle lable-text"><div>{{gpDetails.yAxisLabel}}</div></span>
                        <span class="right lable-text"
                              style="max-width: 90px;">{{contents.lblFopHigh}}</span>
                        <span class="arrow-right"></span>
                        </p>
                    </div>
                        <div class=" impact-scale-x-axis" style="margin-top: -20px;">
                            <p>
                                <span class="arrow-left"></span>
                                <span class="left lable-text"
                                      style="max-width: 90px;">{{contents.lblFopLow}}</span>
                                <span class="middle lable-text"><div>{{gpDetails.xAxisLabel}}</div></span>
                                <span class="right lable-text"
                                      style="max-width: 90px;">{{contents.lblFopHigh}}</span>
                                <span class="arrow-right"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" (click)="closeDialog();" type="button">{{contents.btnFopClose}}</button>
            </div>
        </div>
    </div>
</div>