import { Injectable, ReflectiveInjector, ComponentFactoryResolver } from '@angular/core';
import { OkCancelDialogComponent } from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { LandingAlertDialogComponent } from './landing-alert-component/landing-alert.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
//import { VideoDialogComponent } from './video-dialog-component/video-dialog.component';
import { VideoDialogHottopicComponent } from './video-dialog-hottopics-component/video-dialog-hottopics.component';
import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
import { TermsDialogComponent } from './terms-dialog-component/terms-dialog.component';
import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct.component';
//import { CustomizeReportComponent } from './customize-report-component/customize-report.component';
//import { CoverPageTitleEditComponent } from './cover-page-title-component/cover-page-title.component';
import { LandingDialogComponent } from './landing-page-component/landing-page.component';
import { VideoDialogLandingPageComponent } from './video-dialog-landingPage-component/video-dialog-landingPage.component';
import { AddCustomActionComponent } from './add-custom-action-component/add-custom-action.component';
import { RestoreActionComponent } from './restore-action-component/restore-action.component';
import { ComponentData } from './component-data';
import { TemplateTypes } from './template-types.enum'
import { ActionDetailComponent } from './action-detail-component/action-detail.component';
import { Router, NavigationStart } from '@angular/router';
//import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
import { HelpTopicsComponent } from "./help-topics-component/help-topics.component";
import { SaveCancelDialogComponent } from "./save-cancel-dialog-component/save-cancel-dialog.component";
import { InsightModalComponent } from './insight-component/insight-component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
//import { ThemeDialogComponent } from './theme-dialog-component/theme-dialog.component';
import { addNotesComponent } from './add-notes-component/add-notes.component';
import { addNotesAssesmentComponent } from './add-notes-assesment/add-notes-assesment.component';
import { CreateNewGrowthPlanComponent } from './create-new-growth-plan-component/create-new-growth-plan.component';
////import { F2FLoginComponent } from './f2f-login-component/f2f-login.component';
import { F2FInfoDialogComponent } from './f2f-info-dialog-component/f2f-info-dialog-component';
import { F2FCodeVerificationComponent } from './f2f-code-verification-component/f2f-code-verification.component';
//import { F2FDuplicateGrowthPlanComponent } from './f2f-duplicate-growth-plan/f2f-duplicate-growth-plan.component';
import { GrowthPlanListDto } from '../focus/model/face-to-face.model';
import { CorporateParticipantInfoDialogComponent } from './corporate-participant-info-dialog-component/corporate-participant-info-dialog-component';
import { CorporateOwnerInfoDialogComponent } from './corporate-owner-info-dialog-component/corporate-owner-info-dialog-component';
import { FOPQuadrantComponent } from './fop-quadrant/fop-quadrant.component';
import { ZooomDialogComponent } from './zoom-2x2-matrix/zoom-2x2-matrix';
import { ZooomFocusGPComponent } from './zoom-2x2-focus-gp/zoom-2x2-focus-gp';
import { ViewNoteDialogComponent } from './view-note-component/view-note.component';

import { filter } from 'rxjs/operators';
import { ViewGrowthMatrixAxesComponent } from './view-growth-matrix-axes/view-growth-matrix-axes.component';

@Injectable()
export class ModalDialogService {
    containerElementRef: any;
    componentData: ComponentData = new ComponentData();
    constructor(
        private resolver: ComponentFactoryResolver,
      //  private F2FInfoDialogComponent: F2FInfoDialogComponent
    )
    {
      //  F2FInfoDialogComponent.setModalService(this);
    }

    alert(title: string = "", message: string = "", showCloseButton?: boolean, closeOthers?: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: AlertDialogComponent,
            componentInput: {
                title: title,
                message: message,
                showCloseButton: showCloseButton,
                closeOthers: closeOthers
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    landingAlert(title: string = "", message: string = "", showCloseButton?: boolean, okButton?:string) {
        this.componentData = Object.assign(new ComponentData(), {
            component: LandingAlertDialogComponent,
            componentInput: {
                title: title,
                message: message,
                showCloseButton: showCloseButton,
                okButton: okButton
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }



    confirm(title: string = "", message: string = "", config?: any, backdropClose: boolean = false) {
        this.componentData = Object.assign(new ComponentData(), {
            component: OkCancelDialogComponent,
            componentInput: {
                title: title,
                message: message,
                config: config,
                backdropClose: backdropClose
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    confirmNavigation(title: string = "", message: string = "", config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: SaveCancelDialogComponent,
            componentInput: {
                title: title,
                message: message,
                config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    close() {
        this.containerElementRef.closeDialog();
    }

    info(templateType: any, templateData: any, hideCloseButton?: boolean, backdropClose: boolean = false) {
        this.componentData = Object.assign(new ComponentData(), {
            component: InfoDialogComponent,
            componentInput: {
                templateType: templateType,
                templateData: templateData,
                hideCloseButton: hideCloseButton,
                backdropClose: backdropClose
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    //video(title: string = "", description: string = "", videoUrl: string = "") {
    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: VideoDialogComponent,
    //        componentInput: {
    //            title: title,
    //            description: description,
    //            videoUrl: videoUrl
    //        }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData);
    //}

    //userProfile(userID: any) {
    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: UserProfileDialogComponent,
    //        componentInput: {
    //            userID: userID
    //        }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData);
    //}

    helpTopics(topicId: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: HelpTopicsComponent,
            componentInput: {
                topicId: topicId
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    actionDetail(actionDetails: any, appContent: any, myGrowthPlanContent: any, siteContents: any, sourceDetails: any, isGrowthPlanLocked: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ActionDetailComponent,
            componentInput: {
                actionDetails: actionDetails,
                appContent: appContent,
                myGrowthPlanContent: myGrowthPlanContent,
                siteContents: siteContents,
                sourceDetails: sourceDetails,
                isGrowthPlanLocked: isGrowthPlanLocked,
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    videoHotTopics(videoUrl: string = "", source: string = "", title: string = "", backdropClose:boolean = false) {      
        this.componentData = Object.assign(new ComponentData(), {
            component: VideoDialogHottopicComponent,
            componentInput: {
                videoUrl: videoUrl,
                source: source,
                title: title,
                backdropClose: backdropClose
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    addCustomAction(templateData: any = {}) {
        this.componentData = Object.assign(new ComponentData(), {
            component: AddCustomActionComponent,
            componentInput: {
                templateData: templateData
            }
        });
        return this.containerElementRef.showDialog(this.componentData);

    }

    formModal(title: string = "", message: string = "", config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: FormDialogComponent,
            componentInput: {
                title: title,
                message: message,
                config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    restoreActions(actions: any, pageContents: any, isGrowthPlanLocked: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: RestoreActionComponent,
            componentInput: {
                actions: actions,
                pageContents: pageContents,
                isGrowthPlanLocked: isGrowthPlanLocked
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    termsModal(fromPage: string, countryId?: number) {
        this.componentData = Object.assign(new ComponentData(), {
            component: TermsDialogComponent,
            componentInput: { countryId: countryId, from: fromPage }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    codeOfConductModel(backdropClose: boolean = false, countryId?: number) {
        this.componentData = Object.assign(new ComponentData(), {
            component: CodeOfConductDialogComponent,
            componentInput: {
                countryId: countryId,
                backdropClose: backdropClose
            }
        });
        return this.containerElementRef.showDialog(this.componentData, { class: 'modal-lg' });
    }
      landingModel(page: number,title:string) {
          this.componentData = Object.assign(new ComponentData(), {
              component: LandingDialogComponent,
              componentInput: { page: page,title:title }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    //customizeReportsModel(reportId: any) {

    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: CustomizeReportComponent,
    //        componentInput: { reportId }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData);
    //}
    //coverPageModel(inputObj: any) {

    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: CoverPageTitleEditComponent,
    //        componentInput: { inputObj: inputObj }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData);
    //}
    addNotesModel(inputObj: any) {

        this.componentData = Object.assign(new ComponentData(), {
            component: addNotesComponent,
            componentInput: { inputObj: inputObj }
        });      
        return this.containerElementRef.showDialog(this.componentData);
    }

    addNotesAssementModel(inputObj: any) {

        this.componentData = Object.assign(new ComponentData(), {
            component: addNotesAssesmentComponent,
            componentInput: { inputObj: inputObj }
        });      
        return this.containerElementRef.showDialog(this.componentData);
    }
    
  unsubscribe() {
    if (this.containerElementRef)
        this.containerElementRef.unsubscribe();
    }

    closeDialogOnNavigationStart(router: Router) {

        router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((x: any) => {
            this.unsubscribe();
        });
    }

    videoLandingPage(videoUrl: string = "") {       
        this.componentData = Object.assign(new ComponentData(), {
            component: VideoDialogLandingPageComponent,
            componentInput: {
                videoUrl: videoUrl
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    showInsight(maturityId: any, driverId: any, isDriver: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: InsightModalComponent,
            componentInput: {
                maturityId: maturityId,
                driverId: driverId,
                isDriver: isDriver
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    //showTheme(themeId: any, legend: string,contents:any) {
    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: ThemeDialogComponent,
    //        componentInput: {
    //            themeId: themeId,
    //            legend: legend,
    //            contents:contents
    //        }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData, { class: 'modal-sm' });
    //}
    confirmReset(contents: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ConfirmResetComponent,
            componentInput: {
                contents: contents
            }
        });
        return this.containerElementRef.showDialog(this.componentData, { class: 'modal-sm' });
    }

    createGrowthPlan(pageContents: any,selectByDefault:any = '') {
        this.componentData = Object.assign(new ComponentData(), {
            component: CreateNewGrowthPlanComponent,
            componentInput: {
                PageContents: pageContents,
                selectByDefault: selectByDefault
            }
        });
        return this.containerElementRef.showDialog(this.componentData);      
    }

    ////createf2fLogin(pageContents: any) {
    ////    this.componentData = Object.assign(new ComponentData(), {
    ////        component: F2FLoginComponent,
    ////        componentInput: {
    ////            PageContents: pageContents
    ////        }
    ////    });
    ////    return this.containerElementRef.showDialog(this.componentData);  
    ////}

    openf2fInfoDialog(pageContents: any, otherContents: any, growthPlan: any, typeId: any, share: boolean = false, unShare: boolean = false) {
        this.componentData = Object.assign(new ComponentData(), {
            component: F2FInfoDialogComponent,
            componentInput: {
                PageContents: pageContents,
                growthPlan: growthPlan,
                typeId: typeId,
                share: share,
                unshare: unShare,
                otherContents: otherContents
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    verifyf2fCode(pageContents: any, growthPlanId: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: F2FCodeVerificationComponent,
            componentInput: {
                PageContents: pageContents,
                growthPlanId: growthPlanId
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    //duplicateGrowthPlan(pageContents: any, growthplan: GrowthPlanListDto) {
    //    this.componentData = Object.assign(new ComponentData(), {
    //        component: F2FDuplicateGrowthPlanComponent,
    //        componentInput: {
    //            PageContents: pageContents,
    //            growthplan: growthplan
    //        }
    //    });
    //    return this.containerElementRef.showDialog(this.componentData);
    //}
     corporateParticipant(growthplan: GrowthPlanListDto) {
        this.componentData = Object.assign(new ComponentData(), {
            component: CorporateParticipantInfoDialogComponent,
            componentInput: {
                growthplan: growthplan
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

  corporateOwner(pageContents: any, otherContents: any, growthPlan: any, typeId: any, isDuplicate?: boolean, showNomination?: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: CorporateOwnerInfoDialogComponent,
            componentInput: {
                PageContents: pageContents,
                otherContents: otherContents,
                growthPlan: growthPlan,
                typeId: typeId,
                isDuplicate: isDuplicate,
                showNomination: showNomination
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    fopQuadrant(actionData: any, contents: any, selActionId: number, gpDetails:any, hasTooltip: boolean = true) {
        this.componentData = Object.assign(new ComponentData(), {
            component: FOPQuadrantComponent,
            componentInput: {
                actionData: actionData,
                contents: contents,
                selActionId: selActionId,
                gpDetails: gpDetails,
                hasTooltip: hasTooltip
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    zoomGP(PageContents, OtherContents, selectedCard, xLabel?:string,yLabel?:string, config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ZooomDialogComponent,
            componentInput: {
                PageContents: PageContents,
                OtherContents: OtherContents,
                selectedCard: selectedCard,
                xLabel:xLabel,
                yLabel:yLabel,
                config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    zoomFopDialog(PageContents, OtherContents, driverId, subDriverId, selectedCard, team, xLabel?:string,yLabel?:string,config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ZooomFocusGPComponent,
            componentInput: {
                PageContents: PageContents,
                driverId: driverId,
                subDriverId: subDriverId,
                selectedCard: selectedCard,
                team: team,
                OtherContents: OtherContents,
                xLabel:xLabel,
                yLabel:yLabel,
                config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }
    validateAlphanumericName(val) {
        const specialChars = /[`@#$%^&+{};="<>?~|/]/;
        return specialChars.test(val);
    }
    viewNote(driverId, driverName, subDriverId,subDriverName,growthPlanId,PageContents, SiteContents,disabled,config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ViewNoteDialogComponent,
            componentInput: {
                PageContents: PageContents,
                SiteContents: SiteContents,
                driverId: driverId,
                subDriverId: subDriverId,
                driverName: driverName,
                subDriverName: subDriverName,
                disabled: disabled,
                growthPlanId: growthPlanId
              //  config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    viewGrowthMatrixAxes(pageContents, growthPlanId) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ViewGrowthMatrixAxesComponent,
            componentInput: {
                PageContents: pageContents,
                growthPlanId: growthPlanId
            }
        });

        return this.containerElementRef.showDialog(this.componentData);
    }
    
}
