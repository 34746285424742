<div class="modal fade login-dialog-modal" bsModal #loginDialog="bs-modal"
     tabindex="-1" role="dialog" [config]="config" (onHide)="onModalClosed($event)" *ngIf ="showLogin">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Login</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="loginDialog.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>


            <div class="modal-body">
                <h3>Your session has expired. Please login to proceed</h3>
                <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !user.valid }">
                        <label for="username">E-mail</label>
                        <input autocomplete="off" type="email" class="form-control" name="user" [(ngModel)]="model.user" #user="ngModel" required email />
                        <div *ngIf="f.submitted && !user.valid" class="help-block">Please enter your e-mail id to continue</div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-ey-default" [disabled]="loading">Login</button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>

            </div>
            
        </div>
    </div>
</div>