import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../service/util.service';
import { AlertService } from '../../service/alert.service';
import { Constants } from '../../service/constant.service';
import { Response as VResponse } from './../../service/model/response';
import { UserService } from './user.service';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";

import { Observable } from 'rxjs';

import { ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
import { ProgressBarService } from '../../progress-bar-module/progres-bar.service';
import { TimerService } from '../../service/timer.service';
import { HTTPService } from '../../service/http.service';
import { Router } from '@angular/router';
import { GrowthPlanSessionDto } from '../../focus/model/face-to-face.model';
import { StorageHelperService } from '../../service/storage-helper.service';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

const AUTH_API = environment.VELOCITY_API;
const AZURE_CLIENTID = environment.AZURE_CLIENTID;

@Injectable()
export class AuthenticationService {
    public token: string;

    constructor(
      private http: HttpClient,
        private utilService: UtilService,
        private userService: UserService,
        private constant: Constants,
        private alert: AlertService,
        private progressbarService: ProgressBarService,
        private timer: TimerService,
        private logService: LogService, private logTypes: LogTypes,
        private httpService: HTTPService,
        private router: Router,
        private storageHelper: StorageHelperService,
    ) {
        //edited
        userService.setAuthenticationService(this);
        // set token if saved in local storage
        //const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        //this.token = currentUser && currentUser.token;
    }



    loginByEmail(email: string): Observable<boolean> {

        return Observable.create(observer => {

            var userObject = this.utilService.doPost(AUTH_API + this.constant.AUTH.API_LOGIN_BY_USER, { "username": email, isAdminApp:false, isPortalApp:true }).subscribe((response: VResponse) => {
                if (response && response.status && (response.code === '1000') && response.result) {
                    //this.storageHelperService.SetSessionValue("currentuser", response.result)
                    this.userService.setCurrentUser(response.result);

                    var currentGrowthPlanId = response.result.currentGrowthPlanId;
                    var curremtGrowthPlanDetails = response.result.currentGrowthPlan;
                    //Set current growthPlan
                    var sessionStorageData: GrowthPlanSessionDto = {
                        growthPlanId: currentGrowthPlanId,
                        growthPlanName: curremtGrowthPlanDetails.growthPlanName,
                        isLocked: curremtGrowthPlanDetails.isLocked,
                        isShared: curremtGrowthPlanDetails.isShared
                    };
                    this.storageHelper.SetSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);

                    observer.next(true);
                    observer.complete();


                    this.timer.setStartTime();
                    this.timer.start();

                    //User IQ
                    //var loggedInUser = this.userService.getCurrentUser();
                    //if (window["_uiq"] && typeof (window["_uiq"].push) != undefined) {
                    //    window["_uiq"].push(['identify', loggedInUser.userEmailEncrypted, {
                    //        user_name: "Velocity User",
                    //        account_id: 1,
                    //        account_name: "Velocity",
                    //        user_email: loggedInUser.userEmailEncrypted,
                    //        custom_hasAssessedAny: loggedInUser.hasAssessedAny,
                    //        custom_isPresentAssessed: false,
                    //        custom_isAspirationAssessed: false,
                    //        custom_isInternalUser: (loggedInUser.userType == 2) ? false : true,
                    //        custom_numberOfEemployees: loggedInUser.numberOfEemployees,
                    //        custom_industry: loggedInUser.industry,
                    //        custom_companyType: loggedInUser.companyType,
                    //        custom_fundingStage: loggedInUser.fundingStage,
                    //        custom_country: loggedInUser.country
                    //    }]);

                    //    window["_uiq"].push(['startTracker']);
                    //}


                } else {
                    //throwError(response.message);
                    //console.log(response.message);
                    observer.next(false);
                    observer.complete();
                }

            }, (err: any) => {
                //console.log(err); 
                observer.next(false);
                observer.complete();
            });

        });

    }

    internalUserLogin(username: string, data?: any, isAuth0?: boolean) {
       
        var user = {};
        if (isAuth0) {
            user = {
                username: username,
                isPortalApp: true,
                isAdminApp: false,
                isAuth0Call: true
            };
        } else {
            user = { username: username, isPortalApp: true, isAdminApp: false };
      }
        return Observable.create(observer => {

            //var userObject = this.utilService.doPost(AUTH_API + this.constant.AUTH.API_INTERNAL_LOGIN,
            //    user).subscribe((response: VResponse) => {
            //        if (response && response.status && (response.code === '1000') && response.result) {
            //            this.userService.setCurrentUser(response.result);
            //            observer.next(true);
            //            observer.complete();
            //            this.timer.start();
            //        } else {
            //            console.log(response.message);
            //            observer.next(false);
            //            observer.complete();
            //        }

            //    }, (err: any) => {
            //        console.log(err); observer.next(false);
            //        observer.complete();
            //    });
            var userObject = this.httpService.request({
                url: AUTH_API + this.constant.AUTH.API_INTERNAL_LOGIN,
                method: 'POST',
                headers: this.getAuth0Header(data),
                params: user
            }).subscribe((response: any) => {
                if (response && response.status && (response.code === '1000') && response.result) {
                    this.userService.setCurrentUser(response.result);
                    if (!isAuth0) {
                        this.storageHelper.SetLocalValue("adal.idtoken", null);
                        this.storageHelper.SetLocalValue("adal.access.token.key" + AZURE_CLIENTID, null);
                    } observer.next(true);
                    observer.complete();

                    var currentGrowthPlanId = response.result.currentGrowthPlanId;
                    var curremtGrowthPlanDetails = response.result.currentGrowthPlan;
                    //Set current growthPlan
                    var sessionStorageData: GrowthPlanSessionDto = {
                        growthPlanId: currentGrowthPlanId,
                        growthPlanName: curremtGrowthPlanDetails.growthPlanName,
                        isLocked: curremtGrowthPlanDetails.isLocked,
                        isShared: curremtGrowthPlanDetails.isShared
                    };
                    this.storageHelper.SetSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);

                    this.timer.setStartTime();
                    this.timer.start();
                    this.logLoggedInEvent(); // Loggin event logged as event.


                    //User IQ
                    //var loggedInUser = this.userService.getCurrentUser();
                    //if (window["_uiq"] && typeof (window["_uiq"].push) != undefined) {
                    //    window["_uiq"].push(['identify', loggedInUser.userEmailEncrypted, {
                    //        user_name: "Velocity User",
                    //        account_id: 1,
                    //        account_name: "Velocity",
                    //        user_email: loggedInUser.userEmailEncrypted,
                    //        custom_hasAssessedAny: loggedInUser.hasAssessedAny,
                    //        custom_isPresentAssessed: false,
                    //        custom_isAspirationAssessed: false,
                    //        custom_isInternalUser: (loggedInUser.userType == 2) ? false : true,
                    //        custom_numberOfEemployees: loggedInUser.numberOfEemployees,
                    //        custom_industry: loggedInUser.industry,
                    //        custom_companyType: loggedInUser.companyType,
                    //        custom_fundingStage: loggedInUser.fundingStage,
                    //        custom_country: loggedInUser.country
                    //    }]);

                    //    window["_uiq"].push(['startTracker']);
                    //}
                }
                else if (response && response.code && (response.code === '401' || response.code.indexOf('403') != -1)) {
                    var result = { userLoggedIn: false, message: '' + response.code.toString() };
                    observer.next(result);
                    observer.complete();
                }
                else {
                    //console.log(response.message);
                    observer.next(false);
                    observer.complete();
                }

            }, (err: any) => {
                //console.log(err);
                observer.next(false);
                observer.complete();
            });

        });
    }


  getAuth0Header(accessToken: any) {
    let r = (Math.random() + 1).toString(36).substring(4);
    let headers;

    if (accessToken) {
       headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': r,
        'Authorization': 'Bearer ' + accessToken
      })
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': r
      })
    }
      
        return headers;
    }

    /* Register */
    register(registerReq: any): Observable<any> {
        return Observable.create((observer) => {
            this.utilService.doPost(AUTH_API + this.constant.AUTH.API_REGISTER, registerReq).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000') && response.result) {
                        // this.userService.setCurrentUser(response.result);
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    var code = "";
                    if (error) {
                        var parseError = false;
                        try {
                            if (error._body && JSON.parse(error._body).code) {
                                code = JSON.parse(error._body).code;
                            }
                        }
                        catch (e) {
                            parseError = true;
                        }
                    }
                    if (code == "1100") {
                        //this.dialogService.alert("You already have an account in EY Velocity. Click Ok to redirect to home page").subscribe(x => {
                        //    this.router.navigate(['dashboard']);
                        //});
                        observer.next(false);
                        observer.complete();
                    }
                    else {
                        this.utilService.errorHandler(error);
                    throwError(error);
                    }
                }
            )
        });
    }

    veriyEmail(email: string) {
        return Observable.create((observer) => {
            this.utilService.doPost(AUTH_API + this.constant.AUTH.API_VERIFY_EMAIL, { username: email }).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000')) {
                        // this.userService.setCurrentUser(response.result);
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.utilService.errorHandler(error);
                }
            )
        });
    }

    checkAuth() {//edit
      return this.utilService.doPost(AUTH_API + this.constant.AUTH.API_CHECK_AUTH, null, ProgressBarHelper.PageTransition).pipe(tap(x => {
            if (x && x.result && x.result == true) {
                this.timer.restart();
            }
            else {
                //Nothing to do here as this will be handled by the userservice logout method
            }
        }));
    }

    autoLogout() {
      return this.utilService.doPost(AUTH_API + this.constant.AUTH.AUTO_LOGOUT_API_URL, null, ProgressBarHelper.PageTransition).pipe(tap(x => {
            if (x && x.result && x.result == true) {
                //this.timer.stopAndKill();
                //this.timer.clearStartTime();
            }
            else {
                //Nothing to do here as this will be handled by the userservice logout method
            }
        }));
    }

    ///This method is used to extend session
    refreshToken() {
        return Observable.create(observer => {

            var userObject = this.utilService.doPost(AUTH_API + this.constant.AUTH.API_REFRESH_TOKEN).subscribe((response: VResponse) => {
                if (response && response.status && (response.code === '1000') && response.result) {

                    var currentUser = this.userService.getCurrentUser();
                    currentUser.token = response.result.token;
                    this.userService.setCurrentUser(currentUser);
                    observer.next(true);
                    observer.complete();
                    this.timer.restart();
                } else {
                    observer.next(false);
                    observer.complete();
                }

            }, (err: any) => {
                observer.next(false);
                observer.complete();
            });

        });
        
    }

    authenticateExternalUser(email: string, accessToken: string) {
        var progressBarOptions = ProgressBarHelper.DefaultPost;
        return Observable.create((observer) => {

          this.progressbarService.enable(progressBarOptions);
          let r = (Math.random() + 1).toString(36).substring(4);
          let headers;

          if (accessToken) {
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              'rid': r,
              'Authorization': 'Bearer ' + accessToken
            })
          } else {
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              'rid': r
            })
          }
          
          const requestOptions = {
            headers: headers
          };
          this.http.post<VResponse>(AUTH_API + this.constant.AUTH.API_AUTHENTICATE_External_User, JSON.stringify({ username: email }), requestOptions)
            //    .map(x => x.json())
            .pipe(tap((x: any) => {
              this.progressbarService.disable(progressBarOptions);
            }))
            //this.http.post(AUTH_API + this.constant.AUTH.API_AUTHENTICATE_External_User, JSON.stringify({ username: email }), requestOptions)
            //    .map(x => x.json())
            //    .do((x: any) => {
            //        this.progressbarService.disable(progressBarOptions);
            //    })
                .subscribe(
                (response: VResponse) => {
                    this.progressbarService.disable(progressBarOptions);
                    if (response && response.status && (response.code === '1000')) {
                        this.userService.setCurrentUser(response.result);
                        var result = { userLoggedIn: true, message: '' };
                        observer.next(result);
                        observer.complete();

                        var currentGrowthPlanId = response.result.currentGrowthPlanId;
                        var curremtGrowthPlanDetails = response.result.currentGrowthPlan;
                        //Set current growthPlan
                        var sessionStorageData: GrowthPlanSessionDto = {
                            growthPlanId: currentGrowthPlanId,
                            growthPlanName: curremtGrowthPlanDetails.growthPlanName,
                            isLocked: curremtGrowthPlanDetails.isLocked,
                            isShared: curremtGrowthPlanDetails.isShared
                        };
                        this.storageHelper.SetSessionValue(this.constant.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);

                        this.timer.setStartTime();
                        this.timer.start();
                        this.logLoggedInEvent();

                        //User IQ
                       // var loggedInUser = this.userService.getCurrentUser();
                        //if (window["_uiq"] && typeof (window["_uiq"].push) != undefined) {
                        //    window["_uiq"].push(['identify', loggedInUser.userEmailEncrypted, {
                        //        user_name: "Velocity User",
                        //        account_id: 1,
                        //        account_name: "Velocity",
                        //        user_email: loggedInUser.userEmailEncrypted,
                        //        custom_hasAssessedAny: loggedInUser.hasAssessedAny,
                        //        custom_isPresentAssessed: false,
                        //        custom_isAspirationAssessed: false,
                        //        custom_isInternalUser: (loggedInUser.userType == 2) ? false : true,
                        //        custom_numberOfEemployees: loggedInUser.numberOfEemployees,
                        //        custom_industry: loggedInUser.industry,
                        //        custom_companyType: loggedInUser.companyType,
                        //        custom_fundingStage: loggedInUser.fundingStage,
                        //        custom_country: loggedInUser.country
                        //    }]);

                        //    window["_uiq"].push(['startTracker']);
                        //}

                    } else if (response && response.code && (response.code === '400')) {
                        //this.utilService.errorHandler(response.message || '');
                        throwError(response.message);
                        //?? This usually happens when the data (email) is bad. Need to check if we need to show a message ("Could not get data from linkedin"), and redirect the user back to privacy
                    }
                    else if (response && response.code && (response.code === '401' || response.code.indexOf('403') != -1)) {
                        var result = { userLoggedIn: false, message: '' + response.code.toString() };
                        observer.next(result);
                        observer.complete();
                    }
                    else {
                        //this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.progressbarService.disable(progressBarOptions);
                    //this.utilService.errorHandler(error);
                }
                )
        });
    }

    logLoggedInEvent() {
        let log = new LogAction();
        log.action = this.logTypes.LOGGED_IN;
        this.logService.logAction(log);
    }

    validateMembershipId(params: any) {
        return Observable.create((observer) => {
            this.utilService.doPost(AUTH_API + this.constant.AUTH.API_MEMBERSHIP, params).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000') ) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.utilService.errorHandler(error);
                }
            )
        });
    }

    isAuthorizedURL(params: any) {
        return Observable.create((observer) => {
            this.utilService.doGet(AUTH_API + this.constant.AUTH.API_AUTHORIZED_URL + '/' + params.type + '/'+ params.id , params).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.utilService.errorHandler(error);
                }
            )
        });
    }

    getMyEyUserDetails(params) {
        return Observable.create((observer) => {
            this.utilService.doPost(AUTH_API + this.constant.AUTH.GET_MY_EY_USER_DETAILS, params).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.utilService.errorHandler(error);
                }
            )
        });
    }
   
}
