import { Component, Input, Output, EventEmitter, OnInit, Injector, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'growth-plan-bubble-test',
  templateUrl: './growth-plan-bubble-test.component.html',
    styleUrls: ['./growth-plan-bubble-test.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GrowthPlanBubbleTestComponent implements OnInit, OnChanges {

    scaledPosition: any = { x: 0, y: 0 };
    actualPosition: any = { x: 0, y: 0 };
    parentRectangle: any = { x: 0, y: 0, width: 0, height: 0 };
    timeoutHandler: any;
    isLongPress: boolean = false;
    @Input('action') action: any;
    @Input('size') size: any;

    @Input('highLightBubbles') highLightBubbles: boolean;
    @Output('onDragStart') dragStart: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClick') cardClick: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClickAndHold') clickAndHold: EventEmitter<any> = new EventEmitter<any>();

    @Input('isDragEnabled') isDragEnabled: boolean = true;
    @Input('participant') participant: any = null;
    @Input('count') count: number = 1;
    @Input('showBorder') showBorder: boolean = false;
    constructor(

        private injector: Injector
    ) {
    }

    ngOnInit() {

    }

    onDragStart(ev: any) {
        this.dragStart.emit(ev);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let prop in changes) {
            //if (prop == "scaledPosition") {
            //    var change = changes[prop];
            //    console.log(change.isFirstChange());
            //}
            if (prop == "action") {
                var change = changes[prop];
                //console.log(change);
            }
        }
    }

    getBubblePosition(action: any) {
        return { top: action.yPos, left: action.xPos };
    }

    onCardSelected(e: any, selectedAction: any) {

        e.stopPropagation();
        if (!this.isLongPress) {
            this.cardClick.emit(selectedAction);
        }

    }

    onMouseup() {
      //  if (this.timeoutHandler) {
          //  clearTimeout(this.timeoutHandler);
           // this.timeoutHandler = null;
            this.isLongPress = false;

      //  }
        
        this.highLightBubbles = false;
        this.clickAndHold.emit("");
    }

    onMousedown(action: any) {
      //  this.timeoutHandler = setTimeout(() => {
            this.isLongPress = true;
            this.timeoutHandler = null;
            
            this.clickAndHold.emit(action);
      //  }, 500);
    }
    getZvalue(action) {
        if (action.active)
            return 102;
        if (action.zValue)
            return action.zValue;
        return 101;
    }
}
