import { Component, Input, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { VelocityService } from '../../service/velocity.service';
import { SaveCustomAction } from './../../focus/model/save-newCustomAction.model';
import { UtilService } from './../../service/util.service';
@Component({
    //moduleId: module.id.toString(),
    selector: "add-cutom-action-dialog",
    templateUrl: './add-custom-action.component.html', 
    styleUrls: ['./add-custom-action.component.css']
})

export class AddCustomActionComponent {
    templateData: any = {};
    //TODO - if edit is needed
    isEdit: boolean = false;
  @ViewChild('customActionModal', { static: true }) customActionModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    actionId: number;
    actionName = '';

    @Output("closeEvent") componentEvent: any = new EventEmitter();

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private utilService: UtilService
    ) {
        this.templateData = this.injector.get('templateData');
        this.actionId = this.templateData.id;
        this.actionName = this.templateData.title;
    }

    ngOnInit() {

    }



    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.customActionModal.config = this.config;
        this.customActionModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.customActionModal.hide();
    }

    saveAction() {
            // Call the API
        //let saveData = new SaveCustomAction(this.templateData.growthPlanId, this.actionName, '', this.templateData.driverId, this.templateData.themeId, true, 0);
        let modifiedData = { id: this.actionId, growthPlanId: this.templateData.growthPlanId, title: this.actionName, driverId: this.templateData.driverId };
        this.velocityService.modifyCustomActionCards(modifiedData).subscribe(
                (data: any) => {
                    if (data) {
                        this.returnedValue = "success";
                        this.closeDialog();
                    }
                }
            );
                          
    }

}
