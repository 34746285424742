import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { TimerService } from '../../service/timer.service';
import { UserService } from '../../auth/service/user.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../auth/service/auth.service';
import { LogAction, LogTypes } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';
import { Constants } from '../../service/constant.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Response } from './../../service/model/response';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

const CHECK_AUTH_API_URL = environment.VELOCITY_API + 'user/CheckAuth';

@Component({
    selector: 'timeout-handler',
    templateUrl: 'timeout-handler.component.html'
})

export class TimeoutHandlerComponent extends BaseComponent {
    loggedOut: boolean = false;
    siteContents: any;


    constructor(private injector: Injector, private dialogService: ModalDialogService, private timer: TimerService, private userService: UserService, private router: Router, private authService: AuthenticationService,
        private logService: LogService,
        private logTypes: LogTypes,
      private auth: AuthService,
        private constants: Constants,
      private _http: HttpClient
    ) {
        super(injector);
        this.siteContents = this.SiteContents;
    }

    logEvent() {
        let log = new LogAction();
        log.action = this.logTypes.TIME_OUT;
        this.logService.logAction(log);
    }

    ngOnInit() {
        this.timer.timeoutAction.subscribe((action: string) => {
            if (action === "start") {
                this.loggedOut = false;

            }
            else if (action === "showdialog") {

              var dialogText = action === "showdialog" ? "Your session will end soon. Click ok to extend your session" : "Your session will end soon. Please save your work.";

              this.dialogService.alert("", dialogText, false).subscribe(x => {

                if (!this.loggedOut) {

                      let headers = {
                        headers: this.getHeadersWithToken()
                      }

                  this._http.post<Response>(CHECK_AUTH_API_URL, null, headers)
                           // .map(res => res.json()).
                        .subscribe((response: Response) => {
                                if (response && response.status && (response.code === '1000') && response.result && response.result == true) {
                                    this.timer.restart();
                                }
                                else {
                                    //kill session and redirect to welcome
                                    //this.loggedOut = true;
                                    //this.userService.logout();
                                    //if (this.adalService.userInfo.isAuthenticated) {

                                    //    this.adalService.logOut();
                                    //}
                                    //else {
                                    //    if (this.constants.USE_LINKEDIN_LOGIN) {
                                    //        this.oidcSecurityService.logoff();
                                    //    }
                                    //    else {
                                    //        this.router.navigate(['/welcome']);
                                    //    }
                                    //}
                                    this.logoutUser(true);
                                }
                            }, (err: any) => {

                                this.logoutUser(false);

                            });

                    }
                    else {

                        this.logoutUser(true);
                    }

                    //TODO  - handle unauth response

                });
            }
            else if (action === "showdialoglast") {
                this.timer.restart();
                var dialogText = "Your session will end soon. Please save your work.";

                this.dialogService.alert("", dialogText, false).subscribe(x => {
                    console.log("loggedouot", this.loggedOut);
                    if (!this.loggedOut) {
                    }
                    else {
                        this.logoutUser(true);
                    }


                });
            }
            else if (action == "logout") {
                this.logoutUser(true);
            }
        });


    }

    logoutUser(loggerEnabled: boolean = false) {
        // debugger;
        this.loggedOut = true;
        if (loggerEnabled) {
            this.logEvent();
        }
        this.userService.logout();
       // setTimeout(() => {
            //var currentUser = this.userService.getCurrentUser();
            //if (currentUser != null) {
                var dialogText = "Your session has expired.";
                var me = this;
                this.dialogService.alert("", dialogText, false, true).subscribe(x => {
                  
                        if (me.constants.USE_MYEY_LOGIN) {
                          this.auth.logout({
                            returnTo: environment.POST_LOGOUT_REDIRECT_URI
                          });
                        }
                        else {
                            me.router.navigate(['/welcome']);
                        }

                });
          //  }
       // }, 2500);


    }

  private getHeadersWithToken() {
    var currentUser = this.userService.getCurrentUser();
    let headers;
    //  headers.append('Accept', 'application/json');
    let r = (Math.random() + 1).toString(36).substring(4);
    //  headers.append('rid', r);
    //  if (currentUser && currentUser.token) {
    //      headers.append('Authorization', 'Bearer ' + currentUser.token);
    //}
    if (currentUser && currentUser.token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': this.getRandom(),
        'Authorization': 'Bearer ' + currentUser.token
      })
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': this.getRandom()
      })
    }
    return headers;
    }
    getRandom() {
      return (Math.random() + 1).toString(36).substring(4);
    }


}
