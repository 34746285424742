
<div class="modal fade landingPageModel" bsModal #videoModalHottopic="bs-modal" 
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <!--<div *ngIf="showLoaderForVideo">Loading</div>-->
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png"/>close
                </div>
                <div class="video-modal-content">
                    <video width="100%" controls controlsList="nodownload" autoplay>
                        <source src="{{videoUrl}}">
                    </video>
                    <!--<div class="playIcon"></div>-->
                </div>
              
                </div>
            
        </div>
    </div>
</div>