import { Component, OnInit, Input, Injector, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from './../../component/base.component';
import { VelocityService } from "../../service/velocity.service";
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';


@Component({
    selector: 'help-topics-component',
  templateUrl: './help-topics.component.html',
  styleUrls: ['./help-topics.component.scss']
})

export class HelpTopicsComponent extends BaseComponent implements OnInit {

    @ViewChild('userProfileModal') userProfileModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    @ViewChild('subTopicAccordion') subTopicElement: ElementRef;


    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    sectionId: any;
    topicId: any;
    helpTopics: any = [];
    helpSections: any = [];
    updateProgressBar: ProgressBarOptions;
    setInitialHeight: boolean = true;

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,

    ) {
        super(injector);
        this.sectionId = this.injector.get('topicId');
    }

    ngOnInit() {
        this.updateProgressBar = ProgressBarHelper.newUpdateProgressOption("help-topics");

        this.velocityService.getHelpTopics(this.sectionId, this.updateProgressBar).subscribe((data: any) => {
            if (data) {

                this.helpTopics = data;

                let selectedSection = this.helpTopics.find(item => item.isSelected == true);
                if (selectedSection) {
                    this.topicId = selectedSection.id;
                }
                else {
                    this.topicId = this.helpTopics.length > 0 ? this.helpTopics[0].id : 0;
                }

                //console.log(this.helpTopics);

                this.getSectionsList(this.topicId, this.sectionId);
                this.setInitialHeight = true;

            }
        });

    }

    ngAfterViewInit() {
        this.setScrollHeight(this.sectionId, true);
    }

    onGetHelpSections(selectedTopicId) {

        this.getSectionsList(selectedTopicId, 0);
        this.setInitialHeight = true;
    }

    onSetActiveSection(topicId, sectionId, isActive) {

        // this.setScrollHeight(sectionId, isActive);
        this.setInitialHeight = false;
        this.setActiveSection(topicId, sectionId);

    }

    private setScrollHeight(sectionId: any, isActive: boolean) {

        var selected = this.subTopicElement.nativeElement.querySelector('#subTopic_' + sectionId);

        if (selected != null) {
            let sectionHeight = selected.querySelector(".topic-transition").scrollHeight;

            if (isActive) {
                selected.querySelector(".topic-transition").style.height = sectionHeight + 'px';
            }
            else {
                selected.querySelector(".topic-transition").style.height = '0px';

            }
        }


    }

    private setActiveSection(topicId, sectionId) {

        this.setActiveTopics(topicId);

        if (sectionId != 0) {

            this.helpSections.map((item) => {

                if (item && item.hasOwnProperty("active")) {
                    item.active = (item.active == false && sectionId == item.id) ? true : false;
                }
                else {
                    item.active = (sectionId == item.id) ? true : false;
                }

                this.setScrollHeight(item.id, item.active);
            });

        } else {
            //sectionId == 0
            this.helpSections.map((item, index) => {
                item.active = index == 0;
                this.setScrollHeight(item.id, item.active);
            });

        }
    }


    private getSectionsList(topicId, selectionId) {

        this.velocityService.getHelpSections(topicId, this.updateProgressBar).subscribe((helpSections: any) => {
            if (helpSections) {
                //console.log(helpSections);
                this.helpSections = helpSections;

                this.setActiveSection(topicId, selectionId);
            }
        });
    }

    private getTopicId(topicId) {
        let menuItem = this.helpSections.find((item) => item.helpTopicId == topicId);
        return (menuItem && menuItem.helpTopicId != "") ? menuItem.helpTopicId : null;
    }

    private setActiveTopics(topicId) {
        this.helpTopics.map((item) => {
            item.active = topicId == item.id;
        });
    }

    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.userProfileModal.config = this.config;
        this.userProfileModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.userProfileModal.hide();
    }

}
