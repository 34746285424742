<div class="modal fade ey-dialog-modal create-new-growth-plan"
     bsModal #createNewGrowthPlanModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">


    <div class="modal-dialog"   update-panel-action="click">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{PageContents.lblCreateGrowthPlan}}</h4>
               <img class="whiteClose" (click)="closeBtn()" src="../../../assets/images/close-bt.png" />               
            </div>
            <div class="modal-body" eyv-update-panel="createNewGrowthPlan">
                <p class="create-desc ellipsis" [innerHTML]="isFacilitator ? PageContents.lblDSPlanSelectionAndCreationText : PageContents.lblCreateGrowthPlanTagLine"></p>
                <div class="plan-type-container">
                    <ng-container *ngFor="let plan of growthPlanTypeList">
                        <div class="radiobox">
                            <label class="container" [ngClass]="{'dis':plan.id == 3 && user.userType != 2}">
                                {{plan.growthPlanText}}
                                <input type="radio" [disabled]="plan.id == 3 && user.userType != 2" [value]="plan.id" [(ngModel)]="growthPlanType" (click)="growthPlanTypeChange(plan.id)" />
                                <span class="checkmark" [ngClass]="{'disabled':plan.id == 3 && user.userType != 2}"></span>
                            </label>
                        </div>
                        <div class="sub-title" *ngIf="plan.id == 1">
                            {{PageContents.lblSelfHelpText}}
                        </div>
                        <div class="sub-title" *ngIf="plan.id == 4">
                            {{PageContents.lblDemoHelpText}}
                        </div>
                        <div class="sub-title" *ngIf="plan.id == 2">
                            {{PageContents.lblFacilitatedHelpText}}
                        </div>
                        <div class="sub-title" *ngIf="(plan.id == 3 || plan.id == 5) && (isFacilitator || user.userType == 2)">
                            {{isFacilitator ? PageContents.lblFaclitatedLeadershipHelpText: PageContents.lblLeadershipHelpText }}
                        </div>
                        <div class="sub-title" *ngIf="plan.id==3 && user.userType != 2">
                            {{subTitle1}}<a href="mailto:{{PageContents.lblVelocitySupportEmail}}?subject={{PageContents.lblLeadershipTeamMeetingInquiry}}">{{PageContents.lblVelocitySupport}}</a>{{subTitle2}}
                        </div>
                        <div class="sub-title" *ngIf="plan.id == 6">
                            {{PageContents.lblDemoMeetingTypeDescription}}
                        </div>

                    </ng-container>
                </div>
                <ng-container *ngIf="growthPlanType == 1 || growthPlanType == 4">
                    <form name="form" (ngSubmit)="selfGrowthPlan.form.valid && !invalidErrorMessage && createGrowthPlan()" #selfGrowthPlan="ngForm" novalidate>
                        <div class="plan-name-container">
                            <label class="plan-name-lbl">{{PageContents.lblPlanName}}</label>
                            <input class="plan-name" autocomplete="off" type="text" name="planName"
                                   [ngClass]="{'red-border-class': errorEmptyGP || errorMessage || (selfGrowthPlan.submitted && !planName.valid)}"
                                   [(ngModel)]="growthPlan.growthPlanName" #planName="ngModel" maxlength="50" required  (keyup)="onKeyUp($event)" />
                            <div *ngIf="errorEmptyGP || (selfGrowthPlan.submitted && !planName.valid)" class="help-block">{{PageContents.lblEnterAssessmentName}}</div>
                            <div *ngIf="errorMessage" class="help-block">{{errorMessage}}</div>
                            <div *ngIf="invalidErrorMessage" class="help-block">{{PageContents.lblGrowthAssessmentValidation}}</div>
                        </div>
                        <div class="growth-plan-footer">
                            <button class="btn-outline">{{PageContents.lblCreateGrowthPlanSubmit}}</button>
                            <span class="or">{{PageContents.lblCreateGrowthPlanOr}}</span>
                            <span class="back" (click)="closeBtn()">{{PageContents.lblCreateGrowthPlanCancel}}</span>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="growthPlanType == 2 || growthPlanType == 3 || growthPlanType == 5 || growthPlanType == 6">  
                    <div class="growth-plan-footer">
                        <button class="btn-outline" (click)="startFacilitated()">{{PageContents.lblCreateGrowthPlanNext}}</button>                       
                    </div> 
                </ng-container>
            </div>
        </div>
    </div>
</div>