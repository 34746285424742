import { Component, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, Injector } from '@angular/core';
import {OkCancelDialogComponent} from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { LandingAlertDialogComponent } from './landing-alert-component/landing-alert.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
//import { VideoDialogComponent } from './video-dialog-component/video-dialog.component';
import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
import { TermsDialogComponent } from './terms-dialog-component/terms-dialog.component';
import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct.component';
//import { CustomizeReportComponent } from './customize-report-component/customize-report.component';
//import { CoverPageTitleEditComponent } from './cover-page-title-component/cover-page-title.component';
import {AddCustomActionComponent } from './add-custom-action-component/add-custom-action.component';
import { ComponentData } from './component-data';
import { RestoreActionComponent } from './restore-action-component/restore-action.component';
import { VideoDialogHottopicComponent } from './video-dialog-hottopics-component/video-dialog-hottopics.component'
import { ActionDetailComponent } from './action-detail-component/action-detail.component';
//import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
import { HelpTopicsComponent } from "./help-topics-component/help-topics.component";
import { InsightModalComponent } from './insight-component/insight-component';
//import { ThemeDialogComponent } from './theme-dialog-component/theme-dialog.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { addNotesComponent } from './add-notes-component/add-notes.component';
////import { F2FLoginComponent } from './f2f-login-component/f2f-login.component';
import { F2FInfoDialogComponent } from './f2f-info-dialog-component/f2f-info-dialog-component';
import { CorporateParticipantInfoDialogComponent } from './corporate-participant-info-dialog-component/corporate-participant-info-dialog-component';
import { CorporateOwnerInfoDialogComponent } from './corporate-owner-info-dialog-component/corporate-owner-info-dialog-component';
import { FOPQuadrantComponent } from './fop-quadrant/fop-quadrant.component';
import { ZooomDialogComponent } from './zoom-2x2-matrix/zoom-2x2-matrix';
import { ZooomFocusGPComponent } from './zoom-2x2-focus-gp/zoom-2x2-focus-gp';
import { ViewNoteDialogComponent } from './view-note-component/view-note.component';

@Component({
    selector: 'dynamic-modal-container',
    entryComponents: [
       // OkCancelDialogComponent,
        AlertDialogComponent,
        LandingAlertDialogComponent,
        InfoDialogComponent, 
       // VideoDialogComponent,
        VideoDialogHottopicComponent,
        FormDialogComponent, 
        TermsDialogComponent,
        CodeOfConductDialogComponent,
       // CustomizeReportComponent,
       // CoverPageTitleEditComponent,
        AddCustomActionComponent,
        RestoreActionComponent,
        ActionDetailComponent,
        //UserProfileDialogComponent,
        HelpTopicsComponent,
        InsightModalComponent,
       // ThemeDialogComponent,
        ConfirmResetComponent,
        addNotesComponent,
       //// F2FLoginComponent,
        F2FInfoDialogComponent,
        CorporateParticipantInfoDialogComponent,
        CorporateOwnerInfoDialogComponent,
        FOPQuadrantComponent,
        ZooomDialogComponent,
        ZooomFocusGPComponent,
        ViewNoteDialogComponent
    ],
    template: `<div #dvDynamicModalContainer></div>`
})
export class DynamicModalContainerComponent {
    currentComponent:any[] = [];
    @ViewChild('dvDynamicModalContainer', { read: ViewContainerRef }) dvDynamicModalContainer: ViewContainerRef;

    constructor(private resolver: ComponentFactoryResolver) { }

    componentData: ComponentData;

    ngOnInit() { }

    showDialog(componentData: ComponentData) {
        this.componentData = componentData;
  
        let inputProviders = Object.keys(this.componentData.componentInput).map((inputName) => { return { provide: inputName, useValue: this.componentData.componentInput[inputName] }; });
        //let resolvedInputs = ReflectiveInjector.resolve(inputProviders);
       // let injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, this.dvDynamicModalContainer.parentInjector);        
        let injector = Injector.create({
            providers: inputProviders,
            parent: this.dvDynamicModalContainer.parentInjector
        });
        let factory = this.resolver.resolveComponentFactory(this.componentData.component);
        let component = factory.create(injector);
        this.dvDynamicModalContainer.insert(component.hostView);
        let createdComponentInstance: any = component.instance;
        //if (this.currentComponent) {
        //    this.currentComponent.destroy();
        //}
        this.currentComponent.push(component);

        //calls the component method to show the dialog
        let returnedObservable: any = createdComponentInstance.openDialog();
        if (createdComponentInstance.componentEvent) {
            createdComponentInstance.componentEvent.subscribe((e: string) => this.componentEvent(e));
        }
        return returnedObservable;        
    }

    closeDialog() {
        var index = this.currentComponent.length - 1;
       
        if (this.currentComponent && this.currentComponent[index] && this.currentComponent[index].instance && this.currentComponent[index].instance.closeDialog) {
            this.currentComponent[index].instance.closeDialog();
        }

    }

    componentEvent(event: any) {
        if (event.value === "closed" || event.close === 'close') {
            var index = this.currentComponent.length - 1;

            if (this.currentComponent && this.currentComponent[index]) { 
                this.currentComponent[index].destroy();
                this.currentComponent.pop();
            }
        }

        if (event.value === "destroyed") {
            if (this.currentComponent) {                
                //TODO - Need to find a way todestroy the component when the modal is closed
                //this.currentComponent.destroy();
            }
        }

        if (event.value == 'others') {
            if (this.currentComponent.length > 1) {
                for (var i = 0; i < this.currentComponent.length - 1; i++)
                    this.currentComponent[i].destroy();
            }
        }
    }

    unsubscribe() {
        var index = this.currentComponent.length - 1;
        if (this.currentComponent && this.currentComponent[index] && this.currentComponent[index].instance && this.currentComponent[index].instance.observable) {
            if (this.currentComponent[index].instance.observable && this.currentComponent[index].instance.observable.source && this.currentComponent[index].instance.observable.source.observers && this.currentComponent[index].instance.observable.source.observers.length) {
                for (let observer of this.currentComponent[index].instance.observable.source.observers) {
                    if (observer.unsubscribe) {
                        observer.unsubscribe();
                    }
                }
            }
            this.closeDialog();
        }
    }

    ngOnDestroy() {
        if (this.currentComponent) {
            for (var i = 0; i < this.currentComponent.length;i++)
            this.currentComponent[i].destroy();
            this.currentComponent = [];
        }
    }    
}
