import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';



@Component({
    //moduleId: module.id.toString(),
    selector: "code-of-conduct-dialog",
  templateUrl: './code-of-conduct.component.html',
  styleUrls: ['./code-of-conduct.component.scss']
})

export class CodeOfConductDialogComponent implements OnInit {

    SiteContents: any = {};
    observable: Observable<any>;
    codeOfConduct: string = "";
    subject: any;
    data: any;
    titleText: string = "";
    @Output("closeEvent") componentEvent: any = new EventEmitter()
    @ViewChild('container') container: ElementRef;
    backdropClose: boolean = false;
    constructor(
        private injector: Injector,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocity: VelocityService) {
        this.getCodeOfConduct();
        this.backdropClose = this.injector.get('backdropClose');   
    }

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


    @ViewChild('codeOfConductModal') termsModal: any;
    returnedValue: any = {};

    ngOnInit() {
        //change
        this.utilService.getContent(CONTENTS.SITE_CONTENT).subscribe(
            (data: any) => {
                this.SiteContents = data;
                this.titleText = this.SiteContents.lblCodeofConduct;
            }
        );
        this.termsModal.config.ignoreBackdropClick = !this.backdropClose;
    }



    getCodeOfConduct() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("termsAndCondition");
        this.velocity.getCodeofConduct( updateProgressBar).subscribe(
            (result: any) => {
                console.log("term is " + this.titleText);

                this.codeOfConduct = result;
            }
        );
    }
   
    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }
    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });

    }
    okayClicked() {
       // this.returnedValue.action = 'yes';
        this.closeDialog();
    }
    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.termsModal.config = this.config;
        this.termsModal.show();
        return this.observable;
    }

    public closeDialog() {
        
        this.returnedValue.action = 'yes';
        this.termsModal.hide();
    }
}


