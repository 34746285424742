import { Component, OnInit, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserService } from './../../auth/service/user.service';
import { CONTENTS } from './../../config/content.const';
import { Constants } from './../../service/constant.service';
import { BaseComponent } from './../base.component';
import { ComponentContent } from './../component-content.decorator'
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { TemplateTypes } from "../../modal-dialog-module/template-types.enum";
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";


@ComponentContent(CONTENTS.HOME)
@Component({
    selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent extends BaseComponent implements OnInit {
    currentYear: any = new Date().getFullYear();
    footerContents: any;
    isLoggedIn = false;
    siteContents: any;
    headerLinks: any;
    @Input("menuContent")
    get menuContent(): any { return this._menuContent; }
    set menuContent(menuContent: any) {
        this._menuContent = menuContent;
    }
    private _menuContent: any = {};

    constructor(
        private utilService: UtilService,
        private dialogService: ModalDialogService,
        private router: Router,
        private userService: UserService,
        private injector: Injector,
        private logService: LogService,
        private logTypes: LogTypes,
        private constants: Constants
    ) {
        super(injector);
    }

    ngOnInit() {
      this.footerContents = this.PageContents;
      this.siteContents = this.SiteContents;
      this.userService.getCurrentUserAsObservable().subscribe((user: any) => {
          if (user) {
              this.headerLinks = user.footerLinks;
             
          }
      });       

      //subscribe and set the variable so that any change from any other component should reflect here.
        this.userService.isLoggedIn().subscribe((x: boolean) => {
            this.isLoggedIn = x;
        });
  }

    navigateTo(route: string) {
        this.router.navigate([route]);
    }

    showCopyrightPopup(): void {
        //this.showCustomizeReport();
        this.dialogService.info(TemplateTypes.COPYRIGHT_POPUP, { content: this.footerContents.footer.copyrightDescription.replace('#year', this.currentYear) }, false, true);
    }
    showCodeOfConduct() {
        const dialog = this.dialogService.codeOfConductModel(true);
        dialog.subscribe(
            (data) => {
                if (data && data.action && (data.action === 'yes')) {
                }
            }
        );
    }
    //showCustomizeReport() {
    //    const dialog = this.dialogService.customizeReportsModel(1);
    //    dialog.subscribe(
    //        (data) => {
    //            if (data && data.action && (data.action === 'yes')) {
    //            }
    //        }
    //    );
    //}
    showAccordianMenu(event)
    {
        //console.log(event);

        let accordian = event.target;
        accordian.classList.toggle("active");
        let panel = accordian.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
    }

    logMenuNavigation(sublink: any) {
        if(sublink.menuName == "BenchmarkingReport") {
            window.open(sublink.routes[0]);
        }
        let log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to ' + sublink.title;
        this.logService.logAction(log);
    }

    getMenuValue(item) {
        if (item.element && this._menuContent) {
            return this._menuContent[item.element] ? this._menuContent[item.element] : item.title;
        }
        return item.title;
    }
}
