<div class="modal fade ey-dialog-modal confirm-reset-modal"
         bsModal #confirmResetModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{contents.pageContents.lblConfirmReset}}</h4>
            </div>
            <div class="modal-body" eyv-update-panel="confirm-reset">
                <!--<p *ngIf="resetGNWarning" [innerHTML]="resetGNWarning"></p>-->
                <p *ngIf="draftCount > 0">
                    <span>{{resetGNWarning[0]}}</span>
                    <span class="anchor" (click)="gotoReport()">{{contents.pageContents.msgResetWarningWithDraftReportClick}}</span>
                    <span>{{resetGNWarning[1]}}</span>

                </p>
                <p>{{contents.pageContents.msgSaveReportTitle}}</p>
                <div class="form-group">
                    <input autocomplete="off" maxlength="60" class="form-control" [(ngModel)]="reportName" [disabled]="optOut">
                </div>
                <div class="label-style checkbox">
                    <label>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="optOut">
                        <span class="cr">
                            <i class="cr-icon fa fa-check"></i>
                        </span>
                        {{contents.pageContents.lblGPSaveReport}}
                    </label>
</div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn-outline me-2" (click)="cancelClicked();" type="button"
                                    [innerHTML]="contents.siteContents.buttonCancel || 'CANCEL'"></button>
                            <button class="btn-outline" (click)="okayClicked();" type="button"
                                    [innerHTML]="contents.pageContents.lblGPProceed || 'OK'" [disabled]="reportName.trim() == '' && !optOut"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>