<div class="people-picker-container" [class.multiselect]="(multiSelect === false)">
    <!--<div class="selected-user" *ngFor="let user of selectedUsers">
        <span class="user-text" title="{{user.Email}}">{{user.DisplayName}}</span><button class="delete-user" (click)="deleteSelectedUser(user)" type="button"><span>x</span></button>
    </div>-->
    <div class="user-search-input">
        <input type="text" [disabled]="disabled" [readonly]="selectedUsers.length > 0" autocomplete="off" placeholder="{{ (placeholder != undefined) ? placeholder : PageContents.lblSearchByNameEmailCompany}}" maxlength="50" [(ngModel)]="inputText" (keyup)="onKeyPress($event)" (keydown)="onKeyDown($event)" (onpaste)="onPaste();" #nameInput id="txtSearchInput" [ngClass]="{'red-border-class': PageContents.PeoplepickerError}" />
    </div>
</div>
<div class="preloader-holder" [class.multiselect-preloader-holder]="(multiSelect === true)" *ngIf="showPreLoader">
    {{PageContents.lblLoading}}
</div>

<div class="empty-text-holder" [class.multiselect-empty-text-holder]="(multiSelect === true)" *ngIf="showEmptyText">
    {{PageContents.lblZeroResultsFound}}
</div>
<div class="selection-holder" (fireApi)="fireApi($event)" [peoplePick]="appendData" custom-scroll [ngStyle]="{'height': getHeight()}" [class.multiselect-selection-holder]="(multiSelect === true)" *ngIf="filteredUsers.length > 0">
    <div *ngFor="let user of filteredUsers" class="filtered-user-list" (click)="selectUser(user)">
        {{user.firstName}}&nbsp;{{user.lastName}} <span class="company">{{user.company}}</span>
    </div>
</div>