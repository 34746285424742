import { Component, Input, Output, EventEmitter, OnInit, Injector, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';


@Component({
    selector: 'act-filter-dropdown',
  templateUrl: './action-filter-dropdown.component.html',
  styleUrls: ['./action-filter-dropdown.component.scss'],
})

export class ActionFilterDropdownComponent implements OnInit, OnChanges {

    @Input('drivers') drivers: any =[];
    @Input('actionList') actionList: any=[];
    @Output("slectedFilter") slectedFilter: any = new EventEmitter<any>();
    @Output("clickFilter") clickFilter: any = new EventEmitter();
    @Input('appContent') appContent: any = {};
    @Input('selectedDriver') selectedDriver = 0;
    alldriver = {};
    public selectedFilterValue:any = {};
    ngOnInit() {
        console.log(this.drivers);
        this.alldriver = {
            count: 0,
            driverName: this.appContent.lblAllDrivers,
            driverSort: 0,
            driverTag: this.appContent.lblAllDrivers,
            id: 0
        }
        this.selectedFilterValue = this.alldriver;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        for (let prop in changes) {
            
            if (prop == "drivers") {
                var change = changes[prop];
                if(change['currentValue'].length > 0) {
                    this.getDriversCount();
                }
            }
            if (prop == "actionList") {
                var change2 = changes[prop];
                if (change2['currentValue'].length > 0) {

                    this.getDriversCount();
                }
            }
            if (prop == 'selectedDriver') {
                let selDriver = this.drivers.filter((x: any) => x.id == changes[prop]['currentValue'])[0];
                this.selectedFilterValue = selDriver;

            }
        }
    }
   
    selectFilter(driver, event) {
        if (driver.count > 0) {
            this.selectedFilterValue = driver;
            this.clickFilter.emit();
            this.slectedFilter.emit(this.selectedFilterValue.id);
        } else {
            event.preventDefault();
            event.stopPropagation();

        }
    }

    getDriversCount() {
        if (this.drivers.length > 0 && this.actionList.length > 0) {
            if (this.drivers.filter((x: any) => x.id == 0).length == 0)
                 this.drivers.unshift(this.alldriver);

            this.drivers.map((obj) => {
                if (obj.id == 0)
                    obj.count = this.actionList.length;
                else
                  obj.count = this.actionList.filter((x: any) => x.driverTag == obj.id).length;
                return obj;
            });
        }
    }
    clickFilterfn() {
        this.clickFilter.emit();
    }
}
