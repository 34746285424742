import { Component, ViewChild, Injector, Output, EventEmitter, ElementRef } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { StorageHelperService } from '../../service/storage-helper.service';
import { Constants } from "../../service/constant.service";
import { UtilService } from '../../service/util.service';



@Component({
    //moduleId: module.id.toString(),
    selector: "fop-quadrant",
  templateUrl: './fop-quadrant.component.html',
  styleUrls: ['./fop-quadrant.component.scss']
})

export class FOPQuadrantComponent {
    observable: Observable<any>;
    subject: any;
  @ViewChild('fopQuadrantModal', { static: true }) fopQuadrantModal: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    offSet: any = { left: 0, top: 0, width: 0, height: 0 };
  @ViewChild('Quadrant', { static: true }) quadrant: ElementRef;

    actionData: any = {};
    currentGrowthPlanName: any = '';
    contents: any;
    gpDetails: any;
    hasTooltip: boolean = false;

    selActionId: number;
    public config = {
        animated: true,
        keyboard: false,
        backdrop: false,
        ignoreBackdropClick: true
    };
    showTooltip: any = [];
    width: number;
    topcClass: string = '';
    class: string = '';
    left: string;
    maxHeight: number;
    overflow: string = '';
    containerHeight: number = 0;
    constructor(
        private injector: Injector,
        private storageHelper: StorageHelperService,
        private constants: Constants,
        private utilService: UtilService
    ) {
        this.actionData = this.injector.get('actionData');
        this.contents = this.injector.get('contents');
        this.selActionId = this.injector.get('selActionId');
        this.gpDetails = this.injector.get('gpDetails');
        this.hasTooltip = this.injector.get('hasTooltip');
        
        this.actionData.forEach((a) => {
            this.showTooltip[a.id] = false;
        });
    }
    ngOnInit() {
        let currentGrowthPlan: any = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
        this.currentGrowthPlanName = currentGrowthPlan.growthPlanName;
    }
    openDialog() {
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.fopQuadrantModal.config = this.config;
        this.fopQuadrantModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.fopQuadrantModal.hide();
        this.componentEvent.emit({ value: "closed" });
    }
    onModalClosed() {
        this.componentEvent.emit({ value: "closed" });
    }

    getBubblePosition(action: any) {
        this.offSet = this.utilService.getOffset(this.quadrant.nativeElement);
        return { top: (action.yPos / 100 * this.offSet.height), left: (action.xPos / 100 * this.offSet.width) };

    }

    triggerTooltip(e, action, quadrant) {
        if(this.hasTooltip) {
            this.overflow = '';
            this.maxHeight = 0;
            if (!e.target.classList.contains('mCSB_dragger_bar')) {
                let keys = Object.keys(this.showTooltip);
                keys.forEach((key) => {
                    this.showTooltip[key] = false;
                });
                if (!e.target.classList.contains('fa-times')) {
                    this.showTooltip[action.id] = true;
                    setTimeout(() => {
                        let width = document.getElementById('four').offsetWidth;
                        let height = document.getElementById('four').offsetHeight;
                        let left = this.getBubblePosition(action).left;
                        let top = this.getBubblePosition(action).top;
                        let bubbleWidth, noOfBuubleInOneRow, tooltipHeight;

                        bubbleWidth = document.getElementById('bubble' + action.id.toString()).offsetWidth;
                        this.width = 175;
                        tooltipHeight = document.getElementById(action.id.toString()).offsetHeight;

                        setTimeout(() => {
                            bubbleWidth = document.getElementById('bubble' + action.id.toString()).offsetWidth;
                            this.width = 175;
                            tooltipHeight = document.getElementById(action.id.toString()).offsetHeight;
                            //console.log(tooltipHeight);
                            //console.log(top);
                            //console.log(left)
                            //console.log(this.width);
                            this.overflow = '';
                            if (action.fopList.length > 7) {
                                this.overflow = 'scroll';
                                this.maxHeight = document.getElementById('hr6').offsetTop - document.getElementById('bubble-c-' + action.id).offsetTop;
                                console.log(this.maxHeight);
                                console.log(document.getElementById('hr6').offsetTop);
                                console.log(document.getElementById('bubble-c-'+action.id).offsetTop);
                            } else {
                                //this.maxHeight = 37 * action.fopList.length;
                                console.log('hr' + (action.fopList.length - 2));
                                if (action.fopList.length == 1) {
                                    this.maxHeight = 37;
                                } else {
                                    this.maxHeight = document.getElementById('hr' + (action.fopList.length - 2)).offsetTop;
                                }
                                
                            }
                            if (quadrant == 'first' || quadrant == 'third') {
                                if ((width - left) > this.width) {
                                    //show at right
                                    this.class = 'right'
                                    if (quadrant == 'third' || quadrant == 'fourth') {
                                        if (top > tooltipHeight) {
                                            //show at left top
                                            this.topcClass = 'bottom-arrow';
                                        } else {
                                            this.topcClass = '';
                                        }
                                        if (action.fopList.length > 4) {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                    } else {
                                        let bottom = height - top;
                                        if (bottom > tooltipHeight) {
                                            this.topcClass = '';
                                        } else {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                        if (action.fopList.length > 4) {
                                            this.topcClass = '';
                                        }
                                    }
                                } else {
                                    //show at left
                                    this.class = 'left'
                                    console.log(quadrant);
                                    if (quadrant == 'third' || quadrant == 'fourth') {
                                        console.log('dd');
                                        if (top > tooltipHeight) {
                                            //show at left top
                                            this.topcClass = 'bottom-arrow';
                                        } else {
                                            this.topcClass = '';
                                        }
                                        if (action.fopList.length > 4) {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                    } else {
                                        let bottom = height - top;
                                        if (bottom > tooltipHeight) {
                                            this.topcClass = '';
                                        } else {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                        if (action.fopList.length > 4) {
                                            this.topcClass = '';
                                        }
                                    }
                                }
                            } else {
                                if (left > this.width) {
                                    //show at left
                                    this.class = 'left'
                                    console.log(quadrant);
                                    if (quadrant == 'third' || quadrant == 'fourth') {
                                        console.log('dd');
                                        if (top > tooltipHeight) {
                                            //show at left top
                                            this.topcClass = 'bottom-arrow';
                                        } else {
                                            this.topcClass = '';
                                        }

                                        if (action.fopList.length > 4) {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                        
                                    } else {
                                        let bottom = height - top;
                                        if (bottom > tooltipHeight) {
                                            this.topcClass = '';
                                        } else {
                                            this.topcClass = 'bottom-arrow';
                                        }

                                        if (action.fopList.length > 4) {
                                            this.topcClass = '';
                                        }
                                    }

                                } else {
                                    //show at right
                                    this.class = 'right'
                                    if (quadrant == 'third' || quadrant == 'fourth') {
                                        if (top > tooltipHeight) {
                                            //show at left top
                                            this.topcClass = 'bottom-arrow';
                                        } else {
                                            this.topcClass = '';
                                        }

                                        if (action.fopList.length > 4) {
                                            this.topcClass = 'bottom-arrow';
                                        }
                                    } else {
                                        let bottom = height - top;
                                        if (bottom > tooltipHeight) {
                                            this.topcClass = '';
                                        } else {
                                            this.topcClass = 'bottom-arrow';
                                        }

                                        if (action.fopList.length > 4) {
                                            this.topcClass = '';
                                        }
                                    }
                                }
                            }

                            //if (top > tooltipHeight + 10) {
                            //    this.class = 'top';

                            //}else {
                            //    this.class = 'bottom';
                            //}
                            //if (this.class == 'top') {
                            //    if ((left + bubbleWidth / 2) > this.width) {
                            //        this.left = ((-1 * this.width) + (bubbleWidth / 2)).toString();
                            //        this.topcClass = 'right-arrow';
                            //    } else {
                            //        this.left = '0';
                            //        this.topcClass = 'left-arrow';
                            //    }
                            //}
                            //else {
                            //    if ((left + bubbleWidth / 2) > this.width) {
                            //        this.left = ((-1 * this.width) + (bubbleWidth / 2)).toString();
                            //        this.topcClass = 'right-arrow';
                            //    } else {
                            //        this.left = '0';
                            //        this.topcClass = 'left-arrow';
                            //    }
                            //}
                        }, 10);

                    }, 10);
                }
            }
        } else {
           return;
        }
    }

    closeTooltip(action) {
        console.log(action);
        this.showTooltip[action.id] = false;
    }
}
