import { Component, OnInit, ViewChild, OnChanges, Input, EventEmitter, Output, Injector } from '@angular/core';
import { ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../component/base.component';

@Component({
    selector: 'app-flash-message',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.scss']
})
export class FlashMessageComponent extends BaseComponent implements OnInit {
    @Input("show") show: boolean = true;
    @Input("flashType") flashType: string = '';
    @Input("pageContent") pageContent: any = {};
    @Output("showHideChange") showHideChange: EventEmitter<any> = new EventEmitter<any>();


    constructor(private injector: Injector) {
        super(injector);
    }

    ngOnChanges(change: any) {
        console.log(change);
        if (change['show']['currentValue'] === true) {
            setTimeout(() => {
                this.close();
            }, this.SiteContents.flashMessageTimer);
        }
    }

    ngOnInit() {
        
    }

    close() {
        this.show = false;
        this.showHideChange.emit(false)
    }
}