<div class="velocity-page" (contextmenu)="onRightClick($event)" (click)="true">
  <div class="velocity min-body-height">
    <page-transition-progess></page-transition-progess>
    <page-posting-overlay></page-posting-overlay>
    <app-header [menuContent]="menuContent"></app-header>
    <!--display none given as the min height of a col is 1px and is showing a white line. Need to re-visit this-->
    <!--<div class="col-md-10 col-md-offset-1"><custom-alert></custom-alert></div>-->
    <div class="alert-container">
      <custom-alert></custom-alert>
    </div>
<!--    <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>-->

    <router-outlet *ngIf="!isIframe" (activate)="onActivate($event)"></router-outlet>
  </div>
    <app-footer [menuContent]="menuContent"></app-footer>
    <dynamic-modal-container #dynamicModalContainer></dynamic-modal-container>

    <login-dialog></login-dialog>
</div>

<cookie-banner></cookie-banner>
<timeout-handler></timeout-handler>


