import { Component, Input, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
//import { ModalModule, Modal } from 'ng2-modal';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    //moduleId: module.id.toString(),
    selector: "confirm-reset",
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})

export class ConfirmResetComponent {
    contents: any;
    reportName = '';
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    optOut = false;
    resetGNWarning = [];
    draftCount = 0;

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private router: Router
) {
        this.contents = this.injector.get('contents');
    }
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

  @ViewChild('confirmResetModal', { static: true }) confirmResetModal: any;
    returnedValue: any = {};


    ngOnInit() {
        var updateProgressBar = ProgressBarHelper.newUpdateProgressOption("confirm-reset");
        this.resetGNWarning = [];
        this.draftCount = 0;
        var gid = 0;
        if (this.contents.resetGrowthPlanId) {
            gid = this.contents.resetGrowthPlanId
        }
        this.velocityService.getReportName(gid,updateProgressBar).subscribe(
            (data: any) => {
                this.reportName = data.reportName;
                this.draftCount = data.draftReportCount
                if (this.draftCount > 0) {
                    var text = this.contents.pageContents.msgResetWarningWithDraftReport.replace('#no', this.draftCount);
                    var splitText = text.split('#click');
                    if (splitText.length > 1) {
                        this.resetGNWarning[0] = splitText[0];
                        this.resetGNWarning[1] = splitText[1];
                    }
                  //  let htmlContent = '<a href="act/summary">' + this.contents.pageContents.msgResetWarningWithDraftReportClick + '</a>'
                   // this.resetGNWarning = this.contents.pageContents.msgResetWarningWithDraftReport.replace("#click", htmlContent);
                   // this.resetGNWarning = 
                }
            }
        )
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }
    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        if (this.optOut) {
            this.returnedValue.action = 'optout';
        }
        else {
            this.returnedValue.data = this.reportName;
            this.returnedValue.action = 'yes';
        }
                    this.closeDialog();
               
    }

    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.confirmResetModal.config = this.config;
        this.confirmResetModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.confirmResetModal.hide();
    }
    gotoReport() {
        this.router.navigate(['act', 'summary']);

    }
}
