<div class="ey-velocity-header d-none d-lg-block" *ngIf="isLoggedIn" #header>
        <nav class="navbar navbar-inverse navbar-expand-md">
            <!--<div class="container-outer">-->
            <div class="main-container">
                <div class="no-margin" style="display:flex">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <!--<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>-->
                        <a class="navbar-brand ey-brand" style="cursor:pointer" (click)="navigateToDashboard(); logMenuNavigation('Dashboard')">
                            <img class="ey-brand-logo" src="../../../assets/logo/ey-logo.png">
                            <div class="lable"><p class="ey-label">{{brandName}}</p></div>
                        </a>
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav fluid-width">
                            <li class="fluid-full-width relative-style" >
                                <form class="navbar-form navbar-left fluid-full-width" [ngClass]="{'focusSearch':focusInput || searchString!=''}">
                                    
                                    <div class="d-lg-none d-xl-block">
                                        <div class="input-group mb-3 hideShowSearch fluid-full-width">
                                            <div class="input-group-prepend" [ngClass]="{'show-icons': searchString==''}">
                                                <span class="input-group-text">
                                                    <!--<button class="btn-search" type="button"></button> -->
                                                    <img (click)="search()" src="../../../assets/images/search-icon-gs.png">
                                                </span>
                                            </div>
                                            <div class="input-group-prepend" [ngClass]="{'show-icons': searchString!=''}">
                                                <span class="input-group-text">
                                                    <!--<i class="fa fa-times" (click)="searchString=''"></i>
                                                    <button class="btn-search" type="button" (click)="search()"></button>-->
                                                    <img (click)="search()" src="../../../assets/images/search-icon-gs.png">
                                                </span>
                                            </div>
                                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="searchString"
                                                   name="searchString" placeholder="{{searchPlaceholder}}"
                                                   aria-label="Search keywords"
                                                   (input)="changeSearch($event.target.value)"
                                                   (focus)="focusInput = true"
                                                   (blur)="focusInput = false"
                                                   (keyup.enter)="search()">
                                            <div class="input-group-append" [ngClass]="{'show-icons': searchString!=''}">
                                                <span class="input-group-text">
                                                    <!-- <button class="btn-search" type="button" (click)="search()"></button>-->
                                                    <!--  <i class="fa fa-times" (click)="searchString=''"></i>-->
                                                    <img class="fa-times" (click)="searchString=''" src="../../../assets/images/clear-icon-gs.png">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                

                                    <div class="input-group d-md-none d-lg-block d-xl-none">
                                        <span class="input-group-prepend" *ngIf="!isSearchBoxVisible">
                                            <span class="input-group-text">
                                                <button class="btn-search" type="button" (click)="isSearchBoxVisible=true"></button>
                                                </span>
                                            </span>
                                        <div class="form-group input-group search-container" *ngIf="isSearchBoxVisible" #searchButton>
                                            <div class="row search-row">
                                                <div class="col-md-1"><button class="btn-close" type="button" (click)="isSearchBoxVisible=false;searchString=''"></button></div>
                                                <div class="col-md-10">
                                                    <input autocomplete="off" type="text" class="form-control" [(ngModel)]="searchString"
                                                           name="searchString" placeholder="{{searchPlaceholder}}"
                                                           aria-label="Search keywords"
                                                           (input)="changeSearch($event.target.value)"
                                                           (focus)="focusInput = true"
                                                           (blur)="focusInput = false"
                                                           (keyup.enter)="search()">
                                                </div>
                                                <div class="col-md-1"><button class="btn-search-menu" type="button" (click)="search()"></button></div>
                                            </div>
                                        </div>
</div>
                                </form>
                                <search-dropdown [searchString]="searchString" (selectSearch)="searchString=''" [close]="startSearch"></search-dropdown>
                            </li>
                        </ul>
                    <ul class="nav navbar-nav ml-auto">
                            <li class="dropdown nav-item" routerLinkActive='active' [ngClass]='{"show-menu": !isSearchBoxVisible, "hide-menu": isSearchBoxVisible}' *ngFor="let link of headerLinks">
                                <a *ngIf='link.children && (link.children.length >0); else nav_link'
                                   class="dropdown-toggle nav-link"
                                   aria-haspopup="true"
                                   aria-expanded="false" (mouseenter)="showDropdown = true" (click)="showDropdown = true">
                                    {{getMenuValue(link)}}
                                    <i class="fa fa-bold fa-angle-down fa-lg" aria-hidden="true"></i>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-left" *ngIf='link.children && (link.children.length >0)'>
                                    <li class="nav-item" [style.display]="showDropdown?'block':'none'">
                                        <a class="nav-link" *ngFor="let sublink of link.children">
                                            <span *ngIf="sublink.menuName != 'BenchmarkingReport'" [routerLink]="sublink.routes[0]" (click)="hideNavigation(sublink.menuName, sublink.routes[0]);">{{getMenuValue(sublink)}}</span>
                                            <span *ngIf="sublink.menuName == 'BenchmarkingReport'" (click)="hideNavigation(sublink.menuName, sublink.routes[0]);">{{getMenuValue(sublink)}}</span>
                                        </a>
                                    </li>
                                </ul>
                                <ng-template #nav_link>
                                    <a class="nav-link" [routerLink]="[link.routes[0]]" (click)="logMenuNavigation(link.menuName)">{{getMenuValue(link)}}</a>
                                </ng-template>
                            </li>

                            <li class="nav-item"  routerLinkActive='active' *ngIf="isLoggedIn" [ngClass]="{'notificationLink' : showLanguageDropdown}">
                                <a #language (click)="showLanguageChange('Language')" class="notification-link nav-link">
                                    <div class="notification">
                                        <img class="fa-bell-o" src="../../../assets/images/language.png">
                                        <span class="language-text">{{languageText}}</span>
                                    </div>
                                </a>
                            </li>

                            <li class="nav-item" routerLinkActive='active' *ngIf="isLoggedIn" [ngClass]="{'notificationLink' : showNotificationPane}">
                                <!--<a [routerLink]="['/notification']"><div class="notification"><i class="fa fa-bell-o fa-lg" aria-hidden="true"></i><span class="badge">{{1}}</span></div></a>-->
                                <a (click)="showNotification('Notifications')" class="notification-link nav-link">
                                    <div class="notification">
                                        <!-- <i class="fa fa-bell-o fa-lg" aria-hidden="true"></i>-->
                                        <img class="fa-bell-o" src="../../../assets/images/notification-icon-header.png">
                                        <span class="badge" *ngIf="isNotification">{{notificationCount}}</span>
                                    </div>
                                </a>
                            </li>

                            <li routerLinkActive='active' class="dropdown nav-item" *ngIf="isLoggedIn">
                                <a class="dropdown-toggle profile nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (mouseenter)="showProfileDropdown = true" (click)="showProfileDropdown = true">
                                    <img [src]="profileUrl" class="avatar img-fluid"><i class="fa fa-bold fa-angle-down fa-lg" aria-hidden="true"></i>
                                </a>

                                <ul class="dropdown-menu dropdown-menu-profile">
                                    <li [style.display]="showProfileDropdown?'block':'none'">
                                        <div class="dropdownContainer">
                                            <div class="row rowContainer">

                                                <div class="profileContainer">
                                                    <img class="appheader-profile-image" [src]="profileUrl" />
                                                </div>
                                                <div class="textContainer">
                                                    <div class="nameStyle">{{preferredName}}</div>
                                                    <div class="emailStyle">{{userEmail}}</div>
                                                    <div class="underLineText" (click)="navigateToUserDetails(); hideProfileDropDown()">
                                                        <u> {{pageContent.profile.linkMyProfile}}</u>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <li>
                                    <li [style.display]="showProfileDropdown?'block':'none'">
                                        <div class="row rowContainer">
                                            <div class="col-md-6 dummmyPaddingRight">
                                                <button class="btn btn_black_profile" (click)="navigateToSettings(); logMenuNavigation('Settings')">
                                                    <i class="fa fa-cog"></i>
                                                    {{pageContent.profile.buttonSettings}}
                                                </button>
                                            </div>
                                            <div class="col-md-6 dummmyPaddingLeft">
                                                <button class="btn btn_black_profile" (click)="logout()">
                                                    <i class="fa fa-unlock"></i>
                                                    {{pageContent.profile.buttonLogout}}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <!--<li><a>Action</a></li>
        <li><a href="javascript:;" (click)="logout()">Logout</a></li>-->
                                </ul>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                </div>
            </div>
            <!--</div>-->
            <!-- /.container-fluid -->
        </nav>
    </div>

    <div class="small-header d-lg-none d-xl-none" *ngIf="isLoggedIn" #header>
        <div class="container-outer">
            <div class="row">
                <div class="col-6 col-md-6">
                    <div class="small-header-logo-block" style="cursor:pointer" (click)="navigateToDashboard(); logMenuNavigation('Dashboard')">
                        <img class="small-header-logo" src="../../../assets/logo/ey-logo.png"><div class="small-header-label-velocity"><p class="small-header-velocity">{{brandName}}</p></div>
                    </div>
                </div>
                <div class="col-6 col-md-6">
                    <div class="row small-header-right float-right" routerLinkActive='active' *ngIf="isLoggedIn">
                        <div class="small-header-notification">
                            <a class="small-header-bell hide-for-small-screens" #smalllanguage style="margin: 0px 7px 0px 0px;" (click)="showLanguageChange('Language')">
                                <img class="fa-bell-o" src="../../../assets/images/language.png">
                                <span class="language-text">{{languageText}}</span>
                            </a>
                            
                            <a class="small-header-bell" (click)="showNotification('Notifications')">
                                <!-- <span class="fa fa-bell-o fa-lg small-header-bell"></span>-->
                                <img class="fa-bell-o" src="../../../assets/images/notification-icon-header.png">
                                <span class="small-header-badge" *ngIf="isNotification">{{notificationCount}}</span>
                            </a>

                            <div style="height:100%;display:inline-block" (mouseover)="showProfilePopupFn(true)" (mouseleave)="showProfilePopupFn(false)">
                                <img src="{{profileUrl}}" class="small-header-avatar img-fluid" />
                                <div class="profile-tablet-container" *ngIf="showProfilePopup && small">
                                    <div class="row rowContainer">

                                        <div class="profileContainer">
                                            <img class="appheader-profile-image" [src]="profileUrl" />
                                        </div>
                                        <div class="textContainer">
                                            <div class="nameStyle">{{preferredName}}</div>
                                            <div class="emailStyle">{{userEmail}}</div>
                                            <div class="underLineText" (click)="openProfile()">
                                                <u> {{pageContent.profile.linkMyProfile}}</u>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row rowContainer">
                                        <div class="col-xs-6 col-sm-6 dummmyPaddingRight">
                                            <button class="btn btn_black_profile" (click)="openSettings();logMenuNavigation('Settings')">
                                                <i class="fa fa-user"></i>
                                                {{pageContent.profile.buttonSettings}}

                                            </button>
                                        </div>
                                        <div class="col-xs-6 col-sm-6 dummmyPaddingLeft">
                                            <button class="btn btn_black_profile" (click)="logout()">
                                                <i class="fa fa-unlock"></i>
                                                {{pageContent.profile.buttonLogout}}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <a href="javascript:void(0)" class="navicon-container" (click)="toggleMenu()">
                                <div class="navicon"></div>
                                <div class="navicon"></div>
                                <div class="navicon"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="{'show-small-header': isDropDownMenuVisible, 'hide-small-header': !isDropDownMenuVisible}">
                <div class="small-header-accordiancontainer">

                    <div class="small-header-accordian relative-style">
                        <div class="input-group d-lg-block d-xl-none">
                            <div class="input-group-append" *ngIf="searchString!=''">
                                <span class="input-group-text">
                                    <button class="btn-close" type="button" (click)="searchString=''"></button>
                                    </span>
                                </div>
                                <input autocomplete="off" type="text" class="form-control menu-search-text"
                                       placeholder="{{searchPlaceholder}}"
                                       [(ngModel)]="searchString" name="searchString"
                                       aria-label="Search keywords"
                                       (input)="changeSearch($event.target.value)"
                                       (focus)="focusInput = true"
                                       (blur)="focusInput = false"
                                       (keyup.enter)="search()">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <button class="btn-search" type="button" (click)="search()"></button>
                                    </span>
                                    </div>
</div>
                        <search-dropdown [searchString]="searchString" [close]="startSearch" class="mobileHeader" (selectSearch)="searchString=''"></search-dropdown>
                    </div>

                    <ng-container *ngFor="let link of headerLinks">
                        <div class="active-div" routerLinkActive='active'>
                            <button *ngIf="link.hasChild" class="small-header-accordian" routerLinkActive='active' (click)="accessMenu($event , link)">{{getMenuValue(link)}}</button>
                            <button *ngIf="!link.hasChild" class="small-header-accordian no-dropdown" routerLinkActive='active' [routerLink]="[link.routes[0]]" (click)="accessMenu($event , link)">{{getMenuValue(link)}}</button>
                            <div class="small-header-accordion-panel card" *ngIf="link.hasChild">
                                <div *ngFor="let subLink of link.children">
                                    <a class="accordian-menu-link" *ngIf="subLink.menuName != 'BenchmarkingReport'" href="javascript:void(0)" [routerLink]="[subLink.routes[0]]" (click)="accessMenu(null , subLink)">{{getMenuValue(subLink)}}</a>
                                    <a class="accordian-menu-link" *ngIf="subLink.menuName == 'BenchmarkingReport'" href="javascript:void(0)" (click)="accessMenu(null , subLink)">{{getMenuValue(subLink)}}</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--<button class="small-header-accordian no-dropdown" (click)="navigateMainMenu($event, 'connect', 'ey-connect', null, 'Connect')">{{pageContent.connect.text}}</button>

                    <button class="small-header-accordian" (click)="showSmallMenu($event)">{{pageContent.focus.text}}</button>
                    <div class="small-header-accordion-panel card">
                        <div><a class="accordian-menu-link" href="javascript:void(0)" (click)="navigate(null, 'focus','seven-drivers', null, '7 Drivers of Growth')">{{pageContent.focus.sevenDriverOfGrowth}}</a></div>
                        <div><a class="accordian-menu-link" href="javascript:void(0)" (click)="navigate(null, 'focus','hot-topics', null, 'Hot Topics')">{{pageContent.focus.hotTopics}}</a></div>
                    </div>

                    <button class="small-header-accordian" (click)="showSmallMenu($event)">{{pageContent.deliver.text}}</button>
                    <div class="small-header-accordion-panel card">
                        <div><a class="accordian-menu-link" href="javascript:void(0)" (click)="navigate(null, 'act','my-growth-plan', null, 'My Growth Plan')">{{pageContent.focus.myGrowthPlan}}</a></div>
                        <div><a class="accordian-menu-link" href="javascript:void(0)" (click)="navigate(null, 'act','tool-resources', null, 'Tools & Resources')">{{pageContent.deliver.toolsResources}}</a></div>
                        <div><a class="accordian-menu-link" href="javascript:void(0)" (click)="navigate(null, 'act','summary', null, pageContent.deliver.growthSummaryReport)">{{pageContent.deliver.growthSummaryReport}}</a></div>

                    </div>
                    <button class="small-header-accordian no-dropdown" (click)="navigateMainMenu($event, 'dashboard', null, null, 'Dashboard')">{{pageContent.dashBoard.text}}</button>-->
                    <!--<div class="small-header-accordian">
                        <div><a class="accordian-actions-link" href="javascript:void(0)"  (click)="navigate('my-story', null, null)">{{pageContent.myStory.text}}</a></div>
                    </div>-->
                    <!-- Commented my story for future storties
                        <button class="small-header-accordian no-dropdown" (click)="navigateMainMenu($event, 'my-story', null, null, 'My Story')">{{pageContent.myStory.text}}</button>
                    -->
                    <!--Static texts need to be change-->
                    <!--<div class="small-header-accordian">
                        <div><a class="accordian-actions-link" href="javascript:void(0)">Action</a></div>
                    </div>-->
                    <button class="small-header-accordian no-dropdown" routerLinkActive='active' [routerLink]="['user-detail','user-details']" [routerLinkActiveOptions]="{exact:true}" (click)="navigate($event, 'user-detail', null, null, 'My Profile')">{{pageContent.profile.linkMyProfile}}</button>
                    <button class="small-header-accordian no-dropdown" routerLinkActive='active' [routerLink]="['user-detail','settings']" (click)="navigate($event, 'user-detail','settings', null, 'settings')">{{pageContent.profile.buttonSettings}}</button>

                    <div class="small-header-accordian">
                        <div><a class="accordian-actions-link" href="javascript:void(0)" (click)="logout()">Logout</a></div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="dropdown-notifications-container" *ngIf="showNotificationPane" [style.height.px]="screenHeight">
        <app-notification> </app-notification>
    </div>

    <div class="dropdown-languagechange-container" [style.left.px]="languagePos" *ngIf="showLanguageDropdown" [ngClass]="{'removeTopSpace':navIsFixed }" custom-scroll>
        <label *ngFor="let a of language" class="container">
            <span class="language-text">{{a.languageName}} - {{a.abbreviation}}</span>
            <input type="radio" name="radio" (change)="changeLanguage(a)"  [checked]="a.languageId == selLanId">
            <span class="checkmark"></span>
        </label>
    </div>
