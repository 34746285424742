<div class="modal fade ey-dialog-modal" 
[ngClass]='{
    "register-email-modal": (customize.type === "REGISTER_CONFIRM")
    }' 
bsModal #formModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="modalForm" (ngSubmit)="okayClicked()" novalidate>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="control-label required" [innerHTML]="message"></label>
                      <input autocomplete="off" maxlength="100" class="form-control" formControlName="email" placeholder="Email address">
                      <span class="has-error" *ngIf="emailDuplicate">This email has been registered already</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
               
                        <div class="action-buttons">
                            <button class="btn btn-cancel" (click)="cancelClicked();" type="button"
                            [innerHTML]="customize.action.cancel || 'CANCEL'"></button>
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button"
                            [innerHTML]="customize.action.ok || 'OK'" [disabled]="modalForm.invalid"></button>
                        </div>
                   
            </div>
        </div>
    </div>
</div>