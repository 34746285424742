import { Component, OnInit, ElementRef, ViewChild, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BaseComponent } from "../base.component";
import { UserService } from "../../auth/service/user.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { LogService } from "../../service/log.service";
//import { setTimeout } from 'core-js/library/web/timers';


@Component({
    selector: 'cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent extends BaseComponent implements OnInit {
    showCookie: boolean = false;
    enableCookieBanner: boolean = false;
    siteContents: any;
    noTC: boolean = false;
    
    constructor(private injector: Injector,       
        private userService: UserService,
        private logService: LogService,
        private logTypes: LogTypes) {
        super(injector);
    }

    ngOnInit() {

        this.siteContents = this.SiteContents;
        let me = this;
        this.userService.getCurrentUserAsObservable().subscribe((user: any) => {
            if (user && user.hasOwnProperty("cookieAccepted")) {
                if (user.cookieAccepted) {
                    this.showCookie = false;
                    setTimeout(function () {
                        me.enableCookieBanner = !user.cookieAccepted;
                    }, 500);
                } else {
                    this.enableCookieBanner = !user.cookieAccepted;
                    setTimeout(function () {
                        me.showCookie = true;
                    }, 500);
                }
            }
            else {
                me.enableCookieBanner = false;
                me.showCookie = false;
            }

        });
        this.userService.isTermsCondition().subscribe((data: any) => {
            if (data) {
                setTimeout(function () {
                    me.noTC = data;
                }, 500);
            }
        });
    }
    
    onAccept() {
        let log = new LogAction();
        log.action= this.logTypes.COOKIE_ACCEPTED;
        this.logService.logAction(log);
        this.userService.setCookiePolicy(true);
    }
}
