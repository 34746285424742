import { Component, Input, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';

import {Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';

@Component({
    //moduleId: module.id.toString(),
    selector: "video-dialog-hottopics",
    templateUrl: './video-dialog-hottopics.component.html', 
    styleUrls: ['./video-dialog-hottopics.component.css']
})

export class VideoDialogHottopicComponent {
    videoUrl: string = "";
    source: string = "";
    title: string = "";

  @ViewChild('videoModalHottopic', { static: true }) videoModal: ModalDirective ;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    backdropClose: boolean = false;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(
        private injector: Injector)
    {
        this.videoUrl = this.injector.get('videoUrl');
        this.source = this.injector.get('source');
        this.title = this.injector.get('title');
        this.backdropClose = this.injector.get('backdropClose');
    }

    ngOnInit() {
        this.videoModal.config.ignoreBackdropClick = !this.backdropClose;
    }



    onModalClosed($event:any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }
    

    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.videoModal.config = this.config;
        this.videoModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.videoModal.hide();
    }
 
}
