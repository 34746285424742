<!--<modal #okCancelModal modalClass="eyb-dialog-modal" [closeOnEscape]='false' [closeOnOutsideClick]='false' (onClose)="onModalClosed();">
    <modal-header>
        <h1>{{title}}</h1>
    </modal-header>
    <modal-content>
        <div class="row">
            <div class="col-md-12">{{message}}</div>
        </div>
    </modal-content>
    <modal-footer>
        <div class="row">
            <div class="col-md-6">
                <button class="btn" (click)="cancelClicked();" type="button">Cancel</button>
                <button class="btn btn-eyb-default" (click)="okayClicked();" type="button">Ok</button>
            </div>
        </div>
    </modal-footer>
</modal>-->


<div class="modal fade ey-dialog-modal"
     [ngClass]='{
    "location-modal": (customize.type === "LOCATION_DELETE"),
     "notification-modal": (customize.type === "NOTIFI"),
     "hq-change-modal": (customize.type === "HQ_CHANGE_DELETE"),
    "register-confirm-modal": (customize.type === "REGISTER_CONFIRM"),
    "leave-community-modal": (customize.type === "LEAVE_COMMUNITY"),
     "account-delete": (customize.type === "ACCOUNT_DELETE")

    }'
     bsModal #okCancelModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
      </div>
      <div class="modal-body" *ngIf="!customize.html; else loadHtmlContent">
        {{message}}
      </div>

      <ng-template #loadHtmlContent>
        <div class="modal-body" [innerHtml]="message"></div>
      </ng-template>

      <div class="modal-footer" *ngIf="customize.type !== 'HQ_CHANGE_DELETE' && customize.type !== 'LEAVE_COMMUNITY'">

        <div class="action-buttons">
          <button class="btn-outline btno-cancel" (click)="cancelClicked();" type="button"
                  [innerHTML]="customize.action.cancel || 'CANCEL'"></button>
          <button class="btn-outline" (click)="okayClicked();" type="button"
                  [innerHTML]="customize.action.ok || 'OK'"></button>
        </div>

      </div>
      <div class="modal-footer" *ngIf="customize.type === 'HQ_CHANGE_DELETE' || customize.type === 'LEAVE_COMMUNITY'">
        <button (click)="okayClicked();" type="button" class="btn btn-ey-default btn-ok"
                [innerHTML]="customize.action.ok || 'OK'"></button>
        <span class="or">{{customize.action.or || 'or'}}</span>

        <a class="btn-cancel"
           (click)='cancelClicked()'>
          {{customize.action.cancel || 'CANCEL'}}
        </a>

      </div>
    </div>
  </div>
</div>
