import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VelocityService } from 'src/app/service/velocity.service';

@Component({
  selector: 'app-view-growth-matrix-axes',
  templateUrl: './view-growth-matrix-axes.component.html',
  styleUrls: ['./view-growth-matrix-axes.component.scss']
})
export class ViewGrowthMatrixAxesComponent implements OnInit {
  @ViewChild('growthMatrixAxesModal', { static: true }) growthMatrixAxesModal: any;

  @Output("closeEvent") componentEvent: any = new EventEmitter();

  public config = {
    animated: true,
    keyboard: false,
    backdrop: false,
    ignoreBackdropClick: true
  };

  observable: Observable<any>;
  subject: any;
  pageContents: any;
  currentGrowthPlanId: any;

  matrixDetails: any = [];

  constructor(private injector: Injector, private velocityService: VelocityService) { 
    this.pageContents = this.injector.get('PageContents');
    this.currentGrowthPlanId = this.injector.get('growthPlanId');
  }

  ngOnInit(): void {
    this.getGrowthMatrixDiscrepancies();
  }

  openDialog() {
    this.subject = new Subject();
    this.observable = this.subject.asObservable();
    this.growthMatrixAxesModal.config = this.config;
    this.growthMatrixAxesModal.show();
    return this.observable;
  }

  public closeDialog() {
      this.growthMatrixAxesModal.hide();
      this.componentEvent.emit({ value: "closed" });
  }
  onModalClosed() {
      this.componentEvent.emit({ value: "closed" });
  }

  getGrowthMatrixDiscrepancies() {
    let params;
    params = { growthplanId: this.currentGrowthPlanId, userId: -1 };

    this.velocityService.getGrowthMatrixDiscrepancy(params).subscribe((response: any) => {
      this.matrixDetails = response;
    });
  }

}
