import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,

    OnChanges,
    SimpleChanges
} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { BaseComponent } from './../../component/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LogTypes, LogAction } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';

import { GrowthPlanListDto, DuplicateGrowthPlanData, GrowthPlanSessionDto } from '../../focus/model/face-to-face.model';
import { FaceToFaceService } from '../../service/face-to-face.service';
import { StorageHelperService } from '../../service/storage-helper.service';
import { Constants } from '../../service/constant.service';
import { DataShareService } from '../../service/data-share.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: "corporate-participant-info-dialog",
  templateUrl: "./corporate-participant-info-dialog-component.html",
  styleUrls: ['./corporate-participant-info-dialog-component.scss']
})

export class CorporateParticipantInfoDialogComponent extends BaseComponent implements OnInit, AfterViewInit {


    observable: Observable<any>;
    subject: any;
    PageContents: any = {};
  
    isFacilitator: boolean;
    growthPlanId: any;
  //  growthPlan: any;
    showNomination: boolean=false;
    growthPlanDetailsArr: any = {};
    ownerDetailsArr: any = {};
    showSelectGP: boolean = true;
    selfGP: any = [];
    selectedGP: number;
  @ViewChild('body',{ static: true }) body: ElementRef;

    @Output("closeEvent") componentEvent: any = new EventEmitter()
  @ViewChild('corporateParticipantInfoDialogModal', { static: true }) cpInfoDialogModal: ModalDirective;
  //  activeStep: number = 1;
    isLoaded: boolean = false;
    selectedGPName = '';
    gpTitle: string = '';
    duplicateGrowthPlanName: string = '';
    growthPlanName: string = '';
    errorMessageData: string = '';  
    errorGPEmptyDuplicate: boolean = false;
    errorGPEmpty = false;
    errorMessageRename = false;
    errorExist = false;
    errorMsg = '';
    selectedMessage = '';
    height = '310px';
    constructor(
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private injector: Injector,
        private router: Router,
        private logTypes: LogTypes,
        private logService: LogService,
        private faceToFaceService: FaceToFaceService,
        private storageHelper: StorageHelperService,    
        private constants: Constants,
        private dataShareService: DataShareService
    ) {
        super(injector);
        this.growthPlanId = this.injector.get('growthplan');
       
    }

    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    returnedValue: any = {};

    ngOnInit() {
    // this.growthPlanId = this.growthPlan.growthPlanId;      

    // this.getGPDetails();
     //this.getselfNominations();
     var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");

     var gpDetails = this.velocityService.getCorporateGPNomination(this.growthPlanId, updateProgressBar);
     var getselfNominations = this.velocityService.getSelfNomination(updateProgressBar);

     forkJoin(gpDetails, getselfNominations)
         .subscribe(
         (data) => {
             setTimeout(() => {
                 this.height = this.body.nativeElement.offsetHeight + 15+'px';
             }, 100);
             console.log(data);
             if (data[0]) {
                 this.growthPlanDetailsArr = data[0];
                             }
             if (data[1]) {
                 console.log(data[1]);
                 this.selfGP = data[1];
                
             }
             if (this.growthPlanDetailsArr.nominatedGrowthPlanId) {
                 let selectedGPNameArr = this.selfGP.filter((x: any) => x.growthPlanId === this.growthPlanDetailsArr.nominatedGrowthPlanId);
                 if (selectedGPNameArr.length > 0) {
                     this.selectedGPName = selectedGPNameArr[0].growthPlanName;
                    // this.selectedGP = this.growthPlanDetailsArr.nominatedGrowthPlanId;
                 }
                 this.selfGP = this.selfGP.filter((x: any) => x.growthPlanId !== this.growthPlanDetailsArr.nominatedGrowthPlanId);
                 let name = "'" + this.selectedGPName + "'";
                 this.selectedMessage = this.PageContents.lblAlreadyNominatedMsg.replace('#name', name);
             }
             if (this.selfGP.length > 0) {
                 this.selectedGP = this.selfGP[0]["growthPlanId"]
             }
             this.isLoaded = true;
         });
     this.utilService.getContent(CONTENTS.GROWTH_PLAN_SESSION).subscribe(
         (data: any) => {
             this.PageContents = data;
             console.log(data);
         }
     );
    }

    //getGPDetails() {
    //    var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");
    //    this.velocityService.getCorporateGPNomination(this.growthPlanId, updateProgressBar).subscribe((data) => {
    //        //console.log(data.GrowthPlanDetails);
    //        if (data) {
    //            this.growthPlanDetailsArr = data;
    //        //    this.growthPlanName = data.GrowthPlanDetails.growthPlanName;
    //          //  this.ownerDetailsArr = data.GrowthPlanOwnerDetails;
    //        }
    //    });
    //}
    //getselfNominations() {
    //    this.velocityService.getSelfNomination().subscribe((data) => {
           
    //        if (data) {
    //            this.selfGP = data;
    //        }
    //    });
    //}
    
    goToDriverAssessment() {
        this.router.navigate(['focus', 'ey.aimaturitymodel']);
    }


    getContent() {
        if (this.growthPlanDetailsArr.isAssessmentStarted) {
            return this.PageContents.lblChangeGrowthplanTooltip;
        }else {
            return '';
        }
    }

    
  

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    ngAfterViewInit() {

    }
    okayClicked() {


    }
    openDialog() {
        this.returnedValue = {};
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.cpInfoDialogModal.config = this.config;
        this.cpInfoDialogModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.cpInfoDialogModal.hide();
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }

    saveGrowthPlan(type: number) {
        if (type == 2 && this.gpTitle == '') {
            this.errorGPEmpty = true;
            return;
        }
        if(type == 1 && !this.selectedGP)
              return;

        var params = {
            selfGrowthPlanId: 0,
            growthPlanName: '',
            corporateGrowthPlanId: this.growthPlanId
        };
        if (type == 2) {
            params.growthPlanName = this.gpTitle;
        } else {
            params.selfGrowthPlanId = this.selectedGP;
        }
        this.velocityService.saveNomination(params).subscribe((data) => {
            if (data) {
                    if (data.growthPlanId) {
                        this.returnedValue = "yes";
                        this.closeDialog();                       
                    }
                    else if (data.message == 'duplicate_growthplan_name') {
                        this.errorMessageRename = true;
                        this.errorMsg = this.PageContents.lblPlanAlreadyExists;
                    } else if (data.message == 'growthplan_already_nominated') {
                        this.errorExist = true;
                    }
                    else {
                        this.returnedValue = "error";
                        this.closeDialog();
                    }                
            }
        });
    }


    routingToSevenDriver() {
        debugger;
        
    }
    cancelGpName() {
        this.gpTitle = '';
        this.showNomination = false;
        this.showSelectGP = true;
        this.errorGPEmpty = false;
        this.errorMessageRename = false;
        this.errorExist = false;
        if (this.selfGP.length > 0) {
            if (this.growthPlanDetailsArr.nominatedGrowthPlanId) {

                this.selectedGP = this.growthPlanDetailsArr.nominatedGrowthPlanId;
            } else
                this.selectedGP = this.selfGP[0]["growthPlanId"];
        }
        
    }
    clearError() {
        this.errorGPEmpty = false;
        this.errorMessageRename = false;
        this.errorExist = false;
    }

    onKeyValidate(event) {
        //if (this.value.match(/[^a-zA-Z0-9 ]/g)) 
        var regex = new RegExp(/^[\w\-\s]+$/);
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }
    //getFacDetails(facDetails: any) {
    //    this.facDetails = facDetails;
    //    this.activeStep++;
    //}
    //getAttDetails(attDetails: any) {
    //    this.attendeeInfo = attDetails;
    //    this.activeStep++;
    //}
    //getSelDetails(selDetails: any) {
    //    this.selMember = selDetails;
    //    this.activeStep++;
    //}
    //getConfirmDetails(getConfirmDetails: any) {

   // }
    //back(value: any) {
    //    this.activeStep--;
    //}


}


