import {
    Component,
  ViewEncapsulation,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';
import { LookUpService } from '../../service/look-up.service';
import { BaseComponent } from '../../component/base.component';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
//import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";



@Component({
    //moduleId: module.id.toString(),
    selector: "landing-dialog",
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LandingDialogComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
     
    siteContents: any = {};
    pageContents: any = {};
    observable: Observable<any>;
    contents: string = "";
    subject: any;
    data: any;
    titleText: string = "";
    @Output("closeEvent") componentEvent: any = new EventEmitter()
  @ViewChild('container', { static: true }) container: ElementRef;
    page = 1;
    contactReason: any[] = [];
    countryList: any[] = [];
    contactForm: any = {};
    
    nameValidator: boolean = true;
    emailValidator: boolean = true;
    reasonValidator: boolean = true;
    descValidator: boolean = true;
    isLoad: boolean;
    isPrivacy: boolean;
    emailErrorText: string = "";
    

    constructor(
        private injector: Injector,
     //   private mScrollbarService: MalihuScrollbarService,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocity: VelocityService,
        private lookUpService: LookUpService,
        private logService: LogService,
        private logTypes: LogTypes,
    ){
        super(injector);
    }

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


  @ViewChild('landingModal',{ static: true }) landingModal: any;
    returnedValue: any = {};

    ngOnInit() {

        this.utilService.getContent(CONTENTS.LANDING_CONTACT_US).subscribe(
            (data: any) => {
                this.pageContents = data; 
            }
        );

        this.siteContents = { ...this.SiteContents };

        this.page = this.injector.get('page');
        this.titleText = this.injector.get('title');

        if (this.page == 1) {
            this.getPrivacy();
        } else if (this.page == 2) {
            this.getCodeOfConduct();
        }
        else if (this.page == 3) {
            this.isPrivacy = false;
            this.isLoad = true;
            this.getContactUs();
            this.getcountryList();
        }
    }

    ngAfterViewInit() {
        const vm = this;
        //this.mScrollbarService.initScrollbar('#code-of-conduct', {
        //    axis: 'y', theme: 'minimal',
        //    callbacks: {
        //        onTotalScroll: () => {
        //            // this.updateAccpet();
        //        }
        //    }
        //});
    }
    ngOnDestroy() {
       // this.mScrollbarService.destroy('#code-of-conduct');
    }

    getCodeOfConduct() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("landingPage");
        this.velocity.getCodeofConduct(updateProgressBar).subscribe(
            (result: any) => {
                this.contents = result;
            }
        );
    }

    getPrivacy() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("landingPage");

        this.velocity.getPrivacyPolicyShort(updateProgressBar).subscribe(
            (data) => {
                if (data) {
                    this.contents = data;
                }
            }
        )
    }


    validateContactUs() {
        this.nameValidator = this.validateNameInput();
        this.emailValidator = this.validateEmailInput();
        this.reasonValidator = this.validateReasonInput();
        this.descValidator = this.validateDescInput();

        if (this.nameValidator && this.emailValidator && this.reasonValidator && this.descValidator) {
          this.saveContactUs();
        }
    }
    cancelContactUs() {
       this.closeDialog();
    }
    openPrivacy() {
        this.isPrivacy = true;
        this.titleText = 'Privacy';
        this.getPrivacy();
    }
    closePrivacy() {
        this.isPrivacy = false;
        this.titleText = this.pageContents.lblContactUs;
    }

    validateNameInput() {
        if (this.contactForm.name && this.contactForm.name.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    validateEmailInput() {
        if (this.contactForm.email && this.contactForm.email.length > 0) {

            let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(this.contactForm.email) == false) {
                this.emailErrorText = 'Please enter email in correct format';
                return false;
            }
            return true;
        }
        else {
            this.emailErrorText = this.pageContents.msgEmailValidation;
            return false;
        }
    }

    validateReasonInput() {
        if (this.contactForm.creason != 0) {
            return true;
        }
        else {
            return false;
        }
    }

    validateDescInput() {
        if (this.contactForm.desc && this.contactForm.desc.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    getContactUs() {
        this.lookUpService.getContactDetails().subscribe((data) => {
            this.contactReason = data.reasons;
            this.contactForm.creason = 0;
        });
    }

    getcountryList() {
        this.lookUpService.getCountry().subscribe((data) => {
            this.countryList = data.countries;
            this.contactForm.ccountry = 0;
        });
    }

    saveContactUs() {
        let params = {
            name: this.contactForm.name,
            email: this.contactForm.email,
            reasonId: this.contactForm.creason,
            countryId: this.contactForm.ccountry,
            description: this.contactForm.desc
        };
        this.velocity.saveContactUs(params).subscribe(
            (data: any) => {

                //let log = new LogAction();
                //log.action = this.logTypes.CONTACTUS_CREATED;
                //log.otherInfo = this.contactForm.email;
                //this.logService.logAction(log);

                this.isLoad = false;
                console.log('Api Response is  from save api is -----' + data);
            });
    }

    //cancelClicked() {
    //    this.returnedValue = "no";
    //    this.closeDialog();
    //}
    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }
    //okayClicked() {
    //    // this.returnedValue.action = 'yes';
    //    this.closeDialog();
    //}
  openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.landingModal.config = this.config;
        this.landingModal.show();
        return this.observable;
    }

    public closeDialog() {

        this.returnedValue.action = 'yes';
        this.landingModal.hide();
    }
}


