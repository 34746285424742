import { Injectable } from '@angular/core';

@Injectable()
export class StorageHelperService {
    constructor() { }

    SetSessionValue<T>(key: string, data: T) {
        var dataString = JSON.stringify(data);
        window.sessionStorage[key] = dataString;
    }

    GetSessionValue<T>(key: string): T {
        var returnItem: T = null;
        if (window.sessionStorage[key] != null || window.sessionStorage[key] != undefined) {
            returnItem = JSON.parse(window.sessionStorage[key]);
        }
        return returnItem as T;
    }

    SetLocalValue<T>(key: string, data: T) {
        var dataString = JSON.stringify(data);
        window.localStorage[key] = dataString;
    }
    SetLocalGrowthPlan(data) {
        let currentUser:any = this.GetLocalValue("currentuser");
        currentUser.currentGrowthPlan = data;
        currentUser.currentGrowthPlanId = data.growthPlanId;
        this.SetLocalValue("currentuser", currentUser);
    }
    GetLocalValue<T>(key: string): T {
        var returnItem: T = null;
        if (window.localStorage[key] != null || window.localStorage[key] != undefined) {
            returnItem = JSON.parse(window.localStorage[key]);
        }
        return returnItem as T;
    }

    ClearSessionValue<T>(key: string) {
        window.sessionStorage[key] = null;
    }

    ClearAndKillSession() {
        window.sessionStorage.clear();
    }

    ClearLocalValue<T>(key: string) {
        window.localStorage[key] = null;
    }

    ClearAndKillLocal() {
        window.localStorage.clear();
    }
}