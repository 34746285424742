
<div class="modal fade ey-ra-modal" bsModal #restoreActionModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-dialog-centered " handleOverflow [configSet]="configSet" [hasApply]="hasApply">
        <div class="modal-content">
            <div class="modal-body">
                <!--<div class="floating-dialog-close float-right" (click)="closeDialog()">
        <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />close
    </div>-->
                <div class=" restore-actions">
                    <div class="restore-actions-heading">
                        {{pageContents.lblDeletedActions}}
                    </div>
                    <img class="whiteClose" (click)="closeDialog()" src="../../../assets/images/close-bt.png" />
                    <!--<div class="float-right restore-actions-restore-all  col-6 ">
            <button class="btn btn-ey-default" (click)="restoreAllActions()" [style.visibility]="getActionsCount()>0?'visible':'hidden'">Restore All</button>
        </div>-->
                </div>
                <div class="restore-actions-container" custom-scroll>
                    <ng-container *ngFor="let action of actions;let last=last;">
                        <div class="container restore-action-container" *ngIf="action.isSelected==false" [ngClass]="{'last-container':last}">
                            <div class="action-bubble item" w>
                                <div class="action-card-bubble" [ngClass]="getBgColor(action.rag)">{{action.cardNumber
                        }}
                    </div>
                            </div>
                            <div class="action-name item-center">
                                {{action.title}}
                            </div>
                            <div *ngIf="!isGrowthPlanLocked" class="action-restore item">
                                <button class="btn btn-restore" (click)="restoreAction(action)">
                                    {{pageContents.lblRestore}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="!isGrowthPlanLocked" class=" restore-actions-footer">
                    <button class="btn btn-restore" (click)="restoreAllActions()">
                        {{pageContents.lblRestoreAll}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>