<!--<div class="gp-action-bubble" [ngClass]="{'highLight': highLightBubbles,'gp-bubble-locked':!isDragEnabled }" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)" eyv-draggable [eyv-dragEnabled]="isDragEnabled" [eyv-dragData]="action" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)">
    {{action.cardNumber}}
</div>-->

<div *ngIf="participant==null && (count > 1 || showTooltip)" (click)="clickCountBubble($event)">
    <div id="bubble{{zoomView}}{{action.id}}" class="gp-action-count-bubble tooltip {{class}} {{action.id}} {{zIndexClass}} {{zoomView}}"  [ngClass]="{'showBorder':showYellowBorder}" [style.left]="getBubblePosition(action).left+'px'" [style.top]="getBubblePosition(action).top+'px'" (click)="onCardSelected($event, action)">
        <span *ngIf="count < 10">&nbsp;</span>{{count}}<span class="span">&nbsp;</span>
        <!--<svg width="9" height="9" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.169702 2.31244L3.35443 3.96353C3.4474 4.01182 3.55253 4.01182 3.6455 3.96353L6.83023 2.31244C7.0565 2.1951 7.0565 1.80432 6.83023 1.68697L3.64564 0.0357221C3.59996 0.0118613 3.55033 -0.000488281 3.5001 -0.000488281C3.44988 -0.000488281 3.40024 0.0118613 3.35457 0.0357221L0.169702 1.68682C-0.0565674 1.80416 -0.0565674 2.1951 0.169702 2.31244ZM6.83037 3.69182L6.03617 3.28041L3.82624 4.42525C3.72289 4.47885 3.6131 4.50603 3.50003 4.50603C3.38697 4.50603 3.27732 4.47885 3.17382 4.42525L0.964038 3.28041L0.169702 3.69182C-0.0565674 3.809 -0.0565674 4.19963 0.169702 4.31682L3.35443 5.96666C3.4474 6.01478 3.55253 6.01478 3.6455 5.96666L6.83037 4.31682C7.05664 4.19963 7.05664 3.809 6.83037 3.69182ZM6.83037 5.68869L6.03917 5.27885L3.82624 6.42525C3.72289 6.47885 3.6131 6.50603 3.50003 6.50603C3.38697 6.50603 3.27732 6.47885 3.17382 6.42525L0.96103 5.27885L0.169702 5.68869C-0.0565674 5.80588 -0.0565674 6.1965 0.169702 6.31369L3.35443 7.96353C3.4474 8.01166 3.55253 8.01166 3.6455 7.96353L6.83037 6.31369C7.05664 6.1965 7.05664 5.80588 6.83037 5.68869Z" fill="black"/>
    </svg>-->
        <span class="stack-img-container">
            <img class="img" src="../../../assets/images/layer-group-solid.png" />
        </span>
        <span *ngIf="count < 10" class="span">&nbsp;</span>
        <!--{{action|json}}-->
        <div class="tooltiptext {{class}} {{size}} {{topcClass}}"
             [style.left.px]="left" [style.width.px]="width" [style.margin-left.px]="marginLeft"
             [style.top]="top"
             id="{{zoomView}}{{action.id}}" *ngIf="showTooltip">
            <div style="width:100%">
                <div class="tooltip-label" id="tooltip-label{{zoomView}}{{action.id}}">
                    {{pageContents.lblMGPTwoByTwoStackedActions}}
                </div>
                <div class="close-btn close-tooltip pl-1" (click)="showTooltip = false;">
                    <i class="fa fa-times"></i>
                    <!--<img class="close-btn" style="height:10px;" src="/assets/images/close-btn.png">-->
                </div>
            </div>
            <br /><br />
            <div style="display:flex;flex-wrap:wrap;justify-content:center;width:100%;" class="pt-1 pb-1" id="bubble-c-{{zoomView}}{{action.id}}">
                <ng-container *ngFor="let a of actions; let i = index">
                    <div class="mt-1 gp-action-bubble gp-action-bubble-count"
                         (click)="onCardSelected($event, a)" eyv-draggable [eyv-dragEnabled]="true"
                         [eyv-dragData]="a" [eyv-dragData-size]="size" (onEYVDragStart)="onDragStart($event)"
                         [ngClass]="getClassName(a)" (onClick)="onselectedCard($event,a)">
                        {{a.cardNumber}}
                    </div>&nbsp;
                    <br *ngIf="(i+1) %3 == 0" />
                    <div *ngIf="(i+1) %3 == 0">&nbsp;&nbsp;</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>