import { Component, OnInit, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { VelocityService } from '../../service/velocity.service';
import { BaseComponent } from './../../component/base.component';

@Component({
    //moduleId: module.id.toString(),
    selector: "view-note-dialog-landingPage",
    templateUrl: './view-note.component.html',
    styleUrls: ['./view-note.component.scss']
})

export class ViewNoteDialogComponent extends BaseComponent implements OnInit {
   // videoUrl: string = "";
    @ViewChild('ViewNoteModel', { static: true }) viewNoteModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<any>;
    returnedValue: string = 'closed';
    driverId = 0;
    subDriverId = 0;
    driverName = '';
    subDriverName = '';
    PageContents: any;
    SiteContents: any;
    title = '';
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    notes: any;
    disabled: boolean = false;
    growthPlanId = 0;
    showBlur: boolean = false;
    noNotesAvailable : boolean = false;
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private dialogService: ModalDialogService
    ) {
        super(injector);

        this.driverId = this.injector.get('driverId');
        this.subDriverId = this.injector.get('subDriverId');
        this.subDriverName = this.injector.get('subDriverName');
        this.driverName = this.injector.get('driverName');
        this.PageContents = this.injector.get('PageContents'); 
        this.SiteContents = this.injector.get('SiteContents');
        this.disabled = this.injector.get('disabled');
        this.growthPlanId = this.injector.get('growthPlanId');
        if (this.driverId != 0)
            this.title = this.PageContents.lblViewNoteTitle.replace("#pillarname", this.driverName);
        else
            this.title = this.PageContents.lblViewSubDriverNoteTitle.replace("#subpillarname", this.subDriverName);

    }

    ngOnInit() {
        this.getNotes();

    }

    getNotes() {
        if (this.driverId != 0) {
            var params = {
                'driverId': this.driverId,
                'growthPlanId': this.growthPlanId
            };
            this.velocityService.getDriverNotes(params).subscribe(
                (data: any) => {
                    this.notes = data;
                    if(!this.notes.length)
                        this.noNotesAvailable = true;
                });
        } else {
            var params1 = {
                'subDriverId': +this.subDriverId,
                'growthPlanId': this.growthPlanId
            };
            this.velocityService.getSubDriverNotes(params1).subscribe(
                (data: any) => {
                    this.notes = data;
                    if(!this.notes.length)
                        this.noNotesAvailable = true;
                });
        }
    }

    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next({ 'Notelength': this.notes.length });       
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.viewNoteModal.config = this.config;
        this.viewNoteModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.viewNoteModal.hide();
    }
    
    addNote() {
        this.showBlur = true;

        var inputParams = {
            driverId: this.driverId,
            isLocked: this.disabled,
            gpId: this.growthPlanId,
            driverName: this.driverName,
            noteTitle: this.PageContents.lblNoteTitle,
            notePlaceholder: this.PageContents.lblNotePlaceholder,
            submitBtnlbl: this.SiteContents.buttonSubmit,
            orLbl: this.SiteContents.buttonOr,
            cancelLbl: this.SiteContents.buttonCancel,
            lblcustomActionCardRequired: this.PageContents.lblNoteValidation,
            fromView:true
        }
        if (this.driverId == 0) {
            inputParams['isSubDriver'] = true;
            inputParams['driverId'] = this.subDriverId;
            inputParams['driverName'] = this.subDriverName;
            inputParams['noteTitle'] = this.PageContents.lblAddSubDriverNoteTitle;
        }
        this.dialogService.addNotesAssementModel(inputParams).subscribe(
            (data: any) => {
                this.showBlur = false;
                if (data.action == 'yes') {
                    this.noNotesAvailable = false;
                    this.getNotes();
                }
            }

        );
    }

    editNote(note:any) {
        console.log(note);
        this.showBlur = true;
        console.log(this.driverId);

        var inputParams = {
            isLocked: this.disabled,
            gpId: this.growthPlanId,
            notePlaceholder: this.PageContents.lblNotePlaceholder,
            submitBtnlbl: this.SiteContents.buttonSubmit,
            orLbl: this.SiteContents.buttonOr,
            cancelLbl: this.SiteContents.buttonCancel,
            lblcustomActionCardRequired: this.PageContents.lblNoteValidation,
            fromView:true,
            note:note
        }
        if (this.driverId != 0) {
            inputParams['driverId'] = this.driverId;
            inputParams['driverName'] = this.driverName;
            inputParams['noteTitle'] = this.PageContents.lblNoteTitle;
            inputParams['editNoteTitle'] = this.PageContents.lblEditNoteTitle;
        } else {
            console.log(this.subDriverId);
            inputParams['driverId'] = this.subDriverId;
            inputParams['driverName'] = this.subDriverName;
            inputParams['noteTitle'] = this.PageContents.lblAddSubDriverNoteTitle;
            inputParams['editNoteTitle'] = this.PageContents.lblEditSubDriverNoteTitle;
            inputParams['isSubDriver'] = true;
        }
        this.dialogService.addNotesAssementModel(inputParams).subscribe(
            (data: any) => {
                this.showBlur = false;
                if (data.action == 'yes') {
                    this.getNotes();
                }
            }

        );
    }

    deleteNote(note: any) {
        this.showBlur = true;

        let config: any = {
            type: 'LEAVE_COMMUNITY',
            html: true,
            action: {
                cancel: this.SiteContents.buttonCancel,
                ok: this.SiteContents.buttonContinue,
                or: this.OtherContents.buttonOr
            }
        };
        this.dialogService.confirm(this.PageContents.lblTitleDeleteNote, this.PageContents.lblMessageDeleteNote, config).subscribe((data) => {
            if (data && data === 'yes') {
                if (this.driverId !=0 )
                    this.deleteDriverNoteAPI(note.noteId);
                else
                    this.deleteSubDriverNoteAPI(note.noteId);
            }
            this.showBlur = false;

        });
    }
    deleteDriverNoteAPI(noteId) {
        var params = {
            'noteId': noteId
        };
        this.velocityService.deleteDriverNote(params).subscribe(
            (data: any) => {
                this.getNotes();
            });
    }
    deleteSubDriverNoteAPI(noteId) {
        var params = {
            'noteId': noteId
        };
        this.velocityService.deleteSubDriverNote(params).subscribe(
            (data: any) => {
                this.getNotes();
            });
    }
}
